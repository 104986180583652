import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../utils/actions'
import { Segment, Grid, Ref, Sticky, Header, Form, Divider, Icon, Label, Button, List, Statistic, Dropdown, } from 'semantic-ui-react'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import * as noliAPI from '../../utils/noliAPI'
import * as masks from '../../utils/masks'
import moment from 'moment'
import OrdenadorEnderecos from '../../components/OrdenadorEnderecos'
import OrcamentoItens from '../../components/OrcamentoItens'
import OrcamentoItensCustomizados from '../../components/OrcamentoItensCustomizados'
import _ from 'lodash'
import { withRouter } from 'react-router'
import { money, payment, simulatorCalculate } from '../../utils/helpers' 

class AlterarOrcamento extends React.Component {

  state = {
    orcamento: {
      ordem_servico: "",
      data_transporte: 0,
      cliente: { nome: "", telefone: "", cpf: "", email: "" },
      veiculo_indicado: {},
      enderecos: [],
      distancia_transporte: 0,
      itens: [],
      itens_customizados: [],
      numero_ajudante: 0,
      servicos: {
        montagem_desmontagem: [],
        protecao_itens: {}
      }
    },
    assemblyDisassembly: [],
    protectionItems: [],
    protectionItemSelected: {},
    original: {},
    calcObj: {},
    calcRes: {},
    loading: false,
    finishAction: "",
    finishObs: "",
  }
  contextRef = React.createRef()

  componentDidMount = () => {

    this.props.setPageTitle('Carregando página...')
    if (this.props.match.params.id) {
      noliAPI.buscarOrcamento(this.props.match.params.id)
        .then(res => {
          if (res.data) {
            this.setState({ orcamento: res.data, original: res.data }, this.debounceCalculations)
            this.props.setPageTitle('Alterar Orçamento')
            this.prepareServices()
          }
          else this.props.history.push('/')
        })
    } else {
      this.prepareServices()
      this.props.setPageTitle('Simular Orçamento')
    }
  }

  prepareServices = () => {
    const { protecao_itens, montagem_desmontagem } = this.props.pageInfo
    if (!montagem_desmontagem) this.props.fetchMontagemDesmontagem()
    if (!protecao_itens) this.props.fetchProtecaoItens()

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { protecao_itens, montagem_desmontagem } = this.props.pageInfo
    if (nextProps.pageInfo.montagem_desmontagem) {
      if (nextProps.pageInfo.montagem_desmontagem !== montagem_desmontagem) {
        this.handlePrepareAssemblyDisassembly(nextProps.pageInfo)
      }
    }
    if (nextProps.pageInfo.protecao_itens) {
      if (nextProps.pageInfo.protecao_itens !== protecao_itens) {
        this.handlePrepareProtectionItems(nextProps.pageInfo)
      }
    }
  }

  handlePrepareProtectionItems = (props) => {
    const { protecao_itens } = props
    const { orcamento, protectionItemSelected } = this.state
    const { servicos } = orcamento

    if (protecao_itens) {
      const filterArray = protecao_itens.filter((element) => element.disponivel === 1 )
      const newArray = filterArray.map((element) => {
          return {
            key: element.id, text: element.titulo, value: element.titulo
    
        }
      })

      this.setState({ protectionItems: newArray })
    }
  }

  getProtectionItemSelected = (title: string) => {
    const { protectionItems, protectionItemSelected, orcamento } = this.state
    const { protecao_itens } = this.props.pageInfo
    const { servicos } = orcamento
    const selected = (protectionItems.length > 0 && servicos) ? protecao_itens.filter((element) => element.titulo === title) : []
    return selected
  }

  getAssemblyDisassemblySelected = (item) => {
    const { servicos } = this.state.orcamento
    const selected = servicos && servicos.montagem_desmontagem && servicos.montagem_desmontagem.find((element) => element.id === item.id)
    if (selected) return selected
    return item
  }


  handlePrepareAssemblyDisassembly = (props) => {
    const { montagem_desmontagem } = props


    if (montagem_desmontagem) {
      const newArray = montagem_desmontagem.map((element) => {
        if (element.disponivel) {
          return {
            ...element,
            qtd_item: 0,
            valor_servico: 0
          }
        }
      })

      this.setState({ assemblyDisassembly: newArray })
    }
  }

  handleSingleOrcamentoChange = (e, { name, value }) => this.setState({
    orcamento: {
      ...this.state.orcamento, [name]: value
    },
    loading: true
  }, this.debounceCalculations)

  handleDateOrcamentoChange = (e, { name, value }) => this.setState({
    orcamento: {
      ...this.state.orcamento, [name]: moment(value, 'ddd DD/MM/YYYY HH:mm').format('X')
    },
    loading: true
  }, this.debounceCalculations)

  updateEnderecos = (distancia, enderecos) => this.setState({
    orcamento: {
      ...this.state.orcamento,
      distancia_transporte: distancia,
      enderecos: enderecos
    },
    loading: true
  }, this.debounceCalculations)

  updateItensCustomizados = (val) => this.setState({
    orcamento: {
      ...this.state.orcamento, itens_customizados: val
    },
    loading: true
  }, this.debounceCalculations)

  addOrRemoveItem = (item, qty = 1) => {
    const toAdd = {
      ...item,
      quantidade: 1
    }
    const _init = this.state.orcamento.itens.length > 0 ? [] : [{ ...item, quantidade: 1 }]
    const itens = this.state.orcamento.itens
    const idsArray = this.state.orcamento.itens.map(i => i.item_id)
    const newItens = _.reduce(itens, (acc, i, index, arr) => {

      if (i.id === item.id) { // Se encontrar na lista
        if (i.quantidade > 1) acc.push({ ...i, quantidade: i.quantidade + qty })
        else if (qty > 0) acc.push({ ...i, quantidade: i.quantidade + qty })
      } else { // Se não encontrar na lista
        acc.push(i)
      }
      // Se entrar no último loop e não encontrar no array


      if (index + 1 === arr.length && !idsArray.includes(item.item_id)) acc.push(toAdd)
      return acc
    }, _init)


    this.setState({
      orcamento: {
        ...this.state.orcamento, itens: newItens
      },
      loading: true
    }, this.debounceCalculations)
  }

  createCalcObj = () => {
    const { orcamento } = this.state
    const obj = {
      ajudantes:orcamento.numero_ajudante,
      customizados: orcamento.itens_customizados.map(i => this.prepareCustomItems(i)),
      distancia: Number(orcamento.distancia_transporte),
      enderecos: this.prepareEnderecos(orcamento.enderecos),
      itens: this.prepareItens(orcamento.itens),
      taxaId: orcamento.taxa_orcamento_id,
      ...orcamento?.id ?  orcamento?.servicos?.insurance_cargo[0] ? {withInsuranceCargo: true}  : {withInsuranceCargo: false} : {withInsuranceCargo: true},
      ...orcamento?.servicos?.insurance_cargo && orcamento?.servicos?.insurance_cargo[0] & {insuranceCargoFeeId: orcamento?.servicos?.insurance_cargo[0].id},
    }

    return obj
  }

  prepareCustomItems = (item) => {
    return {
      ...item,
      aereo: item.aereo === 1,
      descricao: item.descricao,
      peso: Number(item.peso),
      altura: Number(item.altura),
      largura: Number(item.largura),
      comprimento: Number(item.comprimento)
    }
  }

  prepareEnderecos = (e) => {
    return e.map(e => {
      return {
        ...e,
        LatLng: {
          latitude: e.latitude,
          longitude: e.longitude
        },
        escadas: e.escadas === 1,
        predio: e.predio === 1,
        sobrado: e.sobrado === 1,
      }
    })
  }

  prepareItens = (itens) => {
    return itens.map(i => {
      return {
        imagem: i.imagem,
        aereo: i.aereo === 1,
        altura: i.altura,
        categoria: i.categoria,
        comprimento: Number(i.comprimento),
        contador: 0, // ??
        descricao: i.descricao,
        desmontavel: i.desmontavel === 1,
        favorito: i.favorito === 1,
        largura: Number(i.largura),
        montado: false,
        na_vertical: i.na_vertical === 1,
        pode_vertical: i.pode_vertical === 1,
        quantidade: i.quantidade,
        suggested_price: i?.suggested_price,
      }
    })
  }

  calculate = () => {
    const calcObj = this.createCalcObj()
    this.setState({ calcObj: calcObj }, () => {
      noliAPI.calcularOrcamento(this.state.calcObj).then((res) => {
        this.setState({ loading: false })
        if (res.status !== 200) {
          return this.props.sendMessage([], "Houve um erro ao realizar o cálculo do orçamento.")
        }
        if (res.data[0]) {
          this.setState({ calcRes: (calcObj.enderecos > 0 || calcObj.distancia > 0 || calcObj.itens.length > 0 || calcObj.customizados.length > 0) ? res.data[0] : {}, finishAction: "", finishObs: "" })
        }

      }).catch(() => {
        this.setState({ loading: false })
        return this.props.sendMessage([], "Houve um erro interno. Tente novamente mais tarde")
      })
    })
  }

  debounceCalculations = _.debounce(() => this.calculate(), 1000)

  handleChangeProtecionItems = (e, { name, value }) => {
    const { orcamento } = this.state
    const findSelected = this.getProtectionItemSelected(value)

    this.setState({
      orcamento: {
        ...orcamento,
        servicos: {
          ...orcamento.servicos,
          protecao_itens: findSelected[0]
        }
      }
    })
  }

  handleAddAssemblyDisassembly = (e, { name, value }) => {
    const element = value
    const { orcamento } = this.state
    const { montagem_desmontagem } = orcamento.servicos

    const existsItem = orcamento.servicos && montagem_desmontagem && montagem_desmontagem.find(item => item.id === element.id);
    if (existsItem) {
      const updateItem = montagem_desmontagem.map((item) => {
        if (item.id === element.id) {
          item.qtd_item += 1
          item.valor_servico += Number(item.valor_base)
          //calculateTotalValue(item.valor_base)
        }
        return item
      })

      this.setState({
        orcamento: {
          ...orcamento,
          servicos: {
            ...orcamento.servicos,
            montagem_desmontagem: updateItem
          }
        }
      })
    } else {
      const newData = new Object({
        ...element,
        qtd_item: 1,
        valor_servico: Number(element.valor_base)
      })

      this.setState({
        orcamento: {
          ...orcamento,
          servicos: {
            ...orcamento.servicos,
            montagem_desmontagem: [...montagem_desmontagem, newData]
          }
        }
      })
    }
  }

  handleRemoveAssemblyDisassembly = (e, { name, value }) => {
    const element = value
    const { orcamento } = this.state
    const { montagem_desmontagem } = orcamento.servicos

    if (!element.qtd_item) return
    if (element.qtd_item > 1) {
      const updateData = montagem_desmontagem.map((item) => {
        if (item.id === element.id) {
          item.qtd_item -= 1
          item.valor_servico -= Number(item.valor_base)
          //calculateTotalValue(- Number(item.valor_base))
        }
        return item
      })

      this.setState({
        orcamento: {
          ...orcamento,
          servicos: {
            ...orcamento.servicos,
            montagem_desmontagem: updateData
          }
        }
      })

    } else {

      //calculateTotalValue(- Number(element.valor_base))
      const removeItem = montagem_desmontagem.filter(item => {
        return item.id !== element.id
      })

      this.setState({
        orcamento: {
          ...orcamento,
          servicos: {
            ...orcamento.servicos,
            montagem_desmontagem: removeItem
          }
        }
      })
    }
  }





  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleFinish = (e) => {
    //console.log(e); //TODO
  }


  RenderHelpers = () => {
    const { numero_ajudante } = this.state.orcamento
    return (
      <Form.Group>
        <Form.Field
          control={Label}
          content={1}
          color="green"
          size="big"
          width="6"
        />
        <Form.Field
          control={Label}
          content={"Ajudantes"}
          basic
          size="big"
          width="4"
          style={{ width: '100%', textAlign: "center" }}
        />
        <Form.Field
          width="4"
        />
        <Form.Field
          control={Button.Group}
          width="2"
        >
          <Button icon="minus" color="red" type="button"
            onClick={this.handleSingleOrcamentoChange}
            value={numero_ajudante - 1}
            name="numero_ajudante"
            disabled={numero_ajudante === 0}
          />
          <Button.Or text={numero_ajudante} />
          <Button icon="plus" color="green" type="button"
            onClick={this.handleSingleOrcamentoChange}
            value={numero_ajudante + 1}
            name="numero_ajudante"
            disabled={numero_ajudante === 10}
          />
        </Form.Field>
      </Form.Group>
    )
  }

  RenderServiceAssemblyDisassembly = () => {
    const { assemblyDisassembly, orcamento } = this.state
    return assemblyDisassembly.map((item, index) => {
      const element = this.getAssemblyDisassemblySelected(item) 

      return (
        <Form.Group key={index} >
          <Form.Field
            control={Label}
            content={item?.id + 1}
            color="green"
            size="big"
            width="6"
          />
          <Form.Field
            control={Label}
            content={item?.titulo}
            basic
            size="big"
            width="4"
            style={{ width: '100%', textAlign: "center" }}
          />
          <Form.Field
            width="4"
          />
          <Form.Field
            control={Button.Group}
            width="2"
          >
            <Button icon="minus" color="red" type="button"
              onClick={this.handleRemoveAssemblyDisassembly}
              value={element}
              name="disassembly"
            //disabled={element.qtd_item === 0}
            />
            <Button.Or text={element?.qtd_item} />
            <Button icon="plus" color="green" type="button"
              onClick={this.handleAddAssemblyDisassembly}
              value={element}
              name="disassembly"
            //disabled={element.qtd_item === 30}
            />
          </Form.Field>
        </Form.Group>
      )
    })

  }


  RenderServiceProtectionItems = () => {
    const { protectionItems, orcamento, protectionItemSelected } = this.state

    const { servicos } = orcamento
    if (!servicos.protecao_itens || !protectionItems) return
    const selected = this.getProtectionItemSelected(servicos.protecao_itens.titulo)

    return (
      <Form.Group>
        <Form.Field
          control={Label}
          content={4}
          color="green"
          size="big"
          width="6"
        />
        <Form.Field
          control={Label}
          content={"Proteção dos itens"}
          basic
          size="big"
          width="4"
          style={{ width: '100%', textAlign: "center" }}
        />
        <Form.Field
          width="3"
        />
        <Form.Field width="3">
          <Button size="mini" fluid type="button">
            <Dropdown
              options={protectionItems} 
              size="tiny"
              width="4"
              value={selected[0] ? selected[0].titulo : protectionItems[0] ? protectionItems[0].value : 'Selecione uma opção'}
              //ordem={protectionItems.value}
              disabled={false}
              onChange={this.handleChangeProtecionItems}
              item
              fluid
              search
            />
          </Button>
        </Form.Field>
      </Form.Group>
    )
  }

  render() {

    const { orcamento, loading, calcRes, original, finishAction, finishObs } = this.state
    const { ordem_servico, data_transporte, created_at, cliente, enderecos, itens, numero_ajudante, distancia_transporte, veiculo_indicado, servicos, pagamentos } = orcamento
    const customizados = orcamento.itens_customizados ? orcamento.itens_customizados : []
    const created_at_format = moment(created_at).format('ddd DD/MM/YYYY hh:mm')

    //const valor_original = original.pagamentos ? original.pagamentos.reduce((a,b)=> a+b.valor, 0).toFixed(2) : 0

    //const valor_original = original.valor ? original.valor.toFixed(2) : 0
    const valor_original = original.valor ? simulatorCalculate(original.pagamentos) : 0
    const valor_calculo = calcRes.valorTotal ? Number(calcRes.valorTotal - valor_original) : 0
    const valor_atual = Number(simulatorCalculate(pagamentos, true, servicos, calcRes.volumeTotal) + valor_calculo).toFixed(2)

    //const valor_reajuste = calcRes.valorTotal ? (calcRes.valorTotal - valor_original).toFixed(2) : 0

    const valor_reajuste = calcRes.valorTotal ? (valor_atual - valor_original).toFixed(2) : 0

    //console.log('valores ', valor_original, valor_atual, valor_reajuste, valor_calculo, calcRes, servicos, orcamento)

    const veiculo_ideal = calcRes.veiculo ? (valor_reajuste > 0 || valor_reajuste < 0) ? calcRes.veiculo : veiculo_indicado : veiculo_indicado

    // const titulo_reajuste = valor_reajuste > 0 ? 'Valor a ser pago' : 'Valor a ser estornado'
    const finishOpts = valor_reajuste < 0
      ? [{ text: 'Estorno', value: 'estorno' }]
      : [{ text: 'Cartão', value: 'cartao' }, { text: 'Boleto', value: 'boleto' }]

    return (
      <Grid padded>
        <Ref innerRef={this.contextRef}>
          <Grid.Row>
            <Grid.Column mobile="16" tablet="13" computer="13">
              <Segment color="blue">
                <Header as='h1' icon='wordpress forms' content={this.props.match.params.id ? "Alterar orçamento" : "Simular orçamento"} subheader={"Ordem #" + ordem_servico} />
                <Form>
                  <Segment basic>
                    <Form.Group widths="equal">
                      <Form.Field
                        control={Form.Input}
                        label="Ordem de Serviço"
                        type="text"
                        name="ordem_servico"
                        value={"#" + ordem_servico}
                      />
                      <Form.Field
                        control={Form.Input}
                        label="Data de Criação"
                        type="text"
                        name="created_at"
                        value={created_at_format}
                      />
                      <Form.Field
                        control={DateTimeInput}
                        label="Data de Agendamento"
                        type="text"
                        value={data_transporte ? moment.unix(data_transporte).utcOffset(-3).format('ddd DD/MM/YYYY  HH:mm') : created_at_format}
                        dateTimeFormat="ddd DD/MM/YYYY HH:mm"
                        localization="pt-br"
                        name="data_transporte"
                        onChange={this.handleDateOrcamentoChange}
                        initialDate={new Date()}
                        clearable
                      />
                      <Form.Field
                        control={Form.Input}
                        label="Tempo para Transporte"
                        type="text"
                        name="created_at"
                        value={moment().to(data_transporte ? moment.unix(data_transporte) : created_at_format)}
                      />
                    </Form.Group>
                  </Segment>
                  <Divider horizontal>
                    <Header as='h4' color="blue"><Icon name='user' />Informações do Usuário</Header>
                  </Divider>
                  <Segment basic>
                    <Form.Group widths='equal'>
                      <Form.Field
                        control={Form.Input}
                        label="Nome"
                        value={cliente.nome}
                      />
                      <Form.Field
                        control={Form.Input}
                        label="CPF"
                        value={cliente.cpf ? masks.maskCpf(cliente.cpf) : "CPF não fornecido"}
                      />
                      <Form.Field
                        control={Form.Input}
                        label="Telefone"
                        value={cliente.telefone ? masks.maskTelefone(cliente.telefone) : "Telefone não fornecido"}
                      />
                      <Form.Field
                        control={Form.Input}
                        label="Email"
                        value={cliente.email !== "" ? cliente.email : "Email não fornecido"}
                      />
                    </Form.Group>
                  </Segment>
                  <Divider horizontal>
                    <Header as='h4' color="blue"><Icon name='map' />Endereços do Transporte ({enderecos.length})</Header>
                  </Divider>
                  <Segment basic>
                    <OrdenadorEnderecos enderecos={enderecos} onCalculate={this.updateEnderecos} distancia={Number(distancia_transporte)} />
                  </Segment>
                  <Divider horizontal>
                    <Header as='h4' color="blue"><Icon name='boxes' />Itens ({itens.length})</Header>
                  </Divider>
                  <Segment basic>
                    <OrcamentoItens
                      ItemList={itens}
                      onAddOrRemove={this.addOrRemoveItem}
                    />
                  </Segment>
                  <Divider horizontal>
                    <Header as='h4' color="blue"><Icon name='dolly flatbed' />Customizados ({customizados.length})</Header>
                  </Divider>
                  <Segment basic>
                    <OrcamentoItensCustomizados
                      ItemList={customizados}
                      onChange={this.updateItensCustomizados}
                    />
                  </Segment>
                  <Divider horizontal>
                    <Header as='h4' color="blue"><Icon name='ambulance' />Outros Serviços</Header>
                  </Divider>
                  <Segment basic>
                    {this.RenderHelpers()}
                    {this.RenderServiceAssemblyDisassembly()}
                    {this.RenderServiceProtectionItems()}

                  </Segment>
                </Form>

              </Segment>
            </Grid.Column>

            <Sticky as={Grid.Column} mobile="16" tablet="3" computer="3" context={this.contextRef}>
              <Segment.Group>
                <Segment color='grey' inverted loading={loading} textAlign="center">
                  <Header as="h5" icon="edit outline" content="Orçamento Re-Calculado" />
                  <Statistic size="tiny" inverted color="blue">
                    <Statistic.Label>Valor original</Statistic.Label>
                    <Statistic.Value>
                      {money(valor_original)}
                    </Statistic.Value>
                  </Statistic>
                  <div>

                    {(valor_atual > 0 || valor_atual < 0) &&
                      <Statistic size="tiny" inverted color="yellow">
                        <Statistic.Label>Valor atual</Statistic.Label>
                        <Statistic.Value>
                          {money(valor_atual)}
                        </Statistic.Value>
                      </Statistic>
                    }


                    <>
                      <br />
                      <Statistic size="mini" inverted color={valor_reajuste > 0 ? 'green' : valor_reajuste < 0 ? 'red' : 'white'}>
                        <Statistic.Label>Valor do reajuste</Statistic.Label>
                        <Statistic.Value>
                          {money(valor_reajuste)}
                        </Statistic.Value>
                      </Statistic>
                    </>


                  </div>


                </Segment>

                {valor_reajuste > 0 || valor_reajuste < 0 &&
                  <Segment color='grey' inverted>
                    <Form size="mini" inverted onSubmit={this.handleFinish}>
                      <Form.Field
                        control={Form.Select}
                        options={finishOpts}
                        placeholder="Forma de pagamento"
                        name="finishAction"
                        onChange={this.handleChange}
                      />
                      <Form.Field
                        control={Form.Input}
                        placeholder={finishAction === 'cartao' ? 'Link de cobrança' : finishAction === 'boleto' ? "Linha digitável do boleto" : finishAction === 'estorno' ? "Observações" : "Selecione uma opção acima"}
                        disabled={finishAction !== 'cartao' && finishAction !== 'boleto' && finishAction !== 'estorno'}
                        name="finishObs"
                        value={finishObs}
                        onChange={this.handleChange}
                      />
                      <Form.Field
                        control={Form.Button}
                        type="submit"
                        content="Salvar Alterações"
                        fluid
                        size="mini"
                        color="orange"
                        disabled={(finishAction !== 'cartao' && finishAction !== 'boleto' && finishAction !== 'estorno') || finishObs === ""}
                      />
                    </Form>
                  </Segment>
                }
                <Segment color="black" inverted>
                  <List inverted>
                    <List.Item header="PARÂMETROS" />
                    <List.Item icon="road" content={"Distância : " + Number(distancia_transporte).toFixed(2) + " km"} />
                    <List.Item icon="boxes" content={"Itens: " + orcamento.itens.length + " (qtd " + orcamento.itens.reduce((a, b) => a + b.quantidade, 0) + ")"} />
                    <List.Item icon="dolly flatbed" content={"Customizados : " + orcamento.itens_customizados.length + " (qtd " + orcamento.itens_customizados.reduce((a, b) => a + b.quantidade, 0) + ")"} />
                    <List.Item icon="user" content={"Ajudantes Selecionados : " + orcamento.numero_ajudante} />
                    <List.Item icon="user" content={"Ajudantes Recomendados : " + (calcRes.ajudantesRecomendado ? calcRes.ajudantesRecomendado : 0)} />
                    {Object.keys(veiculo_ideal).length > 0 &&
                      <List.Item icon="truck" content={"Veículo ideal : " + veiculo_ideal.descricao} />
                    }
                  </List>
                </Segment>
              </Segment.Group>
            </Sticky>
          </Grid.Row>
        </Ref>
      </Grid>
    )
  }
}

const mapStateToProps = ({ pageInfo, itens }) => {
  return {
    pageInfo, itens
  };
};

export default connect(mapStateToProps, actions)(withRouter(AlterarOrcamento))
