import _ from 'lodash'
import React, { Component } from 'react'
import { Search} from 'semantic-ui-react'

const initialState = { isLoading: false, results: [], value: '' }

class DashboardSearch extends Component {
  state = initialState

  handleResultSelect = (e, { result }) => {
    this.setState({ value: "" })
    this.props.onSelect(result)
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })
    let list = this.props.transportes

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState)

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result.find)

      this.setState({
        isLoading: false,
        results: _.filter(list, isMatch).map(r=> {return {
          title: r.title,
          description: r.description,
          price: r.price,
          ordem: r.ordem_servico,
          lat: (r.rota_transportador[0]) ? r.rota_transportador[0].latitude : 0,
          lng: (r.rota_transportador[0]) ? r.rota_transportador[0].longitude: 0
        }})

      })
    }, 300)
  }

  render() {
    const { isLoading, value, results } = this.state

    return (
      <Search
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={_.debounce(this.handleSearchChange, 500, {
          leading: true,
        })}
        results={results}
        value={value}
        disabled={this.props.transportes.length === 0}
        fluid
        input={{fluid:true}}
        placeholder="Busque por alguma palavra chave"
        label="Buscar"
        noResultsMessage="Não foram encontrados dados que correspondem à palavra digitada."
      />
    )
  }
}

export default DashboardSearch
