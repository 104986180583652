import React from 'react'
import { Menu, Icon, Sidebar, Dropdown } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import * as actions from '../utils/actions'
import { connect } from 'react-redux'

const dados_veiculos = [
  { key: 'tipo', icon: 'dolly flatbed', text: 'Tipos de Veículo', value: '/dados_veiculos/tipos' },
  { key: 'marca', icon: 'registered outline', text: 'Marcas e Modelos', value: '/dados_veiculos/marcas' },
  { key: 'categoria-noli', icon: 'truck', text: 'Veículos Noli', value: '/dados_veiculos/noli' },
]

const dados_servicos = [
  { key: 'protecao_itens', icon: 'boxes', text: 'Proteção de Itens', value: '/cadastro/servicos/protecaoItens' },
  { key: 'montagem_desmontagem', icon: 'boxes', text: 'Montagem/Desmontagem', value: '/cadastro/servicos/montagemDesmontagem' }
]

const dados_caixas = [
  { key: 'box_packages', icon: 'boxes', text: 'Pacotes', value: '/cadastro/servicos/protecaoItens' },
  { key: 'box_periods', icon: 'boxes', text: 'Períodos', value: '/cadastro/servicos/montagemDesmontagem' }
]

class SideMenu extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      editOpen: false,
      configOpen: false,
      boxOpen: false,
      transportersOpen: false,
      userLoaded: false
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user.permissionsLoaded && this.props.user.permissionsLoaded)
      this.setState({ userLoaded: true })
  }

  goto = (route, title) => {
    this.setState({ editOpen: false, configOpen: false, boxOpen: false, transportersOpen: false })
    this.props.hide()
    this.props.setPageTitle(title)
    this.props.history.push(route)
  }

  dropDownGoto = (e, { value, options, name }) => {
    this.goto(value, options.filter(d => d.value === value)[0].text)
  }

  render() {
    const {
      modulo_itens,
      modulo_orcamentos,
      modulo_taxas,
      modulo_clientes,
      modulo_veiculos,
      modulo_cadastro,
      modulo_transportadores,
      modulo_permissoes,
      modulo_funcionarios,
      modulo_cupons,
      modulo_servicos,
      modulo_caixas
    } = this.props.user.permissions
    const { userLoaded } = this.state
    if (userLoaded)
      return (
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.props.hide}
          vertical
          visible={this.props.visible}
          width='thin'
          style={{ width: 170 }}
        >
          {modulo_orcamentos && modulo_orcamentos.read &&
            <Menu.Item as="a" onClick={() => this.goto("/", " Mudanças e carretos")}>
              <Icon name='dashboard' />
              Mudanças
            </Menu.Item>
          }
          {modulo_transportadores && modulo_transportadores.read &&
            <Dropdown
              item
              pointing="left"
              direction="right"
              icon="truck"
              text="Transportadores"
              open={this.state.transportersOpen}
              onOpen={() => this.setState({ transportersOpen: true,configOpen: false, editOpen: false })}
              onClose={() => this.setState({transportersOpen: false, configOpen: false, editOpen: false })}
            >
              <Dropdown.Menu style={{ minWidth: 130 }}>
                <Dropdown.Item
                  icon="truck"
                  text="Cadastros"
                  onClick={() => this.goto("/transportadores", "Transportadores cadastrados")}
                />

                <Dropdown.Item
                  icon="truck"
                  text="Ajudantes Cadastrados"
                  onClick={() => this.goto("/helpers", "Ajudantes cadastrados")}
                />
              </Dropdown.Menu>
            </Dropdown>
          }
          {modulo_orcamentos && modulo_orcamentos.read &&
            <Menu.Item as="a" onClick={() => this.goto("/orcamentos", "Orçamentos")}>
              <Icon name='clipboard' />
              Orçamentos
            </Menu.Item>
          }
          {modulo_caixas && modulo_caixas.read &&
            <Dropdown
              item
              pointing="left"
              direction="right"
              icon="box"
              text="Caixas"
              open={this.state.boxOpen}
              onOpen={() => this.setState({ boxOpen: true, configOpen: false, editOpen: false })}
              onClose={() => this.setState({ boxOpen: false, configOpen: false, editOpen: false })}
            >
              <Dropdown.Menu style={{ minWidth: 130 }}>
                {
                  <Dropdown.Item
                    icon="boxes"
                    text="Dashboard caixas"
                    onClick={() => this.goto("/box/dashboard", "Dashboard Caixas")}
                  />
                }
                {modulo_caixas && modulo_caixas.read &&
                  <Dropdown.Item
                    icon="boxes"
                    text="Aluguel de caixas"
                    onClick={() => this.goto("/box", "Cadastrar e Alterar Itens")}
                  />
                }
              </Dropdown.Menu>
            </Dropdown>
          }
          {modulo_clientes && modulo_clientes.read &&
            <Menu.Item as="a" onClick={() => this.goto("/clientes", "Clientes")}>
              <Icon name='users' />
              Clientes
            </Menu.Item>
          }
          {modulo_cadastro && modulo_cadastro.read &&
            <Dropdown
              item
              pointing="left"
              direction="right"
              icon="edit"
              text="Cadastro"
              open={this.state.editOpen}
              onOpen={() => this.setState({ editOpen: true, configOpen: false })}
              onClose={() => this.setState({ editOpen: false, configOpen: false })}
            >
              <Dropdown.Menu style={{ minWidth: 130 }}>
                {modulo_cupons && modulo_cupons.read &&
                  <Dropdown.Item
                    icon="ticket"
                    text="Cupons"
                    onClick={() => this.goto("/cadastro/cupons", "Cadastrar e Alterar Cupons de Desconto")}
                  />
                }
                {modulo_itens && modulo_itens.read &&

                  <Dropdown.Item>
                    <Icon name="dolly flatbed" />
                    <Dropdown
                      text="Itens"
                      onChange={this.dropDownGoto}
                      direction="right"
                    >
                      <Dropdown.Menu style={{ minWidth: 130 }}>
                        <Dropdown.Item
                          icon="tv"
                          text="De mudança"
                          onClick={() => this.goto("/cadastro/itens", "Cadastrar e Alterar Itens")}
                        />
                        <Dropdown.Item
                          icon="shopping cart"
                          text="Para venda"
                          onClick={() => this.goto("/cadastro/productsforsale", "Cadastrar e Alterar Produtos para venda")}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Dropdown.Item>

                }
                {modulo_taxas && modulo_taxas.read &&
                  <Dropdown.Item
                    icon="dollar sign"
                    text="Taxas"
                    onClick={() => this.goto("/cadastro/taxa", "Cadastrar e Alterar Taxas Noli")}
                  />
                }

                {modulo_servicos && modulo_servicos.read &&
                  <Dropdown.Item>
                    <Icon name="dolly flatbed" />
                    <Dropdown
                      text="Serviços"
                      onChange={this.dropDownGoto}
                      //options={dados_servicos}
                      direction="right"
                    >
                      <Dropdown.Menu style={{ minWidth: 130 }}>
                        <Dropdown.Item
                          icon="boxes"
                          text="Proteção de Itens"
                          onClick={() => this.goto("/cadastro/servicos/protecaoItens", "Proteção de Itens")}
                        />
                        <Dropdown.Item
                          icon="wrench"
                          text="Montagem/Desmontagem"
                          onClick={() => this.goto("/cadastro/servicos/montagemDesmontagem", "Montagem/Desmontagem")}
                        />
                        <Dropdown.Item
                          icon="boxes"
                          text="Parceiros"
                          onClick={() => this.goto("/cadastro/servicos/parceiros", "Parceiros")}
                        />
                        <Dropdown.Item>
                          <Icon name="boxes" />
                          <Dropdown
                            text="Caixas"
                            onChange={this.dropDownGoto}
                            direction="right"
                          >

                            <Dropdown.Menu style={{ minWidth: 130 }}>
                              <Dropdown.Item
                                icon="boxes"
                                text="Pacotes"
                                onClick={() => this.goto("/cadastro/servicos/box/packages", "Pacotes")}
                              />
                              <Dropdown.Item
                                icon="boxes"
                                text="Períodos"
                                onClick={() => this.goto("/cadastro/servicos/box/periods", "Períodos")}
                              />
                              <Dropdown.Item
                                icon="cogs"
                                text="Controle de caixas"
                                onClick={() => this.goto("/cadastro/servicos/box/stock/control", "Controle de caixas")}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Icon name="boxes" />
                          <Dropdown
                            text="Mudanças"
                            onChange={this.dropDownGoto}
                            direction="right"
                          >

                            <Dropdown.Menu style={{ minWidth: 130 }}>
                              <Dropdown.Item
                                icon="boxes"
                                text="Bloqueio de datas"
                                onClick={() => this.goto("/cadastro/servicos/mudancas/datas/bloqueadas", "Bloqueio de datas agendadas")}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Dropdown.Item>
                }

                {modulo_veiculos && modulo_veiculos.read &&
                  <Dropdown.Item>
                    <Icon name="car" />
                    <Dropdown
                      text="Veículo"
                      onChange={this.dropDownGoto}
                      options={dados_veiculos}
                      direction="right"
                    />
                  </Dropdown.Item>
                }
                {modulo_funcionarios && modulo_funcionarios.read &&
                  <Dropdown.Item
                    icon="users"
                    text="Funcionários"
                    onClick={() => this.goto("/cadastro/funcionario", "Cadastrar e Alterar Informações de Funcionários")}
                  />
                }
                {modulo_permissoes && modulo_permissoes.read &&
                  <Dropdown.Item
                    icon="unlock"
                    text="Permissões"
                    onClick={() => this.goto("/cadastro/permissoes", "Cadastrar e Alterar Permissões de Funcionários")}
                  />
                }
              </Dropdown.Menu>
            </Dropdown>
          }
          <Dropdown
            item
            pointing="left"
            direction="right"
            icon="cog"
            text="Configurações"
            open={this.state.configOpen}
            onOpen={() => this.setState({ configOpen: true, editOpen: false })}
            onClose={() => this.setState({ editOpen: false, configOpen: false })}
          >
            <Dropdown.Menu style={{ minWidth: 130 }}>
              <Dropdown.Item
                icon="user circle outline"
                text="Dados Pessoais"
                onClick={() => this.goto("/configuracoes/dadospessoais", "Alterar Dados Pessoais")}
              />
              <Dropdown.Item
                icon="key"
                text="Alterar Senha"
                onClick={() => this.goto("/configuracoes/novasenha", "Alterar Senha")}
              />
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item as='a' onClick={this.props.logout}>
            <Icon name='sign-out' />
            Logout
          </Menu.Item>
        </Sidebar >
      )
    else return null
  }
}

const mapStateToProps = ({ pageInfo }) => {
  return {
    pageInfo
  };
};


export default connect(mapStateToProps, actions)(withRouter(SideMenu))
