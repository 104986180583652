import * as noliAPI from './noliAPI'
import * as masks from './masks'
import formatDate from './formatDate'
import _ from 'lodash'
import { getCategoryFlagByColor } from './helpers'
import { FETCH_TRANSPORTERS_CONTACT_CATEGORY } from './reducer'

const SET_PAGE_TITLE = "SET_PAGE_TITLE"
const SET_EXPIRATION = "SET_EXPIRATION"
const SEND_MESSAGE = "SEND_MESSAGE"
const RESET_MESSAGE = "RESET_MESSAGE"
const ENABLE_DIMMER = "ENABLE_DIMMER"
const DISABLE_DIMMER = "DISABLE_DIMMER"
const SET_PERMISSIONS_DATA = "SET_PERMISSIONS_DATA"

export const setPageTitle = (title) => dispatch => {
  dispatch({
    type: SET_PAGE_TITLE,
    payload: title
  })
}

export const setAuthExpiration = (exp) => dispatch => {
  dispatch({
    type: SET_EXPIRATION,
    payload: exp
  })
}

const _msgProps = { icon: "warning sign", error: true, size: "big" }

export const sendMessage = (message, header, customProps = _msgProps, dimmed = true) => dispatch => {
  let _msg = {
    props: { ...customProps, header: header },
    msgs: typeof message === "string" ? [message] : message
  }
  dispatch({
    type: ENABLE_DIMMER,
    payload: dimmed
  })
  dispatch({
    type: SEND_MESSAGE,
    payload: _msg
  })

}

export const resetMessage = () => dispatch => {
  dispatch({ type: RESET_MESSAGE })
  dispatch({ type: DISABLE_DIMMER })
}

export const disableDimmer = () => dispatch => {
  dispatch({ type: DISABLE_DIMMER })
}

export const fetchPermissionsData = () => async dispatch => {
  const requests = [
    noliAPI.buscarGruposPermissao(),
    noliAPI.buscarPermissoesAcesso(),
  ]
  Promise.all(requests).then(res => {
    let payload = {}
    payload.gruposOpt = res[0].data.map(g => { return { text: g.descricao, value: g.id.toString() } })
    payload.acessosOpt = res[1].data.map(a => { return { text: a.descricao, value: a.id.toString() } })
    payload.grupos = res[0].data
    payload.acessos = res[1].data
    dispatch({
      type: SET_PERMISSIONS_DATA,
      payload: payload
    })
  })
}



const FETCH_CLIENTES_ORCAMENTOS = "FETCH_CLIENTES_ORCAMENTOS"



export const fetchClientesOrcamentos = (all = true, open = true, canceled = false) => async dispatch => {

  let request = all ? noliAPI.buscarClientesOrcamento() : canceled ? noliAPI.buscarClientesOrcamentosCancelados() : noliAPI.buscarClientesOrcamento(false, open)
  return request.then(res => {
    let payload
    if (res.data.data)
      payload = res.data.data.map(function (c) {
        return {
          title: c.nome + " (N" + c.id + ")",
          description: c.email,
          price: masks.maskTelefone(c.telefone),
          key: c.id,
          find: c.nome + ' ' + c.telefone + ' ' + c.email + 'N' + c.id,
          ...c,
        }
      })
    dispatch({
      type: FETCH_CLIENTES_ORCAMENTOS,
      payload: payload
    })

  })
}

const FETCH_CLIENTES_RENT_BOX = "FETCH_CLIENTES_RENT_BOX"

export const rentBoxOrdersUsers = (all = true, open = true, canceled = false) => async dispatch => {
  let request = all ? noliAPI.rentBoxOrdersOfUsers() : canceled ? noliAPI.rentBoxOrdersCanceledOfUsers() : noliAPI.rentBoxOrdersOpenOfUsers(open) 
  return request.then(res => {
    if (res.status !== 200) {
      return dispatch({
        type: FETCH_CLIENTES_RENT_BOX,
        payload: []
      })
    }
    let payload
    if (res.data.data)
      payload = res.data.data.map(function (c) {
        return {
          title: c.nome + " (N" + c.id + ")",
          description: c.email,
          price: masks.maskTelefone(c.telefone),
          key: c.id,
          find: c.nome + ' ' + c.telefone + ' ' + c.email + 'N' + c.id,
          ...c,
        }
      })
    dispatch({
      type: FETCH_CLIENTES_RENT_BOX,
      payload: payload
    })

  })
}

const FETCH_SEARCHED_TRANSPORTADORES = "FETCH_SEARCHED_TRANSPORTADORES"
const FETCH_AGREE_TRANSPORTADORES = "FETCH_AGREE_TRANSPORTADORES"

export const fetchTransportadores = (params) => async dispatch => {
  const request = params.agree ? noliAPI.buscarTransportadoresConcordam(params.agree) : noliAPI.buscarTransportadoresCampoValor(params.type, params.value)
  return request.then(res => {
    if (res.status === 200) {
      if (res.data.data) {
        let payload = res.data.data ? res.data.data.map((t, i) => {
          return {
            ...t,
            title: "" + t.nome,
            price: masks.maskTelefone(t.telefone),
            key: i,
            description: t.email,
          }
        }) : []
        if (!params.agree)
          dispatch({ 
            type: FETCH_SEARCHED_TRANSPORTADORES,
            payload: payload
          })
        else
          dispatch({
            type: FETCH_AGREE_TRANSPORTADORES,
            payload: payload,
            agree: params.agree
          })
        return res
      }
    }
  })
}


export const clearSearchedTransportadores = () => dispatch => {
  dispatch({
    type: FETCH_SEARCHED_TRANSPORTADORES,
    payload: []
  })
}

const SET_TRANSPORTADORES_FILTERS = "SET_TRANSPORTADORES_FILTERS"
const SET_DADOS_VEICULOS = "SET_DADOS_VEICULOS"

export const fetchTransportadoresFilters = () => async dispatch => {
  const requests = [
    noliAPI.buscarTiposVeiculos(),
    noliAPI.buscarMarcasVeiculos(),
    noliAPI.buscarCoresVeiculos(),
    noliAPI.buscarCarrocerias(),
    noliAPI.buscarTodosModelos(),
    noliAPI.buscarCategoriasNoli(),
    noliAPI.buscarMarcas(),
    noliAPI.buscarServicosTransportador(),
    noliAPI.buscarEstados(),
  ]
  Promise.all(requests).then(res => {
    const tipos = res[0].data.data
    const marcasVeiculos = res[1].data.data // Marcas que possuem veículos cadastrados
    const cores = res[2].data.data
    const carrocerias = res[3].data.data
    const modelos = res[4].data.data
    const categoriaNoli = res[5].data.data
    const marcas = res[6].data.data
    const tipo_servicos = res[7].data.data
    const estados = res[8].data

    let payload = {
      veiculos: [{ value: 0, text: "Tipo de veículo" }, { value: 2, text: "Carroceria" }, { value: 1, text: "Marca" }],
      campos: [
        { value: 'telefone', text: "Telefone" },
        { value: 'nome', text: "Nome" },
        { value: 'cpf', text: "CPF" },
      ],
      tipo_rntrc: [
        { value: "CTC", text: "CTC" },
        { value: "ETC", text: "ETC" },
        { value: "TAC", text: "TAC" }
      ],
      anos: formatDate.yearRanges(1980, new Date().getYear() + 1901)
        .map(y => { return { text: y, value: y.toString() } }),
      tipos: tipos.map(t => { return { text: t.descricao, value: t.id } }),
      marcasVeiculos: marcasVeiculos.map(m => { return { text: m.descricao, value: m.id } }),
      cores: cores.map(c => { return { text: c.descricao, value: c.id } }),
      carrocerias: carrocerias.map(t => { return { text: t.descricao, value: t.id } }),
      categoriaNoli: categoriaNoli.map(t => { return { text: t.descricao, value: t.id } }),
      marcas: marcas.map(m => { return { text: m.descricao, value: m.id } }),
      tipo_servicos: tipo_servicos.map(s => { return { text: s.descricao, value: s.id } }),
      enderecos: {
        estados: estados ? estados.map(s => { return { text: s.nome, value: s.sigla } }) : []
      }
    }
    dispatch({
      type: SET_TRANSPORTADORES_FILTERS,
      payload: payload
    })
    dispatch({
      type: SET_DADOS_VEICULOS,
      payload: {
        tipos: tipos,
        marcasVeiculos: marcasVeiculos,
        carrocerias: carrocerias,
        modelos: modelos,
        marcas: marcas,
        categoriaNoli: categoriaNoli,
      }
    })
  })
}




export const fetchTransportersContactCategory = () => async dispatch => {
  noliAPI.buscarCategoriasDeContatoDeTransportadores().then(res => {
    dispatch({
      type: FETCH_TRANSPORTERS_CONTACT_CATEGORY,
      payload: res.data.data.map(t => {
        return {
          key: t.id,
          id: t.id,
          text: t.description,
          value: t.description,
          color: t.color_flag,
          image: { avatar: true, src: getCategoryFlagByColor(t.color_flag) },
        }
      })
    })
  })
}



const FETCH_TIPOS_CUPOM = "FETCH_TIPOS_CUPOM"

export const fetchTiposCupom = () => async dispatch => {
  noliAPI.buscarTiposCupom().then(res => {
    dispatch({
      type: FETCH_TIPOS_CUPOM,
      payload: res.data.data.map(t => { return { text: t.descricao, value: t.id } })
    })
  })
}


const FETCH_TAXAS = "FETCH_TAXAS"

export const fetchTaxas = () => async dispatch => {
  noliAPI.buscarTaxasOrcamento().then(res => {
    if (res.msg === "Sucesso")
      dispatch({
        type: FETCH_TAXAS,
        payload: res.data
      })
  })
}

const FETCH_RENT_BOX_STOCK_CONTROL = "FETCH_RENT_BOX_STOCK_CONTROL"

export const fetchRentBoxStockControl = (payload: {}) => async dispatch => {
  noliAPI.findRentBoxStockControl(payload).then(res => {
    if (res.status === 200)
      dispatch({
        type: FETCH_RENT_BOX_STOCK_CONTROL,
        payload: res.data
      })
  })
} 

const FETCH_PROTECAO_ITENS = "FETCH_PROTECAO_ITENS"

export const fetchProtecaoItens = () => async dispatch => {
  noliAPI.buscarServicosProtecao().then(res => {
    if (res.status === 200)
      dispatch({
        type: FETCH_PROTECAO_ITENS,
        payload: res.data
      })
  })
}

const FETCH_MONTAGEM_DESMONTAGEM = "FETCH_MONTAGEM_DESMONTAGEM"

export const fetchMontagemDesmontagem = () => async dispatch => {
  noliAPI.buscarServicosMontagemDesmontagem().then(res => {
    if (res.status === 200)
      dispatch({
        type: FETCH_MONTAGEM_DESMONTAGEM,
        payload: res.data
      })
  })
}


const FETCH_ITENS = "FETCH_ITENS"

export const fetchItens = () => async dispatch => {
  noliAPI.buscarItens().then(res => {
    dispatch({
      type: FETCH_ITENS,
      payload: res
    })
  })
}


const FETCH_PRODUCTS_FOR_SALE = "FETCH_PRODUCTS_FOR_SALE"

export const fetchProductsForSale = () => async dispatch => {
  noliAPI.findAllProductsForSale().then(res => {
    dispatch({
      type: FETCH_PRODUCTS_FOR_SALE,
      payload: res.data
    })
  })
}

const FETCH_USER = "FETCH_USER"
const SET_PERMISSIONS = "SET_PERMISSIONS"
const LOGOUT_USER = "LOGOUT_USER"

export const fetchUser = () => async dispatch => {
  return noliAPI.buscarPerfil().then(res => {
    dispatch({
      type: FETCH_USER,
      payload: res.data
    })
    return res.data
  })
}

export const setUserPermissions = (permissions) => dispatch => {
  let toStore = permissions.reduce((acc, cur) => {
    acc[cur.descricao] = _.pick(cur, ['create', 'delete', 'update', 'read'])
    return acc
  }, {})
  dispatch({
    type: SET_PERMISSIONS,
    payload: toStore
  })
}

export const logoutUser = () => dispatch => dispatch({
  type: LOGOUT_USER,
})
