import React from 'react'
import {Grid, Segment, Form, Header, Input, Button, Message} from 'semantic-ui-react'
import * as noliAPI from '../../utils/noliAPI'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'

const AlterarSenha = (props) => {

  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [form, setForm] = React.useState({
    atual: "",
    nova: "",
    repetir: ""
  })

  if(props.title === "Painel Noli") props.setPageTitle('Alterar Senha')

  const handleSubmit = () => {
    setLoading(true)
    let payload = {}
    if(localStorage.getItem('@painel-noli/firstLogin')) payload.senha_atual = localStorage.getItem('@painel-noli/pw')
    else payload.senha_atual = form.atual
    payload.nova_senha = form.nova
    payload.first_login = localStorage.getItem('@painel-noli/firstLogin') ? true : false
    noliAPI.alterarSenha(payload).then(res =>{ 
      if(res.status === 200 ){
        if(localStorage.getItem('@painel-noli/firstLogin')){
          localStorage.removeItem('@painel-noli/firstLogin')
          localStorage.removeItem('@painel-noli/pw')
          props.check()
          window.location.reload(false);
        }else{
          setForm({atual: "", nova: "", repetir: ""})
          setSuccess(true)
          setLoading(false)
        }
      }else{
        setLoading(false)
      }
    })
  }

  const handleChange = (e,{name, value}) => {
    setSuccess(false)
    setForm({...form, [name]: value})
  }

  const validar = () => {
    let errors = []
    const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/
    if(form.nova !== form.repetir) errors.push('Nova senha e Repetir Nova Senha não conferem')
    if(!reg.test(form.nova)) {
      errors.push('A Nova Senha deve conter no mínimo um número')
      errors.push('A Nova Senha deve conter no mínimo uma letra maiúscula')
      errors.push('A Nova Senha deve conter no mínimo um dos seguintes caracteres especiais $&@#')
      errors.push('A Nova senha deve conter no mínimo 8 caracteres')
    }
    if(localStorage.getItem('@painel-noli/firstLogin') && form.nova === localStorage.getItem('@painel-noli/pw'))
      errors.push('Crie uma senha diferente da anterior')
    return errors
  }

  const errors = validar()
  return (
    <Grid padded centered columns="1" className="full-height-grid with-nav">
      <Grid.Column
        mobile="16"
        computer="8"
        tablet="8"
        stretched
        verticalAlign="middle"
      >
        <Segment color="green" padded textAlign="left">
          <Header content="Altere sua senha" />
          <Form
            onSubmit={handleSubmit}
            loading={loading}
            error={errors.length > 0}
            success={success}
          >
            <Message
              error
              header={errors.length > 0 ? "Verifique antes de enviar:" : "Senha alterada com sucesso!"}
              list={errors}
            />
            <Message
              success
              header="Senha alterada com sucesso!"
            />
            {!localStorage.getItem('@painel-noli/firstLogin') &&
              <Form.Field
                control={Input} type="password"
                label="Senha atual"
                required
                name="atual"
                onChange={handleChange}
                value={form.atual}
                disabled={loading}
              />
            }
            <Form.Field
              control={Input} type="password"
              label="Nova Senha"
              required
              name="nova"
              onChange={handleChange}
              value={form.nova}
              disabled={loading}
            />
            <Form.Field
              control={Input} type="password"
              label="Repetir Nova Senha"
              required
              name="repetir"
              onChange={handleChange}
              value={form.repetir}
              disabled={loading}
            />
            <Form.Field
              control={Button}
              type="submit"
              primary
              fluid
              disabled={loading || (errors.length > 0)}
            >
              Alterar Senha
            </Form.Field>
            {localStorage.getItem('@painel-noli/firstLogin') &&
              <Form.Field
                control={Button}
                type="button"
                color="red"
                fluid
                onClick={props.logout}
              >
                Sair do Painel
              </Form.Field>
            }
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

const mapStateToProps = ({pageInfo}) => pageInfo

export default connect(mapStateToProps, actions)(AlterarSenha)
