import React from 'react'
import { Form, Grid, Segment, Input, Button, Checkbox, Image, Message } from 'semantic-ui-react'
import logo from '../../assets/noli-logo.png'
import { realizarLogin, setDefaultToken } from '../../utils/noliAPI'


class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      keepConnected: localStorage.getItem('@painel-noli/keepLogged') === "true",
      loading: false,
      error: ""
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  handleCheck = (e, { checked }) => {
    this.setState({ keepConnected: checked })
    localStorage.setItem('@painel-noli/keepLogged', checked)
  }

  handleSubmit = () => {
    if (this.state.email !== "" && this.state.password !== "") {
      this.setState({ loading: true })
      realizarLogin(this.state.email, this.state.password)
        .then((res) => {
          if (res.status === 200) {
            setDefaultToken(res.headers['noli-auth-token'])
            if (res.data.first_login === 1) {
              localStorage.setItem('@painel-noli/firstLogin', true)
              localStorage.setItem('@painel-noli/pw', this.state.password)
            }
            this.props.check()

          } else {
           
            this.setState({ loading: false, error: res.data ? res.data.message : "Erro. Usuário ou senha incorretos." })
          }

        })
        .catch(error => {
          this.setState({ loading: false })
        })
    }
  }

  render() {
    const formSize = "large"
    const { loading, error } = this.state

    return (
      <Grid padded centered columns="1" className="full-height-grid">
        <Grid.Column
          mobile="16"
          computer="6"
          tablet="8"
          stretched
          verticalAlign="middle"
        >
          <Segment color="blue" padded textAlign="left">
            <Segment basic padded>
              <Image size="small" centered src={logo} />
            </Segment>

            <Form size={formSize} onSubmit={this.handleSubmit} loading={loading} error={error !== ""}>
              <Message
                error
                header={"Falha na autenticação"}
                content={error}
              />
              <Form.Field
                control={Input} type="email"
                label="Email"
                required
                name="email"
                onChange={this.handleChange}
                value={this.state.email}
                disabled={loading}
              />
              <Form.Field
                control={Input} type="password"
                label="Senha"
                required
                name="password"
                onChange={this.handleChange}
                value={this.state.password}
                disabled={loading}
              />
              <Form.Field
                control={Checkbox} type="checkbox"
                label="conectar automaticamente em futuros acessos."
                name="keepConnected"
                onChange={this.handleCheck}
                checked={this.state.keepConnected}
                disabled={loading}
              />
              <Form.Field control={Button} size={formSize} type="submit" primary fluid disabled={loading}>ACESSAR PAINEL</Form.Field>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    )
  }

}

export default Login
