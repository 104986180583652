import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../utils/actions'
import * as masks from '../../../utils/masks'
import _ from 'lodash'
import { Segment, Grid, Header, Button, Table, Confirm, Form, Input, Checkbox, Icon, Label } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react';
import * as noliAPI from '../../../utils/noliAPI'
import SearchTransporterHelpersFilter from '../../../components/transporters/helpers/SearchTransporterHelpersFilter'
import formatDate from '../../../utils/formatDate'
import SearchTransportadorFilter from '../../../components/SearchTransportadorFilter'

const _defaultFunc = {
    name: "",
    last_name: "",
    date_of_born: "",
    phone: "",
    rg: "",
    cpf: "",
    details: "",
    transportersAssociate: [],
    is_auth: 1,
}

class Helpers extends React.Component {

    state = {
        loading: false,
        list: [],
        formVal: _defaultFunc,
        newOpen: false,
        editOpen: false,
        selected: _defaultFunc,
        edited: _defaultFunc,
        reload: false
    }

    componentDidMount() {
        if (this.props.title === "Painel Noli") this.props.setPageTitle('Cadastrar e alterar informações de ajudantes')
    }

    handleFormChange = (e, { value, name }) => this.setState({
        formVal: {
            ...this.state.formVal, [name]: value
        }
    })

    handleFormCPFChange = (e, { value, name }) => this.setState({
        formVal: {
            ...this.state.formVal, [name]: masks.maskCpf(value)
        }
    })

    handleFormRGChange = (e, { value, name }) => this.setState({
        formVal: {
            ...this.state.formVal, [name]: masks.maskRG(value) 
        }
    })

    handleFormTELChange = (e, { value, name }) => this.setState({
        formVal: {
            ...this.state.formVal, [name]: masks.maskTelefone(value)
        }
    })

    handleFormCheck = (e, { checked, name }) => this.setState({
        formVal: {
            ...this.state.formVal, [name]: checked ? 1 : 0
        }
    })

    handleNewFnConfirmToggle = () => this.setState({
        newOpen: !this.state.newOpen,
        formVal: _defaultFunc
    })

    handleEditFnConfirmToggle = (selected = _defaultFunc) => this.setState({
        editOpen: !this.state.editOpen,
        selected: selected, formVal: selected
    })

    handleSubmit = () => {
        const { formVal } = this.state
        this.setState({ loading: true })
        if (this.validarForm(true)) {

            let payload = {}
            if (formVal.first_name) payload.first_name = formVal.first_name
            if (formVal.last_name) payload.last_name = formVal.last_name
            if (formVal.email) payload.email = formVal.email
            if (formVal.date_of_born) payload.date_of_born = formatDate.calendarToDateServer(formVal.date_of_born)
            if (formVal.rg) payload.rg = masks.unmaskRG(formVal.rg)
            if (formVal.cpf) payload.cpf = masks.unmaskCpf(formVal.cpf)
            if (formVal.phone) payload.phone = masks.unmaskTelefone(formVal.phone)
            payload.is_auth = Boolean(formVal.is_auth)
            payload.transportersAssociate = formVal.transportersAssociate

            noliAPI.createTransporterHelpers(payload)
                .then((res) => {
                    if (res.status === 200) {
                        this.props.sendMessage([], "Ajudante cadastrado com sucesso!", { color: 'blue', icon: "check circle" }, false)
                        this.setState({ newOpen: false })
                    } else {
                        this.props.sendMessage(res.data.msg, "Erro ao tentar cadastrar", { color: 'red', icon: "times circle" }, false)
                    }
                    this.reloadList()
                    this.setState({ loading: false })
                })
                .catch(error => {
                    this.setState({ loading: false })
                    this.props.sendMessage([], "Erro ao tentar cadastrar", { color: 'red', icon: "times circle" }, false)
                })
        }
    }

    reloadList = () => {
        this.setState({ reload: false })
        noliAPI.findTransporterHelpers({ withTransporters: true }).then(res => {
            if (res.status === 200) {
                this.setList(res.data)
               
            }
            this.setState({ loading: false, newOpen: false, editOpen: false, reload: true })
        })
    }

    setList = (list) => {
        if (list.length > 0) {
            list = list.map((item) => {
                return {
                    ...item,
                    date_of_born: item.date_of_born ? formatDate.dateUSAToBR(item.date_of_born) : item.date_of_born
                }
            })
        }

        this.setState({ list: list })
    }

    validarForm = (send = false) => {
        const { formVal } = this.state
        let errors = []
        if (formVal.first_name === "") errors.push("Nome não pode ser vazio")
        if (formVal.last_name === "") errors.push("Sobrenome não pode ser vazio")
        if (formVal.phone === "") errors.push("Telefone não pode ser vazio")
        if (formVal.rg === "") errors.push("RG não pode ser vazio")
        if (formVal.cpf) if (formVal.cpf.length !== 14 || !masks.isValidCPF(formVal.cpf)) errors.push("CPF inválido")

        if (send && errors.length > 0) this.props.sendMessage(errors, "Verifique os seguintes erros", { color: 'red' }, false)
        if (errors.length > 0) return false
        else return true
    }

    handleEditSubmit = () => {
        const { formVal, selected } = this.state
        this.setState({ loading: true })
        const payload = {}
        if (formVal.first_name) if (formVal.first_name !== selected.first_name) payload.first_name = formVal.first_name
        if (formVal.last_name) if (formVal.last_name !== selected.last_name) payload.last_name = formVal.last_name
        if (formVal.email) if (formVal.email !== selected.email) payload.email = formVal.email
        if (formVal.date_of_born) if (formVal.date_of_born !== selected.date_of_born) payload.date_of_born = formatDate.calendarToDateServer(formVal.date_of_born)
        if (formVal.rg) if (formVal.rg !== selected.rg) payload.rg = masks.unmaskRG(formVal.rg)
        if (formVal.cpf) if (formVal.cpf !== selected.cpf) payload.cpf = masks.unmaskCpf(formVal.cpf)
        if (formVal.phone) if (formVal.phone !== selected.phone) payload.phone = masks.unmaskTelefone(formVal.phone)
        if (formVal.is_auth !== selected.is_auth) payload.is_auth = Boolean(formVal.is_auth)
        if (!_.isEqual(formVal.transportersAssociate, selected.transportersAssociate)) payload.transportersAssociate = formVal.transportersAssociate

        if (Object.keys(payload).length > 0) {

            noliAPI.updateTransporterHelpers(payload, formVal.id).then(res => {
                this.setState({ loading: false })
                if (res.status === 200) {
                    this.props.sendMessage([], "Ajudante alterado com sucesso!", { color: 'blue', icon: "check circle" }, false)
                    this.setState({ editOpen: false })
                } else {
                    this.props.sendMessage(res.data.msg, "Erro ao tentar alterar ajudante", { color: 'red', icon: "times circle" }, false)
                }
                this.reloadList()

            })
                .catch(error => {
                    this.setState({ loading: false })
                    this.props.sendMessage([], "Erro ao tentar alterar ajudante", { color: 'red', icon: "times circle" }, false)
                })

        }

    }

    render() {
        const { loading, reload } = this.state
        return (
            <Grid padded>
                <Grid.Row reversed="computer">
                    <Grid.Column computer="4" mobile="16" tablet="6" stretched>
                        <Segment>
                            <Header
                                as='h5'
                                icon='add'
                                content="Cadastro"
                            />
                            <Button
                                content="Novo ajudante"
                                icon="user"
                                fluid
                                color="green"
                                labelPosition='left'
                                onClick={this.handleNewFnConfirmToggle}
                                disabled={!this.props.user.permissions[this.props.permission].create}
                            />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column computer="12" mobile="16" tablet="10" stretched>
                        <Segment>
                            <Header
                                as='h5'
                                icon='search'
                                content="Buscar Ajudantes:"
                            />
                            <SearchTransporterHelpersFilter
                                setList={this.setList}
                                loading={loading}
                                setLoading={(load) => this.setState({ loading: load })}
                                reload={reload}
                            />
                            {this.renderConfirm()}
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Segment loading={loading}>
                            <Header
                                as='h5'
                                icon='users'
                                content="Ajudantes Noli"
                            />
                            <Table selectable striped>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>ID</Table.HeaderCell>
                                        <Table.HeaderCell>Nome completo</Table.HeaderCell>
                                        <Table.HeaderCell>Telefone</Table.HeaderCell>
                                        <Table.HeaderCell>CPF</Table.HeaderCell>
                                        <Table.HeaderCell>RG</Table.HeaderCell>
                                        <Table.HeaderCell>Transportadores</Table.HeaderCell>
                                        <Table.HeaderCell>Autorizado</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.renderFuncTable()}
                                </Table.Body>
                            </Table>
                        </Segment>
                        {this.renderEditConfirm()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    renderFuncTable = () => {
        if (this.state.list.length > 0) {
            return this.state.list.map(f => {
                return (
                    <Table.Row
                        key={f.id}
                        negative={!f.is_auth}
                        positive={!!f.is_auth}
                        onClick={() => this.handleEditFnConfirmToggle(
                            {
                                ...f,
                                cpf: masks.maskCpf(f.cpf),
                                rg: masks.maskRG(f.rg),
                                phone: masks.maskTelefone(f.phone),
                            })
                        }
                        className="selectable-row"
                        disabled={!this.props.user.permissions[this.props.permission].update}
                    >
                        <Table.Cell><small>{f.id}</small></Table.Cell>
                        <Table.Cell><small>{f?.first_name + ' ' + f?.last_name}</small></Table.Cell>
                        <Table.Cell><small>{f.phone && masks.maskTelefone(f.phone)}</small></Table.Cell>
                        <Table.Cell><small>{f?.cpf && masks.maskCpf(f.cpf)}</small></Table.Cell>
                        <Table.Cell><small>{f.rg && masks.maskRG(f.rg)}</small></Table.Cell>
                        {f.transportersAssociate.length > 0 ?
                            <Table.Cell textAlign="left">
                                {
                                    f.transportersAssociate.map((item, index) => {
                                        return (

                                            <Label
                                                as='a'
                                                value={item}
                                                key={item.id + "-" + index}
                                                content={item.name}
                                                color="purple"
                                                size="small"
                                            />

                                        )
                                    })
                                }
                            </Table.Cell>
                            : <Table.Cell><small>Nenhum transportador associado</small></Table.Cell>
                        }
                        <Table.Cell><Icon name={f.is_auth ? 'check circle' : 'times circle'} /></Table.Cell>
                    </Table.Row>
                )
            })
        } else {
            return (
                <Table.Row>
                    <Table.Cell colSpan={10}>Ajudante(s) não encontrado(s)</Table.Cell>
                </Table.Row>
            )
        }
    }

    handleAddTag = (data) => {
        const toAdd = { id: data.id, name: data.nome }
        if (toAdd.id) {
            this.setState({
                formVal: {
                    ...this.state.formVal, transportersAssociate: [...this.state.formVal.transportersAssociate, toAdd]
                }
            })
        }
    }

    handleRemoveTag = (e, { value }) => {
        const { transportersAssociate } = this.state.formVal
        let tagsPrev = [...transportersAssociate]
        const index = transportersAssociate.indexOf(value)
        if (index !== -1)
            tagsPrev.splice(index, 1)
        this.setState({
            formVal: {
                ...this.state.formVal, transportersAssociate: tagsPrev
            }
        })
    }

    RenderContent = () => {
        const { formVal, loading, editOpen } = this.state




        return (
            <Segment basic loading={loading}>
                <Form>
                    <Form.Group>
                        <Form.Field
                            control={Input}
                            label="Nome"
                            name="first_name"
                            value={formVal.first_name}
                            onChange={this.handleFormChange}
                            width="6"
                            required
                        />
                        <Form.Field
                            control={Input}
                            label="Sobrenome"
                            name="last_name"
                            value={formVal.last_name}
                            onChange={this.handleFormChange}
                            width="6"
                            required
                        />
                        <Form.Field
                            control={DateInput}
                            label="Data de Nascimento"
                            name="date_of_born"
                            value={formVal.date_of_born}
                            onChange={this.handleFormChange}
                            width="4"
                            localization="pt-br"
                            maxDate={new Date()}
                            dateFormat="DD/MM/YYYY"
                            closable
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            control={Input}
                            label="Telefone"
                            name="phone"
                            maxLength="15"
                            value={formVal.phone}
                            onChange={this.handleFormTELChange}
                            required
                        />
                        <Form.Field
                            control={Input}
                            label="E-mail"
                            name="email"
                            value={formVal.email}
                            onChange={this.handleFormChange}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            control={Input}
                            label="RG"
                            name="rg"
                            value={formVal.rg}
                            onChange={this.handleFormRGChange}
                            required
                            maxLength={12}
                        />
                        <Form.Field
                            control={Input}
                            label="CPF"
                            name="cpf"
                            value={formVal.cpf}
                            onChange={this.handleFormCPFChange}
                        />
                    </Form.Group>
                    <Form.Group inline>
                        <EscolherTransportador {...this.props} handleAddTag={this.handleAddTag} />
                        {formVal.transportersAssociate.length > 0 && formVal.transportersAssociate?.map((t, i) => (
                            <Label
                                as='a'
                                onClick={this.handleRemoveTag}
                                value={t}
                                key={t + "-" + i}
                                content={t.name}
                                color="purple"
                                onRemove={this.handleRemoveTag}
                                size="small"
                            />))}

                    </Form.Group>
                    <Form.Group inline>
                        <Form.Field
                            control={Checkbox}
                            label="Autorizado"
                            name="is_auth"
                            checked={formVal.is_auth === 1}
                            onChange={this.handleFormCheck}
                        />
                    </Form.Group>
                </Form>
            </Segment>
        )
    }

    renderConfirm = () => {
        const { formVal, loading, editOpen } = this.state
        const valid = this.validarForm()
        return (
            <>
                <Confirm
                    open={this.state.newOpen}
                    header="Novo Ajudante"
                    onCancel={this.handleNewFnConfirmToggle}
                    onConfirm={this.handleSubmit}
                    cancelButton="Cancelar"
                    confirmButton={
                        <Button
                            content="Cadastrar Ajudante"
                            disabled={_.isEqual(formVal, _defaultFunc) || loading || !valid}
                        />
                    }
                    content={this.RenderContent()}
                />
            </>
        )
    }

    renderEditConfirm = () => {
        const { formVal, loading, selected } = this.state
        return (
            <>
                <Confirm
                    open={this.state.editOpen}
                    header="Editar Ajudante"
                    onCancel={() => this.handleEditFnConfirmToggle()}
                    onConfirm={this.handleEditSubmit}
                    cancelButton="Cancelar"
                    confirmButton={
                        <Button
                            content="Alterar Ajudante"
                            disabled={_.isEqual(selected, formVal) || loading}
                        />
                    }
                    content={this.RenderContent()}
                />
            </>
        )
    }


}

class EscolherTransportador extends React.Component {

    state = {
        open: false,
        selected: {}
    }

    form = React.createRef()

    toggleOpen = () => {
        this.setState({ open: !this.state.open })
    }

    confirm = () => {
        if (this.form.current.submit()) {
            this.toggleOpen()
            //this.props.refresher()
        }
    }

    returnData = (data) => {
        this.props.handleAddTag(data)
        this.setState({
            selected: {
                ...data
            }
        })
    }

    render() {
        return (
            <>
                <Form.Field
                    control={Button}
                    content={'Associar transportador'}
                    fluid
                    color={"grey"}
                    width="6"
                    icon="search"
                    type="submit"
                    labelPosition="left"
                    // disabled={props.loading || (filterType !== "all" && searchField === "")}
                    onClick={this.toggleOpen}
                />

                <Confirm
                    open={this.state.open}
                    onCancel={this.toggleOpen}
                    onConfirm={this.confirm}
                    cancelButton="Cancelar"
                    confirmButton="Selecionar Transportador"
                    size="tiny"
                    header={"Escolher transportador"}
                    content={
                        <SearchTransportadorFilter ref={this.form} {...this.props} return_data={this.returnData} />
                    }
                />
            </>
        )
    }

}

const mapStateToProps = ({ pageInfo }) => pageInfo

export default connect(mapStateToProps, actions)(Helpers)
