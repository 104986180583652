import moment from 'moment';
import 'moment/locale/br';

const formatDate = {

  "firstDayMonth": () => {

    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    firstDay = moment(firstDay).format('YYYY-MM-DD');

    return firstDay

  },
  "lastDayMonth": () => {

    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var lastDay = new Date(y, m + 1, 0);
    lastDay = moment(lastDay).format('YYYY-MM-DD');

    return lastDay

  },
  "convertDateTimeToDate": (date) => {
    moment.locale('br');
    var dt = moment.utc(date).local().format('DD/MM/YYYY');
    return dt
  },
  "convertDateTime": (date) => {
    moment.locale('br');
    var dt = moment.utc(date).local().format('DD/MM/YYYY HH:mm');
    return dt
  },
  "converDateTimeToDateMonth": (date) => {
    moment.locale('br');
    var dt = moment.utc(date).local().format('DD/MM');
    return dt
  },
  "converDateTimeToDateForEvent": (date) => {
    moment.locale('br');
    //var local_date= moment.utc(date).local().format('DD/MM/YYYY HH:mm');
    var substring = date.substring(11, 16)
    var dt = moment(date).zone("-03:00").format('DD/MM/YYYY') + ' - ' + substring

    return dt
  },
  "calendarToDateServer": date => {
    return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")
  },
  "dateUSAToBR": date => {
    return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
  },
  "dateNow": () => {
    return moment(new Date()).format("DD/MM/YYYY")
  },
  "dateBR": (date, is_unix: boolean = false) => {
    if (is_unix) {
      return moment.unix(date).format("DD/MM/YYYY")
    }
    return moment(date).format("DD/MM/YYYY")
  },
  "dateServer": date => {
    return moment(date).format("YYYY-MM-DD")
  },
  "dateTimeServer": date => {
    if (moment(new Date()).isDST()) {
      const dateR = moment(date).utcOffset(-2).format("DD/MM/YYYY HH:mm")
      //console.log("entrou utc ", date,dateR )
      return dateR
    } else {
      const dateR = moment(date).utcOffset(-3).format("DD/MM/YYYY HH:mm")
      //console.log("nao entrou utc ", date,dateR )
      return dateR
    }

    //return moment.utc(date).local().format('DD/MM/YYYY HH:mm')
  },
  "getMinutesBetweenDates"(startDate) {
    let date_receive = moment(startDate).format('YYYY-MM-DDTHH:mm:ss')
    let date_now = moment(moment().toDate()).format('YYYY-MM-DDTHH:mm:ss')
    let start = moment(date_receive)
    let end = moment(date_now)
    let diff = start.diff(end, 'minutes')

    if (diff > 60 || diff < 0) {
      diff = start.diff(end, 'hours')
      return diff + 'h'
    } else {
      return diff + 'm'
    }
  },
  "getTime": (date, is_unix: boolean = false) => {
    moment.locale('br');
    if (is_unix) {
      if (moment.unix(date).isDST()) {
        return moment.unix(date).utcOffset(-2).format("HH:mm")
      } else {
        return moment.unix(date).utcOffset(0).local().format("HH:mm")
      }
    }
    const dt = moment.utc(date).local().format('hh:mm');
    return dt
  },
  "checkStart": (date) => {
    moment.locale('br');
    let dateServer = moment.unix(date).utcOffset(0).local().subtract(3, 'hours').format("YYYY-MM-DD HH:mm")
    let dateNowToCompare = moment().format("YYYY-MM-DD HH:mm")
    let compare = moment(dateNowToCompare).isAfter(moment(dateServer));
    return compare
  },
  "getDateUnixTimestamp": (date) => {
    // moment.locale('br');
    if (moment.unix(date).isDST()) {
      return moment.unix(date).utcOffset(-2).format("DD/MM/YYYY - HH:mm")
    } else {
      return moment.unix(date).utcOffset(0,true).format("DD/MM/YYYY - HH:mm")
    }
  },
  "getDateAndTimeUnixTimestamp": (date) => {
    if (moment.unix(date).isDST()) {
      return moment.unix(date).utcOffset(-2).format("DD/MM/YYYY - HH:mm")
    } else {
      return moment.unix(date).utcOffset(0,true).format("DD/MM/YYYY - HH:mm")
    }
  },
  "getTimeNow": () => {
    moment.locale('br');
    let date = moment(new Date()).format("DD/MM/YYYYTHH:mm")
    return date
  },
  "getTimestamp": (date) => {
    moment.locale('br');
    return moment(date).unix();
  }, "checkChangeDate": (date, dateToCompare: Date = new Date()) => {
    moment.locale('br');
    const dateReceive = moment.unix(date).format("YYYY-MM-DD HH:mm")
    const dateNowToCompare = moment(dateToCompare).format("YYYY-MM-DD HH:mm")
    const compare = moment(dateReceive).isAfter(moment(dateNowToCompare));
    return compare
  }, yearRanges: (start, end, step = 1) => {
    const allNumbers = [start, end, step].every(Number.isFinite);
    if (!allNumbers) {
      throw new TypeError('range() expects only finite numbers as arguments.');
    }
    if (step <= 0) {
      throw new Error('step must be a number greater than 0.');
    }
    if (start > end) {
      step = -step;
    }
    const length = Math.floor(Math.abs((end - start) / step)) + 1;
    return Array.from(Array(length), (x, index) => start + index * step);
  }, expirationDatePix: (expiration_date: Number, creation_date: string, withFormat: Boolean = true) => {
    const create_date = new Date(creation_date)
    if (withFormat) {
      return moment(create_date).add(expiration_date, 'seconds').local().format("DD/MM/YYYY - HH:mm")
    }
    return moment(create_date).add(expiration_date, 'seconds').format("YYYY-MM-DDTHH:mm:ss")
  }, getDifferenceInSeconds: (dateStart, dateEnd) => {
    const date_start = moment(new Date(dateStart))
    const date_end = moment(new Date(dateEnd))
    const diff = date_end.diff(date_start, 'seconds')

    return diff
  },
  scheduleRentBoxDate: (date: number, time: number) => {
    const _date = moment.unix(date).format("YYYY-MM-DD")
    const _time = moment.unix(time).format('HH:mm')

    const unix_date = moment(_date + ' ' + _time, "YYYY-MM-DD HH:mm");

    return moment(unix_date).unix()
  },
  periodsRentBox: (dateStart: number, dateEnd: number) => {
    const date_start = moment.unix(dateStart).format("DD/MM/YYYY")
    const date_end = moment.unix(dateEnd).format("DD/MM/YYYY")

    return date_start + ' - ' + date_end
  },
  periodsRentBoxTime: (dateStart: number, dateEnd: number) => {
    const date_start = moment.unix(dateStart).format("HH:mm")
    const date_end = moment.unix(dateEnd).format("HH:mm")

    return date_start + ' - ' + date_end
  },
  dateToUnix: (date) => {
    //console.log('date received ', date, moment(date, "DD/MM/YYYY HH:mm").valueOf(), )
    return moment(moment(date, "DD/MM/YYYY").valueOf()).unix()
  },
  timeToUnix: (date) => {
    //console.log('date received ', date, moment(date, "DD/MM/YYYY HH:mm").valueOf(), )
    return moment(moment(date, "HH:mm").valueOf()).unix()
  },
  compareTime: (first_Time, second_time) => {
    const beginningTime1 =moment.unix(first_Time).format("HH:mm")
    const endTime1 =  moment.unix(second_time).format("HH:mm")
    const beginningTime = moment(beginningTime1, 'HH:mm');
    const endTime = moment(endTime1, 'HH:mm');
    const is_beginning = beginningTime.isBefore(endTime);
    return is_beginning
  },
  isSameDay: (first_date,second_date) => {
    const moment1 = moment(moment.unix(first_date).format("DD/MM/YYYY"), 'DD/MM/YYYY');
    const moment2 = moment(moment.unix(second_date).format("DD/MM/YYYY"), 'DD/MM/YYYY');
    const isSameDay =moment1.isSame(moment2, 'day');
    return isSameDay
  }


}

export default formatDate;
