import React from 'react'
import {Form, Search, Message, Button} from 'semantic-ui-react'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'
import * as masks from '../../utils/masks'
import { selectTransporterToTransportRentBox, transportUpdate, updateRentBoxTransportOrder} from '../../utils/noliAPI'
import {AxiosResponse} from 'axios'
import formatDate from '../../utils/formatDate'

class RentBoxtransporterFilter extends React.Component {

  state = {
    filter: "all",
    secondFilter: "nome",
    keyword: "",
    results: [],
    loading: false,
    value: "", 
    selected: this.props.transport.transporter ? this.props.transport.transporter : {},
    error: "",
    open: false,
  }

  componentDidMount() {
    this.props.clearSearchedTransportadores()
  }

  submit = () => {
    this.setState({loading: true}) 
    const {transport_id,transport, schedule_date,schedule_time} = this.props
    const {selected} = this.state
    if('nome' in selected){
        const payload ={
          transporter_id: selected.id,
          transport_id: transport_id,
          transport_order_id: transport.id,
          is_delivery: Boolean(transport.is_delivery),
          date_schedule: formatDate.getDateAndTimeUnixTimestamp(formatDate.scheduleRentBoxDate(schedule_date, schedule_time))
        }
        return selectTransporterToTransportRentBox(payload).then((resp: AxiosResponse) => {
         if(resp.status === 200) {
            return true
          } else {
            return false
          }
        })
    } else {
      this.setState({loading: false, error: "Selecione um transportador"})
      return false
    }

  }

  changeFilter = (e, {value}) => {
    this.setState({filter: value, selected: {}, open: false, value: "", loading: true})
    if(value==="agreed" && !(this.props.transport_id in this.props.transportadores)){
      this.props.fetchTransportadores({agree: this.props.transport_id}).then(()=> this.setState({loading: false}))
    }else this.setState({loading: false})
  }

  changeSecondFilter = (e, { value }) => this.setState({secondFilter: value})

  handleKeywordChange = (e, {value}) => this.setState({keyword: value})

  getTransportadorFromAPI = (type, keyword) => {
    this.setState({loading: true})
    this.props.fetchTransportadores({type:type, value: keyword}).then(x => this.setState({loading:false}))
  }

  handleKeyPress = (e) => {
    if(e.charCode === 13)
      this.getTransportadorFromAPI(this.state.secondFilter, this.state.keyword)
  }

  handleResultSelect = (e, { result }) => {
    this.setState({ selected: result, value: result.nome, open: false })
  }

  handleSearchChange = (e, { value }) => {  
    this.setState({ loading: true, value })
    let list = this.state.filter === "all" ? this.props.transportadores.searched : this.props.transportadores[this.props.transport_id]

    list = list.map(l => {
      return {
        ...l,
        find: "nome " + l.nome + " telefone " + l.telefone + " email " + l.email + " nota " + l.nota_inicial 
      }
    })

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState({loading: false, results: [], value: ''})

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result.find)

      this.setState({
        loading: false,
        results: _.filter(list, isMatch),
        open: true
      })
    }, 300)
  }

  renderLabel = () => {
    if(this.state.filter === "") return "Buscar Transportador"
    else if(this.state.filter === "all" && ("searched" in this.props.transportadores)) return "Buscar Transportador (" + this.props.transportadores.searched.length + " encontrados)"
    else if(this.state.filter === "agreed" && (this.props.transport_id in this.props.transportadores)) return "Buscar Transportador (" + this.props.transportadores[this.props.transport_id].length + " concordaram)"
    else return "Buscar Transportador"
  }

  openDropdown = () => {
    this.state.value === "" ? this.setState({open: true})
    : this.setState({open: false})
  }


  onRemoveItem = (item) => {
    if (item?.id) {
      this.setState({ loading: true })
      const {transport} = this.props
      updateRentBoxTransportOrder({ transporter_id: null }, transport.id).then(res => {
        this.setState({ loading: false })
        if (res.status === 200) {
          this.setState({ selected: {} })
          this.props.callRefresh()
        }
      }).catch(() => {
        this.setState({ loading: false })
      })
    }
  }



  render() {
    const {loading, results, value, filter, secondFilter, selected, open, keyword} = this.state
    return (
      <div style={{margin: 0, padding: "10px 15px"}} className="confirm-form-container">
        <Form onSubmit={this.submit} loading={loading}>
          <Form.Field
            control={Form.Select}
            options={[
              {text: "Todos", key:0, value: 'all'},
              {text: "Concordaram", key:1, value: 'agreed'},
            ]}
            label="Transportadores"
            placeholder="Filtros"
            onChange={this.changeFilter}
            value={filter}
          />
          { filter === "all" &&
            <>
              <Form.Field
                control={Form.Select}
                options={[
                  {text: "Telefone", key:0, value: 'telefone'},
                  {text: "Nome", key:1, value: 'nome'},
                  {text: "CPF", key:2, value: 'cpf'},
                  {text: "Email", key:3, value: 'email'}
                ]}
                label="Buscar transportador por"
                placeholder="Filtros"
                onChange={this.changeSecondFilter}
                value={secondFilter}
              />
              <Form.Field
                control={Form.Input}
                label="Palavra-chave"
                placeholder={"Digite aqui o " + secondFilter + " do transportador que deseja buscar"}
                onChange={this.handleKeywordChange}
                value={keyword}
                action={{
                  color: 'teal', icon: 'search', content: 'Buscar', type:'button', onClick: () => this.getTransportadorFromAPI(secondFilter, keyword)
                }}
                onKeyPress={this.handleKeyPress}
                required
              />
            </>
          }
          <Form.Field
            control={Search}
            label={this.renderLabel()}
            placeholder="Digite nome, telefone ou outras Informações"
            loading={loading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            results={(value === "" && filter === "all") ? this.props.transportadores.searched : results}
            value={value}
            disabled={filter.length < 1}
            noResultsMessage="NENHUM TRANSPORTADOR ENCONTRADO"
            noResultsDescription="É possível que nenhum transportador tenha concordado com o serviço até o momento."
            open={open}
            onFocus={() => this.openDropdown()}
            onBlur={() => this.setState({open: false})}
          />
          <Message
            icon='truck'
            header={'nome' in selected ? "Transportador Selecionado" : "Selecione um transportador no campo acima"}
            content={'nome' in selected && (
              <>
                Nome: {selected.nome} <br />
                Telefone: {masks.maskTelefone(selected.telefone)}<br />
                <Link to={"/cadastro/transportador/" + selected.id} target="_blank">
                  Ver dados completos
                </Link>
                <Button
                  floated='right'
                  size="mini"
                  icon="delete"
                  color='red'
                  onClick={() => { this.onRemoveItem(selected) }}
                />
              </>
            )}
            size="mini"
            negative={!('nome' in selected)}
            positive={'nome' in selected}
          />
        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({ transportadores }) => {
	return {
		transportadores
	};
};

export default connect(mapStateToProps, actions, null, {forwardRef: true})(RentBoxtransporterFilter) 