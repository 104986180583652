import React from 'react'
import * as actions from '../../utils/actions'
import SearchClienteFiltros from '../../components/SearchClienteFiltros'
import {Grid, Segment, Card, Header, Icon} from 'semantic-ui-react'
import {connect} from 'react-redux'


class Clientes extends React.Component{

  state = {
    results: [],
    loading: false,
    message: ""
  }

  componentDidMount(){
    this.props.setPageTitle('Clientes')
  }

  renderCards = () => {
    return this.state.results.map((a,i) => {
      return (
        <Card key={i} color="blue">
          <Card.Content>
            <Card.Header>{a.nome ? a.nome : "Nome não fornecido"} #{a.id}</Card.Header>
            <Card.Meta>{a.email}</Card.Meta>
            <Card.Description>
              <p><b>CPF:</b> {a.cpf ? a.cpf : "Não fornecido"}</p>
              <p><b>TEL:</b> {a.telefone}</p>
              <p><b>Data Nascimento:</b> {a.data_nascimento ? a.data_nascimento : "Não fornecido"}</p>
            </Card.Description>
          </Card.Content>
        </Card>
      )
    })
  }

  onResult = (data) => {
    if(data && data.data.length > 0)
      this.setState({results: data.data})
    else
      this.setState({message: "Não foi possível encontrar o cliente pelas informações fornecidas."})

  }

  render(){

    return (
      <Segment basic loading={this.state.loading}>
        <Grid centered>
          <Grid.Row>
            <Grid.Column computer="12" tablet="14" mobile="16">
              <Segment color="blue">
                <SearchClienteFiltros onResult={this.onResult} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Column computer="16" tablet="16" mobile="16" >
            <Segment color="blue" placeholder>
              <Header icon>
                <Icon name={this.state.results.length > 0 ? "search" : this.state.message ? "search" : "arrow alternate circle up outline" } circular />
                <Header.Content>
                  {this.state.results.length > 0 ? "Resultados da pesquisa" : this.state.message ? this.state.message : "Busque o cliente pelo formulário acima" }
                </Header.Content>
              </Header>
              <Card.Group itemsPerRow="1">
                {this.renderCards()}
              </Card.Group>
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>

    )
  }

}

const mapStateToProps = ({ clientes }) => {
	return {
		clientes
	};
};

export default connect(mapStateToProps, actions)(Clientes)
