import React from 'react'
import {Card, Segment, Header, Icon, Grid, Button, Divider, Statistic, Loader} from 'semantic-ui-react'
import * as noliAPI from '../../utils/noliAPI'
import * as masks from '../../utils/masks'
import OrcamentosList from '../../components/OrcamentosList'


class OrcamentosCliente extends React.Component {

  state = {
    loading: true,
    activeBtn: "all",
    orcamentos: [],
    clienteSelected: {},
    short: false
  }

  componentDidMount(){
    if(this.props.computedMatch){
      noliAPI.buscarDetalhesCliente(this.props.computedMatch.params.cid).then(res=>{
        if(res.data.length > 0) this.setState({
          clienteSelected: res.data[0],
          activeBtn: "open",
        }, () => this.buscarOrcamentos())
      })
    }else this.setState({clienteSelected: this.props.clienteSelected})
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.clienteSelected!==this.props.clienteSelected){
      if(this.props.clienteSelected.id){
        this.setState({activeBtn: "all", clienteSelected:this.props.clienteSelected}, () => this.buscarOrcamentos())
      }
    }
  }

  onOrcamentoFilter = (e,{name}) => {
    if(this.state.activeBtn !== name)
      this.setState({activeBtn: name}, () => this.buscarOrcamentos())
  }

  buscarOrcamentos = () => {
    this.setState({loading: true})

    let req
    switch(this.state.activeBtn){
      case "all": req = noliAPI.buscarOrcamentosDoCliente(this.state.clienteSelected.id)
        break
      case "open": req = noliAPI.buscarOrcamentosAbertosDoCliente(this.state.clienteSelected.id)
        break
      case "close": req = noliAPI.buscarOrcamentosAbertosDoCliente(this.state.clienteSelected.id, false)
        break
      case "canceled": req = noliAPI.buscarOrcamentosCanceladosDoCliente(this.state.clienteSelected.id)
        break
      default: req = noliAPI.buscarOrcamentosDoCliente(this.state.clienteSelected.id)
    }
    req.then(res => {
      this.setState({loading: false, orcamentos: res.data})
    })
  }

  render = () => {
    const cliente = this.state.clienteSelected
    if(this.props.clienteSelected && Object.keys(this.props.clienteSelected).length === 0){
      return (
        <Segment placeholder>
          <Header icon>
            <Icon name='search' />
            Nenhum cliente selecionado
          </Header>
          <Segment.Inline>
            Filtre e selecione um cliente na lista à esquerda
          </Segment.Inline>
        </Segment>
      )
    }else if(!cliente.id) {
      return (
        <Segment placeholder>
          <Header icon>
            <Loader size="massive" active inline /><br />
            Carregando dados
          </Header>
        </Segment>
      )
    }else{
      const {activeBtn, loading, orcamentos} = this.state
      return (
        <Grid padded className="bg-white">
          <Grid.Row>
            <Grid.Column computer="16" tablet="16" mobile="16">
              <Card.Group itemsPerRow="4" stackable>
                <Card color="blue" fluid>
                  <Card.Content>
                    <Card.Header>Nome</Card.Header>
                    <Card.Description textAlign="center">
                      <Statistic size="mini" value={cliente.nome ? cliente.nome : "Nome não fornecido"} />
                    </Card.Description>
                  </Card.Content>
                </Card>
                <Card color="blue" fluid>
                  <Card.Content>
                    <Card.Header>Telefone</Card.Header>
                    <Card.Description textAlign="center">
                      <Statistic size="mini" value={ cliente.telefone ? masks.maskTelefone(cliente.telefone) : "Telefone Não fornecido"} />
                    </Card.Description>
                  </Card.Content>
                </Card>
                <Card color="blue" fluid>
                  <Card.Content>
                    <Card.Header>CPF</Card.Header>
                    <Card.Description textAlign="center">
                      <Statistic size="mini" value={ cliente.cpf ? masks.maskCpf(cliente.cpf) : "CPF Não fornecido"} />
                    </Card.Description>
                  </Card.Content>
                </Card>
                <Card color="blue" fluid>
                  <Card.Content>
                    <Card.Header>E-mail</Card.Header>
                    <Card.Description textAlign="center">
                      <b style={{fontSize:'1.2em'}}>{cliente.email ? cliente.email : "EMAIL NÃO FORNECIDO"}</b>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Card.Group>
            </Grid.Column>
          </Grid.Row>
          <Divider horizontal>ORÇAMENTOS DO CLIENTE</Divider>
          { !this.state.short &&
          (<Grid.Row>
            <Grid.Column mobile="16" tablet="16" computer="4">
              <Button
                basic={activeBtn !== "all"}
                loading={loading && activeBtn==="all"}
                name="all" onClick={this.onOrcamentoFilter}
                color='orange'
                fluid
              >
                Todos
              </Button>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="4">
              <Button
                basic={activeBtn !== "open"}
                loading={loading && activeBtn==="open"}
                name="open" onClick={this.onOrcamentoFilter}
                color='blue'
                fluid
              >
                Abertos
              </Button>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="4">
              <Button
                basic={activeBtn !== "close"}
                loading={loading && activeBtn==="close"}
                name="close" onClick={this.onOrcamentoFilter}
                color='green'
                fluid
              >
                Finalizados
              </Button>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="4">
              <Button
                basic={activeBtn !== "canceled"}
                loading={loading && activeBtn==="canceled"}
                name="canceled" onClick={this.onOrcamentoFilter}
                color='red'
                fluid
              >
                Cancelados
              </Button>
            </Grid.Column>
          </Grid.Row>)
          }
          <Grid.Row>
            <Grid.Column>
              <OrcamentosList
                loading={loading}
                orcamentos={orcamentos}
                filterType={activeBtn}
                refresher={this.buscarOrcamentos}
                active={this.props.computedMatch ? this.props.computedMatch.params.ordem : false}
                match={this.props.computedMatch}
                permission={this.props.permission}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )
    }
  }

}

export default OrcamentosCliente
