import React from 'react'
import { Grid, Label, List, Segment } from 'semantic-ui-react'
import {money} from "../utils/helpers"

const ListaItens = (props) => {

  return (
    <List relaxed>
      <List.Header >
            <Label color="red" ribbon content={"Itens para transporte"} />
          </List.Header>
      <List.Item>
        <List divided className="scrollable-y" style={{ maxHeight: 400 }}> 
          {
            props.itens.map(i => {
              const infos = i.peso > 0
                ? "A.: " + i.altura + "m / L.: " + i.largura + "m / C.: " + i.comprimento + "m / P.: " + i.peso + " kg / (P/S) : " + money(i.suggested_price)
                : "A.: " + i.altura + "m / L.: " + i.largura + "m / C.: " + i.comprimento + "m" + " kg / (P/S) : " + money(i.suggested_price)
              return (
                <List.Item active={i.peso > 1000} key={i.id}>
                  <List.Content floated="right">
                    x {i.quantidade}
                  </List.Content>
                  <List.Content>
                    <List.Header>
                      <List.Icon name="dolly flatbed" />
                      {i.descricao}
                    </List.Header>
                    <List.Description content={infos} />
                  </List.Content>
                </List.Item>
              )
            })
          }
          {props.customizados &&
            props.customizados.map(i => {
              const infos = i.peso > 0
                ? "A.: " + i.altura + "m / L.: " + i.largura + "m / C.: " + i.comprimento + "m / P.: " + i.peso + " kg / (P/S) : " + money(i?.suggested_price | 0 )
                : "A.: " + i.altura + "m / L.: " + i.largura + "m / C.: " + i.comprimento + "m" + " kg / (P/S) : " + money(i?.suggested_price | 0)
              return (
                <List.Item active={i.peso > 1000} key={i.id}>
                  <List.Content floated="right">
                    x {i.quantidade}
                  </List.Content>
                  <List.Content>
                    <List.Header className="inputCls" >
                      <List.Icon name="dolly flatbed" color='red' />
                      {i.descricao}
                    </List.Header>
                    <List.Description content={infos} className="inputCls"  />
                  </List.Content>
                </List.Item>
              )
            })
          }
        </List>
      </List.Item>
    </List>
  )
}

export default ListaItens
