import React from 'react'
import { Segment, Header, Table, Button, Confirm, Input, Dropdown, Message} from 'semantic-ui-react'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'
import {buscarTipoPorCarrocerias, adicionarCarroceria, alterarCarroceria} from '../../utils/noliAPI'
import _ from 'lodash'

const Carrocerias = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [open, openConfirm] = React.useState(false)
  const [selected, setSelected] = React.useState({})
  const [edited, setEdited] = React.useState({id: 0, descricao: ""})
  const [action, setAction] = React.useState("edit")
  const [relations, setRelations] = React.useState([])
  const [originalRelations, setOriginalRelations] = React.useState([])
  const [carrocerias, setCarroceriasList] = React.useState([])
  const [searchVal, searchChange] = React.useState("")

  const editConfirm = (e, {value}) => {
    setAction("edit")
    setLoading(true)
    setSelected(value)
    buscarTipoPorCarrocerias(value.id)
    .then(res => {
      const data = res.data.data.map(r=> r.id)
      setRelations(data)
      setOriginalRelations(data)
      setLoading(false)
    })
    setEdited(value)
    openConfirm(true)
  }

  const addConfirm = () => {
    setAction("new")
    setRelations([])
    setEdited({id: 0, descricao: ""})
    setSelected({})
    openConfirm(true)
  }

  const changeRelations = (e, {value}) => {
    setRelations(value)
  }

  const submit = () => {
    setLoading(true)
    if(action === "new"){
      const payload = {
        descricao: edited.descricao,
        tipo_veiculo_id: relations.map(r => {
          return {id: r}
        })
      }
      adicionarCarroceria(payload).then(res => {
        if(res.status_code === "00"){
          props.sendMessage("Carroceria cadastrada com sucesso", "Sucesso", {color:'blue', icon:'check'})
          props.fetchTransportadoresFilters()
        }else props.sendMessage("Houve um erro ao tentar cadastrar", "Erro")
        setLoading(false)
        openConfirm(false)
        clearSearch()
      })
    }else if(action === "edit"){
      let payload = {}
      if(edited.descricao !== selected.descricao) payload.descricao = edited.descricao
      if(!_.isEqual(relations.sort(), originalRelations.sort()))
        payload.tipo_veiculo_id = relations.map(r => {
          return {id: r}
        })
        if(Object.keys(payload).length > 0) alterarCarroceria(payload, selected.id).then(res => {
          if(res.status_code === "00"){
            props.sendMessage("Carroceria alterada com sucesso", "Sucesso", {color:'blue', icon:'check'})
            props.fetchTransportadoresFilters()
          }else props.sendMessage("Houve um erro ao tentar alterar", "Erro")
          setLoading(false)
        })
        else(setLoading(false))
        openConfirm(false)
        clearSearch()
    }
  }

  const confirmButton = () => {
    if(action === "edit")
      return <Button
        primary
        content="Alterar"
        disabled={relations.length < 1 || edited.descricao==="" || (_.isEqual(relations.sort(), originalRelations.sort()) && descricoes.indexOf(edited.descricao.toLowerCase()) !== -1)}
        onClick={submit}
      />
    else
      return <Button
        primary
        content="Adicionar novo"
        disabled={relations.length < 1 || edited.descricao==="" || descricoes.indexOf(edited.descricao.toLowerCase()) > -1}
        onClick={submit}
      />
  }

  const descricoes = props.dados_veiculos ? props.dados_veiculos.carrocerias.map(c=> c.descricao.toLowerCase()) : []

  const handleSearch = (e, {value}) => {
    searchChange(value)
    const re = new RegExp(_.escapeRegExp(value), 'i')
    const isMatch = (result) => re.test(result.descricao)
    setCarroceriasList(_.filter(props.dados_veiculos.carrocerias, isMatch))
  }

  const clearSearch = () => {
    searchChange("")
    setCarroceriasList([])
  }

  const list = carrocerias.length > 0 ? carrocerias : props.dados_veiculos && searchVal==="" ? props.dados_veiculos.carrocerias : []

  return (
    <>
      <Segment loading={loading}>
        <Header
          as='h4'
          icon='dolly flatbed'
          content="Editar Tipos de Carrocerias"
        />
        <Input
          fluid
          action={<Button icon="x" basic onClick={clearSearch} />}
          style={{marginBottom: 7}}
          onChange={handleSearch}
          value={searchVal}
          placeholder="Busque uma Carroceria"
        />
        <div className="scrollable-y top-bottom-border" style={{height:"66vh"}}>
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Editar</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { list.length > 0 ? _.map(list, (t) => {
                return (
                  <Table.Row key={t.id + "-" + t.descricao}>
                    <Table.Cell width="1">{t.id}</Table.Cell>
                    <Table.Cell>{t.descricao}</Table.Cell>
                    <Table.Cell width="6" textAlign="right">
                      <Button
                        icon="edit"
                        onClick={editConfirm}
                        color="green"
                        value={t}
                        disabled={!props.user.permissions[props.permission].update}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              ) : (<Table.Row><Table.Cell colSpan="3" negative>Carroceria não encontrada</Table.Cell></Table.Row>)
            }
            </Table.Body>
          </Table>
        </div>
        <Button
          icon="add"
          labelPosition="right"
          content="Nova Carroceria"
          primary
          onClick={addConfirm}
          style={{marginTop: 7}}
          disabled={!props.user.permissions[props.permission].create}
        />
      </Segment>
      <Confirm
        open={open}
        header={action==="new" ? "Nova carroceria" : "Alterar Carroceria (#" + selected.id + ") " + selected.descricao }
        content={
          <Segment basic loading={loading}>
            {action === "new" && edited.descricao === "" && <Message negative content="A descrição não pode ser vazia" />}
            {action === "new" && edited.descricao !== "" && descricoes.indexOf(edited.descricao.toLowerCase()) > -1 && <Message negative content="Este tipo de carroceria já existe" />}
            {action === "edit" && (_.isEqual(relations.sort(), originalRelations.sort()) && descricoes.indexOf(edited.descricao.toLowerCase()) > -1) && <Message negative content="Adicione ou remova um item em tipos de veículo" />}
            Descrição
            <Input
              value={edited.descricao}
              style={{margin: '7px 0'}}
              onChange={(e, {value}) => setEdited({
                id: selected.id,
                descricao: value
              })}
              fluid
            />
            Tipos de Veículo
            <Dropdown
              multiple
              selection
              fluid
              style={{margin: '7px 0'}}
              options={props.transp_filters ? props.transp_filters.tipos : []}
              value={relations}
              onChange={changeRelations}
              upward
            />
          </Segment>
        }
        onCancel={() => openConfirm(false)}
        confirmButton={confirmButton}
        size="tiny"
      />
    </>
  )
}

const mapStateToProps = ({pageInfo}) => pageInfo

export default connect(mapStateToProps, actions)(Carrocerias)
