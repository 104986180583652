import React from 'react'
import { Segment, Header, Table, Button, Confirm, Input, Message, Dropdown, Form, Checkbox} from 'semantic-ui-react'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'
import _ from 'lodash'
import {alterarTipoVeiculo, adicionarTipoVeiculo, buscarTipoCarrocerias, alterarOrdemTipo} from '../../utils/noliAPI'

const TiposVeiculo = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [open, openConfirm] = React.useState(false)
  const [selected, setSelected] = React.useState({})
  const [edited, setEdited] = React.useState({id: 0, descricao: ""})
  const [action, setAction] = React.useState("edit")
  const [relations, setRelations] = React.useState([])
  const [originalRelations, setOriginalRelations] = React.useState([])
  const [tipos, setTiposList] = React.useState([])
  const [searchVal, searchChange] = React.useState("")
  const [original, setOriginalList] = React.useState([])

  React.useEffect(() => {
    setOriginalList(props.dados_veiculos);
  }, [props.dados_veiculos]);

  const editConfirm = (e, {value}) => {
    setAction("edit")
    setLoading(true)
    setSelected(value)
    buscarTipoCarrocerias(value.id)
    .then(res => {
      const data = res.data.data.map(r=> r.id)
      setRelations(data)
      setOriginalRelations(data)
      setLoading(false)
    })
    setEdited(value)
    openConfirm(true)
  }

  const addConfirm = () => {
    setAction("new")
    setRelations([])
    setEdited({id: 0, descricao: ""})
    setSelected({})
    openConfirm(true)
  }

  const handleToggle = () => {
    if(action === "order") {
      setAction("edit")
      setTiposList(original.tipos)
    }
    else setAction("order")
  }

  const changeRelations = (e, {value}) => {
    setRelations(value)
  }

  const submit = () => {
    setLoading(true)
    if(action === "new"){
      const payload = {
        descricao: edited.descricao,
        carroceria_veiculo_id: relations.map(r => {
          return {id: r}
        })
      }
      adicionarTipoVeiculo(payload).then(res => {
        if(res.status_code === "00"){
          props.sendMessage("Tipo de veículo cadastrado com sucesso", "Sucesso", {color:'blue', icon:'check'})
          props.fetchTransportadoresFilters()
        }else props.sendMessage("Houve um erro ao tentar cadastrar", "Erro")
        setLoading(false)
        openConfirm(false)
        clearSearch()
      })
    }else if(action === "edit"){
      let payload = {}
      if(edited.descricao !== selected.descricao) payload.descricao = edited.descricao
      if(!_.isEqual(relations.sort(), originalRelations.sort()))
        payload.carroceria_veiculo_id = relations.map(r => {
          return {id: r}
        })
        if(Object.keys(payload).length > 0) alterarTipoVeiculo(payload, selected.id).then(res => {
          if(res.status_code === "00"){
            props.sendMessage("Tipo de veículo alterado com sucesso", "Sucesso", {color:'blue', icon:'check'})
            props.fetchTransportadoresFilters()
          }else props.sendMessage("Houve um erro ao tentar alterar", "Erro")
          setLoading(false)
        })
        else(setLoading(false))
        openConfirm(false)
        clearSearch()
    }
  }

  const confirmButton = () => {
    if(action === "edit")
      return <Button
        primary
        content="Alterar"
        disabled={relations.length < 1 || edited.descricao==="" || (_.isEqual(relations.sort(), originalRelations.sort()) && descricoes.indexOf(edited.descricao.toLowerCase()) !== -1)}
        onClick={submit}
      />
    else
      return <Button
        primary
        content="Adicionar novo"
        disabled={relations.length < 1 || edited.descricao==="" || descricoes.indexOf(edited.descricao.toLowerCase()) > -1}
        onClick={submit}
      />
  }

  const descricoes = props.dados_veiculos ? props.dados_veiculos.tipos.map(c=> c.descricao.toLowerCase()) : []

  const handleSearch = (e, {value}) => {
    searchChange(value)
    const re = new RegExp(_.escapeRegExp(value), 'i')
    const isMatch = (result) => re.test(result.descricao)
    setTiposList(_.filter(props.dados_veiculos.tipos, isMatch))
  }

  const clearSearch = () => {
    searchChange("")
    setTiposList([])
  }

  const list = tipos.length > 0 ? tipos : props.dados_veiculos && searchVal==="" ? props.dados_veiculos.tipos : []

  const moveTipo = (oldIndex, positions) => {
    const newIndex = oldIndex + positions
    const toMove = list[oldIndex]
    let newTipo = [...list]
    newTipo.splice(oldIndex, 1)
    newTipo.splice(newIndex, 0, toMove)
    setTiposList(newTipo.map((end, i)=> {return {...end, ordem:i+1}}))
  }

  const saveNewOrder = () => {
    const payload = {ordem: list.map((m,i) => {return {id: m.id, ordem: i+1}})}
    alterarOrdemTipo(payload)
    .then(res => {
      setAction("new")
      if(res.status_code === "00"){
        props.sendMessage("Ordens de Tipos de Veículo alteradas com sucesso!", "Sucesso", {color:'blue', icon:'check'})
        props.fetchTransportadoresFilters()
      }else props.sendMessage("Houve um erro ao tentar alterar ordens", "Erro")
      setLoading(false)
    })
  }

  return (
    <>
      <Segment loading={loading}>
        <Header
          as='h4'
          icon='truck'
          content="Editar Tipos de Veículo"
        />
        <Input
          fluid
          action={<Button icon="x" basic onClick={clearSearch} />}
          style={{marginBottom: 7}}
          onChange={handleSearch}
          value={searchVal}
          placeholder="Busque um Tipo de Veículo"
        />
        <div className="scrollable-y top-bottom-border" style={{height:"66vh"}}>
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Editar</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { list.length > 0 ? _.map(list, (t,i) => {
                return (
                  <Table.Row key={t.id + "-" + t.descricao}>
                    <Table.Cell width="1">{t.id}</Table.Cell>
                    <Table.Cell>{t.descricao}</Table.Cell>
                    <Table.Cell width="6" textAlign="right">
                      {action !== "order"
                      ? <Button
                          icon="edit"
                          onClick={editConfirm}
                          color="green"
                          value={t}
                          disabled={!props.user.permissions[props.permission].update}
                        />
                      : <Button.Group >
                        <Button
                          icon="arrow up"
                          disabled={i===0}
                          onClick={()=> moveTipo(i, -1)}
                        />
                        <Button
                          icon="arrow down"
                          disabled={i===list.length-1}
                          onClick={()=> moveTipo(i, 1)}
                        />
                      </Button.Group>
                      }
                    </Table.Cell>
                  </Table.Row>
                )}
              ) : (<Table.Row><Table.Cell colSpan="3" negative>Tipo de Veículo não encontrado</Table.Cell></Table.Row>)
            }
            </Table.Body>
          </Table>
        </div>
        <Form >
          <Form.Group inline style={{marginBottom: 0}}>
            { action !== "order"
              ? <Form.Field
                control={Button}
                type="button"
                icon="add"
                labelPosition="right"
                content="Novo Tipo"
                primary
                onClick={addConfirm}
                style={{marginTop: 7}}
                disabled={!props.user.permissions[props.permission].create}
              />
              : <Form.Field
                control={Button}
                type="button"
                icon="save"
                labelPosition="right"
                content="Salvar Ordem"
                color="orange"
                onClick={saveNewOrder}
                style={{marginTop: 7}}
                disabled={_.isEqual(list, original.tipos)}
              />
            }
            <Form.Field
              control={Checkbox}
              toggle
              label="Ordenar"
              checked={action === "order"}
              onChange={handleToggle}
              disabled={!props.user.permissions[props.permission].update}
            />
          </Form.Group>
        </Form>
      </Segment>
      <Confirm
        open={open}
        header={action==="new" ? "Novo Tipo de Veículo" : "Alterar Tipo de Veículo (#" + selected.id + ") " + selected.descricao }
        content={
          <Segment basic loading={loading}>
            {action === "new" && edited.descricao === "" && <Message negative content="A descrição não pode ser vazia" />}
            {action === "new" && edited.descricao !== "" && descricoes.indexOf(edited.descricao.toLowerCase()) > -1 && <Message negative content="Este tipo de veículo já existe" />}
            {action === "edit" && (_.isEqual(relations.sort(), originalRelations.sort()) && descricoes.indexOf(edited.descricao.toLowerCase()) > -1) && <Message negative content="Adicione ou remova uma carroceria" />}
            Descrição
            <Input
              value={edited.descricao}
              style={{margin: '7px 0'}}
              onChange={(e, {value}) => setEdited({
                id: selected.id,
                descricao: value
              })}
              fluid
            />
            Carrocerias
            <Dropdown
              multiple
              selection
              fluid
              style={{margin: '7px 0'}}
              options={props.transp_filters ? props.transp_filters.carrocerias : []}
              value={relations}
              onChange={changeRelations}
              upward
            />
          </Segment>
        }
        onCancel={() => openConfirm(false)}
        confirmButton={confirmButton}
        size="tiny"
      />
    </>
  )
}

const mapStateToProps = ({pageInfo}) => pageInfo

export default connect(mapStateToProps, actions)(TiposVeiculo)
