import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../../utils/actions'
import * as masks from '../../../../utils/masks'
import _ from 'lodash'
import { Segment, Grid, Header, Button, Table, Confirm, Form, Input, Checkbox, Icon, Dropdown } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react';
import * as noliAPI from '../../../../utils/noliAPI'
import SearchPartnersFilter from '../../../../components/SearchPartnersFilter' 
import formatDate from '../../../../utils/formatDate' 
import {AxiosResponse} from 'axios';

const _defaultPartner = {
  name: "",
  description: "",
  label: "",
  color: "",
  logo_url: "",
  address_url: "",
  screen_name: "",
  category_name: "",
  partner_services_category_id:null,
  available: 1
}

const _defaultPartnerCategory = {
  name: "",
  description: "",
  label: "",
  logo_url: "",
  address_url: "",
  available: 1
}

class PartnerServices extends React.Component {

  state = {
    loading: false,
    list: [],
    categoryList: [],
    formVal: _defaultPartner,
    formValPartnerCategory: _defaultPartnerCategory,
    newOpenPartner: false,
    newOpenCategory: false,
    editOpen: false,
    selected: _defaultPartner,
    edited: _defaultPartner,
    reload: false
  }


  loadCategories = () => {
    //this.setState({ reload: false })
    noliAPI.findServicePartnerCategory().then(res => {
      if (res.status === 200) {
        const list_formatted = res.data.map((item) => {
          return {
            key: item.id.toString(), text: item.name, value: item.name,id:item.id
          }
        })
        this.setState({ categoryList: list_formatted })
      } else {
        this.setState({ categoryList: [] })
      }

      //this.setState({ loading: false, newOpenPartner: false, editOpen: false, reload: true })
    })
  }

  componentDidMount() {
    if (this.props.title === "Painel Noli") this.props.setPageTitle('Cadastrar e Alterar Informações de parceiros')
    this.loadCategories()
  }

  handleFormChange = (e, { value, name }) => {
    const newState = {
      ...this.state.formVal,
      ...name === 'category_name' && {partner_services_category_id: this.state.categoryList.filter((item) => item.value === value)[0].id}
    }
    this.setState({
    formVal: {
      ...newState, [name]: value,

    }
  })
}

handleFormChangeCategory = (e, { value, name }) => {
  this.setState({
    formValPartnerCategory: {
    ...this.state.formValPartnerCategory, [name]: value
  }
})
}

  handleFormCheckCategory = (e, { checked, name }) => this.setState({
    formValPartnerCategory: {
      ...this.state.formValPartnerCategory, [name]: checked ? 1 : 0
    }
  })

  handleFormCheck = (e, { checked, name }) => this.setState({
    formVal: {
      ...this.state.formVal, [name]: checked ? 1 : 0
    }
  })

  handleNewPartnerConfirmToggle = () => this.setState({
    newOpenPartner: !this.state.newOpenPartner,
    formVal: _defaultPartner
  })


  handleNewCategoryConfirmToggle = () => this.setState({
    newOpenCategory: !this.state.newOpenCategory,
    formValPartnerCategory: _defaultPartnerCategory
  })

  handleEditPartnerConfirmToggle = (selected = _defaultPartner) => this.setState({
    editOpen: !this.state.editOpen,
    selected: selected, formVal: selected
  })

  handleSubmit = () => {
    const { formVal } = this.state
    this.setState({ loading: true })
    if (!this.validarForm(true)) {
      this.setState({ loading: false })
    } else {
      const payload = {
        ...formVal.name && {name: formVal.name},
        ...formVal.label && {label: formVal.label},
        ...formVal.description && {description: formVal.description},
        ...formVal.name && {name: formVal.name},
        ...formVal.logo_url && {logo_url: formVal.logo_url},
        ...formVal.address_url && {address_url: formVal.address_url},
        ...formVal.partner_services_category_id && {partner_services_category_id: formVal.partner_services_category_id},
        ...formVal.screen_name && {screen_name: formVal.screen_name},
        available: Boolean(formVal.available)
      }
      if (Object.keys(payload).length > 0) {
        this.setState({ loading: true })
        noliAPI.createServicePartner(payload).then((res: AxiosResponse) => {
  
          if(res.status === 200) {
            this.setState({ newOpenPartner: false })
            this.reloadList()
             this.props.sendMessage([], "Dados do parceiro criado com sucesso!", { color: 'blue', icon: "check circle" }, false)
          } else {
            this.props.sendMessage([], "Erro ao tentar criar dados do parceiro", { color: 'red', icon: "times circle" }, false)
          }

          this.setState({ loading: false })

        })
      }  
      // CHAMADA API 
    }
  }

  handleSubmitCategory = () => {
    const { formValPartnerCategory } = this.state
    this.setState({ loading: true })
    if (!this.validarFormCategory(true)) {
      this.setState({ loading: false })
    } else {
      const payload = {
        ...formValPartnerCategory.name && {name: formValPartnerCategory.name},
        ...formValPartnerCategory.label && {label: formValPartnerCategory.label},
        ...formValPartnerCategory.description && {description: formValPartnerCategory.description},
        ...formValPartnerCategory.logo_url && {logo_url: formValPartnerCategory.logo_url},
        available: Boolean(formValPartnerCategory.available)
      }
      if (Object.keys(payload).length > 0) {
        this.setState({ loading: true })
        noliAPI.createServicePartnerCategory(payload).then((res: AxiosResponse) => {
  
          if(res.status === 200) {
            this.setState({ newOpenCategory: false })
            this.reloadList()
             this.props.sendMessage([], "Categoria criada com sucesso!", { color: 'blue', icon: "check circle" }, false)
          } else {
            this.props.sendMessage([], "Erro ao tentar criar categoria", { color: 'red', icon: "times circle" }, false)
          }

          this.setState({ loading: false })

        })
      } 
    }
  }

  reloadList = () => {
    this.setState({ reload: false })

    noliAPI.findServicePartner().then(res => {
      if (res.status === 200) {
        this.setList(res.data)
      }
      this.setState({ loading: false, newOpenPartner: false, editOpen: false, reload: true })
    })
  }

  setList = (list) => {
    this.setState({ list: list })
  }

  validarForm = (send = false) => {
    const { formVal } = this.state
    let errors = []
    if (formVal.name === "") errors.push("Nome da empresa não pode ser vazio")
    if (formVal.label === "") errors.push("Apelido não pode ser vazio")
    if (formVal.description === "") errors.push("Descrição não pode ser vazio")
    if (formVal.logo_url === "") errors.push("URL Logo não pode ser vazio")
    if (formVal.address_url === "") errors.push("URL Site não pode ser vazio")

    if (send && errors.length > 0) this.props.sendMessage(errors, "Verifique os seguintes erros", { color: 'red' }, false)
    if (errors.length > 0) return false
    else return true
  }


  validarFormCategory = (send = false) => {
    const { formValPartnerCategory } = this.state
    let errors = []
    if (formValPartnerCategory.name === "") errors.push("Nome da empresa não pode ser vazio")
    if (formValPartnerCategory.label === "") errors.push("Apelido não pode ser vazio")
   // if (formValPartnerCategory.description === "") errors.push("Descrição não pode ser vazio")
   // if (formValPartnerCategory.logo_url === "") errors.push("URL Logo não pode ser vazio")

    if (send && errors.length > 0) this.props.sendMessage(errors, "Verifique os seguintes erros", { color: 'red' }, false)
    if (errors.length > 0) return false
    else return true
  }


  handleEditSubmit = () => {
    const { formVal, selected } = this.state

    let payload = {}
    
    if (formVal.name !== selected.name) payload.name = formVal.name
    if (formVal.label !== selected.label) payload.label = formVal.label
    if (formVal.description !== selected.description) payload.description = formVal.description
    if (formVal.logo_url !== selected.logo_url) payload.logo_url = formVal.logo_url
    if (formVal.address_url !== selected.address_url) payload.address_url = formVal.address_url
    if (formVal.screen_name !== selected.screen_name) payload.screen_name = formVal.screen_name
    if (formVal.partner_services_category_id !== selected.partner_services_category_id) payload.partner_services_category_id = formVal.partner_services_category_id
    if (formVal.available !== selected.available) payload.available = Boolean(formVal.available)

    if (Object.keys(payload).length > 0) {
      this.setState({ loading: true })
      noliAPI.updateServicePartner( payload,formVal.id).then((res: AxiosResponse) => {

        if(res.status === 200) {
          this.setState({ editOpen: false })
          this.reloadList()
           this.props.sendMessage([], "Dados do parceiro alterado com sucesso!", { color: 'blue', icon: "check circle" }, false)
        } else {
          this.props.sendMessage([], "Erro ao tentar alterar dados do parceiro", { color: 'red', icon: "times circle" }, false)
        }
        this.setState({ loading: false })
      })
    }  


  }

  render() {
    const { loading, reload } = this.state
    return (
      <Grid padded>
        <Grid.Row reversed="computer">
          <Grid.Column computer="4" mobile="16" tablet="6" stretched>
            <Segment>
              <Header
                as='h5'
                icon='add'
                content="Cadastro de categorias"
              />
              <Button
                content="Nova categoria"
                icon="user"
                fluid
                color="green"
                labelPosition='left'
                onClick={this.handleNewCategoryConfirmToggle}
                disabled={!this.props.user.permissions[this.props.permission].create}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer="4" mobile="16" tablet="6" stretched>
            <Segment>
              <Header
                as='h5'
                icon='add'
                content="Cadastro de parceiros"
              />
              <Button
                content="Novo parceiro"
                icon="user"
                fluid
                color="green"
                labelPosition='left'
                onClick={this.handleNewPartnerConfirmToggle}
                disabled={!this.props.user.permissions[this.props.permission].create}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer="8" mobile="16" tablet="10" stretched>
            <Segment>
              <Header
                as='h5'
                icon='search'
                content="Buscar parceiros:"
              />
              <SearchPartnersFilter
                setList={this.setList}
                categoryList={this.state.categoryList}
                loading={loading}
                setLoading={(load) => this.setState({ loading: load })}
                reload={reload}
              />
              {this.renderOpenPartner()}
              {this.renderOpenCategory()}
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment loading={loading}>
              <Header
                as='h5'
                icon='users'
                content="Parceiros da Noli"
              />
              <Table selectable striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell>Nome(App)</Table.HeaderCell>
                    <Table.HeaderCell>Descrição</Table.HeaderCell>
                    <Table.HeaderCell>Site (URL)</Table.HeaderCell>
                    <Table.HeaderCell>Categoria</Table.HeaderCell>
                    <Table.HeaderCell>Ativo</Table.HeaderCell>
                    <Table.HeaderCell>Criado em</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.renderFuncTable()}
                </Table.Body>
              </Table>
            </Segment>
            {this.renderEditConfirm()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  renderFuncTable = () => {
    if (this.state.list.length > 0) {
      return this.state.list.map(f => {
        return (
          <Table.Row
            key={f.id}
            negative={!f.available}
            positive={!!f.available}
            onClick={() => this.handleEditPartnerConfirmToggle(f)
            }
            className="selectable-row"
            disabled={!this.props.user.permissions[this.props.permission].update}
          >
            <Table.Cell><small>{f.id}</small></Table.Cell>
            <Table.Cell><small>{f.name}</small></Table.Cell>
            <Table.Cell><small>{f.label}</small></Table.Cell>
            <Table.Cell><small>{f.description}</small></Table.Cell>
            <Table.Cell><small>{f.address_url}</small></Table.Cell>
            <Table.Cell><small>{f.category_name}</small></Table.Cell>
            <Table.Cell><Icon name={f.available ? 'check circle' : 'times circle'} /></Table.Cell>
            <Table.Cell><small>{formatDate.dateTimeServer(f.created_at)}</small></Table.Cell>
          </Table.Row>
        )
      })
    } else {
      return (
        <Table.Row>
          <Table.Cell colSpan={10}>Parceiro(s) não encontrado(s)</Table.Cell>
        </Table.Row>
      )
    }
  }

  renderOpenPartner = () => {
    const { formVal, loading, newOpenPartner, categoryList } = this.state
    const valid = this.validarForm()
    return (
      <>
        <Confirm
          open={newOpenPartner}
          header="Novo parceiro"
          onCancel={this.handleNewPartnerConfirmToggle}
          onConfirm={this.handleSubmit}
          cancelButton="Cancelar"
          confirmButton={
            <Button
              content="Cadastrar parceiro"
              disabled={_.isEqual(formVal, _defaultPartner) || loading || !valid}
            />
          }
          content={
            <Segment basic loading={loading}>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="Nome da empresa"
                    name="name"
                    value={formVal.name}
                    onChange={this.handleFormChange}
                    width="6"
                    required
                  />
                  <Form.Field
                    control={Input}
                    label="Nome exibido no app"
                    name="label"
                    value={formVal.label}
                    onChange={this.handleFormChange}
                    width="6"
                    required
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    label="Descrição"
                    control={Form.TextArea}
                    value={formVal.description}
                    width="8"
                    name="description"
                    onChange={this.handleFormChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="URL Logo"
                    name="logo_url"
                    value={formVal.logo_url}
                    onChange={this.handleFormChange}
                    width="12"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="URL Site"
                    name="address_url"
                    value={formVal.address_url}
                    onChange={this.handleFormChange}
                    width="12"
                    required
                  />
                </Form.Group>

                <Form.Group >
                <Form.Field
                    label='Categoria'
                    control={Dropdown}
                    selection
                    fluid
                    //style={{ margin: '7px 0' }}
                    options={categoryList}
                    value={formVal.category_name}
                    name="category_name"
                    onChange={this.handleFormChange} 
                    upward
                    width="6"
                    required
                  />
                  <Form.Field
                    control={Input}
                    label="Nome da tela no app"
                    name="screen_name"
                    value={formVal.screen_name}
                    onChange={this.handleFormChange}
                    width="6"
                  />
                </Form.Group>

                <Form.Group inline>

                  <Form.Field
                    control={Checkbox}
                    label="Ativo"
                    name="available"
                    checked={formVal.available === 1}
                    onChange={this.handleFormCheck}
                  />
                </Form.Group>
              </Form>
            </Segment>
          }
        />
      </>
    )
  }

  renderOpenCategory = () => {
    const { loading, newOpenCategory, categoryList,formValPartnerCategory } = this.state
    const valid = this.validarFormCategory()
    return (
      <>
        <Confirm
          open={newOpenCategory}
          header="Nova categoria"
          onCancel={this.handleNewCategoryConfirmToggle}
          onConfirm={this.handleSubmitCategory}
          cancelButton="Cancelar"
          confirmButton={
            <Button
              content="Cadastrar categoria"
              disabled={_.isEqual(formValPartnerCategory, _defaultPartnerCategory) || loading || !valid}
            />
          }
          content={
            <Segment basic loading={loading}>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="Nome"
                    name="name"
                    value={formValPartnerCategory.name}
                    onChange={this.handleFormChangeCategory}
                    width="6"
                    required
                  />
                  <Form.Field
                    control={Input}
                    label="Nome exibido no app"
                    name="label"
                    value={formValPartnerCategory.label}
                    onChange={this.handleFormChangeCategory}
                    width="6"
                    required
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    label="Descrição"
                    control={Form.TextArea}
                    value={formValPartnerCategory.description}
                    width="8"
                    name="description"
                    onChange={this.handleFormChangeCategory}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="URL Logo"
                    name="logo_url"
                    value={formValPartnerCategory.logo_url}
                    onChange={this.handleFormChangeCategory}
                    width="12"
                  />
                </Form.Group>
                <Form.Group inline>
                  <Form.Field
                    control={Checkbox}
                    label="Ativo"
                    name="available"
                    checked={formValPartnerCategory.available === 1}
                    onChange={this.handleFormCheckCategory}
                  />
                </Form.Group>
              </Form>
            </Segment>
          }
        />
      </>
    )
  }

  renderEditConfirm = () => {
    const { formVal, loading, selected, categoryList } = this.state
    return (
      <>
        <Confirm
          open={this.state.editOpen}
          header="Editar parceiro"
          onCancel={() => this.handleEditPartnerConfirmToggle()}
          onConfirm={this.handleEditSubmit}
          cancelButton="Cancelar"
          confirmButton={
            <Button
              content="Alterar parceiro"
              disabled={_.isEqual(selected, formVal) || loading}
            />
          }
          content={
            <Segment basic loading={loading}>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="Nome da empresa"
                    name="name"
                    value={formVal.name}
                    onChange={this.handleFormChange}
                    width="6"
                    required
                  />
                  <Form.Field
                    control={Input}
                    label="Nome exibido no app"
                    name="label"
                    value={formVal.label}
                    onChange={this.handleFormChange}
                    width="6"
                    required
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    label="Descrição"
                    control={Form.TextArea}
                    value={formVal.description}
                    width="8"
                    name="description"
                    onChange={this.handleFormChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="URL Logo"
                    name="logo_url"
                    value={formVal.logo_url}
                    onChange={this.handleFormChange}
                    width="12"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="URL Site"
                    name="address_url"
                    value={formVal.address_url}
                    onChange={this.handleFormChange}
                    width="12"
                    required
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Field
                    label='Categoria'
                    control={Dropdown}
                    selection
                    fluid
                    //style={{ margin: '7px 0' }}
                    options={categoryList}
                    value={formVal.category_name}
                    name="category_name"
                    onChange={this.handleFormChange}  
                    upward
                    width="6"
                    required
                  />
                  <Form.Field
                    control={Input}
                    label="Nome da tela no app"
                    name="screen_name"
                    value={formVal.screen_name}
                    onChange={this.handleFormChange}
                    width="6"
                  />
                </Form.Group>
                <Form.Group inline>
                  <Form.Field
                    control={Checkbox}
                    label="Ativo"
                    name="available"
                    checked={formVal.available === 1}
                    onChange={this.handleFormCheck}
                  />
                </Form.Group>
              </Form>
            </Segment>
          }
        />
      </>
    )
  }
}

const mapStateToProps = ({ pageInfo }) => pageInfo

export default connect(mapStateToProps, actions)(PartnerServices)
