import React from "react";
import { GoogleMap, DirectionsService, DirectionsRenderer  } from '@react-google-maps/api'
import {Button} from 'semantic-ui-react'
import mapStyle from "../utils/MapStyleWizard"

class DirectionsMap extends React.Component {
  state = {
    mapCenter: { lat: -23.5651478, lng: -46.6404272 },
    directions: {},
    isOpen: false
  }

  directionsCallback = (res) => {
    if(res)
      if(res.status === "OK")
        this.setState({directions: res}) 
  }

  render() {
    const {mapCenter, isOpen} = this.state
    const {adresses, orcamento} = this.props
    const waypoints = adresses.slice(1, -1).map(a => {
      return {
        location: {lat: a.latitude, lng: a.longitude},
        stopover: true
      }
    })

    if(!isOpen)
      return(
        <Button
          primary
          content="Ver endereços no mapa"
          icon="map"
          onClick={() => this.setState({isOpen: true})}
        />
      )
    else
      return(
        <>
          <Button
            content="Esconder mapa"
            icon="map"
            onClick={() => this.setState({isOpen: false})}
            style={{marginBottom: 10}}
            color="orange"
          />
          <GoogleMap
            id={'orcamento-directions-map' + orcamento.ordem_servico}
            center={mapCenter}
            zoom={10}
            mapContainerStyle={{height: 400, width: '100%'}}
            options={{
              styles: mapStyle()
              }}
          >
            <DirectionsService
              options={{
                destination: {lat: adresses[adresses.length-1].latitude, lng: adresses[adresses.length-1].longitude},
                origin: {lat: adresses[0].latitude, lng: adresses[0].longitude},
                travelMode: "DRIVING",
                waypoints: waypoints
              }}
              callback={this.directionsCallback}
            />
            <DirectionsRenderer
              options={{
                directions: this.state.directions
              }}

            />
          </GoogleMap>
        </>
      )
  }
}

export default DirectionsMap
