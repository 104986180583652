import React, { useEffect, useState } from 'react'
import { Grid, Segment, Header, Button, Label, Icon, Form, Input, Confirm, Dropdown, Checkbox, Table, Message } from 'semantic-ui-react'
import * as actions from '../../../../utils/actions'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as noliAPI from '../../../../utils/noliAPI'
import FuncionariosPermissao from '../../../../components/FuncionariosPermissao'
import formatDate from '../../../../utils/formatDate'
import RentBoxPackagesSchedule from '../../../../components/box/RentBoxPackagesSchedule'
import { DateInput, TimeInput, DateTimeInput } from 'semantic-ui-calendar-react'
import { AxiosResponse } from 'axios'

class BoxPeriods extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      details: [],
      dates: [],
      times: [],
      mode: "addDate",
      openConfirm: false,
      openConfirmTime: false,
      form: {},
      loading: false,
      packagesList: [],
      packagesLoad: false
    }
  }

  async componentDidMount() {
    if (this.props.pageInfo.title === "Painel Noli") this.props.setPageTitle("Cadastrar e alterar datas/horários de pacotes")
    this.handlePrepareData()
  }

  handlePrepareData = async () => {
    await Promise.all([this.handleGetDates(), 
    this.handleGetTimes(),
    this.handleGetPackagesToPeriods()])
  }

  handleGetPackagesToPeriods = () => {
    this.setState({ packagesLoad: true })
    noliAPI.rentBoxPackages({ with_period_dates: true }).then(res => {
      this.setState({ packagesList: res.data, packagesLoad: false })
    })
  }

  handleGetDates = () => {
    noliAPI.rentBoxScheduleDates({ available: true }).then((resp) => {
      if (resp.status === 200) {
        if (resp.data.length > 0) {
          this.setState({ dates: resp.data })
        }
      }
    })
  }

  handleGetTimes = () => {
    noliAPI.rentBoxScheduleTimes({ available: true }).then((resp) => {
      if (resp.status === 200) {
        if (resp.data.length > 0) {
          this.setState({ times: resp.data })
        }
      }
    })
  }

  handleChange = (e, { name, value, checked }) => {

  }

  addGroupSubmit = () => {

  }

  addPermissionSubmit = () => {

  }


  fetchFuncionariosBySelected = (e, { name, value }) => {

  }

  openToEdit = (mode, item) => {
    this.setState({ loading: true, mode: mode })
    if (mode === 'editDates') {
      this.setState({
        loading: false,
        form: {
          item: item,
          times: this.state.times
        },
        openConfirm: true
      })
    } else {
      this.setState({
        loading: false,
        form: {
          item: item,
        },
        openConfirmTime: true
      })
    }
  }

  openToNew = (mode) => {
    this.setState({ loading: true, mode: mode })
    if (mode === 'addDate') {
      this.setState({
        loading: false,
        form: {
          times: this.state.times
        },
        selected: null,
        openConfirm: true
      })
    } else {
      this.setState({
        loading: false,
        form: null,
        openConfirmTime: true
      })
    }
  }

  commitChanges = () => {
    this.setState({ loading: true })
  }

  onChangePeriod = (props) => {
    this.setState({
      selected: {
        ...props
      },
    })
  }

  onChangeTimes = (props) => {
    this.setState({
      selected: {
        ...props
      },
    })
  }

  handleCallbackPackageList = (status) => {
    if (status === 200) {
      //this.setState({packagesList: false})
      this.handlePrepareData().then(() => {
        this.props.sendMessage([], "Periodo associado com sucesso!", { color: 'blue', icon: "check circle" }, false)
      })

    } else {
      this.props.sendMessage([], 'Houve um erro tentar ao associar os períodos. Tente novamente mais tarde')
    }

  }
  
  handleConfirmDate = () => {
    const { period, times_delivery, times_fallback, } = this.state.selected

    /*if(times_delivery.length === 0 || times_delivery.length === 0) {
      return this.props.sendMessage([], 'Necessário ao menos um horário associado.')
    }*/

    this.setState({ loading: true })
    if (period) {
      const payload = {
        start_date: formatDate.dateToUnix(period.start_date),
        end_date: formatDate.dateToUnix(period.end_date),
        available: Boolean(period.available),
        times_delivery: times_delivery.length > 0 ? {
          times: times_delivery.map((element) => { return { time_id: this.state.times.filter((item) => item.id === Number(element))[0].id } }
          )
        } : { times: [] },
        times_fallback: times_fallback.length > 0 ? {
          times: times_fallback.map((element) => { return { time_id: this.state.times.filter((item) => item.id === Number(element))[0].id } }
          )
        } : { times: [] }
      }


      if (period.id) {
        noliAPI.updateRentBoxScheduleDates(payload, period.id).then((resp: AxiosResponse) => {
          if (resp.status === 200) {
            this.handlePrepareData().then(() => {
              this.setState({ openConfirm: false, loading: false })
            })
            this.props.sendMessage([], "Periodo atualizado com sucesso!", { color: 'blue', icon: "check circle" }, false)

          } else {
            this.setState({ loading: false })
            this.props.sendMessage([], 'Houve um erro tentar ao atualizar o período. Tente novamente mais tarde')
          }
        }).catch(() => {
          this.setState({ loading: false })
          this.props.sendMessage([], 'Houve um erro tentar ao atualizar o período. Tente novamente mais tarde')
        })
      } else {
        noliAPI.createRentBoxScheduleDates(payload).then((resp: AxiosResponse) => {
          if (resp.status === 200) {
            this.handlePrepareData().then(() => {
              this.setState({ openConfirmTime: false, loading: false })
            })
            this.props.sendMessage([], "Periodo criado com sucesso!", { color: 'blue', icon: "check circle" }, false)

          } else {
            this.setState({ loading: false })
            this.props.sendMessage([], 'Houve um erro tentar ao criar o período. Tente novamente mais tarde')
          }
        }).catch(() => {
          this.setState({ loading: false })
          this.props.sendMessage([], 'Houve um erro tentar ao criar o período. Tente novamente mais tarde')
        })
      }
    }
    // this.setState({ openConfirm: false, loading: true })
  }


  handleConfirmTimes = () => {
    const { period } = this.state.selected
    this.setState({ loading: true })
    if (period) {
      const payload = {
        start_time: formatDate.timeToUnix(period.start_time),
        end_time: formatDate.timeToUnix(period.end_time),
        available: Boolean(period.available)
      }
      if (period.id) {
        noliAPI.updateRentBoxScheduleTimes(payload, period.id).then((resp: AxiosResponse) => {
          if (resp.status === 200) {
            this.handlePrepareData().then(() => {
              this.setState({ openConfirmTime: false, loading: false })
            })
            this.props.sendMessage([], "Horário atualizado com sucesso!", { color: 'blue', icon: "check circle" }, false)

          } else {
            this.setState({ loading: false })
            this.props.sendMessage([], 'Houve um erro tentar ao atualizar o horário. Tente novamente mais tarde')
          }
        }).catch(() => {
          this.setState({ loading: false })
          this.props.sendMessage([], 'Houve um erro tentar ao atualizar o horário. Tente novamente mais tarde')
        })
      } else {
        noliAPI.createRentBoxScheduleTimes(payload).then((resp: AxiosResponse) => {
          if (resp.status === 200) {
            this.handlePrepareData().then(() => {
              this.setState({ openConfirmTime: false, loading: false })
            })
            this.props.sendMessage([], "Horário atualizado com sucesso!", { color: 'blue', icon: "check circle" }, false)

          } else {
            this.setState({ loading: false })
            this.props.sendMessage([], 'Houve um erro tentar ao atualizar o horário. Tente novamente mais tarde')
          }
        }).catch(() => {
          this.setState({ loading: false })
          this.props.sendMessage([], 'Houve um erro tentar ao atualizar o horário. Tente novamente mais tarde')
        })
      }
    }
  }


  render() {
    const { selectedType, openConfirm, openConfirmTime, mode, loading, selectedName, details, selectedId, packagesList, packagesLoad, dates, times, form } = this.state

    return (
      <Grid padded>
        <Grid.Row centered>
          <Grid.Column stretched style={{ maxHeight: '40vh' }} computer="8" mobile="16" tablet="10">
            <Segment>
              <Header as="h5">
                <Button
                  as={Label}
                  floated="right"
                  content="Nova data"
                  color="blue"
                  icon="add"
                  onClick={() => this.openToNew('addDate')}
                  disabled={!this.props.user.permissions[this.props.permission].create}
                />
                <Icon name="calendar" />
                <Header.Content>Datas de entrega/retirada</Header.Content>
              </Header>
              <div className="scrollable-y" style={{ maxHeight: '30vh' }}>
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Períodos</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">Editar</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {dates.length > 0 &&
                      dates.map((p, i) =>
                        <Table.Row key={p + "-" + i} className="selectable-row">
                          <Table.Cell negative={!p.available} onClick={() => this.openToEdit("editDates", p)}>
                            {formatDate.periodsRentBox(p.start_date, p.end_date)}</Table.Cell>
                          <Table.Cell negative={!p.available} textAlign="right">
                            <Button
                              icon="edit"
                              color="green"
                              size="mini"
                              onClick={() => this.openToEdit("editDates", p)}
                              disabled={!this.props.user.permissions[this.props.permission].update}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                  </Table.Body>
                </Table>
              </div>
            </Segment>
          </Grid.Column>

          <Grid.Column stretched style={{ maxHeight: '40vh' }} computer="8" mobile="16" tablet="10">
            <Segment>
              <Header as="h5">
                <Button
                  as={Label}
                  floated="right"
                  content="Novo horário"
                  color="blue"
                  icon="add"
                  onClick={() => this.openToNew('addTime')}
                  disabled={!this.props.user.permissions[this.props.permission].create}
                />
                <Icon name="clock" />
                <Header.Content>Horários de entrega/retirada</Header.Content>
              </Header>
              <div className="scrollable-y" style={{ maxHeight: '30vh' }}>
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Horários</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">Editar</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {times.length > 0 &&
                      times.map((p, i) =>
                        <Table.Row key={p + "-" + i} className="selectable-row">
                          <Table.Cell negative={!p.available} onClick={() => this.openToEdit("editTime", p)}>
                            {formatDate.periodsRentBoxTime(p.start_time, p.end_time)}</Table.Cell>
                          <Table.Cell negative={!p.available} textAlign="right">
                            <Button
                              icon="edit"
                              color="green"
                              size="mini"
                              onClick={() => this.openToEdit("editTime", p)}
                              disabled={!this.props.user.permissions[this.props.permission].update}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                  </Table.Body>
                </Table>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <RentBoxPackagesSchedule
              options={this.props.pageInfo.permissoes ? this.props.pageInfo.permissoes.gruposOpt : []}
              list={packagesList}
              loading={packagesLoad}
              user={this.props.user}
              permission={this.props.permission}
              dates={dates}
              handleCallback={(status) => this.handleCallbackPackageList(status)}
            />
            <Confirm
              open={openConfirm}
              header={form?.item ? "Alterar período de entrega/retirada" : "Novo período de entrega/retirada"}
              size="large"
              content={
                <FormDates
                  loading={loading}
                  form={form}
                  onChange={this.onChangePeriod}
                />}
              cancelButton="Cancelar"
              confirmButton={
                <Button
                  content={form?.item ? "Alterar período" : "Novo período"}
                  disabled={false}
                  onClick={this.handleConfirmDate}
                />
              }
              onCancel={() => this.setState(({ openConfirm: false }))}
            />

            <Confirm
              open={openConfirmTime}
              header={form?.item ? "Alterar horário de entrega/retirada" : "Novo horário de entrega/retirada"}
              size="large"
              content={
                <FormTimes
                  loading={loading}
                  form={form}
                  onChange={this.onChangeTimes}
                />}
              cancelButton="Cancelar"
              confirmButton={
                <Button
                  content={form?.item ? "Alterar horário" : "Novo horário"}
                  disabled={false}
                  onClick={this.handleConfirmTimes}
                />
              }
              onCancel={() => this.setState(({ openConfirmTime: false }))}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}


const FormDates = (props) => {

  const [date, setDate] = useState([])
  const [loading, setLoading] = useState(true)
  const [availableDate, setAvailableDate] = useState(true)
  const [times, setTimes] = useState([])
  const [timesSelected, setTimesSelected] = useState([])
  const [dateDeliveryInput, setDateDeliveryInput] = useState("")
  const [dateFallbackInput, setDateFallbackInput] = useState("")

  useEffect(() => {

    if (props.form?.times?.length > 0) {
      const timesFormatted = props.form.times.map((item) => {
        return {
          key: item.id.toString(), text: formatDate.periodsRentBoxTime(item.start_time, item.end_time), value: item.id.toString(),
        }
      })
      setTimes(timesFormatted)
    }

    if (props.form?.item) {
      setDate(props.form.item)
      setAvailableDate(props.form.item.available)
      setDateDeliveryInput(formatDate.dateBR(props.form.item.start_date, true))
      setDateFallbackInput(formatDate.dateBR(props.form.item.end_date, true))

      noliAPI.rentBoxScheduleTimesByDateId(props.form.item.id).then((resp) => {
        if (resp.status === 200) {
          if (resp.data) {
            if (resp.data.length > 0) {
              const t = resp.data.map((item) => item.id.toString())
              setTimesSelected(t)
              setLoading(false)
            }
          }
        }
      })
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const object = {
      period: {
        id: props.form?.item ? props.form.item.id : null,
        start_date: dateDeliveryInput,
        end_date: dateFallbackInput,
        available: availableDate
      },
      times_delivery: timesSelected,
      times_fallback: timesSelected,
    }

    props.onChange(object)

  }, [dateDeliveryInput, dateFallbackInput, timesSelected, availableDate])


  const handleChangeDeliveryDate = (e, { name, value }) => setDateDeliveryInput(value)

  const handleChangeFallbackDate = (e, { name, value }) => {
    setDateFallbackInput(value)
  }

  const handleChangeForm = (e, { name, value, checked }) => {
    setTimesSelected(value)
  }



  const checkChange = (e, { name, checked }) => setAvailableDate(checked ? 1 : 0)

  return (
    <Segment basic loading={props.loading}>
      <Form>
        <p>
          <Label color="grey" ribbon content={"Datas de entrega e retirada"} />
        </p>
        <Form.Group inline widths="equal">
          <Form.Field
            disabled={props.form?.item?.id}
            label="Data de entrega"
            control={DateInput}
            value={dateDeliveryInput}
            dateFormat={'DD/MM/YYYY'}
            onChange={handleChangeDeliveryDate}
            name="data_entrega"
            localization="pt-br"
            closable
          />
          <Form.Field
            disabled={props.form?.item?.id}
            label="Data de retirada"
            control={DateInput}
            value={dateFallbackInput}
            dateFormat={'DD/MM/YYYY'}
            onChange={handleChangeFallbackDate}
            name="data_retirada"
            localization="pt-br"
            closable
            disabled={dateDeliveryInput ? props.form?.item?.id : true}
          />
        </Form.Group>
        <p>
          <Label color="green" ribbon content={"Horários de entrega"} />
        </p>
        <Form.Group inline widths="equal">

          <Dropdown
            multiple
            selection
            fluid
            name="time_delivery"
            style={{ margin: '7px 0' }}
            options={times}
            value={timesSelected}
            onChange={handleChangeForm}
            upward
          />
        </Form.Group>
        <p>
          <Label color="red" ribbon content={"Horários de retirada"} />
        </p>
        <Form.Group inline widths="equal">

          <Dropdown
            multiple
            selection
            fluid
            name="time_fallback"
            style={{ margin: '7px 0' }}
            options={times}
            value={timesSelected}
            onChange={handleChangeForm}
            upward
          />
        </Form.Group>
        <Form.Group inline widths="equal" >
          <Form.Checkbox
            checked={availableDate}
            label="Ativo"
            name="ativo"
            onChange={checkChange}
          />
        </Form.Group>
      </Form>
    </Segment>
  )

}

const FormTimes = (props) => {

  const [dateDeliveryInput, setDateDeliveryInput] = useState("")
  const [dateFallbackInput, setDateFallbackInput] = useState("")
  const [availableDate, setAvailableDate] = useState(true)

  useEffect(() => {

    if (props.form?.item) {
      setAvailableDate(props.form.item.available)
      setDateDeliveryInput(formatDate.getTime(props.form.item.start_time, true))
      setDateFallbackInput(formatDate.getTime(props.form.item.end_time, true))
    }
  }, [])

  useEffect(() => {
    const object = {
      period: {
        id: props.form?.item ? props.form.item.id : null,
        start_time: dateDeliveryInput,
        end_time: dateFallbackInput,
        available: availableDate
      }
    }

    props.onChange(object)

  }, [dateDeliveryInput, dateFallbackInput, availableDate])

  const handleChangeDeliveryDate = (e, { name, value }) => setDateDeliveryInput(value)


  const handleChangeFallbackDate = (e, { name, value }) => {
    setDateFallbackInput(value)
    //handleAddDates(e, { value })
  }

  const checkChange = (e, { name, checked }) => setAvailableDate(checked ? 1 : 0)

  return (
    <Segment basic loading={props.loading}>
      <Form>
        <Form.Group inline widths="equal">
          <Form.Field
            label="Horário de entrega"
            control={TimeInput}
            value={dateDeliveryInput}
            dateFormat={'HH:mm'}
            onChange={handleChangeDeliveryDate}
            name="horario_entrega"
            localization="pt-br"
            closable
          />
          <Form.Field
            label="Horário de retirada"
            control={TimeInput}
            value={dateFallbackInput}
            dateFormat={'HH:mm'}
            onChange={handleChangeFallbackDate}
            name="data_retirada"
            localization="pt-br"
            closable
            disabled={dateDeliveryInput ? false : true}
          />
        </Form.Group>
        <Form.Group inline widths="equal" >
          <Form.Checkbox
            checked={availableDate}
            label="Ativo"
            name="ativo"
            onChange={checkChange}
          />
        </Form.Group>
      </Form>
    </Segment>
  )

}

const mapStateToProps = ({ pageInfo, user }) => { return { pageInfo, user } }

export default connect(mapStateToProps, actions)(BoxPeriods)
