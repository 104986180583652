import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../../utils/actions'
import { Grid, Segment, Header, Form, Button, Label, Icon, Table, Checkbox, Confirm, Image, Input, Item, Dropdown } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import * as noliAPI from '../../../../utils/noliAPI'
import formatDate from '../../../../utils/formatDate'
import moment from 'moment'
import _ from 'lodash'
import { money } from '../../../../utils/helpers'
import { red } from '@material-ui/core/colors'
import { AxiosResponse } from 'axios'

const _formPackage = {
    titulo: "",
    descricao: "",
    preco_original: 0,
    preco_venda: 0,
    quantidade_caixas: 1,
    quantidade_dias: 1,
    itens_inclusos: [],
    periodos: [],
    imagens: [],
    favorito: 0,
    ativo: 1,
    id: 0
}

const BoxPackages = (props) => {


    const [loading, setLoading] = React.useState(false)
    const [filter, setFilter] = React.useState({ campo: 0, valor: "" })
    const [list, setList] = React.useState([])
    const [open, toggleConfirm] = React.useState(false)
    const [modalProps, setModalProps] = React.useState({})
    const [form, setForm] = React.useState(_formPackage)

    React.useEffect(() => {
        setLoading(true);
        getPackages();
    }, []);

    const getPackages = () => {
        noliAPI.rentBoxPackages().then((res: AxiosResponse) => {
            if (res.status === 200) {
                setList(makeDataDTO(res.data))
            }

            setLoading(false)
        })
    }

    const makeDataDTO = (data) => {
        return data.map((item) => {
            return {
                titulo: item.title,
                descricao: item.description,
                preco_original: item.original_price,
                preco_venda: item.sales_price,
                quantidade_caixas: item.box_qtd,
                quantidade_dias: item.rent_days,
                itens_inclusos: item.includes.data,
                imagens: item.images.data,
                favorito: item.favorite,
                ativo: item.available,
                id: item.id
            }
        })
    }

    if (props.title === "Painel Noli") props.setPageTitle('Cadastrar e alterar pacotes de caixas')

    const toggleActive = (e, { value, checked }) => {
        e.stopPropagation()
        setLoading(true)
        /*
          setList(res.data.data)
          setLoading(false)
      */
    }


    const openNewConfirm = () => {
        setModalProps({
            header: "Cadastrar novo pacote",
            type: "new",
            selected: {}
        })
        setForm(_formPackage)
        toggleConfirm(true)
    }

    const selectPackage = (pacote) => {

        if (!loading) {
            const toPick = ['titulo', 'descricao', 'preco_original', 'preco_venda', 'quantidade_caixas', 'quantidade_dias', 'itens_inclusos', 'imagens', 'data_entrega', 'data_retirada', 'favorito', 'ativo', 'id']
            setModalProps({
                header: "Editar pacote",
                type: "edit",
                selected: _.pick(pacote, toPick)
            })
            setForm(_.pick(pacote, toPick))
            toggleConfirm(true)
        }
    }

    const validateToAdd = () => {
        if (form.descricao === "" || form.titulo === "" || form.quantidade_caixas <= 0 || form.quantidade_dias <= 0 || form.preco_venda <= 0 || form.imagens.length === 0 || form.itens_inclusos.length === 0 || form.periodos.length === 0)
            return false
        else return true
    }

    const submit = (isEdit: boolean = false) => {

        if (validateToAdd()) {
            const payload = {
                title: form.titulo,
                description: form.descricao,
                rent_days: Number(form.quantidade_dias),
                box_qtd: Number(form.quantidade_caixas),
                includes: {
                    data: form.itens_inclusos
                },
                images: {
                    data: form.imagens
                },
                available: form.ativo === 1,
                ...form.preco_original && { original_price: Number(form.preco_original) },
                sales_price: Number(form.preco_venda),
                favorite: form.favorito === 1,
                periods: form.periodos
            }

            setLoading(true)

            if (isEdit) {
                //atualizar 
                noliAPI.updateRentBoxPackages(payload, form.id).then((resp: AxiosResponse) => {
                    if (resp.status === 200) {
                        toggleConfirm(false)
                        props.sendMessage([], "Pacote atualizado com sucesso!", { color: 'blue', icon: "check circle" }, false)
                        getPackages()
                    } else {
                        setLoading(false)
                        props.sendMessage([], 'Houve um erro ao atualizar o pacote. Tente novamente mais tarde')
                    }
                }).catch(() => {
                    setLoading(false)
                    props.sendMessage([], 'Houve um erro ao tentar atualizar o pacote. Tente novamente mais tarde')
                })



            } else {
                noliAPI.createRentBoxPackages(payload).then((resp: AxiosResponse) => {
                    if (resp.status === 200) {
                        toggleConfirm(false)
                        props.sendMessage([], "Pacote criado com sucesso!", { color: 'blue', icon: "check circle" }, false)
                        getPackages()
                    } else {
                        setLoading(false)
                        props.sendMessage([], 'Houve um erro ao atualizar o pacote. Tente novamente mais tarde')
                    }
                }).catch(() => {
                    setLoading(false)
                    props.sendMessage([], 'Houve um erro tentar ao atualizar o pacote. Tente novamente mais tarde')
                })

            }

        }
    }


    return (
        <Grid padded>
            <Grid.Row>
                <Grid.Column>
                    <Segment loading={loading}>
                        <Header as="h3">
                            <Button
                                as={Label}
                                floated="right"
                                content="Novo Pacote"
                                size="big"
                                color="blue"
                                icon="add"
                                onClick={openNewConfirm}
                            />
                            <Icon name="boxes" />
                            <Header.Content>Pacotes de caixas Noli</Header.Content>
                        </Header>
                        <Table selectable color="violet">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell textAlign="center">ID</Table.HeaderCell>
                                    <Table.HeaderCell>Titulo</Table.HeaderCell>
                                    <Table.HeaderCell>Descrição</Table.HeaderCell>
                                    <Table.HeaderCell>Qtd. caixas</Table.HeaderCell>
                                    <Table.HeaderCell>Incluso</Table.HeaderCell>
                                    <Table.HeaderCell>Imagens</Table.HeaderCell>
                                    <Table.HeaderCell>Preço original</Table.HeaderCell>
                                    <Table.HeaderCell>Preço de venda</Table.HeaderCell>
                                    <Table.HeaderCell>Favorito</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Ativo</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {list.length > 0
                                    ? list.map((c, i) =>
                                        <Table.Row
                                            key={i + "-" + c.id}
                                            className="selectable-row"
                                            positive={c.ativo === 1}
                                            //negative={c.data_entrega && c.data_retirada < Math.round((new Date()).getTime() / 1000)}
                                            onClick={() => selectPackage(c)}
                                        >
                                            <Table.Cell textAlign="center">{c.id}</Table.Cell>
                                            <Table.Cell>{c.titulo}</Table.Cell>
                                            <Table.Cell><small>{c.descricao}</small></Table.Cell>
                                            <Table.Cell>{c.quantidade_caixas}</Table.Cell>
                                            <Table.Cell>
                                                {c.itens_inclusos.map((element, index) => {
                                                    return (
                                                        <p key={index.toString()}>
                                                            - {element.description}
                                                        </p>
                                                    )


                                                })}
                                            </Table.Cell>
                                            <Table.Cell >
                                                {c.imagens.map(element => {
                                                    return <>
                                                        <Image key={element.id} src={element.url} size='mini' bordered centered /> <br />
                                                    </>
                                                })}
                                            </Table.Cell>
                                            <Table.Cell>{c.preco_original}</Table.Cell>
                                            <Table.Cell>{c.preco_venda}</Table.Cell>
                                            <Table.Cell>{c.favorito ? 'Sim' : 'Não'}</Table.Cell>
                                            <Table.Cell textAlign="center">
                                                <Checkbox
                                                    checked={c.ativo === 1}
                                                    onChange={() => {}}
                                                    value={c.id}
                                                    readOnly
                                                />
                                            </Table.Cell>
                                        </Table.Row>)
                                    : <Table.Row error>
                                        <Table.Cell colSpan='8'>Pacotes não encontrados</Table.Cell>
                                    </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                        <Confirm
                            open={open}
                            header={modalProps.header}
                            size="large"
                            content={<FormModal
                                loading={loading}
                                form={form}
                                onChange={setForm}
                            />}
                            cancelButton="Cancelar"
                            confirmButton={modalProps.type === "new"
                                ? <Button
                                    content="Novo pacote"
                                    disabled={loading || _.isEqual(form, _formPackage) || form.preco_venda <= 0 || form.titulo === "" || form.descricao === ""}
                                    onClick={() => submit()}
                                />
                                : <Button
                                    content="Alterar pacote"
                                    disabled={loading || _.isEqual(form, modalProps.selected) || form.preco_venda <= 0 || form.titulo === "" || form.descricao === ""}
                                    //disabled={false}
                                    onClick={() => submit(true)}
                                />
                            }
                            onCancel={() => toggleConfirm(false)}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

const mapStateToProps = ({ pageInfo }) => pageInfo

export default connect(mapStateToProps, actions)(BoxPackages)

const FormModal = (props) => {

    const { form } = props

    const [tags, setTags] = useState(form.itens_inclusos)
    const [tagInput, setTagInput] = useState("")
    const [images, setImages] = useState(form.imagens)
    const [imageInput, setImageInput] = useState("")
    const [dates, setDates] = useState([])
    const [packagesList, setPackageList] = useState([])


    const singleChange = (e, { name, value }) => props.onChange({ ...form, [name]: value })

    const checkChange = (e, { name, checked }) => props.onChange({ ...form, [name]: checked ? 1 : 0 })

    const handleChangeTags = (e, { name, value }) => setTagInput(value)

    const handleChangeImages = (e, { name, value }) => setImageInput(value)


    const getDatesOfPackage = () => {
        noliAPI.rentBoxPackagesDates(form.id).then(res => {
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setDates(res.data)
                    props.onChange({ ...form, periodos: res.data })
                }
            }
        })
    }
    const getPackagesToPeriods = () => {
        noliAPI.rentBoxScheduleDates({ available: true }).then((resp) => {
            if (resp.status === 200) {
                if (resp.data.length > 0) {
                    setPackageList(resp.data)
                }
            }
        })
    }


    useEffect(() => {
        Promise.all([getDatesOfPackage(), getPackagesToPeriods()])
    }, [])


    const handleAddDates = (e, { value }) => {
        const exists = dates.find((item) => item.id === value.id)
        if (!exists) {
            setDates((prevState) => [...prevState, value])
            props.onChange({ ...form, periodos: [...dates, value] })
        }
    }

    const handleAddTag = (e, { value, checked }) => {
        const toAdd = checked ? { description: value } : { description: tagInput }
        if (toAdd !== "") {
            setTags((prevState) => [...prevState, toAdd])
            setTagInput("")
            props.onChange({ ...form, itens_inclusos: [...tags, toAdd] })
        }
    }

    const handleAddImage = (e, { value, checked }) => {
        const toAdd = checked ? { url: value } : { url: imageInput }
        if (toAdd !== "") {
            setImages((prevState) => [...prevState, toAdd])
            setImageInput("")
            props.onChange({ ...form, imagens: [...images, toAdd] })
        }
    }

    const handleRemoveDate = (e, { value }) => {
        let tagsPrev = [...dates]
        const index = dates.indexOf(value)
        if (index !== -1)
            tagsPrev.splice(index, 1)
        setDates(tagsPrev)
        props.onChange({ ...form, periodos: tagsPrev })
    }

    const handleRemoveTag = (e, { value }) => {
        let tagsPrev = [...tags]
        const index = tags.indexOf(value)
        if (index !== -1)
            tagsPrev.splice(index, 1)
        setTags(tagsPrev)
        props.onChange({ ...form, itens_inclusos: tagsPrev })
    }

    const handleRemoveImage = (value) => {
        let imagesPrev = [...images]
        const index = images.indexOf(value)
        if (index !== -1)
            imagesPrev.splice(index, 1)
        setImages(imagesPrev)
        props.onChange({ ...form, imagens: imagesPrev })
    }

    const clearTags = () => {
        setTags([])
        props.onChange({ ...form, itens_inclusos: [] })
    }

    const clearDates = () => {
        setDates([])
        props.onChange({ ...form, periodos: [] })
    }


    const clearImages = () => {
        setImages([])
        props.onChange({ ...form, imagens: [] })
    }


    const RenderDates = () => {
        return dates.map((t, i) =>
            <Label
                as='a'
                onClick={handleRemoveDate}
                value={t}
                key={i}
                content={formatDate.periodsRentBox(t.start_date, t.end_date)}
                color="purple"
                onRemove={handleRemoveDate}
                size="small"
            />)
    }

    const RenderTags = () => {
        return tags.map((t, i) =>
            <Label
                as='a'
                onClick={handleRemoveTag}
                value={t}
                key={t + "-" + i}
                content={t.description}
                color="purple"
                onRemove={handleRemoveTag}
                size="small"
            />)
    }

    const RenderImages = () => {
        return (
            images.map((t, i) =>
                <>
                    <Item key={i}>
                        <Button basic compact attatched="left" onClick={() => handleRemoveImage(t)}>
                            <Item.Image size="mini" src={t.url} inline spaced />
                        </Button>
                    </Item><br />
                </>
            )
        )
    }

    return (
        <Segment basic loading={props.loading}>
            <Form>
                <Form.Group>
                    <Form.Field
                        label="Titulo"
                        control={Form.Input}
                        value={form.titulo}
                        width="4"
                        name="titulo"
                        onChange={singleChange}
                        required
                    />
                    <Form.Field
                        label="Descrição"
                        control={Form.Input}
                        value={form.descricao}
                        width="8"
                        name="descricao"
                        onChange={singleChange}
                        required
                    />
                    <Form.Field
                        label="Qtd. caixas"
                        control={Form.Input}
                        value={form.quantidade_caixas}
                        type="number"
                        step='1'
                        onChange={singleChange}
                        name="quantidade_caixas"
                        min="1"
                        required
                    />
                    <Form.Field
                        label="Qtd. dias"
                        control={Form.Input}
                        value={form.quantidade_dias}
                        type="number"
                        step='1'
                        onChange={singleChange}
                        name="quantidade_dias"
                        min="1"
                        required
                    />
                </Form.Group>
                <Form.Group inline widths="equal" >
                    <Form.Field
                        label="Preço original"
                        control={Form.Input}
                        value={form.preco_original}
                        type="number"
                        step='1'
                        onChange={singleChange}
                        name="preco_original"
                        min="0"
                    />
                    <Form.Field
                        label="Preço de venda"
                        control={Form.Input}
                        value={form.preco_venda}
                        type="number"
                        step='1'
                        onChange={singleChange}
                        name="preco_venda"
                        min="0"
                        required
                    />
                    <Form.Checkbox
                        checked={form.favorito === 1}
                        label="Favorito"
                        name="favorito"
                        onChange={checkChange}
                    />
                    <Form.Checkbox
                        checked={form.ativo === 1}
                        label="Ativo"
                        name="ativo"
                        onChange={checkChange}
                    />
                </Form.Group>
                <Grid>
                    <Grid.Column mobile="16" tablet="16" computer="8">
                        <Label color="grey" ribbon content={"Datas de entrega e retirada"} />
                        <Form.Group inline widths="equal">
                            <Form.Field
                                control={Dropdown}
                                selection
                                fluid
                                style={{ margin: '7px 0' }}
                                options={packagesList?.map((item, index) => {
                                    return { key: index + '-' + index, text: formatDate.periodsRentBox(item.start_date, item.end_date), value: item }
                                })}

                                value={form}
                                onChange={handleAddDates}
                                upward
                                required
                            />
                        </Form.Group>
                    </Grid.Column>
                    <Grid.Column mobile="16" tablet="16" computer="8">
                        <RenderDates />
                        {dates.length > 0 &&
                            <Label
                                as='a'
                                onClick={clearDates}
                                content="Limpar lista"
                                color="red"
                                onRemove={clearDates}
                                size="small"

                            />
                        }
                    </Grid.Column>
                    <Grid.Column mobile="16" tablet="16" computer="8">
                        <Label color="grey" ribbon content={"Itens inclusos"} />
                        <Form.Group inline widths="equal">
                            <Form.Field required>
                                <Input
                                    fluid
                                    placeholder="Informe os itens que estarão inclusos no pacote"
                                    value={tagInput}
                                    name="itemsInput"
                                    onChange={handleChangeTags}
                                    size='small'

                                    action={
                                        <Button
                                            value={tagInput}
                                            type="button"
                                            color='green'
                                            size="small"
                                            icon
                                            onClick={handleAddTag}
                                        >
                                            <Icon name='plus' />
                                        </Button>
                                    }

                                    list="items"
                                    disabled={false}
                                    loading={false}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Grid.Column>
                    <Grid.Column mobile="16" tablet="16" computer="8">
                        <RenderTags />
                        {tags.length > 0 &&
                            <Label
                                as='a'
                                onClick={clearTags}
                                content="Limpar lista"
                                color="red"
                                onRemove={clearTags}
                                size="small"
                            />
                        }
                    </Grid.Column>
                    <Grid.Column mobile="16" tablet="16" computer="8">
                        <Label color="grey" ribbon content={"Imagens de demonstração"} />
                        <Form.Group inline widths="equal">
                            <Form.Field required>
                                <Input
                                    fluid
                                    placeholder="Cole o link das imagens que serão exibidas no pacote"
                                    value={imageInput}
                                    name="imageInput"
                                    onChange={handleChangeImages}
                                    size='small'
                                    action={
                                        <Button
                                            value={imageInput}
                                            type="button"
                                            color='green'
                                            size="small"
                                            icon
                                            onClick={handleAddImage}
                                        >
                                            <Icon name='plus' />
                                        </Button>
                                    }
                                    //labelPosition='right'
                                    list="items"
                                    disabled={false}
                                    loading={false}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Grid.Column>
                    <Grid.Column mobile="16" tablet="16" computer="8">
                        <RenderImages />
                        {images.length > 0 &&
                            <Label
                                as='a'
                                onClick={clearImages}
                                content="Limpar lista"
                                color="red"
                                onRemove={clearImages}
                                size="small"
                            />
                        }
                    </Grid.Column>
                </Grid>
            </Form>
        </Segment>
    )
}
