import React from 'react'
import { Segment, Grid, Header, Button, Form, Confirm, Table, Label, Icon, Input, Radio, Checkbox } from 'semantic-ui-react'
import _ from 'lodash'
import * as noliAPI from '../../utils/noliAPI'
import { connect } from 'react-redux'
import * as actions from '../../utils/actions'
import formatDate from '../../utils/formatDate'

const _fv = {
  atual: 1,
  valor_ajudante: 0,
  valor_escada: 0,
  valor_margem_erro: 0,
  valor_oferta_demanda: 0,
  valor_volume: 0,
  valor_taxa_noli: 30
}

const Taxas = (props) => {

  if (props.title === "Painel Noli") props.setPageTitle('Alterar Taxas de Orçamentos')

  const [loading, setLoading] = React.useState(true)
  const [taxas, setTaxas] = React.useState([])
  const [open, openConfirm] = React.useState(false)
  const [formVal, formChange] = React.useState(_fv)

  if (!props.taxas) props.fetchTaxas()

  React.useEffect(() => {
    setTaxas(props.taxas);
    setLoading(false)
  }, [props.taxas]);

  const addTaxa = () => {
    setLoading(true)
    noliAPI.adicionarTaxaOrcamento(formVal).then(res => {
      props.fetchTaxas()
    })
    openConfirm(false)
  }

  const handleChange = (e, { name, value }) => {
    formChange({ ...formVal, [name]: Number(value) })
  }

  const handleAtualChange = (e, { value }) => {
    setLoading(true)
    noliAPI.alterarTaxaAtual({ id: value }).then(res => {
      props.fetchTaxas()

    })
  }

  const handleDisable = (e, { value }) => {
    setLoading(true)
    noliAPI.alterarTaxaAtual({ id: value, deleted_at: 1 }).then(res => {
      props.fetchTaxas()

    })
  }

  let atual = props.taxas ? props.taxas.filter(t => t.atual === 1)[0].id : ""

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column>
          <Segment loading={loading}>
            <Header as="h3">
              <Button
                as={Label}
                floated="right"
                content="Nova Taxa"
                size="big"
                color="blue"
                icon="add"
                onClick={() => openConfirm(true)}
                disabled={!props.user.permissions[props.permission].create}
              />
              <Icon name="dollar" />
              <Header.Content>Taxas Noli</Header.Content>
            </Header>
            <Form>
              <Form.Group>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>ID</Table.HeaderCell>
                      <Table.HeaderCell>Criada em</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Valor Escadas</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Valor Ajudante</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Valor Oferta/Demanda</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Valor Volume</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Margem de Erro</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Taxa Noli (à receber)</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Atual</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">Desabilitar</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {taxas && taxas.map(t =>
                      <Table.Row
                        key={t.id + "-taxa"}
                        positive={t.atual === 1}
                        error={t.atual !== 1}
                        disabled={!!t.deleted_at || !props.user.permissions[props.permission].update}>
                        <Table.Cell>{t.id}</Table.Cell>
                        <Table.Cell>{formatDate.dateTimeServer(t.created_at)}</Table.Cell>
                        <Table.Cell textAlign="center">{t.valor_escada}</Table.Cell>
                        <Table.Cell textAlign="center">{t.valor_ajudante}</Table.Cell>
                        <Table.Cell textAlign="center">{t.valor_oferta_demanda}</Table.Cell>
                        <Table.Cell textAlign="center">{t.valor_volume}</Table.Cell>
                        <Table.Cell textAlign="center">{t.valor_margem_erro}</Table.Cell>
                        <Table.Cell textAlign="center">{t.valor_taxa_noli}</Table.Cell>
                        <Table.Cell textAlign="center">
                          <Form.Field
                            control={Radio}
                            name="atual"
                            value={t.id}
                            checked={atual === t.id}
                            onChange={handleAtualChange}
                          />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {!t.deleted_at ?
                            <Form.Field
                              control={Checkbox}
                              name="deleted_at"
                              value={t.id}
                              checked={!!t.deleted_at}
                              onChange={handleDisable}
                            />
                            : "desabilitado"}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Form.Group>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Confirm
        open={open}
        header="Nova Taxa Noli"
        cancelButton="Cancelar"
        confirmButton={
          <Button
            icon="add"
            content="Criar Taxa"
            disabled={_.isEqual(formVal, _fv)}
            onClick={addTaxa}
          />
        }
        onCancel={() => openConfirm(false)}
        content={
          <Segment basic>
            <Form>
              <Form.Group widths='2' unstackable>
                <Form.Field
                  control={Input}
                  label="Valor Escadas (R$)"
                  name="valor_escada"
                  value={formVal.valor_escada}
                  onChange={handleChange}
                  type="number"
                  step="0.1"
                  min="0"
                />
                <Form.Field
                  control={Input}
                  label="Valor Ajudante (R$)"
                  name="valor_ajudante"
                  value={formVal.valor_ajudante}
                  onChange={handleChange}
                  type="number"
                  step="0.1"
                  min="0"
                />
                <Form.Field
                  control={Input}
                  label="Oferta/Demanda (%)"
                  name="valor_oferta_demanda"
                  value={formVal.valor_oferta_demanda}
                  onChange={handleChange}
                  type="number"
                  step="0.1"
                  min="0"
                />
                <Form.Field
                  control={Input}
                  label="Valor Volume (R$)"
                  name="valor_volume"
                  value={formVal.valor_volume}
                  onChange={handleChange}
                  type="number"
                  step="0.1"
                  min="0"
                />
                <Form.Field
                  control={Input}
                  label="Margem de Erro (%)"
                  name="valor_margem_erro"
                  value={formVal.valor_margem_erro}
                  onChange={handleChange}
                  type="number"
                  step="0.1"
                  min="0"
                />
                <Form.Field
                  control={Input}
                  label="Taxa Noli (à receber) (%)"
                  name="valor_taxa_noli"
                  value={formVal.valor_taxa_noli}
                  onChange={handleChange}
                  type="number"
                  step="0.1"
                  min="0"
                />
              </Form.Group>
            </Form>
          </Segment>
        }
      />
    </Grid>
  )
}

const mapStateToProps = ({ pageInfo }) => pageInfo

export default connect(mapStateToProps, actions)(Taxas)
