import React, { useEffect, useState } from 'react'
import { Button, Icon, Confirm, Form, Label, Grid, Dropdown } from 'semantic-ui-react'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import SearchTransportadorFilter from '../SearchTransportadorFilter'
import * as noliAPI from '../../utils/noliAPI'
import formatDate from '../..//utils/formatDate'
import moment from 'moment';
import 'moment/locale/pt-br';
import { Link } from 'react-router-dom'
import RentBoxtransporterFilter from './RentBoxtransporterFilter'

const OrcamentoActions = (props) => {
  const { data, is_delivery } = props
  const { delivery, fallback } = data.details.transport
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer="3" tablet="8" mobile="16"><EscolherTransportador {...props} /></Grid.Column>
        <Grid.Column computer="3" tablet="8" mobile="16"><StatusDoTransporte {...props} /></Grid.Column>
         <Grid.Column computer="3" tablet="8" mobile="16"><AlterarData {...props} /></Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default OrcamentoActions


const StatusDoTransporte = (props) => {

  const [open, toggleOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [status, changeStatus] = useState(0)
  const [reason, changeReason] = useState(null)

  const { data, is_delivery } = props
  const { delivery, fallback } = data.details.transport

  const options = [
    { text: `Iniciar ${is_delivery ? 'entrega' : 'retirada'}`, value: 1, key: 1 },
    { text: `Finalizar ${is_delivery ? 'entrega' : 'retirada'}`, value: 2, key: 2 },
    { text: `Para iniciar ${is_delivery ? 'entrega' : 'retirada'}`, value: 3, key: 3 },
    { text: "Cancelar entrega e retirada", value: 4, key: 4 }
  ]

  function onSubmit() {
    let payload
    if (status === 1) {
      payload = {
        transport_order_start: 1,
        transport_order_end: 0,
        transport_canceled: 0,
        info_details: reason
      }
    }
    if (status === 2) {
      payload = {
        transport_order_end: 1,
        transport_order_start: 1,
        transport_canceled: 0,
        info_details: reason
      }
    }
    if (status === 3) {
      payload = {
        transport_order_end: 0,
        transport_order_start: 0,
        transport_canceled: 0,
        info_details: reason,
      }

    }
    if (status === 4) {
      if (!reason) {
        return
      }
      payload = {
        transport_canceled: 1,
        info_details: reason
      }
    }

    if (status > 0) {
      const address_id = is_delivery ? delivery.address.id : fallback.address.id;
      setLoading(true)
      noliAPI.updateRentBoxStatus({ ...payload, address_id, transport_order_id: is_delivery ? delivery.id : fallback.id }, props.data.id).then(res => {
        if (res.status === 200) {
          props.refresher()
          toggleOpen(false)
        }
        setLoading(false)
      })
    } else {
      toggleOpen(false)
    }

  }

  return (
    <>
      <Button
        color={is_delivery ? 'black' : 'teal'}
        icon
        labelPosition="left"
        onClick={() => toggleOpen(true)}
        fluid
      >
        <Icon name="dashboard" />Status da {is_delivery ? 'entrega' : 'retirada'}
      </Button>
      <Confirm
        open={open}
        onCancel={() => toggleOpen(false)}
        onConfirm={onSubmit}
        cancelButton="Cancelar"
        confirmButton="Atualizar Status"
        size="tiny"
        header={`Alterar status do transporte ${is_delivery ? ' (ENTREGA)' : ' (RETIRADA)'}`}
        content={
          <div style={{ margin: 0, padding: "10px 15px" }} className="confirm-form-container">
            <Form onSubmit={onSubmit} loading={loading}>
              <Form.Field
                control={Form.Select}
                options={options}
                required
                label="Novo Status"
                onChange={(e, { value }) => changeStatus(value)}
                placeholder="Selecione um na lista"
              />
              <Form.Field
                control={Form.Input}
                type="text"
                label="Informações adicionais"
                placeholder="Motivo de cancelamento"
                required={status === 4}
                disabled={status !== 4}
                onChange={(e, { value }) => changeReason(value)}
              />
            </Form>
          </div>
        }
      />
    </>
  )
}

const AlterarData = (props) => {
  const { data, is_delivery } = props
  const { schedule } = data.details
  const { id } = data.packages[0]


  const [open, toggleOpen] = useState(false)
  const [date, changeDate] = useState()
  const [deliveryTime, changeDeliveryTime] = useState()
  const [fallbackTime, changeFallbackTime] = useState()
  const [error, setError] = useState('')
  const [dates, setDates] = useState()
  const [times, setTimes] = useState()

  const onPressButton = () => {
    getDatesOfPackage()
    handleGetTimes()
    toggleOpen(true)

  }

  const handleChangeDeliveryDate = (e, { name, value }) => changeDate(value)

  const handleChangeDeliveryTime = (e, { name, value }) => changeDeliveryTime(value)

  const handleChangeFallbackTime = (e, { name, value }) => changeFallbackTime(value)

  const getDatesOfPackage = () => {

    noliAPI.rentBoxScheduleDates({ available: true }).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          setDates(res.data)
          const find = res.data.filter((element) => element.id === schedule.delivery.date_id) 
          if (find[0]) {
            changeDate(find[0])
          }
        }
      }
    })
  }

  const handleGetTimes = () => {
    noliAPI.rentBoxScheduleTimes({ available: true }).then((resp) => {
      if (resp.status === 200) {
        if (resp.data.length > 0) {
          setTimes(resp.data)
          const findDeliveryTime = resp.data.filter((element) => element.id === schedule.delivery.time_id)
          if (findDeliveryTime[0]) {
            changeDeliveryTime(findDeliveryTime[0])
          }
          const findFallbackTime = resp.data.filter((element) => element.id === schedule.fallback.time_id)
          if (findFallbackTime[0]) {
            changeFallbackTime(findFallbackTime[0])
          }
        }
      }
    })
  }

  function onSubmit() {
    if (date) {
      const payload = {
        delivery_time_id: deliveryTime.id,
        fallback_time_id: fallbackTime.id,
        period_id: date.id
      }
      noliAPI.updateRentBoxScheduleToOrder(payload, Number(data.details.id)).then((resp) => {
        if (resp.status === 200) {
          setError("")
          props.refresher()
          toggleOpen(false)
        } else {
          setError("Erro ao atualizar o período.")
        }
      })

    } else setError("Erro: Digite uma data ou selecione-a pelo mouse")
  }




  return (
    <>
      <Button
        color={is_delivery ? 'twitter' : 'orange'}
        icon
        labelPosition="left"
        onClick={onPressButton}
        fluid
      >
        <Icon name="calendar alternate outline" />
        Alterar período
      </Button>
      <Confirm
        open={open}
        onCancel={() => toggleOpen(false)}
        onConfirm={onSubmit}
        cancelButton="Cancelar"
        confirmButton="Alterar Data"
        size="tiny"
        header="Alterar período de entrega e retirada"
        content={
          <div style={{ margin: 0, padding: "10px 15px" }} className="confirm-form-container">
            <Grid>
              <Grid.Column mobile="16" tablet="16" computer="8">
                <Label color="grey" ribbon content={"Datas de entrega e retirada"} />
                <Form.Group inline widths="equal">
                  <Form.Field
                    control={Dropdown}
                    selection
                    fluid
                    style={{ margin: '7px 0' }}
                    options={dates?.map((item, index) => {
                      return { key: index + '-' + index, text: formatDate.periodsRentBox(item.start_date, item.end_date), value: item }
                    })}

                    value={date}
                    onChange={handleChangeDeliveryDate}
                    upward
                    required
                  />
                </Form.Group>

                <p>
                  <Label color="green" ribbon content={"Horários de entrega"} />
                </p>
                <Form.Group inline widths="equal">

                  <Form.Field
                    control={Dropdown}
                    selection
                    fluid
                    style={{ margin: '7px 0' }}
                    options={times?.map((item, index) => {
                      return { key: index + '-' + index, text: formatDate.periodsRentBoxTime(item.start_time, item.end_time), value: item }
                    })}
                    value={deliveryTime}
                    onChange={handleChangeDeliveryTime}
                    upward
                  />
                </Form.Group>
                <p>
                  <Label color="red" ribbon content={"Horários de retirada"} />
                </p>
                <Form.Group inline widths="equal">

                  <Form.Field
                    control={Dropdown}
                    selection
                    fluid
                    style={{ margin: '7px 0' }}
                    options={times?.map((item, index) => {
                      return { key: index + '-' + index, text: formatDate.periodsRentBoxTime(item.start_time, item.end_time), value: item }
                    })}
                    value={fallbackTime}
                    onChange={handleChangeFallbackTime}
                    upward
                  />
                </Form.Group>
              </Grid.Column>
            </Grid>
            {(error) && <Label basic content={error} color="red" style={{ marginTop: "7px" }} />}
          </div>
        }
      />
    </>
  )
}

class EscolherTransportador extends React.Component {

  state = {
    open: false
  }

  form = React.createRef()

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  confirm = () => {
    if (this.form.current.submit()) {
      this.toggleOpen()
      this.props.refresher()
    }
  }

  render() {
    const { data, is_delivery } = this.props
    const { transport, schedule } = data.details
    const { delivery, fallback } = transport
    return (
      <>
        <Button
          color={is_delivery ? 'green' : 'violet'}
          icon
          labelPosition="left"
          onClick={this.toggleOpen}
          fluid>
          <Icon name="truck"
          />Escolher transportador</Button>
        <Confirm
          open={this.state.open}
          onCancel={this.toggleOpen}
          onConfirm={this.confirm}
          cancelButton="Cancelar"
          confirmButton="Selecionar Transportador"
          size="tiny"
          header={"Escolher transportador #" + data.ordem_servico + (is_delivery ? ' (ENTREGA)' : ' (RETIRADA)')}
          content={
            <RentBoxtransporterFilter
              ref={this.form}
              transport_id={data.id}
              transport={is_delivery ? delivery : fallback}
              schedule_date={is_delivery ? schedule.delivery.start_date : schedule.fallback.end_date}
              schedule_time={is_delivery ? schedule.delivery.start_time : schedule.fallback.start_time}
              callRefresh={() => this.props.refresher()}
            />
          }
        />
      </>
    )
  }

}
