import React from 'react'
import {Button, Icon} from 'semantic-ui-react'

class RefresherButton extends React.Component {

  state = {
    timer: this.props.interval, //in seconds
    display: this.props.interval
  }

  componentDidMount(){
    this.startTimer()
  }

  startTimer = () => {
    this.interval = setInterval(() => {
      this.handleTime()
    }, 1000)
  }

  handleTime = () => {
    if(this.state.display > 0) this.setState(prev=>{return {display: prev.display-1}})
    else if(this.state.display === 0) {
      this.finish()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  finish = () => {
    clearInterval(this.interval);
    this.setState({display: this.state.timer}, this.startTimer())
    this.props.onFinish()
  }

  render(){

    return (
      <Button animated='vertical' color="blue" size="tiny" inverted onClick={this.finish}>
        <Button.Content visible>
          Atualizar em {this.props.counter ? this.state.display : "alguns"} segundos
        </Button.Content>
        <Button.Content hidden>
          Atualizar dados agora <Icon name='refresh' />
        </Button.Content>
      </Button>
    )
  }
}

export default RefresherButton
