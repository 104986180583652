import React from 'react'
import {Form} from 'semantic-ui-react'
import {buscarCliente} from '../utils/noliAPI'
import {maskCpf, maskTelefone, unmaskCpf} from '../utils/masks'


class SearchClienteFiltros extends React.Component{

  state = {
    type: "telefone",
    value: "",
    results: []
  }

  options = [
    {text: "Buscar por TELEFONE", value: "telefone"},
    {text: "Buscar por CPF", value: "cpf"},
    {text: "Buscar por EMAIL", value: "email"},
    {text: "Buscar por NOME", value: "nome"}
  ]

  getInputProps = () => {
    switch(this.state.type){
      case "telefone" :
        return {placeholder: "Digite o Telefone cadastrado", maxLength: 15}
      case "cpf":
        return {placeholder: "Digite o CPF do cliente"}
      case "email" :
        return {placeholder: "Digite o Email"}
      default:
        return {placeholder: "Digite"}
    }
  }

  handleChange = (e, {value, name}) => {
    if(name === "value"){
      if(this.state.type === "cpf") this.setState({[name]: maskCpf(value)})
      else if(this.state.type === "telefone") this.setState({[name]: maskTelefone(value)})
      else this.setState({[name]: value})
    }else this.setState({[name]: value, value: ""})
  }

  handleSubmit = () => {
    let value = ""
    if(this.state.type === "cpf") value = unmaskCpf(this.state.value)
    else if(this.state.type === "telefone") value = unmaskCpf(this.state.value)
    else value = this.state.value

    buscarCliente(this.state.type, value)
      .then(res => this.props.onResult(res.data))
  }

  render(){
    const {type, value} = this.state

    return(
      <Form onSubmit={this.handleSubmit} size="mini">
        <Form.Group>
          <Form.Field control={Form.Select}
            options={this.options}
            value={type}
            onChange={this.handleChange}
            name="type"
            label="Filtro"
            required
            width="5"
          />
          <Form.Field label="Valor" required width="7"
            control={Form.Input}
            name="value"
            onChange={this.handleChange}
            value={value}
            {...this.getInputProps()}
            autoFocus
          />
          <Form.Field control={Form.Button}
            type="submit"
            content="BUSCAR CLIENTE"
            label="Buscar"
            primary
            size="mini"
          />
        </Form.Group>
      </Form>
    )
  }
}

export default SearchClienteFiltros
