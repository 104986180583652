import React from 'react'
import { Sidebar, Segment, Grid, Breadcrumb, Header, Icon, Table, Input } from 'semantic-ui-react'
import * as masks from '../utils/masks'
import _ from 'lodash'
import formatDate from '../utils/formatDate'


class DashboardOrcamentoList extends React.Component {

    state = {
        selected: null,
        value: "",
        loading: false,
        results: []
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) { //ESC key code
            this.props.hide()
        }
    }

    handleSearchChange = (e, { value }) => {
        this.setState({ loading: true, value })
        let list = this.props.list
        setTimeout(() => {

            const re = new RegExp(_.escapeRegExp(this.state.value), 'i') 
            const isMatch = (result) => re.test(result.find)
            const filter = _.filter(list, isMatch)
            this.setState({
              loading: false,
              results: filter 
            })
        }, 300)
    } 

    handleRowSelect = (selected, ordem) => {
        this.setState({ selected: { ...selected, ordem: ordem }, results: [], value: "" })
        window.open('/user/' + selected.id + '/box/' + ordem, '_blank');
    }

    handleGetTransportDate = (t) => {
        const { delivery, fallback } = t.details.transport
        if (delivery.transport_end) {
            const { end_date, start_time } = t.details.schedule.fallback
            const check_date = formatDate.scheduleRentBoxDate(end_date, start_time) 
            return (
                <Table.Cell>
                <b>RETIRADA</b>: {formatDate.getDateAndTimeUnixTimestamp(check_date)}
                {t.andamento === 1
                    && (<><br />Início:
                        {this.handleGetTransportTimeStart(t.details)} 
                    </>)
                }
            </Table.Cell>
            )
        } else {
            const { start_date, start_time } = t.details.schedule.delivery
            const check_date = formatDate.scheduleRentBoxDate(start_date, start_time)
            return (
                <Table.Cell>
                <b>ENTREGA</b>: {formatDate.getDateAndTimeUnixTimestamp(check_date)}
                {t.andamento === 1
                    && (<><br />Início:
                        {this.handleGetTransportTimeStart(t.details)}
                    </>)
                }
            </Table.Cell>
            )
        }
    }

    handleGetTransportTimeStart = (data) => {
        const { delivery, fallback } = data.transport
        if (delivery.transport_end) {
            const { transport_start_date } = fallback
            return formatDate.getDateAndTimeUnixTimestamp(transport_start_date)
        } else {
            const { transport_start_date } = delivery
            return formatDate.getDateAndTimeUnixTimestamp(transport_start_date) 
        }
    }

    handleGetTransportAddress = (data) => {
        const { delivery, fallback } = data.transport
        if (delivery.transport_end) {
            const { address } = fallback
            return masks.maskEndereco(address)
        } else {
            const { address } = delivery
            return masks.maskEndereco(address)
        }
    }

    handleGetTransportToTransporter = (data) => {
        const { delivery, fallback } = data.transport
        if (delivery.transport_end) {
            const { transporter } = fallback
            if (transporter) {
                return transporter
            }
            return null
        } else {
            const { transporter } = delivery
            if (transporter) {
                return transporter
            }
            return null
        }
    }

    renderTransporterData = (t) => {
        const transport = this.handleGetTransportToTransporter(t)
        if (transport){
            return (
                <Header as='h5' inverted>
                    <Header.Content>
                        {transport.nome}
                        <Header.Subheader>{masks.maskTelefone(transport.telefone)}</Header.Subheader>
                    </Header.Content>
                </Header >
            )
    } else {
        return "Transportador Indefinido"
    } 


}



renderTable = (results = false) => {
    const list = (results) ? this.state.results : this.props.list
    const getRowColorClass = (iniciado, atrasado, hoje, amanha) => iniciado === 1 ? "green-row" : atrasado ? "red-row" : hoje ? "blue-row" : amanha ? "orange-row" : 'teal-row'

    if (list.length === 0) return (
        <Table.Row negative>
            <Table.Cell colSpan='5' warning>Não encontrado</Table.Cell>
        </Table.Row>
    )
    else return list.map(t => {
        return (
            <Table.Row
                key={t.id}
                className={getRowColorClass(t.andamento, t.atrasado, t.hoje, t.amanha)}
                onClick={() => this.handleRowSelect(t.user, t.id)} style={{ cursor: 'pointer' }} 
            >
                <Table.Cell>
                    <Header inverted as="h4">
                        <Header.Content>
                            #{t.ordem_servico}
                            <Header.Subheader></Header.Subheader>
                        </Header.Content>
                    </Header>
                </Table.Cell>
                <Table.Cell>
                  {this.handleGetTransportDate(t)}
                </Table.Cell>
                <Table.Cell>
                    <Header as='h5' inverted>
                        <Header.Content>
                            {t.user.nome}
                            <Header.Subheader>{masks.maskTelefone(t.user.telefone)}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Table.Cell>
                <Table.Cell>
                    <small>{this.handleGetTransportAddress(t.details)} <b>(Entrega)</b></small>
                    {/*<br />{masks.maskEndereco(t.enderecos[t.enderecos.length-1])} (Último)*/}
                </Table.Cell>
                <Table.Cell>
                    {this.renderTransporterData(t.details)}
                </Table.Cell>
            </Table.Row>
        )
    })
}

hide = (e) => {
    if (e !== null && e.target.className === "pusher dimmed")
        this.props.hide()
}

render() {
    const { visible, list, type, hide } = this.props
    const { loading, results, value, selected } = this.state
    const tableColor = type === "Em Andamento" ? "green" : type === "Agendadas para hoje" ? "blue" : type === "Atrasadas" ? "red" : type === "Agendadas para amanhã" ? "orange" : "teal"
    return (

        <Sidebar
            direction='top'
            animation='push'
            visible={visible}
            onHide={this.hide} 
            style={{ minHeight: "100vh", overflow: 'hidden' }}
        >
            <Segment attached='top' secondary>
                <Grid reversed='mobile'>
                    <Grid.Row>
                        <Grid.Column mobile="16" tablet="10" computer="10">
                            <Breadcrumb size="small" style={{ margin: '12px' }}>
                                <Breadcrumb.Section link onClick={() => hide()}>Dashboard</Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron' />
                                <Breadcrumb.Section
                                    link={selected === null}
                                    active={selected === null}
                                    onClick={list.length > 0 ?
                                        () => this.setState({ results: [], selected: null, value: "" })
                                        : null
                                    }
                                >{type + " (" + list.length + ")"}</Breadcrumb.Section>
                                {selected && (<>
                                    <Breadcrumb.Divider icon='right arrow' />
                                    <Breadcrumb.Section active>
                                        Última ordem clicada: N{selected.id}-{selected.ordem} 
                                    </Breadcrumb.Section>
                                </>)
                                }

                            </Breadcrumb>
                        </Grid.Column>
                        <Grid.Column mobile="16" tablet="6" computer="6">
                            <Input
                                loading={loading}
                                onChange={_.debounce(this.handleSearchChange, 500, {
                                    leading: true,
                                })}
                                results={results}
                                value={value}
                                disabled={list.length === 0} 
                                fluid
                                icon="search"
                                placeholder="Busque por alguma palavra chave" 
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment attached='bottom' placeholder={list.length === 0} loading={loading}>
                {list.length === 0 ? (
                    <Header icon>
                        <Icon name='shipping fast' color="red" />
                        Não existem entregas / retiradas de caixas {type.toLowerCase()}
                    </Header>
                ) : (<>
                    <Header as='h2' icon='shipping fast' content={'Entregas / Retiradas de caixas ' + type.toLowerCase()} />
                    <Table selectable celled inverted size="small" color={tableColor}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Ordem</Table.HeaderCell>
                                <Table.HeaderCell>Agendado para <br />/ Iniciado em</Table.HeaderCell>
                                <Table.HeaderCell>Cliente</Table.HeaderCell>
                                <Table.HeaderCell>Endereço</Table.HeaderCell>
                                <Table.HeaderCell>Transportador</Table.HeaderCell> 
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.renderTable(value !== "")}
                        </Table.Body>
                    </Table>
                </>
                )
                }
            </Segment> 
        </Sidebar>
    )
}
}

export default DashboardOrcamentoList
 