import React from 'react'
import { Accordion, Segment, Header, Icon, Label, Grid, List, Statistic, Container } from 'semantic-ui-react'
import formatDate from '../utils/formatDate'
import ListaItens from './ListaItens'
import DadosPagamento from './DadosPagamento'
import OrcamentoActions from './OrcamentoActions'
import * as masks from '../utils/masks'
import { connect } from 'react-redux'
import DirectionsMap from './DirectionsMap'
import { LoadScript } from '@react-google-maps/api'
import { calculate_percent, money, valuesOfPayment } from '../utils/helpers'

class OrcamentosList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      activeIndex: props.active ? parseInt(props.active) : "",
      scrolled: false
    }
    this.goto = React.createRef()

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.goto.current && (prevState.activeIndex !== this.state.activeIndex || !this.state.scrolled)) {
      if (!this.state.scrolled) this.setState({ scrolled: true })
      this.goto.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      document.title = this.goto.current.id;
    }
  }

  getFilterType = () => {
    switch (this.props.filterType) {
      case 'all': return ""
      case 'open': return "abertos"
      case 'close': return "fechados"
      case 'canceled': return "cancelados"
      default: return ""
    }
  }

  renderNoResults = () => {
    return (
      <Header icon>
        <Icon name='file alternate outline' />
        Não existem orçamentos {this.getFilterType()} para este cliente
      </Header>
    )
  }

  getValuesOfOnlyTransporter = (orcamento) => {
    const { valor, pagamentos, servicos, taxa_noli } = orcamento
    if (valor) {
      return calculate_percent(Number(valor), Number(taxa_noli))
    }
    return valuesOfPayment(pagamentos, servicos, taxa_noli).total_only_transport
  }

  getTotal = (orcamento) => {
    const { pagamentos, servicos, taxa_noli } = orcamento
    const result = valuesOfPayment(pagamentos, servicos, taxa_noli)
    return money(result.total_value)
  }

  getDistance = (distance) => distance ? Number(distance).toFixed(2) : distance

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }

  RenderContainerTotalValue = ({ orcamento }) => (
    <Container textAlign="center">
      <Statistic size='small' >
        <Statistic.Value>{this.getTotal(orcamento)}</Statistic.Value>
      </Statistic>
    </Container>
  )


  RenderContainerDistance = ({ distance }) => (
    <Container textAlign="center" >
      <Statistic size='small'>
        <Statistic.Value>{this.getDistance(distance)} KM</Statistic.Value>

      </Statistic>
    </Container>
  )

  renderResults = () => {

    return (
      <LoadScript
        id={"script-loader-directions"}
        googleMapsApiKey="AIzaSyA1Ro7FjdmhtqE9KG65JeJQSPIsBlQnHrw"
      >
        <Accordion fluid styled>
          {this.props.orcamentos.map(o => {

            const { activeIndex } = this.state
            const { orcamento } = o

            return (
              <div key={orcamento.id} id={orcamento.ordem_servico} ref={orcamento.id === activeIndex && this.goto}>
                <Accordion.Title
                  active={activeIndex === orcamento.id}
                  index={orcamento.id}
                  onClick={this.handleAccordionClick}
                >
                  <Icon name='dropdown' />
                  {this.renderStatusLabel(orcamento)}
                  {this.renderVisualizadoIcon(orcamento.painel_visualizado)}
                  <Label color="black" size='large' horizontal>
                    Ordem
                    <Label.Detail>{orcamento.ordem_servico}</Label.Detail>
                  </Label>
                  <Label color="blue" size='large' horizontal>
                    <Icon name="clock outline" />
                    {formatDate.getDateUnixTimestamp(orcamento.data_transporte)}
                  </Label>
                  {this.renderTransportadorLabel(orcamento.transportador)}
                  {this.renderAjudantesLabel(orcamento.numero_ajudante)}
                  {this.renderAjudantesLabelName(orcamento.ajudantes)}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === orcamento.id}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile="16" tablet="8" computer="8">
                        <Segment raised color="blue" >
                          <Label ribbon color="blue">Distância</Label>
                          <Container textAlign="center">
                            <this.RenderContainerDistance distance={orcamento.distancia_transporte} />
                          </Container>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column mobile="16" tablet="8" computer="8">
                        <Segment raised color="orange">
                          <Label ribbon color="orange">Valor total</Label>
                          <this.RenderContainerTotalValue orcamento={orcamento} />
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row color="violet">
                      <Grid.Column color="violet" computer={orcamento.transportador ? 10 : 16} mobile="16">
                        <List inverted relaxed>
                          <List.Item>
                            <List.Icon name="map outline" />
                            <List.Content>
                              <List.Header>ENDEREÇOS DO TRANSPORTE</List.Header>
                              <List.List>
                                {orcamento.enderecos.map((e, i) => {
                                  return (
                                    <List.Item key={e.id}>
                                      <List.Icon name="map marker alternate" />
                                      <List.Content>
                                        <List.Header>
                                          {e.rua}, {e.numero} - {e.bairro} - CEP {e.cep} - {e.cidade} / {e.estado}
                                        </List.Header>
                                        <List.Description>
                                          {e.complemento !== "" && "Complemento " + e.complemento + " - "}{this.getInformacoesAdicionais(e)}
                                        </List.Description>
                                      </List.Content>
                                    </List.Item>
                                  )
                                })}
                              </List.List>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            {/* <DirectionsMap adresses={orcamento.enderecos} orcamento={orcamento} /> */}
                          </List.Item>
                        </List>
                      </Grid.Column>
                      {orcamento.transportador &&
                        <Grid.Column computer="6" mobile="16">
                          <Segment color="purple" inverted basic>
                            <List inverted relaxed divided>
                              <List.Item
                                icon="truck"
                                header="TRANSPORTADOR SELECIONADO"
                              />
                              <List.Item disabled>
                                <List.List>
                                  <List.Item icon="user" header={orcamento.transportador.nome} />
                                  <List.Item icon="phone square" header={"TEL: " + masks.maskTelefone(orcamento.transportador.telefone)} />
                                  <List.Item icon="drivers license" header={"CPF: " + masks.maskCpf(orcamento.transportador.cpf)} />
                                </List.List>
                              </List.Item>
                            </List>
                          </Segment>
                        </Grid.Column>
                      }
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column mobile="16" tablet="8" computer="10">
                        {orcamento.servicos && orcamento.servicos.protecao_itens && this.renderProtectionService(orcamento.servicos.protecao_itens)}
                        {orcamento.servicos && orcamento.servicos.montagem_desmontagem && orcamento.servicos.montagem_desmontagem.length > 0 && this.renderAssemblyDisassembly(orcamento.servicos.montagem_desmontagem)}
                        <ListaItens itens={orcamento.itens} customizados={orcamento.itens_customizados} />
                      </Grid.Column>
                      <Grid.Column mobile="16" tablet="8" computer="6">
                        <DadosPagamento
                          services={orcamento.servicos}
                          items={orcamento?.itens}
                          custom_items={orcamento?.itens_customizados}
                          dataOfTransporter={() => this.getValuesOfOnlyTransporter(orcamento)}
                          data={orcamento.pagamentos}
                          refresher={this.props.refresher}
                          clienteInfo={{
                            cliente_id: orcamento.cliente_id,
                            ordem_servico: orcamento.ordem_servico
                          }}
                          transport_id={orcamento.id}
                          complementar={orcamento.info_complementar}
                          canChange={!!this.props.user.permissions[this.props.permission].update}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {!!this.props.user.permissions[this.props.permission].update &&
                      <Grid.Row>
                        <Grid.Column color="black" mobile="16" tablet="16" computer="16">
                          <Icon name="cogs" /> GERENCIAR ORÇAMENTO
                          <Segment basic>
                            <OrcamentoActions
                              data={orcamento} 
                              refresher={this.props.refresher}
                            />
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    }
                  </Grid>
                </Accordion.Content>
              </div>)
          })
          }
        </Accordion>
      </LoadScript>
    )
  }

  renderProtectionService = (data) => {
    return (
      <List.Item>
        <List relaxed>
          <List.Header>
            <Label color="blue" ribbon content={"Proteção de itens"} />
          </List.Header>
          <List.Item >
            <List.List>
              {data.titulo && <p><List.Icon name="boxes" />Tipo: {data.titulo}</p>}
              {data.data_agendamento && <p><List.Icon name="calendar plus" />Empacotamento agendado para: {formatDate.getDateUnixTimestamp(data.data_agendamento)}</p>}
            </List.List>
          </List.Item>
        </List>
      </List.Item>
    )
  }

  renderAssemblyDisassembly = (data) => {
    return (
      <List.Item style={{ marginTop: 30, marginBottom: 15 }}>
        <List relaxed>
          <List.Header>
            <Label color="purple" ribbon content={"Montagem/Desmontagem"} />
          </List.Header>
          {data.map((element) => {
            return <List.Item key={element.id.toString()}>
              <List.List>
                <p><List.Icon name="wrench" />{element.titulo}: {element.qtd_item === 1 ? '1 móvel' : element.qtd_item + ' itens'}</p>
              </List.List>
            </List.Item>
          })}
        </List>
      </List.Item>
    )
  }

  renderStatusLabel = (data) => {
    if (data.transporte_cancelado === 1)
      return <Label color="red" icon="cancel" content="Cancelado" size='tiny' basic className="float-right orcamentos-status-label" />
    if (data.transporte_iniciado === 0)
      return <Label color="teal" icon="play circle" content="Para iniciar" size='tiny' basic className="float-right orcamentos-status-label" />
    if (data.transporte_iniciado === 1 && data.transporte_finalizado === 0)
      return <Label color="brown" icon="truck" content="Iniciado" size='tiny' basic className="float-right orcamentos-status-label" />
    if (data.transporte_iniciado === 1 && data.transporte_finalizado === 1)
      return <Label color="green" icon="flag checkered" content="Finalizado" size='tiny' className="float-right orcamentos-status-label" />
  }

  renderTransportadorLabel = (transportador) => {
    if (transportador !== null) {
      return (
        <Label color="violet" size='large' className="transportador-label" horizontal>
          <Icon name='truck' />
          {transportador.nome}
        </Label>
      )
    } else {
      return (
        <Label color="violet" basic size='large' horizontal>
          <Icon name='truck' />
          Transportador não selecionado
        </Label>
      )
    }
  }

  renderVisualizadoIcon = (visualizado = null) => {
    if (!visualizado || visualizado.valor === 0)
      return (
        <Label icon="eye slash" color="grey" basic content="Não visualizado" size="tiny" className="float-right visualizado-label" />
      )
    else if (visualizado.valor === 1) {
      return (
        <Label icon="eye" color="green" basic content="Visualizado" size="tiny" className="float-right visualizado-label" />
      )
    }
  }

  renderAjudantesLabel = (num) => {
    if (num > 0)
      return <Label icon="users" color="yellow" content={num + " ajudante(s)"} size="small" className="ajudante-label" />
    else
      return <Label icon="users" color="yellow" basic content={"Sem ajudante"} size="small" className="ajudante-label" />
  }

  renderAjudantesLabelName = (helpers: []) => {
    if (helpers.length > 0) {
      return helpers.map((item) => {
        return <Label icon="users" color="yellow" content={item.first_name} size="small" className="ajudante-label" />
      })
    }
  }


  getInformacoesAdicionais = (e) => {
    if ((e.predio === null || e.predio === 0) && (e.sobrado === null || e.sobrado === 0) && (e.andares === null || e.andares === 0)) {
      return 'Imóvel térreo'
    } else if ((e.predio === null || e.predio === 0) && (e.sobrado == null || e.sobrado === 0) && e.andares > 0) {
      return `Imóvel com ${e.andares} lance de escada`
    } else if ((e.predio === null || e.predio === 0) && e.sobrado === 1) {
      return `Sobrado`
    } else if (e.predio === 1 && e.escadas === 1) {
      if (e.andares === null || e.andares === 0) {
        return `Prédio sem elevador / Sem andares de escada`
      }

      if (e.andares === 1) {
        return `Prédio sem elevador / 1 andar de escada`
      }

      if (e.andares > 1) {
        return `Prédio sem elevador / ${e.andares} andares de escada`
      }
    } else if (e.predio === 1 && (e.escadas === null || e.escadas === 0)) {
      if (e.andares === null || e.andares === 0) {
        return `Prédio com elevador / Sem andares`
      }

      if (e.andares === 1) {
        return `Prédio com elevador / 1 andar`
      }

      if (e.andares > 1) {
        return `Prédio com elevador / ${e.andares} andares`
      }
    }
  }

  render() {
    const { loading, orcamentos } = this.props
    return (
      <Segment basic loading={loading} placeholder={!!!orcamentos}>
        {!!!orcamentos ? this.renderNoResults() : this.renderResults()}
      </Segment>
    )
  }
}

const mapStateToProps = ({ user }) => { return { user } }

export default connect(mapStateToProps)(OrcamentosList)
