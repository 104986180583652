import React, { useEffect, useState } from 'react'
import { Accordion, Grid, List, Button, Portal, Image, Dropdown, Segment, Form, Confirm, Label, Icon, Popup, Checkbox } from 'semantic-ui-react'
import formatDate from '../utils/formatDate'
import * as masks from '../utils/masks'
import * as actions from '../utils/actions'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { api_url, associarCategoriasDeContatoDeTransportadores, buscarCategoriaContatoTransportadores, createTransportersOperationIdentifier, updateTransportersOperationIdentifier } from '../utils/noliAPI'
import { AxiosResponse, AxiosRequestConfig } from 'axios'
import { getCategoryFlagByColor } from '../utils/helpers'
const api = require('../constants')
const formDefault = {
  id: null,
  name: null,
  operation_name: null,
  details: null,
  available: false
}

class TransportadorContent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      popupImage: "",
      categorySelected: null,
      categoryDetail: null,
      operation_identifier_selected: null,
      loading: false,
      categories: [],
      toggleConfirm: false,
      colorBackgroundName: 'white'
    }
    this.form = React.createRef();
  }

  componentDidMount() {
    const { data, transportersContactCategory } = this.props
    const { categorySelected, categoryDetail } = this.state
    if (data) {
      if (data.category_contact) {
        if (data?.category_contact?.id && transportersContactCategory.data[0]) {
          const find = transportersContactCategory.data.filter((item) => item.id === data.category_contact.id)
          if (find[0]) {
            const { description_to_transporter } = data.category_contact
            this.setState({ categorySelected: find[0].value, categoryDetail: description_to_transporter ? description_to_transporter : null })
          }
        }
      }

      if (data.operation_identifier) {
        this.setState({ operation_identifier_selected: { name: data.operation_identifier.name, id: data.operation_identifier.id } })
      }
    }
  }

  openPopup = (e, { value }) => {
    this.setState({ open: true, popupImage: value })
  }

  onChangeCategory = (e, item) => {
    const { data, transportersContactCategory } = this.props
    this.setState({ categorySelected: item.value })
  }

  onSubmit = () => {
    const { data, transportersContactCategory } = this.props
    const { categorySelected, categoryDetail } = this.state
    if (categorySelected && transportersContactCategory.data[0]) {
      this.setState({ loading: true })
      const find = transportersContactCategory.data.filter((element) => element.value === categorySelected)
      if (find[0]) {
        associarCategoriasDeContatoDeTransportadores({
          category_id: find[0].id,
          transporter_id: data.id,
          available: true,
          description: categoryDetail
        }).then((resp: AxiosResponse) => {
          this.props.changeColorBackgroundName && this.props.changeColorBackgroundName(find[0].color)
          this.setState({ loading: false, toggleConfirm: false })
        }).catch(() => {
          this.setState({ loading: false, toggleConfirm: false })
        })
      }
    }
  }

  getColorCategorySelected = (value: string) => {
    if (value) {
      const getColor = this.props.transportersContactCategory.data.filter((item) => item.value === value)
      //this.setState({ colorBackgroundName: getColor[0].color })
      return getColor[0].color
    }
  }

  ContactCategory = (props) => {
    //let form = React.createRef();
    const { categorySelected, categoryDetail } = this.state
    const backgroundColor = categorySelected ? this.getColorCategorySelected(categorySelected) : 'white'

    return (
      <>
        <Popup content={categoryDetail ? categoryDetail : 'Adicione uma observação.'} position='bottom center' trigger={
          <Button

            fluid
            color={backgroundColor}
            icon="phone"
            content={categorySelected ? categoryDetail ? categorySelected + ' + OBS.' : categorySelected : "Etiquetas de contato"}
            labelPosition='left'
            onClick={() => this.setState({ toggleConfirm: true })}
          />}
        />
        <Confirm
          open={this.state.toggleConfirm}
          onCancel={() => this.setState({ toggleConfirm: false })}
          onConfirm={() => this.onSubmit()}
          cancelButton="Cancelar"
          confirmButton="Salvar"
          size="tiny"
          header="Etiquetas de contato"
          content={
            <div style={{ margin: 0, padding: "10px 15px" }}>
              <Form onSubmit={this.onSubmit} loading={this.state.loading} >
                <Form.Field
                  label="Categorias"
                  required
                  control={Form.Select}
                  onChange={this.onChangeCategory}
                  value={categorySelected}
                  style={{ background: backgroundColor, color: categorySelected ? 'white' : 'black' }}
                  options={this.props.transportersContactCategory.data}
                  placeholder="Selecione uma opção"
                />
                <Form.Field
                  label="Observação"
                  control={Form.Input}
                  type="text"
                  placeholder="Adicione uma observação"
                  defaultValue={categoryDetail}
                  onChange={(e) => this.setState({ categoryDetail: e.target.value })}
                />
              </Form>
            </div>
          }
        />
      </>
    )
  }

  OperationIdentifier = (props) => {
    //let form = React.createRef();
    const { operation_identifier, id } = this.props.data





    const [form, setForm] = useState(operation_identifier ? {
      ...operation_identifier,
      available: !operation_identifier.available
    } : formDefault)


    const [loading, setLoading] = useState(false)
    const [toggleOperationIdentifier, setToggleOperationIdentifier] = useState(false)

    const onPressConfirm = async () => {

      const { name, operation_name, details, available } = form
      const { operation_identifier_selected } = this.state

      if (!name || !name.trim()) {
        return this.props.sendMessage([], "Erro. campo identificador é obrigatório", { color: 'red', icon: "times circle" }, false)
      }

      if (!operation_identifier_selected && Boolean(available)) {
        return this.props.sendMessage([], "Erro. Para desativar é necessário um identificador ativo para este cadastro.", { color: 'red', icon: "times circle" }, false)
      }

      const payload = {
        available: Boolean(!available),
        ...name && { name: name },
        ...operation_name && { operation_name: operation_name },
        ...details && { details: details }
      }


      setLoading(true)
      let callResponse: AxiosResponse
      if (operation_identifier_selected?.id) {
        callResponse = await updateTransportersOperationIdentifier(payload, operation_identifier_selected?.id)

      } else {
        callResponse = await createTransportersOperationIdentifier({ ...payload, transporter_id: id })
      }
      setLoading(false)
      if (callResponse.status === 200) {
        const { id } = callResponse.data
        if (Boolean(available) && operation_identifier_selected) {
          setForm(formDefault)
          this.setState({ operation_identifier_selected: null })
        } else {

          this.setState({ operation_identifier_selected: { name: name, id: id } })
        }

        setToggleOperationIdentifier(false)
        return this.props.sendMessage([], operation_identifier_selected?.id ? "Dados alterados com sucesso!" : "Dados criados com sucesso!", { color: 'blue', icon: "check circle" }, false)
      } else {
        return this.props.sendMessage([], operation_identifier_selected?.id ? "Erro ao tentar alterar dados do parceiro" : "Erro ao criar alterar dados do parceiro", { color: 'red', icon: "times circle" }, false)
      }


    }

    const onChangeValues = (e, { name, value }) => {
      setForm((prevState) => {
        return {
          ...prevState,
          [name]: value
        }
      })
    }



    const handleCheck = (e, { checked }) => {
      setForm((prevState) => {
        return {
          ...prevState,
          available: checked
        }
      })
    }

    return (
      <>
        <Button
          fluid
          color={form?.name ? 'purple' : 'grey'}
          icon='edit'
          content={form?.name ? form.name : "Identificador de operação/serviço"}
          labelPosition='left'
          onClick={() => setToggleOperationIdentifier(true)}
        />
        <Confirm
          open={toggleOperationIdentifier}
          onCancel={() => setToggleOperationIdentifier(false)}
          onConfirm={onPressConfirm}
          cancelButton="Cancelar"
          confirmButton="Salvar"
          size="tiny"
          header="Identificador de operação/serviço"
          content={
            <div style={{ margin: 0, padding: "10px 15px" }}>
              <Form onSubmit={onPressConfirm} loading={loading} >
                <Form.Group inline widths="equal">
                  <Form.Field
                    label="Identificador"
                    control={Form.Input}
                    type="text"
                    placeholder="Ex: FRU1234"
                    defaultValue={form.name}
                    name='name'
                    onChange={onChangeValues}
                    required
                  />
                  <Form.Field
                    label="Nome da operação"
                    control={Form.Input}
                    type="text"
                    placeholder="Ex: Frubana"
                    name='operation_name'
                    defaultValue={form.operation_name}
                    onChange={onChangeValues}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    label="Detalhes"
                    control={Form.TextArea}
                    value={form.details}
                    name='details'
                    width="8"
                    onChange={onChangeValues}
                  />
                </Form.Group>
                <Form.Group widths={'8'}>
                  <Form.Field
                    control={Checkbox}
                    label={'Desativar'}
                    checked={form.available}
                    onChange={handleCheck}
                  />
                </Form.Group>

              </Form>

            </div>
          }
        />
      </>
    )
  }

  RenderAuthState = () => {
    const { is_auth, work_auth } = this.props.data
    return [
      work_auth === 1 ? <Label color="green" key="apto4" content="Apto a trabalhar" size="mini" className="float-right autorizado-label" />
        : <Label color="red" key="apto3" basic content="Inapto a trabalhar" size="mini" className="float-right autorizado-label" />,
      is_auth === 1
        ? <Label color="teal" key="apto1" basic content="App Autorizado" size="mini" className="float-right autorizado-label" />
        : <Label color="red" key="apto2" content="App Não Autorizado" size="mini" className="float-right autorizado-label" />
    ]
  }


  RenderAccordian = () => {
    const { data } = this.props
    const { open, popupImage, categorySelected, loading } = this.state
    const url = `${api_url}` + 'funcionario/getimage/?url=' + popupImage

    return (
      <Accordion.Content active={this.props.active}>
        <Grid padded>
          <Grid.Row>
            <Grid.Column mobile="16" tablet="16" computer="6">
              <List relaxed>
                <List.Item icon="user" content={data.nome !== null ? data.nome.toUpperCase() : "Cadastrado sem nome"} style={styles.itemHeader} />
                <List.Item>
                  <List divided>
                    <List.Item icon="phone" header={masks.maskTelefone(data.telefone)} />
                    <List.Item icon="calendar check outline" header={"Cadastrado em " + formatDate.dateBR(data.created_at)} />
                    {this.props.data.data_nascimento &&
                      <List.Item>
                        <List.Icon name="birthday cake" />
                        <List.Content>
                          <b>Nascimento: </b> {formatDate.dateBR(this.props.data.data_nascimento)}
                        </List.Content>
                      </List.Item>
                    }
                    {this.props.data.cnh_numero &&
                      <List.Item>
                        <List.Icon name="address card" />
                        <List.Content>
                          <b>Número da CNH: </b> {this.props.data.cnh_numero}
                        </List.Content>
                      </List.Item>
                    }
                    {this.props.data.endereco_id &&
                      <List.Item>
                        <List.Icon name="marker" />
                        <List.Content>
                          <b>Endereço: </b> {data.rua}, {data.numero} - {data.bairro} - {data.cep && "CEP: " + data.cep} / {data.complemento} - {data.cidade}/{data.estado}
                        </List.Content>
                      </List.Item>
                    }
                  </List>
                </List.Item>
              </List>
              {data.tipo_servicos.length > 0 &&
                <List style={{ marginBottom: 10 }}>
                  <List.Item content="SERVIÇOS PRESTADOS" icon="wrench" style={styles.itemHeader} />
                  {data.tipo_servicos.map((t, i) =>
                    <List.Item
                      key={t.id + '-' + i}
                      icon="triangle right"
                      content={t.descricao}
                    />
                  )}
                </List>
              }
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="6" style={{ marginBottom: 15 }}>
              <List relaxed>
                <List.Item icon="shipping fast" content="DADOS DO VEÍCULO" style={styles.itemHeader} />
                <List.Item>
                  <List divided>
                    <List.Item>
                      <List.Icon name="truck" />
                      <List.Content><b>Tipo de veículo:</b> {data.tipo_veiculo ? data.tipo_veiculo : "N/I"}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="archive" />
                      <List.Content><b>Tipo de carroceria:</b> {data.tipo_carroceria ? data.tipo_carroceria : "N/I"}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="star" />
                      <List.Content><b>Marca:</b> {data.marca ? data.marca : "N/I"}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="car" />
                      <List.Content><b>Modelo:</b> {data.modelo ? data.modelo + " (ano " + data.ano + ")" : "N/I"}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="tag" />
                      <List.Content><b>Placa:</b> {data.placa_letras ? data.placa_letras + data.placa_numeros : "N/I"}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="tint" />
                      <List.Content><b>Cor:</b> {data.cor ? data.cor : "N/I"}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="arrows alternate" />
                      <List.Content>
                        <b>Altura:</b> {data.altura ? data.altura : " N/I "} - <b>Largura:</b> {data.largura ? data.largura : " N/I "} - <b>Comprimento:</b> {data.comprimento ? data.comprimento : " N/I "}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="qrcode" />
                      <List.Content>
                        <b>RNTRC:</b> {data.tipo_rntrc ? data.tipo_rntrc : "N/I"} {data.numero_rntrc ? data.numero_rntrc : "N/I"}
                      </List.Content>
                    </List.Item>
                  </List>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="4" color="black" stretched >
              <List inverted>
                <List.Item icon="cogs" header="AÇÕES" />
                {data.cnh &&
                  <List.Item content={
                    <Button
                      color="orange"
                      fluid
                      icon="address card"
                      labelPosition="left"
                      content="Foto da CNH"
                      onClick={this.openPopup}
                      value={data.cnh}
                    />
                  } />
                }
                {data.comprovante &&
                  <List.Item content={
                    <Button
                      color="orange"
                      fluid
                      icon="map marker alternate"
                      labelPosition="left"
                      content="Comprov. redidência"
                      onClick={this.openPopup}
                      value={data.comprovante}
                    />
                  } />
                }
                {data.documento_veiculo &&
                  <List.Item content={
                    <Button
                      color="orange"
                      fluid
                      icon="truck"
                      labelPosition="left"
                      content="Doc. do veículo"
                      onClick={this.openPopup}
                      value={data.documento_veiculo}
                    />
                  } />
                }
                <List.Item content={
                  <Button
                    color="blue"
                    fluid
                    icon="edit outline"
                    labelPosition="left"
                    content="Editar cadastro"
                    as={Link}
                    to={"/cadastro/transportador/" + data.id}
                    target="_blank"
                    disabled={!this.props?.user?.permissions?.modulo_transportadores?.update}
                  />
                } />

                {this.props.transportersContactCategory.data[0] &&
                  <List.Item content={
                    <this.ContactCategory />
                  } />
                }
                <List.Item content={
                  <this.OperationIdentifier {...this.props} />
                } />

              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Portal onClose={() => this.setState({ open: false })} open={open}>
          <div
            style={{
              left: 0,
              position: 'fixed',
              top: 0,
              zIndex: 1000,
              width: "100%"
            }}
          >
            <Grid padded centered columns="1" className="full-height-grid" onClick={() => this.setState({ open: false })}>
              <Grid.Column
                mobile="16"
                computer="6"
                tablet="8"
                stretched
                verticalAlign="middle"
              >
                <Image fluid src={url} />
              </Grid.Column>
            </Grid>
          </div>
        </Portal>
      </Accordion.Content>
    )
  }

  render() {
    const { data, index, active } = this.props
    const { categorySelected, categoryDetail, operation_name, operation_identifier_selected } = this.state
    const backgroundColor = categorySelected ? this.getColorCategorySelected(categorySelected) : 'white'
    return (
      //<this.RenderAccordian />
      <div>
        <Accordion.Title
          active={active === index}
          index={index}
          onClick={this.props.onClick}
        >
          <Icon name='dropdown' />
          <this.RenderAuthState />
          <Label icon="mail" color="blue" basic content={data.email ? data.email : "Email não cadastrado"} size="tiny" className="float-right email-label" />
          <Label icon="address card" basic color="black" content={data.cpf ? masks.maskCpf(data.cpf) : "CPF não cadastrado"} size="tiny" className="float-right cpf-label" />
          <Label icon="phone square" color="black" content={data.telefone ? masks.maskTelefone(data.telefone) : ""} size="tiny" />
          <Popup content={categoryDetail ? categoryDetail : 'Nenhuma observação.'} position='bottom center' trigger={
            <Label size="large" color={backgroundColor} content={data.nome} />
          } />
          {operation_identifier_selected && <Label icon="edit" color="purple" content={operation_identifier_selected.name} size="tiny" />}
        </Accordion.Title>
        <this.RenderAccordian />
      </div>

    )
  }
}

const mapStateToProps = ({ pageInfo, user, transportersContactCategory }) => {
  return {
    pageInfo, user, transportersContactCategory
  };
};

export default connect(mapStateToProps, actions)(TransportadorContent)

const styles = {
  itemHeader: { color: '#1F88BE', fontWeight: 'bold' }
}


