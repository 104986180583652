import React from 'react'
import { Sidebar, Dimmer } from 'semantic-ui-react'
import SideMenu from '../../components/SideMenu'
import TopBar from '../../components/TopBar'
import AppMessages from '../../components/AppMessages' 
import Routes from '../Routes'
import { BrowserRouter } from 'react-router-dom'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'

class AppStructure extends React.Component { 

  constructor(props){
    super(props)
    this.state = {
      visible: false
    }
  }

  hideMenu = () => {
    this.setState({visible: false})
  }

  openMenu = () => {
    this.setState({visible: true})
  }

  render(){

    const {visible} = this.state
    const {dimmed, visibleDim} = this.props.pageInfo
    return(
      <BrowserRouter>
        <Dimmer.Dimmable dimmed={dimmed}>
          <Sidebar.Pushable style={{transform: "none"}}>
            <SideMenu
              visible={visible}
              hide={this.hideMenu}
              logout={this.props.logout}
              user={this.props.user}
            />
            <Sidebar.Pusher style={{minHeight: "100vh"}}>
              <TopBar openMenu={this.openMenu} check={this.props.check} />
              <Routes logout={this.props.logout} check={this.props.check} user={this.props.user} /> 
            </Sidebar.Pusher>
          </Sidebar.Pushable>
          <Dimmer
            active={dimmed}
            style={!visibleDim ? {opacity:0, zIndex:999} : {}}
            onClickOutside={() => this.props.resetMessage()}
          >
            <AppMessages />
          </Dimmer>
        </Dimmer.Dimmable>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = ({ pageInfo, user }) => {
	return {
		pageInfo, user
	};
};

export default connect(mapStateToProps, actions)(AppStructure)
