import React, { useState } from 'react'
import { Button, Icon, Confirm, Form, Label, Grid } from 'semantic-ui-react'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import SearchTransportadorFilter from './SearchTransportadorFilter'
import * as noliAPI from '../utils/noliAPI'
import formatDate from '../utils/formatDate'
import moment from 'moment';
import 'moment/locale/pt-br';
import { Link } from 'react-router-dom'
import SearchModalTransporterFilter from './transporters/helpers/SearchModalTransporterFilter'

const OrcamentoActions = (props) => {
  return (
    <Grid>
      <Grid.Row divided>
        <Grid.Column computer="3" tablet="8" mobile="16"><MarcarVisualizadoBtn {...props} /></Grid.Column>
        <Grid.Column computer="3" tablet="8" mobile="16"><EscolherTransportador {...props} /></Grid.Column>
        <Grid.Column computer="3" tablet="8" mobile="16"><EscolherAjudantes {...props} /></Grid.Column>
        <Grid.Column computer="3" tablet="8" mobile="16"><StatusDoTransporte {...props} /></Grid.Column>
        <Grid.Column computer="3" tablet="8" mobile="16"><AlterarData {...props} /></Grid.Column>

      </Grid.Row>
      <Grid.Row divided>
        <Grid.Column computer="3" tablet="8" mobile="16"><AlterarDadosOrcamento {...props} /></Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default OrcamentoActions

const AlterarDadosOrcamento = (props) => {

  const [loading, setLoading] = useState(false)

  function setVisualizado() {
  }

  return (
    <Button
      loading={loading}
      disabled={loading}
      color={"purple"}
      icon
      labelPosition="left"
      fluid
      as={Link}
      to={"/cadastro/orcamento/" + props.data.id}
      target="_blank"
    >
      <Icon name="settings"
      />Alterar orçamento
    </Button>
  )
}

const MarcarVisualizadoBtn = (props) => {

  const [loading, setLoading] = useState(false)
  const visualizado = (props.data.painel_visualizado) ? (props.data.painel_visualizado.valor === 1) ? true : false : false

  function setVisualizado() {
    setLoading(true)
    const payload = { orcamento_id: props.data.id, valor: (visualizado) ? 0 : 1 }
    noliAPI.orcamentoVisualizado(payload).then(res => {
      setLoading(false)
      if (res.status === 200)
        props.refresher()
    })
  }

  return (
    <Button
      loading={loading}
      disabled={loading}
      color={visualizado ? "green" : "grey"}
      basic={!visualizado}
      icon
      labelPosition="left"
      onClick={setVisualizado}
      fluid
    >
      <Icon name={visualizado ? "eye" : "eye slash outline"} />
      {visualizado ? "Desmarcar como visualizado" : "Marcar como visualizado"}
    </Button>
  )
}

const StatusDoTransporte = (props) => {

  const [open, toggleOpen] = useState(false)
  const [status, changeStatus] = useState(0)
  const [reason, changeReason] = useState("")

  const options = [
    { text: "Iniciar", value: 1, key: 1 },
    { text: "Finalizar", value: 2, key: 2 },
    { text: "Cancelar", value: 3, key: 3 },
    { text: "Para Iniciar", value: 4, key: 4 },
  ]

  function onSubmit() {
    let payload
    if (status === 1) payload = { transporte_iniciado: 1, transporte_finalizado: 0, transporte_cancelado: 0, info_complementar: reason }
    if (status === 2) payload = { transporte_finalizado: 1, transporte_iniciado: 1, info_complementar: reason }
    if (status === 3) payload = { transporte_cancelado: 1, info_complementar: reason }
    if (status === 4) payload = { transporte_cancelado: 0, info_complementar: reason, transporte_iniciado: 0, transporte_finalizado: 0 }
    if (status > 0) {
      noliAPI.atualizarStatusOrcamento(payload, props.data.id).then(res => {
        props.refresher()
        toggleOpen(false)
      })
    } else toggleOpen(false)

  }

  return (
    <>
      <Button
        color="teal"
        icon
        labelPosition="left"
        onClick={() => toggleOpen(true)}
        fluid
      >
        <Icon name="dashboard" />Status do transporte
      </Button>
      <Confirm
        open={open}
        onCancel={() => toggleOpen(false)}
        onConfirm={onSubmit}
        cancelButton="Cancelar"
        confirmButton="Atualizar Status"
        size="tiny"
        header="Alterar status do transporte"
        content={
          <div style={{ margin: 0, padding: "10px 15px" }} className="confirm-form-container">
            <Form onSubmit={onSubmit}>
              <Form.Field
                control={Form.Select}
                options={options}
                required
                label="Novo Status"
                onChange={(e, { value }) => changeStatus(value)}
                placeholder="Selecione um na lista"
              />
              <Form.Field
                control={Form.Input}
                type="text"
                label="Informações adicionais"
                placeholder="Motivo de cancelamento"
                required={status === 3}
                disabled={status !== 3}
                onChange={(e, { value }) => changeReason(value)}
              />
            </Form>
          </div>
        }
      />
    </>
  )
}

const AlterarData = (props) => {
  const [open, toggleOpen] = useState(false)
  const [date, changeDate] = useState("")
  const [error, setError] = useState('')

  function onSubmit() {
    if (date.length > 0) {
      //TODO VALIDAR DADOS ANTES DE ENVIAR
      if (moment(date).isValid() && formatDate.checkChangeDate(moment(date).format('X'))) {
        const payload = {
          data_transporte: formatDate.getTimestamp(date),
          //cliente_id: props.data.cliente_id,
          //transportador_id: props.data.transportador_id
        }
        noliAPI.atualizarDataTransporte(props.data.id, payload).then(res => {
          setError("")
          props.refresher()
          toggleOpen(false)
        })
      } else {
        setError("Erro: A data não pode ser anterior a hoje")
      }
    } else setError("Erro: Digite uma data ou selecione-a pelo mouse")
  }

  return (
    <>
      <Button
        color="orange"
        icon
        labelPosition="left"
        onClick={() => toggleOpen(true)}
        fluid
      >
        <Icon name="calendar alternate outline" />Alterar data do transporte
      </Button>
      <Confirm
        open={open}
        onCancel={() => toggleOpen(false)}
        onConfirm={onSubmit}
        cancelButton="Cancelar"
        confirmButton="Alterar Data"
        size="tiny"
        header="Alterar data do transporte"
        content={
          <div style={{ margin: 0, padding: "10px 15px" }} className="confirm-form-container">
            <DateTimeInput
              onChange={(event, { value }) => changeDate(value)}
              fluid
              value={date}
              dateTimeFormat="YYYY-MM-DDTHH:mm:ss"
              iconPosition="left"
              localization='pt-br'
              popupPosition="top center"
              closable
              clearable
              placeholder="Selecione uma nova data para o transporte"
              label="Nova data"
            />
            {(error) && <Label basic content={error} color="red" style={{ marginTop: "7px" }} />}
          </div>
        }
      />
    </>
  )
}

class EscolherTransportador extends React.Component {

  state = {
    open: false
  }

  form = React.createRef()

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  confirm = () => {
    if (this.form.current.submit()) {
      this.toggleOpen()
      this.props.refresher()
    } else {
      //console.log('error submit ')
    }
  }

  render() {
    return (
      <>
        <Button
          color="violet"
          icon
          labelPosition="left"
          onClick={this.toggleOpen}
          fluid>
          <Icon name="truck"
          />Escolher transportador</Button>
        <Confirm
          open={this.state.open}
          onCancel={this.toggleOpen}
          onConfirm={this.confirm} 
          cancelButton="Cancelar"
          confirmButton="Selecionar Transportador"
          size="tiny"
          header={"Escolher transportador #" + this.props.data.ordem_servico}
          content={
            <SearchTransportadorFilter ref={this.form} {...this.props} />
          }
        />
      </>
    )
  }

}

class EscolherAjudantes extends React.Component {

  state = {
    open: false
  }

  form = React.createRef()

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  confirm = () => {
    if (this.form.current.submit()) {
      this.toggleOpen()
      this.props.refresher()
    }
  }

  render() {
    return (
      <>
        <Button
          color="yellow"
          icon
          labelPosition="left"
          onClick={this.toggleOpen}
          disabled={!this.props.data?.numero_ajudante || this.props.data?.numero_ajudante === 0}
          fluid>
          <Icon name="truck"
          />Escolher ajudantes</Button>
        <Confirm
          open={this.state.open}
          onCancel={this.toggleOpen}
          onConfirm={this.confirm}
          cancelButton="Cancelar"
          confirmButton="Selecionar ajudantes"
          size="tiny"
          header={"Escolher ajudantes #" + this.props.data.ordem_servico}
          content={
            <SearchModalTransporterFilter ref={this.form} {...this.props} />
          }
        />
      </>
    )
  }

}
