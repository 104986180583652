import React from 'react'
import { Accordion, Segment, Label, Icon } from 'semantic-ui-react'
import * as masks from '../utils/masks'
import SearchTransportadorTags from './SearchTransportadorTags'
import TransportadorContent from './TransportadorContent'

class TransportadoresList extends React.Component {

  state = {
    activeIndex: 0,
    data: [],
    results: [],
    colorBackgroundName: 'grey'
  }

  componentDidMount() {
    this.prepareDataForSearch()
  }

  //TODO ATUALIZAR DATA QUANDO ALTERAR PROPS.DATA

  prepareDataForSearch = () => {
    const preparedData = this.props.list.map(t => {
      let find = "nome=" + t.nome + ";cpf=" + t.cpf + ";telefone=" + t.telefone
      find += ";email=" + t.email
      find += ";cnh_numero=" + t.cnh_numero
      find += ";endereco=" + t.rua + ", " + t.numero
      find += ";cidade=" + t.cidade + ";estado=" + (t.estado)
      find += ";cep=" + t.cep
      find += ";placa=" + t.placa_letras + t.placa_numeros
      find += ";cor=" + t.cor
      find += ";ano=" + t.ano
      find += ";rntrc=" + t.numero_rntrc + " " + t.tipo_rntrc
      find += ";largura=" + t.largura
      find += ";altura=" + t.altura
      find += ";comprimento=" + t.comprimento
      find += ";dimensões=" + t.altura + "x" + t.largura + "x" + t.comprimento
      find += ";marca=" + t.marca
      find += ";modelo=" + t.modelo
      find += ";ano_veiculo=" + t.ano
      find += ";tipo_veiculo=" + t.tipo_veiculo
      find += (t.work_auth) ? ";autorizado=true" : ";autorizado=false"
      find += (t.is_auth) ? ";app_autorizado=true" : ";app_autorizado=false"
      find += (t.ano && t.altura && t.comprimento && t.largura && t.documento_veiculo && t.marca && t.modelo && t.numero_rntrc && t.tipo_rntrc && t.tipo_carroceria && t.placa_letras && t.placa_numeros && t.tipo_veiculo) ? ";infos_veiculo=completo" : ";infos_veiculo=incompleto"
      find += (t.altura && t.largura && t.comprimento) ? ";dimensões=completo" : ";dimensões=incompleto"
      find += (t.cpf && t.email && t.nome) ? ";infos_perfil=completo" : ";infos_perfil=incompleto"
      find += (t.rua) ? ";endereco=completo" : ";endereco=incompleto"
      find += (t.cnh && t.comprovante && t.documento_veiculo) ? ";documentação=completo" : ";documentação=incompleto"
      find += (t.tipo_servicos.length > 0)
        ? t.tipo_servicos.reduce((acc, cur) => {
          return acc + ";servico=" + cur.descricao
        }, "")
        : ";servicos=nenhum cadastrado"
      return {
        ...t,
        find: find
      }
    })
    this.setState({ data: preparedData, results: preparedData })
  }

  setResults = (data, reset = false) => {
    if (reset) this.prepareDataForSearch()
    else this.setState({ results: data })
  }

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  renderAuthState = ({ is_auth, work_auth }) => {
    return [
      work_auth === 1 ? <Label color="green" key="apto4" content="Apto a trabalhar" size="mini" className="float-right autorizado-label" />
        : <Label color="red" key="apto3" basic content="Inapto a trabalhar" size="mini" className="float-right autorizado-label" />,
      is_auth === 1
        ? <Label color="teal" key="apto1" basic content="App Autorizado" size="mini" className="float-right autorizado-label" />
        : <Label color="red" key="apto2" content="App Não Autorizado" size="mini" className="float-right autorizado-label" />
    ]
  }

  changeColorBackgroundName = (color: string) => {
    color && this.setState({ colorBackgroundName: color })
  }

  render() {

    const { activeIndex, data, results, colorBackgroundName } = this.state

    return (
      <>
        <SearchTransportadorTags data={data} results={results} onResult={this.setResults} />
        <Segment basic>
          <Accordion fluid styled>
            {results.map((t, i) => {
              return (
                /*<div key={t.id}>
                <Accordion.Title
                  active={activeIndex === i}
                  index={i}
                  onClick={this.handleAccordionClick}
                >
                  <Icon name='dropdown' />
                  {this.renderAuthState(t)}
                  <Label icon="mail" color="blue" basic content={t.email ? t.email : "Email não cadastrado"} size="tiny" className="float-right email-label" />
                  <Label icon="address card" basic color="black" content={t.cpf ? masks.maskCpf(t.cpf) : "CPF não cadastrado"} size="tiny" className="float-right cpf-label" />
                  <Label icon="phone square" color="black" content={t.telefone ? masks.maskTelefone(t.telefone) : ""} size="tiny" />
                  <Label size="large" color={colorBackgroundName} content={t.nome} />
                </Accordion.Title>
                <TransportadorContent active={activeIndex === i} data={t} permission={this.props.permission} changeColorBackgroundName={(color: string) => this.changeColorBackgroundName(color)} />
            </div>*/
                <TransportadorContent key={i} index={i} onClick={this.handleAccordionClick} active={activeIndex === i} data={t} permission={this.props.permission} />
              )
            })}
          </Accordion>
        </Segment>
      </>
    )
  }
}


export default TransportadoresList
