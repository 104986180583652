import React from 'react'
import {Grid} from 'semantic-ui-react'
import Marcas from './Marcas'
import Modelos from './Modelos'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'

const MarcasModelos = (props) => {
  if(props.title === "Painel Noli" || props.title === "Marcas e Modelos") props.setPageTitle('Alterar Marcas e Modelos de veículos')
  if(!props.transp_filters) props.fetchTransportadoresFilters()
  return (
    <Grid padded>
      <Grid.Row >
        <Grid.Column computer={8} tablet={8} mobile={16} style={{maxHeight: '90vh'}} stretched>
          <Marcas {...props} />
        </Grid.Column>
        <Grid.Column computer={8} tablet={8} mobile={16} stretched>
          <Modelos {...props} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const mapStateToProps = ({pageInfo}) => pageInfo

export default connect(mapStateToProps, actions)(MarcasModelos)
