import React from 'react'
import {Grid, Segment, Form, Header, Input, Button, Message} from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react';
import * as noliAPI from '../../utils/noliAPI'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'
import * as masks from '../../utils/masks'
import _ from 'lodash'

const DadosPessoais = (props) => {

  const [loading, setLoading] = React.useState(props.user.nome === "")
  const [success, setSuccess] = React.useState(false)
  const [form, setForm] = React.useState({
    nome: "",
    data_nascimento: "",
    telefone: "",
    cpf: ""
  })

  if(props.pageInfo.title === "Painel Noli") props.setPageTitle('Alterar Dados Pessoais')
  if(!props.user || props.user.nome === "") props.fetchUser().then((data) => {
    setLoading(false)
    setForm(_.pick(data, ['nome', 'data_nascimento', 'telefone', 'cpf']))
  })

  let user = _.pick(props.user, ['nome', 'data_nascimento', 'telefone', 'cpf'])

  const handleSubmit = () => {
    setLoading(true)
    let payload = {}
    if(form.nome !== user.nome) payload.nome = form.nome
    if(form.data_nascimento !== user.data_nascimento) payload.data_nascimento = form.data_nascimento
    if(form.telefone !== user.telefone) payload.telefone = form.telefone
    if(form.cpf !== user.cpf) payload.cpf = form.cpf
    noliAPI.alterarPerfil(payload).then(res =>{
      if(res.data.status_code === "00"){
        setForm(user)
        setSuccess(true)
        props.fetchUser().then((data) => {
          setLoading(false)
          setForm(_.pick(data, ['nome', 'data_nascimento', 'telefone', 'cpf']))
        })
      }else{
        setLoading(false)
      }
    })
  }

  const handleChange = (e,{name, value}) => {
    setSuccess(false)
    setForm({...form, [name]: value})
  }

  const handleMaskChange = (e,{name, value, mask}) => {
    setSuccess(false)
    setForm({...form,
      [name]: mask ==="tel" ? masks.unmaskTelefone(value)
      : mask ==="cpf" ? masks.unmaskCpf(value) : value
    })
  }

  return (
    <Grid padded centered columns="1" className="full-height-grid with-nav">
      <Grid.Column
        mobile="16"
        computer="8"
        tablet="8"
        stretched
        verticalAlign="middle"
      >
        <Segment color="green" padded textAlign="left">
          <Header content="Alterar Dados Pessoais" />
          <Form
            onSubmit={handleSubmit}
            loading={loading}
            success={success}
          >
            <Message
              success
              header="Dados alterados com sucesso!"
            />
            <Form.Field
              control={Input}
              type="text"
              label="Nome"
              name="nome"
              onChange={handleChange}
              value={form.nome}
              disabled={loading}
            />
            <Form.Field
              control={DateInput}
              label="Data de Nascimento"
              name="data_nascimento"
              value={form.data_nascimento}
              onChange={handleChange}
              localization="pt-br"
              maxDate={new Date()}
              dateFormat="DD/MM/YYYY"
              closable
            />
            <Form.Field
              control={Input}
              label="Telefone"
              name="telefone"
              value={masks.maskTelefone(form.telefone)}
              onChange={handleMaskChange}
              mask="tel"
              maxLength="15"
            />
            <Form.Field
              control={Input}
              label="CPF"
              name="cpf"
              value={masks.maskCpf(form.cpf)}
              onChange={handleMaskChange}
              mask="cpf"
            />
            <Form.Field
              control={Button}
              type="submit"
              primary
              fluid
              disabled={loading || _.isEqual(form, user)}
            >
              Alterar Dados
            </Form.Field>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

const mapStateToProps = ({pageInfo, user}) => {return {pageInfo, user}}

export default connect(mapStateToProps, actions)(DadosPessoais)
