import React from 'react'
import { Grid, Label, List, Segment } from 'semantic-ui-react'
import { money } from '../../utils/helpers'

const ListProductItems = (props) => {

  return (
    <List relaxed>
      <List.Header >
            <Label color="red" ribbon content={"Produtos comprados"} />
          </List.Header>
      <List.Item>
        <List divided className="scrollable-y" style={{ maxHeight: 400 }}>
          {
            props.products.map(i => {
              const infos = i.weight > 0
                ? "A.: " + (i.height ? i.height + 'm' : 'n/i') + " / L.: " + (i.width ? i.width + 'm' : 'n/i') + " / C.: " + (i.length ? i.length + 'm' : 'n/i') + " / P.: " + i.weight + " kg"
                : "A.: " + (i.height ? i.height + 'm' : 'n/i') + " / L.: " + (i.width ? i.width + 'm' : 'n/i') + " / C.: " + (i.length ? i.length + 'm' : 'n/i')
              return (
                <List.Item active={i.peso > 1000} key={i.id}>
                  <List.Content floated="right" >
                   V. un.: {money(i.sales_price)} /<b> Qtd.: {i.quantity}</b>
                  </List.Content>
                  <List.Content>
                    <List.Header>
                      <List.Icon name="dolly flatbed" />
                      {i.title}
                    </List.Header>
                    <List.Description content={infos} />
                  </List.Content>
                </List.Item> 
              )
            })
          }

        </List>
      </List.Item>
    </List>
  )
}

export default ListProductItems
