import React from 'react'
import { Segment, Grid, Header, Button, Form, Confirm, Table, Label, Icon, Input, Radio, Checkbox } from 'semantic-ui-react'
import _ from 'lodash'
import * as noliAPI from '../../../../../utils/noliAPI'
import { connect } from 'react-redux'
import * as actions from '../../../../../utils/actions'
import formatDate from '../../../../../utils/formatDate'
import { AxiosResponse } from 'axios'
import { DateInput } from 'semantic-ui-calendar-react'
import moment from 'moment'

const _fv = {
    date: formatDate.dateToUnix(new Date()),
    reason: '',
    available: true
}

const BoxScheduleDates = (props) => {

    if (props.title === "Painel Noli") props.setPageTitle('Alterar datas de agendamento bloqueadas')

    const [loading, setLoading] = React.useState(true)
    const [scheduleDates, setScheduleDates] = React.useState([])
    const [open, openConfirm] = React.useState(false)
    const [modalProps, setModalProps] = React.useState({})
    const [formVal, formChange] = React.useState(_fv)

    //if (!props.stock_control) props.fetchRentBoxStockControl()

    React.useEffect(() => {
        findScheduleDates()

    }, []);

    const findScheduleDates = () => {

        noliAPI.findTransportsBlockScheduleDates({available: true}).then((resp: AxiosResponse) => {
            if (resp.status === 200) {
                setScheduleDates(resp.data);
            }
            setLoading(false)
        })

    }


    const openNewConfirm = () => {
        setModalProps({
            header: "Bloquear data de agendamento de mudanças",
            type: "new",
            selected: {}
        })
        formChange(_fv)
        openConfirm(true)
    }

    const selectDate = (item) => {
        if (!loading) {
            const formValues = {
                ...item
            }
            setModalProps({
                header: "Editar data",
                type: "edit",
                selected: formValues
            })
            formChange(formValues)
            openConfirm(true)
        }
    }

    const submit = (isEdit: boolean = false) => {
        const { id, date, reason, available } = formVal
   
        if (moment(date).isValid() && formatDate.checkChangeDate(date,moment().subtract(1,'day').toISOString())) {
            setLoading(true)

            const payload = {
                ...formVal.date && {date: formVal.date},
                ...formVal.reason && {reason: formVal.reason},
                available: Boolean(formVal.available)
              }

            if (isEdit) {
                updateScheduleDate(payload,id)
            } else {
                createScheduleDate(payload)
            }
        } else {
            props.sendMessage([], 'Erro: A data não pode ser anterior a hoje. Tente novamente mais tarde!')
        }
    }


    const createScheduleDate = (payload: {}) => {
        noliAPI.createTransportsBlockScheduleDates(payload).then(res => {
            if (res.status === 200) {
                openConfirm(false)
                setLoading(false)
                props.sendMessage([], "Data de bloqueio criado com sucesso!", { color: 'blue', icon: "check circle" }, false)
                findScheduleDates()
            } else {
                setLoading(false)
                props.sendMessage([], 'Houve um erro ao criar a data. Verifique se a data já está bloqueada ou tente novamente mais tarde')
            }
        }).catch(() => {
            setLoading(false)
            props.sendMessage([], 'Houve um erro ao tentar criar a data. Tente novamente mais tarde')
        })
    }

    const updateScheduleDate = (payload: {}, id: number) => {
        noliAPI.updateTransportsBlockScheduleDates(payload, id).then(res => {
            if (res.status === 200) {
                openConfirm(false)
                setLoading(false)
                props.sendMessage([], "Data atualizada com sucesso!", { color: 'blue', icon: "check circle" }, false)
                findScheduleDates()
            } else {
                setLoading(false)
                props.sendMessage([], 'Houve um erro ao atualizar a data. Tente novamente mais tarde')
            }
        }).catch(() => {
            setLoading(false)
            props.sendMessage([], 'Houve um erro ao tentar atualizar a data. Tente novamente mais tarde')
        })
    }

    const handleChange = (e, { name, value }) => {
        const { date, reason, available } = formVal
        
        if (name === 'date') {
            return formChange({ ...formVal, date: formatDate.dateToUnix(value) })
        }

        if (name === 'available') {
            return formChange({ ...formVal, available: Boolean(value) })
        }

        formChange({ ...formVal, [name]: value }) 
    }

    return (
        <Grid padded>
            <Grid.Row>
                <Grid.Column>
                    <Segment loading={loading}>
                        <Header as="h3">
                            <Button
                                as={Label}
                                floated="right"
                                content="Incluir nova data"
                                size="big"
                                color="blue"
                                icon="add"
                                onClick={openNewConfirm}
                                disabled={!props.user.permissions[props.permission].create}
                            />
                            <Icon name="calendar" />
                            <Header.Content>Controle de datas</Header.Content>
                        </Header>
                        <Form>
                            <Form.Group>
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Data</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Motivo</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Bloqueado</Table.HeaderCell>
                                            <Table.HeaderCell>Atualizado em</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {scheduleDates && scheduleDates.map(t =>
                                            <Table.Row
                                                onClick={() => selectDate(t)}
                                                key={t.id + "- date"}
                                                positive={Boolean(t.available)}
                                                error={!Boolean(t.available)}
                                                disabled={!Boolean(t.available) || !props.user.permissions[props.permission].update}>
                                                <Table.Cell>{t.id}</Table.Cell>
                                                <Table.Cell textAlign="center">{formatDate.dateBR(t.date, true)}</Table.Cell>
                                                <Table.Cell textAlign="center">{t.reason}</Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    <Checkbox
                                                        checked={Boolean(t.available)}
                                                        onChange={() => { }}
                                                        value={t.available}
                                                        readOnly
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>{formatDate.dateTimeServer(t.updated_at)}</Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                </Table>
                            </Form.Group>
                        </Form>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Confirm
                open={open}
                header={modalProps.header}
                cancelButton="Cancelar"
                confirmButton={
                    <Button
                        icon="add"
                        content={modalProps.type === "new" ? "Incluir data" : "Alterar data"}
                        disabled={_.isEqual(formVal, _fv)}
                        onClick={() => modalProps.type === "new" ? submit() : submit(true)}
                    />
                }
                onCancel={() => openConfirm(false)}
                content={<FormModal
                    loading={loading}
                    form={formVal}
                    handleChange={handleChange}
                    handleAvailable={formChange}
                />}
            />
        </Grid>
    )
}

const FormModal = (props) => {
    const { form, handleChange, loading } = props

    const checkChange = (e, { name, checked }) => props.handleAvailable({ ...form, [name]: checked ? 1 : 0 })

    return (
        <Segment basic loading={loading}>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        //disabled={props.form?.item?.id}
                        label="Data"
                        control={DateInput}
                        value={formatDate.dateBR(form.date, true)}
                        dateFormat={'DD/MM/YYYY'}
                        onChange={handleChange}
                        name="date"
                        width={'10'}
                        localization="pt-br"
                        closable
                        required

                    />
                    <Form.Field
                        control={Input}
                        label="Motivo do bloqueio"
                        name="reason"
                        value={form.reason}
                        onChange={handleChange}

                    />

                </Form.Group>
                <Form.Group inline widths="equal">
                    <Form.Checkbox
                        checked={Boolean(form.available)}
                        label="Ativo"
                        name="available"
                        onChange={checkChange}
                    />
                </Form.Group>
            </Form>
        </Segment>
    )
}

const mapStateToProps = ({ pageInfo }) => pageInfo

export default connect(mapStateToProps, actions)(BoxScheduleDates)
