import React from 'react'
import {Table, Segment, Header, Button, Confirm, Form, Dropdown} from 'semantic-ui-react'
import SearchFuncionariosFilter from './SearchFuncionariosFilter'
import * as masks from '../utils/masks'
import * as noliAPI from '../utils/noliAPI'
import _ from 'lodash'


const FuncionariosPermissao = (props) => {

  const [loading, setLoading] = React.useState(false)
  const [open, openConfirm] = React.useState(false)
  const [list, setList] = React.useState(props.list)
  const [selected, setSelected] = React.useState({})
  const [prevGroup, setPrevGroup] = React.useState("")

  React.useEffect(() => {
    setLoading(props.loading)
    setList(props.list)
  }, [props.list, props.loading]);


  const openModal = (selected) => {
    setLoading(true)
    noliAPI.grupoPermissaoFuncionario(selected.id)
    .then(res => {
      if(res.status === 200) {
        const group = res.data.data.id.toString()
        setPrevGroup(group)
        setSelected({
          ...selected,
          group: group
        })
      }
      else {
        setSelected({...selected, group: ""})
        setPrevGroup()
      }
      openConfirm(true)
      setLoading(false)
    })
  }

  const changeGroup = (e, {value}) => setSelected({...selected, group: value})

  const handleConfirm = () => {
    setLoading(true)
    let payload = {}
    payload.grupo_de_acesso_id = parseInt(selected.group)
    noliAPI.alterarGrupoAcessoFuncionario(payload, selected.id).then(res => {
      setLoading(false)
      openConfirm(false)
    })
  }

  return(
    <Segment loading={loading}>
      <Header as="h4" content="Alterar permissões de funcionário" icon="unlock alternate" />
      <SearchFuncionariosFilter
        setList={setList}
        loading={loading}
        setLoading={(load) => setLoading(load)}
      />
    <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nome</Table.HeaderCell>
            <Table.HeaderCell>Telefone</Table.HeaderCell>
            <Table.HeaderCell>CPF</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Permissões</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.length === 0 ? <Table.Row><Table.Cell colSpan="5">Nenhum funcionário retornado da busca</Table.Cell></Table.Row>
          : list.map((f, i) =>
            <Table.Row key={f.nome + "-" + i} positive>
              <Table.Cell>{f.nome}</Table.Cell>
              <Table.Cell>{f.telefone && masks.maskTelefone(f.telefone)}</Table.Cell>
              <Table.Cell>{f.cpf && masks.maskCpf(f.cpf)}</Table.Cell>
              <Table.Cell>{f.email}</Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  size="mini"
                  icon="unlock"
                  primary
                  onClick={() => openModal(f)}
                  disabled={!props.user.permissions[props.permission].update}
                />
              </Table.Cell>
            </Table.Row>
            )
          }
        </Table.Body>
      </Table>
      <Confirm
        cancelButton="Cancelar"
        confirmButton={<Button
          disabled={_.isEqual(prevGroup, selected.group) || !selected.group}
          primary
          content={"Atualizar Grupo de Permissão"}
        />}
        onCancel={() => openConfirm(false)}
        onConfirm={handleConfirm}
        open={open}
        size="mini"
        header="Editar permissões de funcionário"
        content={
          <Segment basic loading={loading}>
            <Header as="h2" textAlign="center"content={" (#"+selected.id+") " + selected.nome} />
            <Form>
              <Form.Field
                label={"Grupos de permissão do funcionário"}
                control={Dropdown}
                selection
                fluid
                style={{margin: '7px 0'}}
                options={props.options}
                value={selected.group}
                onChange={changeGroup}
                upward
              />
            </Form>
          </Segment>
        }
      />
    </Segment>
  )
}

export default FuncionariosPermissao
