import React from 'react'
import { Segment, Header, Table, Button, Confirm, Input, Dropdown, Message} from 'semantic-ui-react'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'
import {adicionarModelo, alterarModelo} from '../../utils/noliAPI'
import _ from 'lodash'

const Carrocerias = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [open, openConfirm] = React.useState(false)
  const [selected, setSelected] = React.useState({})
  const [edited, setEdited] = React.useState({id: 0, descricao: ""})
  const [action, setAction] = React.useState("edit")
  const [modelos, setModelosList] = React.useState([])
  const [searchVal, searchChange] = React.useState("")

  const editConfirm = (e, {value}) => {
    setAction("edit")
    setSelected(value)
    setEdited(value)
    openConfirm(true)
  }

  const addConfirm = () => {
    setAction("new")
    setEdited({id: 0, descricao: "", marca_id: "", categoria_id: ""})
    setSelected({})
    openConfirm(true)
  }

  const changeMarca = (e, {value}) => setEdited({...edited, marca_id: value})

  const changeCategNoli = (e, {value}) => setEdited({...edited, categoria_id: value})

  const submit = () => {
    setLoading(true)
    if(action === "new"){
      const payload = {
        descricao: edited.descricao,
        marca_id: edited.marca_id,
        categoria_id: edited.categoria_id
      }
      adicionarModelo(payload).then(res => {
        if(res.status_code === "00"){
          props.sendMessage("Modelo cadastrado com sucesso", "Sucesso", {color:'blue', icon:'check'})
          props.fetchTransportadoresFilters()
        }else props.sendMessage("Houve um erro ao tentar cadastrar", "Erro")
        setLoading(false)
        openConfirm(false)
        clearSearch()
      })
    }else if(action === "edit"){
      let payload = {}
      if(edited.descricao !== selected.descricao) payload.descricao = edited.descricao
      if(edited.marca_id !== selected.marca_id) payload.marca_id = edited.marca_id
      if(edited.categoria_id !== selected.categoria_id) payload.categoria_id = edited.categoria_id
      if(Object.keys(payload).length > 0) alterarModelo(payload, selected.id).then(res => {
        if(res.status_code === "00"){
          props.sendMessage("Modelo alterado com sucesso", "Sucesso", {color:'blue', icon:'check'})
          props.fetchTransportadoresFilters()
        }else props.sendMessage("Houve um erro ao tentar alterar", "Erro")
        setLoading(false)
        })
        else(setLoading(false))
        openConfirm(false)
        clearSearch()
    }
  }

  const confirmButton = () => {
    if(action === "edit")
      return <Button
        primary
        content="Alterar"
        disabled={!edited.marca_id || edited.descricao==="" || _.isEqual(edited, selected) || !edited.categoria_id}
        onClick={submit}
      />
    else
      return <Button
        primary
        content="Adicionar novo"
        disabled={!edited.marca_id || edited.descricao==="" || descricoes.indexOf(edited.descricao.toLowerCase()) > -1 || !edited.categoria_id}
        onClick={submit}
      />
  }

  const descricoes = props.dados_veiculos ? props.dados_veiculos.modelos.map(c=> c.descricao.toLowerCase()) : []

  const handleSearch = (e, {value}) => {
    searchChange(value)
    const re = new RegExp(_.escapeRegExp(value), 'i')
    const isMatch = (result) => re.test(result.descricao)
    setModelosList(_.filter(props.dados_veiculos.modelos, isMatch))
  }

  const clearSearch = () => {
    searchChange("")
    setModelosList([])
  }

  const list = modelos.length > 0 ? modelos : props.dados_veiculos && searchVal==="" ? props.dados_veiculos.modelos : []

  return (
    <>
      <Segment loading={loading}>
        <Header
          as='h4'
          icon='car'
          content="Editar Modelos"
        />
        <Input
          fluid
          action={<Button icon="x" basic onClick={clearSearch} />}
          style={{marginBottom: 7}}
          onChange={handleSearch}
          value={searchVal}
          placeholder="Busque um modelo"
        />
        <div className="scrollable-y top-bottom-border" style={{height:"66vh"}}>
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Editar</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { list.length > 0 ? _.map(list, (t) => {
                return (
                  <Table.Row key={t.id + "-" + t.descricao}>
                    <Table.Cell width="1">{t.id}</Table.Cell>
                    <Table.Cell>{t.descricao}</Table.Cell>
                    <Table.Cell width="6" textAlign="right">
                      <Button
                        icon="edit"
                        onClick={editConfirm}
                        color="green"
                        value={t}
                        disabled={!props.user.permissions[props.permission].update}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              ) : (<Table.Row><Table.Cell colSpan="3" negative>Modelo não encontrado</Table.Cell></Table.Row>)
            }
            </Table.Body>
          </Table>
        </div>
        <Button
          icon="add"
          labelPosition="right"
          content="Novo Modelo"
          primary
          onClick={addConfirm}
          style={{marginTop: 7}}
          disabled={!props.user.permissions[props.permission].create}
        />
      </Segment>
      <Confirm
        open={open}
        header={action==="new" ? "Novo Modelo" : "Alterar Modelo (#" + selected.id + ") " + selected.descricao }
        content={
          <Segment basic loading={loading}>
            {action === "new" && edited.descricao === "" && <Message negative content="A descrição não pode ser vazia" />}
            {action === "new" && edited.descricao !== "" && descricoes.indexOf(edited.descricao.toLowerCase()) > -1 && <Message negative content="Este modelo já existe" />}
            {action === "edit" && _.isEqual(edited, selected) && <Message negative content="Altere algum dado" />}
            Descrição
            <Input
              value={edited.descricao}
              style={{margin: '7px 0'}}
              onChange={(e, {value}) => setEdited({
                ...edited,
                descricao: value
              })}
              fluid
            />
            Marca
            <Dropdown
              selection
              fluid
              style={{margin: '7px 0'}}
              options={props.transp_filters ? props.transp_filters.marcas : []}
              value={edited.marca_id}
              onChange={changeMarca}
              upward
            />
            Categoria de Veículo Noli
            <Dropdown
              selection
              fluid
              style={{margin: '7px 0'}}
              options={props.transp_filters ? props.transp_filters.categoriaNoli : []}
              value={edited.categoria_id}
              onChange={changeCategNoli}
              upward
            />
          </Segment>
        }
        onCancel={() => openConfirm(false)}
        confirmButton={confirmButton}
        size="tiny"
      />
    </>
  )
}

const mapStateToProps = ({pageInfo}) => pageInfo

export default connect(mapStateToProps, actions)(Carrocerias)
