import React from 'react'
import {Select} from 'semantic-ui-react'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'


class SearchRentBoxUsers extends React.Component{

  state = {
    loading: false,
  }

  options = [
    {text: "Abertos", value: "true", key: "1"},
    {text: "Finalizados", value: "false", key: "2"},
    {text: "Cancelados", value: "canceled", key: "3"},
    {text: "Todos", value: "all", key: "4"},
  ]

  componentDidMount(){
    this.changeClienteFilter('', 'all')
  }

  changeClienteFilter = (e, {value}) => {
    this.setState({loading: true})
    if(value === "all")
      this.props.rentBoxOrdersUsers().then(res => this.setState({loading: false})) 
    else if (value === "canceled")
      this.props.rentBoxOrdersUsers(false, false, true).then(res => this.setState({loading: false}))
    else
      this.props.rentBoxOrdersUsers(false, value).then(res => this.setState({loading: false})) 
  }


  render(){
    const {loading} = this.state

    return(
      <Select
        placeholder='Selecione um filtro'
        fluid
        options={this.options}
        onChange={this.changeClienteFilter}
        defaultValue="true"
        disabled={loading}
        loading={loading}
      />
    )
  }
}

export default connect("", actions)(SearchRentBoxUsers)
