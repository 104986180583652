import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import {connect} from 'react-redux'
import * as actions from '../utils/actions'
import {Search, Container, Form, Item, Button} from 'semantic-ui-react'
import {maskToBeKey} from '../utils/masks'

class OrcamentoItens extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      results: [],
      value: "",
      categorized: [],
    }
    this._nodes = new Map()
  }

  componentDidMount() {
    if(this.props.itens.length === 0) this.props.fetchItens()
    else{
      this.prepareItensForSearch(this.props.itens)
    }
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.itens.length !== this.props.itens.length){
      this.prepareItensForSearch(this.props.itens)
    }
  }

  prepareItensForSearch = (itens) => {
    //const ItemListIds = this.props.ItemList.map(i => i.item_id)
    const prepared = itens
    /*.filter(f => {
      return !ItemListIds.includes(f.id)
    })*/
    .map(i => { return{
      ...i,
      title: i.descricao,
      image: i.imagem,
      description: i.subtipo_descricao,
      item_id: i.id,
      price: "A: " + i.altura.toFixed(2) + " | L: " + i.largura.toFixed(2) + " | C: " + i.comprimento.toFixed(2)
    }})
    .reduce((acc, atual) => {
      const key = maskToBeKey(atual.categoria)
      if(!acc[key])
        acc[key] = {name: atual.categoria, results: []}
      acc[key].results.push(atual)
      return acc
    }, {})
    this.setState({categorized: prepared})

  }

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, value })
    const list = this.state.categorized

    setTimeout(() => {

    const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
    const isMatch = (result) => re.test(result.title)

    const filteredResults = _.reduce(
      list,
      (memo, data, name) => {
        const results = _.filter(data.results, isMatch)
        if (results.length) memo[name] = { name: list[name].name, results }

        return memo
      }, {} )
      this.setState({
        loading: false,
        results: filteredResults,
        firstLoad:false
      })
    }, 300)

  }

  handleAddItem = (e, { result }) => {
    this.setState({value: ""})
    this.props.onAddOrRemove(result)
  }

  handleRemoveItem = (e, { result }) => {
    this.setState({value: ""})
    this.props.onAddOrRemove(result, -1) 
  }

  handleSelectItem = (e, { result }) => {
    if(this.props.ItemList.map(i => i.item_id).includes(result.item_id)){
      this.setState({value: ""})
      let ref = ReactDOM.findDOMNode(this._nodes.get(result.item_id))
      ref.focus()
      ref.parentNode.classList.add('bouncer')
      setTimeout(()=> ref.parentNode.classList.remove('bouncer'),400)

    }else{
      this.handleAddItem(e, { result })

    }
  }

  render() {
    const { ItemList } = this.props
    const { loading, value, results } = this.state
    return (
      <Container>
        <Form.Field>
          <Search
            fluid
            category
            icon="box"
            loading={loading}
            placeholder="Procure o item que deseja adicionar"
            noResultsMessage="Nenhum item encontrado para essa combinação de caracteres"
            size="large"
            value={value}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            results={results}
            onResultSelect={this.handleSelectItem}
          />
        </Form.Field>

        <Item.Group divided className="scrollable-y" style={{maxHeight:"35vh"}} link>
          {this.renderItens(ItemList)}
        </Item.Group>
      </Container>
    )
  }

  renderItens = (list) => {
    return list
    /*.sort((a,b) => {
      let comparison = 0;
      if (a.descricao > b.descricao) {
        comparison = 1;
      } else if (a.descricao < b.descricao) {
        comparison = -1;
      }
      return comparison;
    })*/
    .map((i, index) => {
      return(
        <Item key={i.item_id + " " + i.descricao + " " + index}>
          <Button basic compact attatched="left" ref={c => this._nodes.set(i.item_id, c)}>
            <Item.Image size="tiny" src={i.imagem} label={i.favorito===1 && {
                icon:'favorite',
                corner: 'right',
                color:'yellow',
                size:'mini'
              }} />
          </Button>
          <Item.Content className="fader">
            <Item.Header content={i.descricao} />
            <Item.Meta content={i.categoria} />
              <Button.Group floated="right" >
                <Button icon="minus" color="red"
                  result={i}
                  onClick={this.handleRemoveItem}
                  type="button"
                />
                <Button.Or text={i.quantidade} />
                <Button icon="add" color="green"
                  result={i}
                  onClick={this.handleAddItem}
                  className="focus-button"
                  type="button"

                />
              </Button.Group>
            <Item.Description>
              Altura: {i.altura.toFixed(2)} m<br />
              Largura: {i.largura.toFixed(2)} m<br />
              Comprimento: {i.comprimento.toFixed(2)} m
            </Item.Description>
          </Item.Content>
        </Item>
      )
    })
  }
}

const mapStateToProps = ({itens}) => {
  return {itens}
}

export default connect(mapStateToProps, actions)(OrcamentoItens)
