import React from 'react'
import 'semantic-ui-css/semantic.min.css'
import './App.css'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import reduxThunk from "redux-thunk"
import reducer from './utils/reducer'

import AuthMiddleware from './components/AuthMiddleware'
document.body.style = 'background: #dfdfdf';


const store = createStore(reducer ,{}, applyMiddleware(reduxThunk));

class App extends React.Component {

  render() {
    return (
      <Provider store={store}>
        <AuthMiddleware />
      </Provider>
    );
  }
}

export default App;
