import _ from 'lodash'
import React, { Component } from 'react'
import { Search} from 'semantic-ui-react'
import { connect } from 'react-redux'

const initialState = { isLoading: false, results: [], value: '' }

class SearchRentBoxUsersFromStore extends Component {
  state = initialState

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title })
    this.props.onSelect(result)
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })
    let list = this.props.usersToRentbox

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState)

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result.find)

      this.setState({
        isLoading: false,
        results: _.filter(list, isMatch),
      })
    }, 300)
  }

  render() {
    const { isLoading, value, results } = this.state

    return (
      <Search
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={_.debounce(this.handleSearchChange, 500, {
          leading: true,
        })}
        results={results}
        value={value}
        disabled={this.props.usersToRentbox.length === 0}
        fluid
        input={{fluid:true}}
        placeholder="Digite nome, celular ou email"
        label="Buscar"
      />
    )
  }
}

const mapStateToProps = ({ usersToRentbox }) => {
	return {
		usersToRentbox
	};
};

export default connect(mapStateToProps)(SearchRentBoxUsersFromStore)
