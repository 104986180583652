import React, {useState} from 'react'
import {Sidebar} from 'semantic-ui-react'
import Dashboard from './DashboardBox'
import DashBoardRentBoxList from '../../../components/DashBoardRentBoxList'
 
const DashboardStructure = (props) => {
  const [dimmed, toggleDimmed] = useState(false)
  const [list, setList] = useState([])
  const [type, setType] = useState("")

  return (
    <Sidebar.Pushable style={{overflow: 'scroll'}}>
      <DashBoardRentBoxList
        list={list}
        visible={dimmed}
        hide={toggleDimmed}
        type={type}
        
      />
      <Sidebar.Pusher dimmed={dimmed}>
        <Dashboard
          openList={() => toggleDimmed(!dimmed)}
          setList={setList}
          setType={setType} />
      </Sidebar.Pusher>

    </Sidebar.Pushable>
  )

}

export default DashboardStructure
