import React from 'react'
import {Grid} from 'semantic-ui-react'
import TiposVeiculo from './TiposVeiculo'
import Carrocerias from './Carrocerias'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'

const DadosVeiculos = (props) => {
  if(props.title === "Painel Noli" || props.title === "Tipos de Veículo") props.setPageTitle('Alterar Tipos de Veículos e Carrocerias')
  if(!props.transp_filters) props.fetchTransportadoresFilters()
  return (
    <Grid padded>
      <Grid.Row >
        <Grid.Column computer={8} tablet={8} mobile={16} style={{maxHeight: '90vh'}} stretched>
          <TiposVeiculo {...props} />
        </Grid.Column>
        <Grid.Column computer={8} tablet={8} mobile={16} stretched>
          <Carrocerias {...props} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const mapStateToProps = ({pageInfo}) => pageInfo

export default connect(mapStateToProps, actions)(DadosVeiculos)
