import logo_category_a from '../assets/category_a.png'
import logo_category_b from '../assets/category_b.png'
import logo_category_c from '../assets/category_c.png'
import logo_category_d from '../assets/category_d.png'
import logo_category_e from '../assets/category_e.png'
import logo_category_f from '../assets/category_f.png'
import logo_default from '../assets/logo_alta.jpg'


export const getCategoryFlagByColor = (color_flag: string) => { 
    switch (color_flag) {
      case 'red':
        return logo_category_a
      case 'blue':
        return logo_category_b
      case 'black':
        return logo_category_c
      case 'green':
        return logo_category_d
      case 'purple':
        return logo_category_e
      case 'yellow':
        return logo_category_f
      default:
        return logo_default
    }
  }


//calcular taxa noli

export const calculate_percent = (value, percent) => { 
    const total = value
    const value_of_noli = (value * percent) / 100;
    const value_of_transporter = value - value_of_noli

    return { total, value_of_transporter, value_of_noli, transport_fee: percent }

}

export const money = (value) => {
    const result: number = JSON.parse(Number(value).toFixed(2))
    return result.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}


export const valuesOfPayment = (payments, services, transportTax) => {

    const paymentValue = payment(payments)
    const paymentOnlyTransport = payment(payments, true, services)
    const paymentTransporter = calculate_percent(paymentOnlyTransport, transportTax)

    const totalPayments = new Object({
        total_value: paymentValue,
        total_only_transport: paymentTransporter,
        transport_fee: transportTax

        //total_transporter: paymentTransporter
    })

    return totalPayments
}

export const payment = (payments, withoutDiscount = null, withoutServices = null) => {
    let totalValue = 0;
    if (!payments) return totalValue
    payments.map(async pay => {
        totalValue += pay.valor
        if (withoutDiscount && pay.cupom_desconto) totalValue += pay.cupom_desconto.valor

    })

    if (withoutServices) if (withoutServices.protecao_itens) if (withoutServices.protecao_itens.valor_servico) totalValue = totalValue - withoutServices.protecao_itens.valor_servico

    return totalValue
}

export const simulatorCalculate = (payments, removeDiscount = false, services, volume = 1) => {
    let totalValue = 0;

    payments && payments.map(async pay => {
        totalValue += pay.valor
        if (removeDiscount && pay.cupom_desconto) totalValue -= pay.cupom_desconto.valor

    })

    if (services) {
        if (services.protecao_itens) {
            if (Object.keys(services.protecao_itens).length > 0) {
                if (services.protecao_itens.valor_servico) {
                    totalValue += services.protecao_itens.valor_servico
                } else {
                    totalValue += Number((services.protecao_itens.valor_base * volume))
                }
            }

        }
        if (services.montagem_desmontagem) {
            if (services.montagem_desmontagem[0]) {
                services.montagem_desmontagem.forEach((element) => {
                    totalValue += element.valor_servico
                })
            }
        }

        // if (services.insurance_cargo) {
        //     if (services.insurance_cargo[0]) {
        //         services.insurance_cargo.forEach((element) => {
        //             console.log('insurance ', element)
        //             totalValue += element.amount
        //         })
        //     }
        // }
    }


    return totalValue
}

const getTotalValue = (orcamentoInfo, pagamento, orcamentoChange = null) => {
    const { valor_total, servicos, volume_total, } = orcamentoInfo
    const { protecao_itens, montagem_desmontagem } = servicos

    let totalValue = valor_total;



    const valueWithProtection = checkServiceProtectionItems(orcamentoInfo)
    const valueWithAssemblyDisassembly = checkServiceAssemblyDisassembly(orcamentoInfo)

    if (valueWithProtection) {
        totalValue = Number(totalValue) + Number(valueWithProtection)
    }

    if (valueWithAssemblyDisassembly) {
        totalValue = Number(totalValue) + Number(valueWithAssemblyDisassembly)
    }

    return totalValue.toFixed(2)
}

const checkServiceProtectionItems = (servicos, volume) => {
    const { protecao_itens } = servicos
    if (Object.keys(protecao_itens).length > 0) {
        const valueWithProtection = Number((protecao_itens.valor_base * volume))
        return valueWithProtection
    }

    return 0
}

const checkServiceAssemblyDisassembly = (servicos) => {
    const { montagem_desmontagem } = servicos
    if (!montagem_desmontagem) return 0
    const valueService = montagem_desmontagem.reduce((sum, item) => sum + Number(item.valor_servico), 0);

    return Number(valueService)
}
