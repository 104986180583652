import React from 'react'
import {TransitionablePortal, Grid, Message} from "semantic-ui-react"
import {connect} from 'react-redux'
import * as actions from '../utils/actions'


const AppMessages = (props) => {

  const {message} = props.pageInfo

  const open = props.pageInfo.dimmed
  return(
    <TransitionablePortal
      open={open}
      transition={{animation: "fly down", duration: 800}}
    >
      <div
        style={{
          left: 0,
          position: 'fixed',
          top: "30%",
          zIndex: 1000,
          width: "100%"
        }}
      >
          <Grid padded centered columns="1" className="app-message" verticalAlign='middle' >
            <Grid.Column mobile="16" computer="9" tablet="10" >
              <Message {...message.props} list={message.msgs} />
            </Grid.Column>
          </Grid>

      </div>
    </TransitionablePortal>

  )
}

const mapStateToProps = ({ pageInfo }) => {
	return {
		pageInfo
	};
};

export default connect(mapStateToProps, actions)(AppMessages)
