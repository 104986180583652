import React from 'react'
import { Grid, Message} from 'semantic-ui-react'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'

const Page401 = (props) => {
  const list = ["Você tentou acessar uma página bloqueada para o grupo de permissões da qual você pertence.", "Se você precisa acessar esta página, fale com um administrador do sistema."]

  if(props.pageInfo.title === "Painel Noli") props.setPageTitle('401 - NÃO AUTORIZADO')

  return (<Grid padded centered columns="1" className="full-height-grid with-nav">
    <Grid.Column
      mobile="16"
      computer="8"
      tablet="8"
      stretched
      verticalAlign="middle"
    >
      <Message error header="CÓDIGO 401 - NÃO AUTORIZADO" list={list} />
    </Grid.Column>
  </Grid>)
}

const mapStateToProps = ({pageInfo}) => {return {pageInfo}}

export default connect(mapStateToProps, actions)(Page401)
