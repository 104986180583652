import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as actions from '../../utils/actions'
import * as mask from '../../utils/masks'
import { Segment, Grid, List, Form, Button, Input, Icon } from 'semantic-ui-react'
import SearchClientesOrcamentos from '../../components/SearchClientesOrcamentos' 
import SearchClientesFromStore from '../../components/SearchClientesFromStore'
import OrcamentosCliente from './OrcamentosCliente'
import { Link } from 'react-router-dom'

class Orcamentos extends React.Component {

  state = {
    loading: false,
    selected: {},
    ordemToGo: "N"
  }

  componentDidMount() {
    this.props.setPageTitle('Orçamentos')
  }

  renderClientesList = () => {
    if (this.props.clientes.length < 1)
      return (
        <List.Item>
          <List.Content>
            <List.Header>Selecione um filtro para atualizar lista de clientes</List.Header>
          </List.Content>
        </List.Item>
      )
    else
      return this.props.clientes.map((c, i) => {
        return (
          <List.Item
            key={i}
            onClick={() => this.selectCliente(c)}
            active={this.state.selected.id === c.id}
          >
            <List.Content>
              <List.Header>{mask.maskTelefone(c.telefone)}</List.Header>
              <List.Content>{c.nome ? c.nome : "Nome não fornecido"}</List.Content>
            </List.Content>
          </List.Item>
        )
      })
  }

  selectCliente = (cliente) => {
    this.setState({ selected: cliente })
  }

  openSimulator = (cliente) => {
    this.setState({ selected: cliente })
  }

  gotoOrdem = () => {
    let url = this.state.ordemToGo.substr(1).split('-')
    if (Number(url[0]) > 0 && Number(url[1]) > 0)
      this.props.history.push('/cliente/' + url[0] + '/orcamento/' + url[1]) //TODO ELSE
  }

  render() {
    return (
      <Segment basic loading={this.state.loading}>
        <Grid relaxed>
          <Grid.Column computer="3" tablet="6" mobile="16">
            <Segment color="blue" inverted size="mini">
              <Button
                loading={false}
                color={"white"}
                icon
                labelPosition="left"
                fluid
                as={Link}
                to={"/cadastro/orcamento"}
                target="_blank"
              >
                <Icon name="settings"
                />Simular orçamento
              </Button>

            </Segment>
            <Segment color="blue" inverted size="mini">
              <Form inverted size="mini">
                Ir para ordem: Ex. N12-345
                <Input
                  label={<Button type="submit" content="ir" onClick={this.gotoOrdem} />}
                  value={this.state.ordemToGo}
                  onChange={(e, { value }) => this.setState({ ordemToGo: mask.maskOrdem(value) })}
                  placeholder="NXX-XXXX"
                  labelPosition="right"
                />
              </Form>
            </Segment>
            <Segment color="blue" inverted size="mini">
              <Form inverted size="mini">
                <Form.Field control={SearchClientesOrcamentos} label="Filtrar" fluid />
                <Form.Field control={SearchClientesFromStore} label="Buscar" onSelect={this.selectCliente} fluid />
              </Form>
            </Segment>
            <Segment color="grey" inverted >
              <List
                divided
                relaxed
                ordered
                inverted
                className="scrollable-y" style={{ maxHeight: "68vh" }}
                selection
              >
                {this.renderClientesList()}
              </List>
            </Segment>
          </Grid.Column>
          <Grid.Column computer="13" tablet="10" mobile="16" stretched>
            <Segment color="blue">
              <OrcamentosCliente clienteSelected={this.state.selected} permission={this.props.permission} /> 
            </Segment>
          </Grid.Column>

        </Grid>
      </Segment>
    )
  }

}

const mapStateToProps = ({ clientes }) => {
  return {
    clientes
  };
};

export default connect(mapStateToProps, actions)(withRouter(Orcamentos))
