import React from 'react'
import * as actions from '../../../utils/actions'
import { connect } from 'react-redux'
import { maskToBeKey } from '../../../utils/masks'
import { Segment, Search, Grid, Header, Rail, Button, Form, Confirm, Table, Image } from 'semantic-ui-react'
import _ from 'lodash'
import * as noliAPI from '../../../utils/noliAPI'
import ReactDOM from 'react-dom'
import { money } from '../../../utils/helpers'

const _fv = {
  title: "",
  description: "",
  details: "",
  price: 0,
  sales_price: 0,
  image: "",
  image_holder: "",
  available: 1,
  favorite: 1,
  height: 0,
  width: 0,
  length: 0,
  weight: 0,
  qt_max: 0,
  products_for_sale_category_id: 1,
}

class ProductsForSale extends React.Component {

  state = {
    categorized: [],
    loading: true,
    value: "",
    results: [],
    selected: {},
    formValue: _fv ,
    modalOpen: false,
    sortCol: 'id',
    direction: 'ascending',
    delModal: false,
    toDelete: {},
  }

  _refs = []

  componentDidMount() {
    this.props.setPageTitle('Cadastrar e alterar produtos')
    if (this.props.productsForSale.length === 0) this.props.fetchProductsForSale()
    else this.prepareItensForSearch(this.props.productsForSale)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.productsForSale.length !== this.props.productsForSale.length || !_.isEqual(prevProps.productsForSale, this.props.productsForSale)) this.prepareItensForSearch(this.props.productsForSale)
  }

  prepareItensForSearch = (itens) => {
    //const ItemListIds = this.props.ItemList.map(i => i.item_id)

    if(itens.length > 0) {

    const prepared = itens
      /*.filter(f => {
        return !ItemListIds.includes(f.id)
      })*/
      .map(i => {
        return {
          ...i,
          title: i.title,
          image: i.image,
          description: i.description,
          item_id: i.id,
          //price: "A: " + i.height.toFixed(2) + " | L: " + i.width.toFixed(2) + " | C: " + i.length.toFixed(2)
          price: money(i.sales_price)
        }
      })
      .reduce((acc, atual) => {
        const key = maskToBeKey(atual.category_description)
        if (!acc[key])
          acc[key] = { name: atual.category_description, results: [] }
        acc[key].results.push(atual)
        return acc
      }, {})
    this._refs = this.props.productsForSale.reduce((acc, cur) => {
      acc[cur.id] = React.createRef(cur.id)
      return acc
    }, {})
  
  }
  this.setState({ categorized: [], loading: false })
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, value })
    const list = this.state.categorized

    setTimeout(() => {

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result.title)

      const filteredResults = _.reduce(
        list,
        (memo, data, name) => {
          const results = _.filter(data.results, isMatch)
          if (results.length) memo[name] = { name: list[name].name, results }
          return memo
        }, {})
      this.setState({
        loading: false,
        results: filteredResults,
        firstLoad: false,
      })
    }, 300)

  }

  handleSelectItem = (e, { result }) => {
    this.setState({ selected: result, value: result.descricao, formValue: result, modalOpen: true })
  }

  handleFocusItem = (e, { result }) => {
    this.setState({ results: [], value: "" })
    let ref = ReactDOM.findDOMNode(this._refs[result.id].current)
    ref.focus()
    ref.parentNode.parentNode.classList.add('bouncer')
    setTimeout(() => ref.parentNode.parentNode.classList.remove('bouncer'), 400)

  }

  changeToAdd = () => {
    this.setState({ value: "", selected: {} })
  }

  handleSingleChange = (e, { name, value }) => this.setState({
    formValue: { ...this.state.formValue, [name]: value }
  })

  handleNumberChange = (e, { name, value }) => this.setState({
    formValue: { ...this.state.formValue, [name]: Number(value) }
  })

  handleToggleChange = (e, { name, checked }) => this.setState({
    formValue: { ...this.state.formValue, [name]: checked ? 1 : 0 }
  })

  handleSubmit = () => {
    this.setState({ loading: true }) 
    const keys = Object.keys(_fv)
    const values = Object.entries(this.state.formValue)
    const prepared = values.reduce((acc, cur) => {
      if (keys.includes(cur[0])) acc[cur[0]] = cur[1]
      return acc
    }, {})
    const isEdit = Object.entries(this.state.selected).length > 0

    
     if (isEdit) noliAPI.updateProductsForSale(this.state.selected.id, prepared)
      .then(res => {
        this.props.fetchProductsForSale()
        this.setState({ loading: false, results: [], modalOpen: false, formValue: _fv })
      })
    else if (this.validateToAdd()) {
      const payload = {
        ...prepared.title && prepared.title.trim() && {title: prepared.title},
        ...prepared.description && prepared.description.trim() && {description: prepared.description},
        ...prepared.details && prepared.details.trim() && {details: prepared.details},
        ...prepared.image && prepared.image.trim() && {image: prepared.image},
        ...prepared.image_holder && prepared.image_holder.trim() && {image_holder: prepared.image_holder},
        ...prepared.price && prepared.price > 0 && {price: prepared.price},
        ...prepared.sales_price && prepared.sales_price > 0 && {sales_price: prepared.sales_price},
        ...prepared.qt_max && prepared.qt_max > 0 && {qt_max: prepared.qt_max},
        ...prepared.height && prepared.height > 0 && {height: prepared.height},
        ...prepared.length && prepared.length > 0 && {length: prepared.length},
        ...prepared.weight && prepared.weight > 0 && {weight: prepared.weight},
        ...prepared.width && prepared.width > 0 && {width: prepared.width}
      }
      noliAPI.createProductsForSale(payload)
      .then(res => {
        this.props.fetchProductsForSale()
        this.setState({ loading: false, results: [], modalOpen: false, formValue: _fv })
      })
  }
    }

  validateToAdd = () => {
    const fv = this.state.formValue
    if (fv.description === "" || fv.title === "" || fv.sales_price <= 0 || fv.image === "")
      return false
    else return true
  }

  deleteItem = () => {
    /*this.setState({loading: true})
    noliAPI.apagarItem(this.state.toDelete.id)
      .then(res => {
        this.props.fetchItens()
        this.setState({delModal: false})
      })*/
  }

  openModal = () => this.setState({ modalOpen: true })

  render() {
    const { loading, value, results, selected, modalOpen, delModal, toDelete } = this.state
    const isEdit = Object.entries(selected).length > 0
    return (
      <Grid padded>
        <Grid.Row reversed='computer'>
          <Grid.Column stretched computer="4" tablet="6" mobile="16">
            <Segment>
              <Header
                as='h5'
                icon='add'
                content="Cadastro"
              />
              <Button
                icon="box"
                labelPosition="left"
                content="Novo Produto"
                onClick={this.openModal}
                fluid
                color="green"
                disabled={!this.props.user.permissions[this.props.permission].create}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer="12" tablet="10" mobile="16">
            <Segment>
              <Header
                as='h5'
                icon='boxes'
                content="Selecione um produto para alterar:"
              />
              <Search
                fluid
                category
                icon="search"
                input={{ fluid: true }}
                loading={loading}
                placeholder="Procure o produto que deseja alterar"
                noResultsMessage="Nenhum item encontrado para essa combinação de caracteres"
                size="large"
                value={value}
                onSearchChange={_.debounce(this.handleSearchChange, 500, {
                  leading: true,
                })}
                results={results}
                onResultSelect={this.handleFocusItem}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment loading={loading}>
              <Header
                as='h2'
                icon="list"
                content="Produtos Cadastrados"
                subheader={"Total de " + this.props.productsForSale.length + " produtos"}
              />
              {this.renderItensTable()}
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Confirm open={modalOpen}
              size="large"
              content={this.renderConfirm}
              onConfirm={this.handleSubmit}
              onCancel={() => this.setState({ modalOpen: false, selected: {}, formValue: _fv })}
              confirmButton={isEdit
                ? <Button disabled={!this.validateToAdd()}>Alterar Item: {selected.descricao}</Button>
                : <Button disabled={!this.validateToAdd()}>Criar Novo Item</Button>
              }
              cancelButton="Cancelar"
            />
            <Confirm
              open={delModal}
              content={"Tem certeza que deseja excluir " + toDelete.descricao + " ?"}
              onConfirm={this.deleteItem}
              onCancel={() => this.setState({ toDelete: {}, delModal: false })}
              confirmButton="Excluir"
              cancelButton="Cancelar"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  handleSort = (col) => this.setState({
    sortCol: col,
    direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
  })

  renderItensTable = () => {
    const { sortCol, direction } = this.state
    const list = _.sortBy(this.props.productsForSale, [sortCol])
    if (direction === 'descending') list.reverse()

    return (
      <Table sortable celled >
        <Table.Header>
          <Table.Row >
            <Table.HeaderCell
              sorted={sortCol === 'id' ? direction : null}
              onClick={() => this.handleSort('id')}
            >
              ID
            </Table.HeaderCell>
            <Table.HeaderCell>Imagem</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortCol === 'descricao' ? direction : null}
              onClick={() => this.handleSort('descricao')}
            >
              Titulo
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortCol === 'descricao' ? direction : null}
              onClick={() => this.handleSort('descricao')}
            >
              Descrição
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortCol === 'categoria' ? direction : null}
              onClick={() => this.handleSort('categoria')}
            >
              Detalhes
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortCol === 'categoria' ? direction : null}
              onClick={() => this.handleSort('categoria')}
            >
              Preço de venda
            </Table.HeaderCell>

            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body >
          {list.map((i, index) => {
            return (
              <Table.Row  negative={!i.available} key={i.id + "#" + i.title + "-" + index}>
                <Table.Cell   textAlign="center">{i.id}</Table.Cell>
                <Table.Cell>
                  <Image src={i.image} size='tiny' bordered centered />
                </Table.Cell>
                <Table.Cell>
                  {i.title}
                  <br />
                </Table.Cell>
                <Table.Cell>
                  {i.description}
                  <br />
                </Table.Cell>
                <Table.Cell>
                  {i.details}
                  <br />
                </Table.Cell>
                <Table.Cell textAlign="center">{money(i.sales_price)}</Table.Cell>
                <Table.Cell textAlign="center" selectable>
                  <Button
                    icon="edit"
                    result={i}
                    onClick={this.handleSelectItem}
                    color="green"
                    ref={this._refs[i.id]}
                    disabled={!this.props.user.permissions[this.props.permission].update}
                  />
                  <Button
                    icon="delete"
                    onClick={() => this.setState({ toDelete: i, delModal: true })}
                    color="red"
                    disabled={!this.props.user.permissions[this.props.permission].delete}
                  />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  renderConfirm = () => {
    const { loading, selected } = this.state
    const isEdit = Object.entries(selected).length > 0
    const fv = this.state.formValue
    return (
      <Segment loading={loading} basic>
        <Header
          as='h2'
          icon={isEdit ? "edit" : "file alternate outline"}
          content={isEdit ? "Alterar produto" : "Novo produto"}
          subheader={isEdit && "(#" + selected.id + ") " + selected.title}
        />
        {isEdit && this.props.user.permissions[this.props.permission].create &&
          <Rail attached internal position='right'>
            <div className="item-right-rail">
              <Button
                fluid
                content="Criar cópia deste item"
                icon="add"
                labelPosition="left"
                color="red"
                onClick={this.changeToAdd}
              />
            </div>
          </Rail>
        }
        <Form>
          <Form.Group>
            <Form.Field
              control={Form.Input}
              label="Titulo do produto"
              name='title'
              placeholder="Titulo do produto"
              value={fv.title}
              onChange={this.handleSingleChange}
              fluid
              width="8"
              required
            />

          </Form.Group>
          <Form.Group>
            <Form.Field
              control={Form.Input}
              label="Descrição do produto"
              name='description'
              placeholder="Descrição do produto"
              value={fv.description}
              onChange={this.handleSingleChange}
              fluid
              width="8"
              required
            />

          </Form.Group>
          <Form.Group>
            <Form.Field
              control={Form.Input}
              label="Detalhes do produto"
              name='details'
              placeholder="Detalhes do produto"
              value={fv.details}
              onChange={this.handleSingleChange}
              fluid
              width="8"
            />

          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Form.Input}
              label="Imagem de exibição"
              name='image'
              placeholder="URL (http://)"
              value={fv.image}
              onChange={this.handleSingleChange}
              required
            />
            <Form.Field
              control={Form.Input}
              label="Imagem Empresa"
              name='image_holder'
              placeholder="Imagem Empresa URL"
              value={fv.image_holder}
              onChange={this.handleSingleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              label="Preço original"
              control={Form.Input}
              value={fv.price}
              type="number"
              step='1'
              onChange={this.handleSingleChange}
              name="price"
              min="0"
            />
            <Form.Field
              label="Preço de venda"
              control={Form.Input}
              value={fv.sales_price}
              type="number"
              step='1'
              onChange={this.handleSingleChange}
              name="sales_price"
              min="0"
              required
            />
            <Form.Field
              label="Quantidade máxima"
              control={Form.Input}
              value={fv.qt_max}
              type="number"
              step='1'
              onChange={this.handleSingleChange}
              name="qt_max"
              min="1"
              required
            />
            <Form.Field
              control={Form.Input}
              label="Altura"
              name='height'
              placeholder=""
              type="number"
              value={fv.height}
              onChange={this.handleNumberChange}
              width="2"
              step="0.1"
            />
            <Form.Field
              control={Form.Input}
              label="Largura"
              name='width'
              placeholder=""
              type="number"
              value={fv.width}
              onChange={this.handleNumberChange}
              width="2"
              step="0.1"
            />
            <Form.Field
              control={Form.Input}
              label="Profundidade"
              name='length'
              placeholder=""
              type="number"
              value={fv.length}
              onChange={this.handleNumberChange}
              width="2"
              step="0.1"
            />
            <Form.Field
              control={Form.Input}
              label="Peso"
              name='weight'
              placeholder=""
              type="number"
              value={fv.weight}
              onChange={this.handleNumberChange}
              width="2"
              step="0.1"
            />
            <Form.Field
              control={Form.Checkbox}
              toggle
              label="Favorito"
              checked={fv.favorito === 1}
              name="favorite"
              onChange={this.handleToggleChange}
              width="2"
            />
            <Form.Field
              control={Form.Checkbox}
              toggle
              label="Ativo"
              checked={fv.available === 1}
              name="available"
              onChange={this.handleToggleChange}
              width="2"
            />

          </Form.Group>
        </Form>
      </Segment>
    )
  }
}

const mapStateToProps = ({ productsForSale }) => {
  return { productsForSale }
}

export default connect(mapStateToProps, actions)(ProductsForSale)
