export const url = {
   "dev": "https://api.dev.nolifretes.com.br/api/", 
   "local_home": "http://192.168.0.8:7000/api/",
   "local_rodrigo": "http://192.168.0.16:7000/api/",
   "local_noli": "http://192.168.15.132:9000/api/",
   "prod_v2": "https://api.nolifretes.com.br/api/",
   "homolog": "https://api.hm.nolifretes.com.br/api/",
   "prod_v1": "https://app.nolifretes.com.br/api/", 
   "phone": "http://172.20.10.2:7000/api/"
}

export const paymentFormsById = {
   credit_card: 1,
   boleto: 2,
   debit_card: 3,
   money: 4,
   pix: 5,
   pay_pal: 6,
   estorno: 9
}

export const paymentStatusPix = {
   ATIVA: 'ATIVA',
   CONCLUIDA: 'CONCLUIDA',
   REMOVIDA_PELO_USUARIO_RECEBEDOR: 'REMOVIDA_PELO_USUARIO_RECEBEDOR',
   REMOVIDA_PELO_PSP: 'REMOVIDA_PELO_PSP',
   EM_PROCESSAMENTO: 'EM_PROCESSAMENTO',
   NAO_REALIZADO: 'NAO_REALIZADO',
   DEVOLVIDO: 'DEVOLVIDO',
}

export const formatStatusPix = (status: paymentStatusPix): string => {
   switch (status) {
      case paymentStatusPix.ATIVA:
         return 'Aguardando pagamento'
      case paymentStatusPix.CONCLUIDA:
         return 'Pagamento confirmado'
      case paymentStatusPix.EM_PROCESSAMENTO:
         return 'Pagamento em processamento'
      case paymentStatusPix.NAO_REALIZADO:
         return 'Devolução não realizada'
      case paymentStatusPix.ATIVA:
         return 'Devolução realizada'
      case paymentStatusPix.REMOVIDA_PELO_PSP:
         return 'Cobrança removida'
      case paymentStatusPix.REMOVIDA_PELO_USUARIO_RECEBEDOR:
         return 'Cobrança removida'
      default:
         return ''
   }
}

export type TransactionStatus =
   | 'processing'
   | 'authorized'
   | 'paid'
   | 'refunded'
   | 'waiting_payment'
   | 'pending_refund'
   | 'refused'
   | 'chargedback'
   | 'analyzing'
   | 'pending_review';


export const formatStatusCreditCard = (status: TransactionStatus): string => {
   switch (status) {
      case 'processing':
         return 'Em processamento'
      case 'authorized':
         return 'Autorizado'
      case 'paid':
         return 'Pago'
      case 'refunded':
         return 'Estornado'
      case 'waiting_payment':
         return 'Aguardando pagamento'
      case 'pending_refund':
         return 'Estorno pendente'
      case 'refused':
         return 'Negado'
      case 'chargedback':
         return 'Aguardando pagamento'
      case 'analyzing':
         return 'Em análise'
      case 'pending_review':
         return 'Revisão pendente'

      default:
         return 'Verificar'
   }
}
