import React from 'react'
import Login from '../screens/login/Login'
import AppStructure from '../screens/appStructure/AppStructure'
import {validarLogin, setDefaultToken} from '../utils/noliAPI'
import {Loader, Dimmer} from 'semantic-ui-react'
import AlterarSenha from '../screens/configuracoes/AlterarSenha'
import {connect} from 'react-redux'
import * as actions from '../utils/actions'

class AuthMiddleware extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      logged: false,
      loading: true
    }
  }

  componentDidMount = () => {
    const keep = localStorage.getItem('@painel-noli/keepLogged') === 'true'
    if (keep)
      this.checkLoggedIn()
    else
      this.setState({loading: false})
  }

  checkLoggedIn = () => {
    const token = localStorage.getItem('@painel-noli/token');
    if (token) {
      setDefaultToken(token)
      validarLogin(token).then(res => {
        if (res.status === 200) {
          this.props.setAuthExpiration(res.data.expires)
          this.setState({logged: true, loading: false})
          setDefaultToken(res.headers['noli-auth-token'])
          this.props.setUserPermissions(res.data.permissions)
        } else{
          this.setState({loading: false})
        }  
      })
    } else {
      this.logout()
      this.setState({loading: false})
    }
  }

  logout = () => {
    localStorage.removeItem('@painel-noli/token')
    this.props.setPageTitle("Painel Noli")
    this.props.logoutUser()
    this.setState({logged: false})
  }


  render() {
    const {logged, loading} = this.state
    if (logged)
      if(!localStorage.getItem('@painel-noli/firstLogin')) 
        return (<AppStructure logout={this.logout} check={this.checkLoggedIn}/>)  
      else {
        return <AlterarSenha logout={this.logout} check={this.checkLoggedIn} /> 
      }
    else {
      if (loading)
        return (<Dimmer active={loading}>
          <Loader active={loading} size="massive" content="Aguarde um momento.."/>
        </Dimmer>)
      else
        return <Login active={false} check={this.checkLoggedIn}/> 
    }
  }

}

export default connect("", actions)(AuthMiddleware)
