import React from 'react'
import * as actions from '../../utils/actions'
import { connect } from 'react-redux'
import {maskToBeKey} from '../../utils/masks'
import {Segment, Search, Grid, Header, Rail, Button, Form, Confirm, Table, Image } from 'semantic-ui-react'
import _ from 'lodash'
import * as noliAPI from '../../utils/noliAPI'
import ReactDOM from 'react-dom'

const _fv = {
  aereo: 0,
  altura: 0,
  categoria: "",
  comprimento: 0,
  descricao: "",
  empresa_descricao: "",
  empresa_imagem: "",
  favorito: 1,
  imagem: "",
  largura: 0,
  na_vertical: 0,
  pode_vertical: 0,
  subtipo_id: 0,
  desmontavel: 0,
}

const subtipo_opt = [
  {value:1 ,text: "Guarda-Roupa & Roupeiro"},
  {value:2 ,text: "Camas & Box"},
  {value:3 ,text: "Estantes & Armários"},
  {value:4 ,text: "Cabeceira de Cama"},
  {value:5 ,text: "Beliche & Treliche"},
  {value:6 ,text: "Cadeiras & Bancos"},
  {value:7 ,text: "Sofás & Poltronas"},
  {value:8 ,text: "Racks & Painéis"},
  {value:9 ,text: "Mesas & Escrivaninhas"},
  {value:10 ,text: "Geladeira & Freezer"},
  {value:11 ,text: "Fogão"},
  {value:12 ,text: "Eletrodomésticos"},
  {value:13 ,text: "Cadeira & Banco"},
  {value:14 ,text: "Utensílios"},
  {value:15 ,text: "Outros"},
  {value:16 ,text: "Máquina & Secadora"},
  {value:17 ,text: "Eletrônicos"},
  {value:18 ,text: "Caixas"},
]

const categoria_opt = [
  {text: "Quarto", value: "Quarto"},
  {text: "Escritório", value: "Escritório"},
  {text: "Sala", value: "Sala"},
  {text: "Cozinha", value: "Cozinha"},
  {text: "Lavanderia", value: "Lavanderia"},
  {text: "Outros", value: "Outros"},
]

class Itens extends React.Component {

  state = {
    categorized: [],
    loading: true,
    value: "",
    results: [],
    selected: {},
    formValue: _fv,
    modalOpen: false,
    sortCol: 'id',
    direction: 'ascending',
    delModal: false,
    toDelete: {},
  }

  _refs = []

  componentDidMount() {
    this.props.setPageTitle('Cadastrar e Alterar Itens')
    if(this.props.itens.length === 0) this.props.fetchItens()
    else this.prepareItensForSearch(this.props.itens)
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.itens.length !== this.props.itens.length || !_.isEqual(prevProps.itens, this.props.itens)) this.prepareItensForSearch(this.props.itens)
  }

  prepareItensForSearch = (itens) => {
    //const ItemListIds = this.props.ItemList.map(i => i.item_id)
    const prepared = itens
    /*.filter(f => {
      return !ItemListIds.includes(f.id)
    })*/
    .map(i => {
      return{
        ...i,
        title: i.descricao,
        image: i.imagem,
        description: i.subtipo_descricao,
        item_id: i.id,
        price: "A: " + i.altura.toFixed(2) + " | L: " + i.largura.toFixed(2) + " | C: " + i.comprimento.toFixed(2)
      }
    })
    .reduce((acc, atual) => {
      const key = maskToBeKey(atual.categoria)
      if(!acc[key])
        acc[key] = {name: atual.categoria, results: []}
      acc[key].results.push(atual)
      return acc
    }, {})
    this._refs = this.props.itens.reduce((acc, cur) => {
      acc[cur.id] = React.createRef(cur.id)
      return acc
    }, {})
    this.setState({categorized: prepared, loading: false})

  }

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, value })
    const list = this.state.categorized

    setTimeout(() => {

    const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
    const isMatch = (result) => re.test(result.title)

    const filteredResults = _.reduce(
      list,
      (memo, data, name) => {
        const results = _.filter(data.results, isMatch)
        if (results.length) memo[name] = { name: list[name].name, results }
        return memo
      }, {} )
      this.setState({
        loading: false,
        results: filteredResults,
        firstLoad:false,
      })
    }, 300)

  }

  handleSelectItem = (e, {result}) => {
    this.setState({selected: result, value: result.descricao, formValue: result, modalOpen: true})
  }

  handleFocusItem = (e, {result}) => {
    this.setState({results: [], value: ""})
    let ref = ReactDOM.findDOMNode(this._refs[result.id].current)
    ref.focus()
    ref.parentNode.parentNode.classList.add('bouncer')
    setTimeout(()=> ref.parentNode.parentNode.classList.remove('bouncer'),400)

  }

  changeToAdd = () => {
    this.setState({value: "", selected: {}})
  }

  handleSingleChange = (e, {name, value}) => this.setState({
    formValue: {...this.state.formValue, [name]: value}
  })

  handleNumberChange = (e, {name, value}) => this.setState({
    formValue: {...this.state.formValue, [name]: Number(value)}
  })

  handleToggleChange = (e, {name, checked}) => this.setState({
    formValue: {...this.state.formValue, [name]: checked ? 1 : 0}
  })

  handleSubmit = () => {
    this.setState({loading: true})
    const keys = Object.keys(_fv)
    const values = Object.entries(this.state.formValue)
    const prepared = values.reduce((acc, cur) => {
      if( keys.includes(cur[0])) acc[cur[0]] = cur[1]
      return acc
    }, {})
    const isEdit = Object.entries(this.state.selected).length > 0
    if(isEdit) noliAPI.alterarItem(this.state.selected.id, prepared)
      .then(res => {
        this.props.fetchItens()
        this.setState({loading: false, results: [], modalOpen:false, formValue: _fv})
      })
    else if(this.validateToAdd()) noliAPI.adicionarItem(prepared)
      .then(res => {
        this.props.fetchItens()
        this.setState({loading: false, results: [], modalOpen:false, formValue: _fv})
      })

  }

  validateToAdd = () => {
    const fv = this.state.formValue
    if(fv.descricao==="" || fv.categoria==="" || fv.subtipo_id===0 || fv.imagem==="" || fv.empresa_descricao==="" || fv.empresa_imagem==="" || fv.altura< 0.1 || fv.largura< 0.1 || fv.comprimento< 0.1)
      return false
    else return true
  }

  deleteItem = () => {
    this.setState({loading: true})
    noliAPI.apagarItem(this.state.toDelete.id)
      .then(res => {
        this.props.fetchItens()
        this.setState({delModal: false})
      })
  }

  openModal = () => this.setState({modalOpen: true})

  render() {
    const {loading, value, results, selected, modalOpen, delModal, toDelete} = this.state
    const isEdit = Object.entries(selected).length > 0
    return (
      <Grid padded>
        <Grid.Row reversed='computer'>
          <Grid.Column stretched computer="4" tablet="6" mobile="16">
            <Segment>
              <Header
                as='h5'
                icon='add'
                content="Cadastro"
              />
              <Button
                icon="box"
                labelPosition="left"
                content="Novo Item"
                onClick={this.openModal}
                fluid
                color="green"
                disabled={!this.props.user.permissions[this.props.permission].create}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer="12" tablet="10" mobile="16">
            <Segment>
              <Header
                as='h5'
                icon='boxes'
                content="Selecione um produto para alterar:"
              />
              <Search
                fluid
                category
                icon="search"
                input={{ fluid: true }}
                loading={loading}
                placeholder="Procure o item que deseja alterar"
                noResultsMessage="Nenhum item encontrado para essa combinação de caracteres"
                size="large"
                value={value}
                onSearchChange={_.debounce(this.handleSearchChange, 500, {
                  leading: true,
                })}
                results={results}
                onResultSelect={this.handleFocusItem}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment loading={loading}>
              <Header
                as='h2'
                icon="list"
                content="Itens Cadastrados"
                subheader={"Total de " + this.props.itens.length + " itens"}
              />
            {this.renderItensTable()}
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Confirm open={modalOpen}
              size="large"
              content={this.renderConfirm}
              onConfirm={this.handleSubmit}
              onCancel={()=>this.setState({modalOpen: false, selected: {}, formValue: _fv})}
              confirmButton={isEdit
                ? <Button disabled={!this.validateToAdd()}>Alterar Item: {selected.descricao}</Button>
                : <Button disabled={!this.validateToAdd()}>Criar Novo Item</Button>
              }
              cancelButton = "Cancelar"
            />
          <Confirm
            open={delModal}
            content={"Tem certeza que deseja excluir " + toDelete.descricao + " ?"}
            onConfirm={this.deleteItem}
            onCancel={() => this.setState({toDelete: {}, delModal: false})}
            confirmButton="Excluir"
            cancelButton = "Cancelar"
          />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  handleSort = (col) => this.setState({
    sortCol: col,
    direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
  })

  renderItensTable = () => {
    const {sortCol, direction} = this.state
    const list = _.sortBy(this.props.itens, [sortCol])
    if(direction === 'descending') list.reverse()

    return(
      <Table sortable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortCol === 'id' ? direction : null}
              onClick={() => this.handleSort('id')}
            >
              ID
            </Table.HeaderCell>
            <Table.HeaderCell>Imagem</Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortCol === 'descricao' ? direction : null}
              onClick={() => this.handleSort('descricao')}
            >
              Descrição
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortCol === 'categoria' ? direction : null}
              onClick={() => this.handleSort('categoria')}
            >
              Categoria
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortCol === 'altura' ? direction : null}
              onClick={() => this.handleSort('altura')}
            >
              Altura
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortCol === 'largura' ? direction : null}
              onClick={() => this.handleSort('largura')}
            >
              Largura
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortCol === 'comprimento' ? direction : null}
              onClick={() => this.handleSort('comprimento')}
            >
              Profundidade
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.map((i,index) => {
            return(
              <Table.Row key={i.id+"#"+i.descricao+"-"+index}>
                <Table.Cell textAlign="center">{i.id}</Table.Cell>
                <Table.Cell>
                  <Image src={i.imagem} size='tiny' bordered centered />
                </Table.Cell>
                <Table.Cell>
                  {i.descricao}
                  <br />
                  <small style={{color:'#888'}}>{i.subtipo_descricao}</small>
                </Table.Cell>
                <Table.Cell textAlign="center">{i.categoria}</Table.Cell>
                <Table.Cell textAlign="center">{i.altura}</Table.Cell>
                <Table.Cell textAlign="center">{i.largura}</Table.Cell>
                <Table.Cell textAlign="center">{i.comprimento}</Table.Cell>
                <Table.Cell textAlign="center" selectable>
                  <Button
                    icon="edit"
                    result={i}
                    onClick={this.handleSelectItem}
                    color="green"
                    ref={this._refs[i.id]}
                    disabled={!this.props.user.permissions[this.props.permission].update}
                  />
                  <Button
                    icon="delete"
                    onClick={() => this.setState({toDelete:i, delModal: true})}
                    color="red"
                    disabled={!this.props.user.permissions[this.props.permission].delete}
                  />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  renderConfirm = () => {
    const {loading, selected} = this.state
    const isEdit = Object.entries(selected).length > 0
    const fv = this.state.formValue
    return(
      <Segment loading={loading} basic>
        <Header
          as='h2'
          icon={isEdit ? "edit" : "file alternate outline"}
          content={ isEdit ? "Alterar Item" : "Novo Item"}
          subheader={isEdit && "(#"+selected.id+") " + selected.descricao}
        />
        { isEdit && this.props.user.permissions[this.props.permission].create &&
          <Rail attached internal position='right'>
            <div className="item-right-rail">
              <Button
                fluid
                content="Criar cópia deste item"
                icon="add"
                labelPosition="left"
                color="red"
                onClick={this.changeToAdd}
              />
            </div>
          </Rail>
        }
        <Form>
          <Form.Group>
            <Form.Field
              control={Form.Input}
              label="Descrição do Item"
              name='descricao'
              placeholder="Descrição do Item"
              value={fv.descricao}
              onChange={this.handleSingleChange}
              fluid
              width="8"
            />
            <Form.Field
              control={Form.Select}
              label="Categoria"
              name='categoria'
              placeholder="Categoria"
              options={categoria_opt}
              value={fv.categoria}
              onChange={this.handleSingleChange}
              fluid
              width="3"
            />
            <Form.Field
              control={Form.Select}
              label="Subtipo"
              name='subtipo_id'
              placeholder="Subtipo"
              options={subtipo_opt}
              value={fv.subtipo_id}
              onChange={this.handleSingleChange}
              fluid
              width="5"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Form.Input}
              label="Imagem de exibição"
              name='imagem'
              placeholder="URL (http://)"
              value={fv.imagem}
              onChange={this.handleSingleChange}
            />
            <Form.Field
              control={Form.Input}
              label="Empresa"
              name='empresa_descricao'
              placeholder="Nome da Empresa"
              value={fv.empresa_descricao}
              onChange={this.handleSingleChange}
            />
            <Form.Field
              control={Form.Input}
              label="Imagem Empresa"
              name='empresa_imagem'
              placeholder="Imagem Empresa URL"
              value={fv.empresa_imagem}
              onChange={this.handleSingleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              control={Form.Input}
              label="Altura"
              name='altura'
              placeholder=""
              type="number"
              value={fv.altura}
              onChange={this.handleNumberChange}
              width="2"
              step="0.1"
            />
            <Form.Field
              control={Form.Input}
              label="Largura"
              name='largura'
              placeholder=""
              type="number"
              value={fv.largura}
              onChange={this.handleNumberChange}
              width="2"
              step="0.1"
            />
            <Form.Field
              control={Form.Input}
              label="Profundidade"
              name='comprimento'
              placeholder=""
              type="number"
              value={fv.comprimento}
              onChange={this.handleNumberChange}
              width="2"
              step="0.1"
            />
            <Form.Field
              control={Form.Checkbox}
              toggle
              label="Favorito"
              checked={fv.favorito === 1}
              name="favorito"
              onChange={this.handleToggleChange}
              width="2"
            />
            <Form.Field
              control={Form.Checkbox}
              toggle
              label="Aéreo"
              checked={fv.aereo === 1}
              name="aereo"
              onChange={this.handleToggleChange}
              width="2"
            />
            <Form.Field
              control={Form.Checkbox}
              toggle
              label="Na vertical"
              checked={fv.na_vertical === 1}
              name="na_vertical"
              onChange={this.handleToggleChange}
              width="2"
            />
            <Form.Field
              control={Form.Checkbox}
              toggle
              label="Desmontável"
              checked={fv.desmontavel === 1}
              name="desmontavel"
              onChange={this.handleToggleChange}
              width="2"
            />
            <Form.Field
              control={Form.Checkbox}
              toggle
              label="Pode na vertical"
              checked={fv.pode_vertical === 1}
              name="pode_vertical"
              onChange={this.handleToggleChange}
              width="2"
            />
          </Form.Group>
        </Form>
      </Segment>
    )
  }
}

const mapStateToProps = ({itens}) => {
  return {itens}
}

export default connect(mapStateToProps, actions)(Itens)
