import React from "react";
import { GoogleMap, LoadScript, Marker, InfoBox, OverlayView } from '@react-google-maps/api'
import {Label} from 'semantic-ui-react'
import * as geocode from '../utils/geocodeInfo'
import mapStyle from "../utils/MapStyleWizard"
import ReactDOM from 'react-dom';
import _ from 'lodash'

const markercolors = ['2185D0', '00B5AD', 'DB2828', 'F2711C', 'FBBD08', '6435C9', 'A333C8', 'E03997', '21BA45', 'A5673F']
const colorNames = ['blue', 'teal', 'red', 'orange', 'yellow', 'violet', 'purple', 'pink', 'green', 'brown']

class DashboardMap extends React.Component {

  state = {
    open: false,
    infoText: "",
    infoPosition: { lat: -23.5651478, lng: -46.6404272 },
    mapCenter: { lat: -23.5651478, lng: -46.6404272 },
    markerIndex: 0,
  }

  markersChanged = new Event('markers-changed', {bubbles: true});

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.triggerOverlay.lat && this.props.triggerOverlay.lng){
      if(prevProps.triggerOverlay.ordem !== this.props.triggerOverlay.ordem)
        this.handleMarkerClick(this.props.triggerOverlay, this.state.markerIndex)
      if(prevProps.triggerOverlay.lat !== this.props.triggerOverlay.lat || prevProps.triggerOverlay.lng !== this.props.triggerOverlay.lng)
        this.handleMarkerClick(this.props.triggerOverlay, this.state.markerIndex)
    }
  } 

  handleMarkerClick = (marker, index) => {
    const position = {
      lat: marker.lat,
      lng:marker.lng
    }
    geocode.fromLatLng(position.lat, position.lng).then(local => {
      this.setState({open: true, infoText: local, infoPosition: position, markerIndex: index})
      this.props.onMarkerClick(marker, local)
    })
  }

  renderMarkers = () => {
    return this.props.markers.filter(m=>m).map((m, i) => {
      const colorIndex = i >= markercolors.length ? i%markercolors.length : i
      const position = {
        lat: m.lat,
        lng:m.lng
      }
      return(
        <Marker
          key={m.key}
          position={position}
          icon={"https://img.icons8.com/android/30/"+ markercolors[colorIndex] +"/truck.png"}
          onClick={() => this.handleMarkerClick(m, i)}
          title={m.ordem + " " + m.transportador}
        >
        </Marker>
      )
    })
  }

  renderInfoBoxes = () => {
    return this.props.markers.filter(m=>m).map((m, i) => {
      const colorIndex = i >= markercolors.length ? i%markercolors.length : i
      const position = {
        lat: m.lat,
        lng:m.lng
      }
      return (
        <OverlayView
          position={position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          key={m.key}
        >
        <Label
          content={m.ordem}
          detail={m.transportador}
          circular
          color={colorNames[colorIndex]}
          size="tiny"
          basic
          onClick={() => this.handleMarkerClick(m, i)}
        />
        </OverlayView>
      )
    })
  }

  onMapLoad = (map) => {

  }

  render() {
    const {markers} = this.props
    const {infoPosition, mapCenter, infoText, open, markerIndex} = this.state

    const overlayViewColor = colorNames[markerIndex >= markercolors.length ? markerIndex % markercolors.length : markerIndex]

    return (
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyA1Ro7FjdmhtqE9KG65JeJQSPIsBlQnHrw"
      >
        <GoogleMap
          id='example-map'
          center={mapCenter}
          zoom={10}
          mapContainerStyle={{height: '100%', width: '100%'}}
          options={{
            styles: mapStyle()
            }}
          onLoad={this.onMapLoad}
        >
          {markers.length > 0 && this.renderMarkers()}
          {markers.length > 0 && this.renderInfoBoxes()}
          {(open && markers.filter(m=>m).length > 0) &&
            <OverlayView
              position={infoPosition}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={(width, height) => ({
                x: -(width / 2),
                y: -(height * 2.4),
              })}
            >
              <Label
                color={overlayViewColor}
                icon="road"
                size="small"
                content={infoText}
              />
            </OverlayView>
          }
        </GoogleMap>
      </LoadScript>
    )
  }
}


export default DashboardMap;
