export const maskCpf = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um tra�o e n�o deixa ser digitado mais nada
}

export function isValidCPF(cpf) {
  if (typeof cpf !== 'string') return false
  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
  cpf = cpf.split('').map(el => +el)
  const rest = (count) => (cpf.slice(0, count-12)
      .reduce( (soma, el, index) => (soma + el * (count-index)), 0 )*10) % 11 % 10
  return rest(10) === cpf[9] && rest(11) === cpf[10]
}

export const unmaskCpf = value => value.replace(/[^\d]+/g, '')

export const unmaskRG = value => value.replace(/[^\d]+/g, '')

export const maskTelefone = value => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d\d)(\d)/g, "($1) $2")
    .replace(/(\d)(\d{4})$/, "$1-$2")
}

export const unmaskTelefone = value => value.replace("(", '').replace(")", '').replace("-", '').replace(" ", '')

export const maskCep = value => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{3})(\d)/g, "$1.$2")
    .replace(/(\d{2})(\d{3})$/, "$1-$2")
}

export const unmaskCep = value => value.replace(".", '').replace("-", '')

export const maskPlaca = value => {
  return value
    .replace(/^(\D{1,3})/g, (c) => c.toUpperCase())
    .replace(/^\d/g, '')
    .replace(/^(\D{3})(\D)/g, '$1')
    .replace(/^(\D{3})(\d)/g, "$1-$2")
    .replace(/(-\d{4})[\s\S]+?$/, "$1")
}

export const maskEndereco = (value) => {
  let endereco = ""
  endereco += value.rua + ", "
  endereco += value.numero + " - "
  endereco += value.complemento ? "Complemento: " + value.complemento + " - " : ''
  endereco += value.bairro + " - "
  endereco += value.cep && "CEP: " + value.cep + " - "
  endereco += value.cidade + " / "
  endereco += value.estado
  return endereco
}

export const maskToBeKey = (value) => {
  if (typeof value === "string")
    return value.normalize('NFD').replace(/\s+/g, '-').replace(/[\u0300-\u036f]/g, "").toLowerCase()
  else return value
}

export const maskOrdem = (value) => {
  return value
    .replace(/^([^nN]{1})/g, '')
    .replace(/^([nN]{1})/g, (c) => c.toUpperCase())
    .replace(/^([nN]{1}\d{0,})([a-zA-Z])/g, '$1')
    .replace(/^([nN]{1}\d{0,}-)(\D{0,})/g, '$1')
    .replace(/^([nN]{1}\d{0,}-)(\d{0,})(\D{0,})/g, '$1$2')
}

export function maskRG(v0, errChar = '?') {
  const v = v0.toUpperCase().replace(/[^\dX]/g, '');
  return  v.replace(/^(\d{1,2})(\d{3})(\d{3})([\dX])$/, '$1.$2.$3-$4')
  // return (v.length === 8 || v.length === 9) ?
  //   v.replace(/^(\d{1,2})(\d{3})(\d{3})([\dX])$/, '$1.$2.$3-$4') :
  //   (errChar + v0)
  //   ;
} 

