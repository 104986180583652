import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../utils/actions'
import * as masks from '../../utils/masks'
import _ from 'lodash'
import {Segment, Grid, Header, Button, Table, Confirm, Form, Input, Checkbox, Icon} from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react';
import * as noliAPI from '../../utils/noliAPI'
import SearchFuncionariosFilter from '../../components/SearchFuncionariosFilter'
import formatDate from '../../utils/formatDate'

const _defaultFunc = {
  nome:"",
  data_nascimento: "",
  telefone: "",
  cpf: "",
  email: "",
  senha: '@noli2021',
  is_admin: 0,
  is_active: 1,
  is_auth: 1,
}

class Funcionarios extends React.Component {

  state = {
    loading: false,
    list: [],
    formVal: _defaultFunc,
    newOpen: false,
    editOpen: false,
    selected: _defaultFunc,
    edited: _defaultFunc,
    reload: false
  }

  componentDidMount() {
    if(this.props.title === "Painel Noli") this.props.setPageTitle('Cadastrar e Alterar Informações de Funcionários')
  }

  handleFormChange = (e, {value, name}) => this.setState({formVal: { 
    ...this.state.formVal, [name] : value
  }})

  handleFormCPFChange = (e, {value, name}) => this.setState({formVal: {
    ...this.state.formVal, [name] : masks.maskCpf(value)
  }})

  handleFormTELChange = (e, {value, name}) => this.setState({formVal: {
    ...this.state.formVal, [name] : masks.maskTelefone(value)
  }})

  handleFormCheck = (e, {checked, name}) => this.setState({formVal: {
    ...this.state.formVal, [name] : checked ? 1 : 0
  }})

  handleNewFnConfirmToggle = () => this.setState({
    newOpen: !this.state.newOpen,
    formVal: _defaultFunc
  })

  handleEditFnConfirmToggle= (selected = _defaultFunc) => this.setState({
    editOpen: !this.state.editOpen,
    selected: selected, formVal: selected
  })

  handleSubmit = () => {
    const {formVal} = this.state
    this.setState({loading: true})
    if(!this.validarForm(true)){
      this.setState({loading: false})
    }else{
      const prep = {
        ...formVal,
        telefone: masks.unmaskTelefone(formVal.telefone),
        cpf: masks.unmaskCpf(formVal.cpf),
      }
      noliAPI.cadastrarFuncionario(prep)
        .then((res) => {
          this.setState({loading: false})
          if(res.data.status_code === "00") this.props.sendMessage([], "Funcionário cadastrado com sucesso!", {color:'blue', icon: "check circle"}, false)
          else this.props.sendMessage(res.data.msg, "Erro ao tentar cadastrar", {color:'red', icon: "times circle"}, false)
          this.reloadList()
        })
        .catch(error => {
          this.props.sendMessage(error, "Erro ao tentar alterar funcionário", {color:'red', icon: "times circle"}, false)
        })
    }
  }

  reloadList = () =>{
    this.setState({reload: false})
    noliAPI.buscarFuncionariosPor().then(res => {
      this.setList(res.data.data)
      this.setState({loading: false, newOpen: false, editOpen:false, reload:true})
    })
  }

  setList = (list) => {
    this.setState({list: list})
  }

  validarForm = (send = false) => {
    const {formVal} = this.state
    let errors = []
    if(formVal.nome === "") errors.push("Nome não pode ser vazio")
    if(formVal.cpf === "") errors.push("CPF não pode ser vazio")
    if(formVal.cpf.length !== 14) errors.push("CPF inválido")
    if(formVal.telefone === "") errors.push("Telefone não pode ser vazio")
    if(formVal.email === "") errors.push("Email não pode ser vazio")
    if(formVal.senha === "") errors.push("Senha não pode ser vazio")

    if(send && errors.length > 0) this.props.sendMessage(errors, "Verifique os seguintes erros", {color:'red'}, false)
    if(errors.length > 0) return false
    else return true
  }

  handleEditSubmit = () => {
    const {formVal, selected} = this.state
    this.setState({loading: true})
    let payload = {}
    if(formVal.nome !== selected.nome) payload.nome = formVal.nome
    if(formVal.email !== selected.email) payload.email = formVal.email
    if(formVal.data_nascimento !== selected.data_nascimento) payload.data_nascimento = formVal.data_nascimento
    if(formVal.cpf !== selected.cpf) payload.cpf = masks.unmaskCpf(formVal.cpf)
    if(formVal.telefone !== selected.telefone) payload.telefone = masks.unmaskTelefone(formVal.telefone)
    if(formVal.senha !== selected.senha) {
      payload.senha = formVal.senha
      payload.first_login = true
    }
    if(formVal.is_admin !== selected.is_admin) payload.is_admin = formVal.is_admin
    if(formVal.is_active !== selected.is_active) payload.is_active = formVal.is_active
    if(formVal.is_auth !== selected.is_auth) payload.is_auth = formVal.is_auth

    if(Object.keys(payload).length > 0) noliAPI.alterarFuncionario(formVal.id, payload).then(res => {

      if(res.data.status_code === "00") this.props.sendMessage([], "Funcionário alterado com sucesso!", {color:'blue', icon: "check circle"}, false)
      else this.props.sendMessage(res.data.msg, "Erro ao tentar alterar funcionário", {color:'red', icon: "times circle"}, false)
      this.setState({editOpen: false})
      this.reloadList()
    })

  }

  render() {
    const {loading, reload} = this.state
    return(
      <Grid padded>
        <Grid.Row reversed="computer">
          <Grid.Column computer="4" mobile="16" tablet="6" stretched>
            <Segment>
              <Header
                as='h5'
                icon='add'
                content="Cadastro"
              />
              <Button
                content="Novo funcionário"
                icon="user"
                fluid
                color="green"
                labelPosition='left'
                onClick={this.handleNewFnConfirmToggle}
                disabled={!this.props.user.permissions[this.props.permission].create}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer="12" mobile="16" tablet="10" stretched>
            <Segment>
              <Header
                as='h5'
                icon='search'
                content="Buscar Funcionários:"
              />
              <SearchFuncionariosFilter
                setList={this.setList}
                loading={loading}
                setLoading={(load) => this.setState({loading: load})}
                reload={reload}
              />
              {this.renderConfirm()}
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment loading={loading}>
              <Header
                as='h5'
                icon='users'
                content="Funcionários Noli"
              />
              <Table selectable striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell>Data de <br />Nascimento</Table.HeaderCell>
                    <Table.HeaderCell>Telefone</Table.HeaderCell>
                    <Table.HeaderCell>CPF</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Admin</Table.HeaderCell>
                    <Table.HeaderCell>Ativo</Table.HeaderCell>
                    <Table.HeaderCell>Auth</Table.HeaderCell>
                    <Table.HeaderCell>Criado em</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.renderFuncTable()}
                </Table.Body>
              </Table>
            </Segment>
            {this.renderEditConfirm()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  renderFuncTable = () => {
    if(this.state.list.length > 0){
      return this.state.list.map(f => {
        return(
          <Table.Row
            key={f.id}
            negative={!f.is_active}
            positive={!!f.is_active}
            onClick={() => this.handleEditFnConfirmToggle(
              {
                ...f,
                senha:"",
                cpf: masks.maskCpf(f.cpf),
                telefone: masks.maskTelefone(f.telefone),
              })
            }
            className="selectable-row"
            disabled={!this.props.user.permissions[this.props.permission].update}
          >
            <Table.Cell><small>{f.id}</small></Table.Cell>
            <Table.Cell><small>{f.nome}</small></Table.Cell>
            <Table.Cell><small>{f.data_nascimento}</small></Table.Cell>
            <Table.Cell><small>{f.telefone && masks.maskTelefone(f.telefone)}</small></Table.Cell>
            <Table.Cell><small>{f.cpf && masks.maskCpf(f.cpf)}</small></Table.Cell>
            <Table.Cell><small>{f.email}</small></Table.Cell>
            <Table.Cell><Icon name={f.is_admin ? 'check circle': 'times circle'} /></Table.Cell>
            <Table.Cell><Icon name={f.is_active ? 'check circle': 'times circle'} /></Table.Cell>
            <Table.Cell><Icon name={f.is_auth ? 'check circle': 'times circle'} /></Table.Cell>
            <Table.Cell><small>{formatDate.dateTimeServer(f.created_at)}</small></Table.Cell>
          </Table.Row>
        )
      })
    }else {
      return(
        <Table.Row>
          <Table.Cell colSpan={10}>Funcionário(s) não encontrado(s)</Table.Cell>
        </Table.Row>
      )
    }
  }

  renderConfirm = () => {
    const {formVal, loading} = this.state
    const valid = this.validarForm()
    return(
      <>
        <Confirm
          open={this.state.newOpen}
          header="Novo Funcionário"
          onCancel={this.handleNewFnConfirmToggle}
          onConfirm={this.handleSubmit}
          cancelButton="Cancelar"
          confirmButton={
            <Button
              content="Cadastrar Funcionário"
              disabled={_.isEqual(formVal, _defaultFunc) || loading || !valid}
            />
          }
          content={
            <Segment basic loading={loading}>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="Nome Completo"
                    name="nome"
                    value={formVal.nome}
                    onChange={this.handleFormChange}
                    width="12"
                    required
                  />
                  <Form.Field
                    control={DateInput}
                    label="Data de Nascimento"
                    name="data_nascimento"
                    value={formVal.data_nascimento}
                    onChange={this.handleFormChange}
                    width="4"
                    localization="pt-br"
                    maxDate={new Date()}
                    dateFormat="DD/MM/YYYY"
                    closable
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    label="CPF"
                    name="cpf"
                    value={formVal.cpf}
                    onChange={this.handleFormCPFChange}
                    required
                  />
                  <Form.Field
                    control={Input}
                    label="Telefone"
                    name="telefone"
                    maxLength="15"
                    value={formVal.telefone}
                    onChange={this.handleFormTELChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="Email"
                    name="email"
                    value={formVal.email}
                    onChange={this.handleFormChange}
                    required
                  />
                  <Form.Field
                    control={Input}
                    label="Senha"
                    type="password"
                    name="senha"
                    value={formVal.senha}
                    onChange={this.handleFormChange}
                    required
                  />
                </Form.Group>
                <Form.Group inline>
                  <Form.Field
                    control={Checkbox}
                    label="Administrador"
                    name="is_admin"
                    checked={formVal.is_admin === 1}
                    onChange={this.handleFormCheck}
                  />
                  <Form.Field
                    control={Checkbox}
                    label="Ativo"
                    name="is_active"
                    checked={formVal.is_active === 1}
                    onChange={this.handleFormCheck}
                  />
                  <Form.Field
                    control={Checkbox}
                    label="Autorizado"
                    name="is_auth"
                    checked={formVal.is_auth === 1}
                    onChange={this.handleFormCheck}
                  />
                </Form.Group>
              </Form>
            </Segment>
          }
        />
      </>
    )
  }

  renderEditConfirm = () => {
    const {formVal, loading, selected} = this.state
    return(
      <>
        <Confirm
          open={this.state.editOpen}
          header="Editar Funcionário"
          onCancel={() => this.handleEditFnConfirmToggle()}
          onConfirm={this.handleEditSubmit}
          cancelButton="Cancelar"
          confirmButton={
            <Button
              content="Alterar Funcionário"
              disabled={_.isEqual(selected, formVal) || loading }
            />
          }
          content={
            <Segment basic loading={loading}>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="Nome Completo"
                    name="nome"
                    value={formVal.nome}
                    onChange={this.handleFormChange}
                    width="12"
                    required
                  />
                  <Form.Field
                    control={DateInput}
                    label="Data de Nascimento"
                    name="data_nascimento"
                    value={formVal.data_nascimento}
                    onChange={this.handleFormChange}
                    width="4"
                    localization="pt-br"
                    maxDate={new Date()}
                    dateFormat="DD/MM/YYYY"
                    closable
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    label="CPF"
                    name="cpf"
                    value={formVal.cpf}
                    onChange={this.handleFormCPFChange}
                    required
                  />
                  <Form.Field
                    control={Input}
                    label="Telefone"
                    name="telefone"
                    maxLength="15"
                    value={formVal.telefone}
                    onChange={this.handleFormTELChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="Email"
                    name="email"
                    value={formVal.email}
                    onChange={this.handleFormChange}
                    required
                  />
                  <Form.Field
                    control={Input}
                    label="Senha"
                    type="password"
                    name="senha"
                    value={formVal.senha}
                    onChange={this.handleFormChange}
                    required
                  />
                </Form.Group>
                <Form.Group inline>
                  <Form.Field
                    control={Checkbox}
                    label="Administrador"
                    name="is_admin"
                    checked={formVal.is_admin === 1}
                    onChange={this.handleFormCheck}
                  />
                  <Form.Field
                    control={Checkbox}
                    label="Ativo"
                    name="is_active"
                    checked={formVal.is_active === 1}
                    onChange={this.handleFormCheck}
                  />
                  <Form.Field
                    control={Checkbox}
                    label="Autorizado"
                    name="is_auth"
                    checked={formVal.is_auth === 1}
                    onChange={this.handleFormCheck}
                  />
                </Form.Group>
              </Form>
            </Segment>
          }
        />
      </>
    )
  }
}

const mapStateToProps = ({pageInfo}) => pageInfo

export default connect(mapStateToProps, actions)(Funcionarios)
