import React from 'react'
import {Form, Dropdown, Search, Button, Label, Container, Message} from 'semantic-ui-react'
import { createClient } from '@google/maps';
import _ from 'lodash'

const client = createClient({
  key: 'AIzaSyA1Ro7FjdmhtqE9KG65JeJQSPIsBlQnHrw',
  Promise: Promise
})

const tipo_imovel = [
  {key: "predio_sem_elevador", text: "Prédio sem Elevador", value: "sem"},
  {key: "predio_com_elevador", text: "Prédio com Elevador", value: "com"},
  {key: "Outros", text: "Outros", value: "outros"}
]

class OrdenadorEnderecos extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      enderecos: [],
      loading: false,
      results: [],
      totalDistance: 0,
      msgs: []
    }
  } 

  componentDidUpdate(prevProps, prevState) {
    if(prevState.enderecos.length === 0 && this.props.enderecos.length > 0){
      this.setState({
        enderecos: this.props.enderecos.map(end=> {
          return {
            ...end,
            rua: end.rua + ", " + end.numero,
            place_id: null
          }}),
        totalDistance: this.props.distancia
      })
    }
  }

  handleSearchEnderecoChange = (e, { ordem, value }) => {
    const splitAdd = value.split(' - ')[0]
    const splitted = splitAdd.split(/,\s/)
    const numero = splitted.length === 2 ? splitted[1] : "S/N"
    const newEnd = this.state.enderecos.map(end=> {
      if (end.ordem === ordem) return {
        ...end,
        rua: splitAdd,
        numero: numero,
        place_id: null
      }
      else return end
    })
    this.setState({enderecos: newEnd, loading: true})
    if(value.length > 4){
      client.placesAutoComplete({
        input: value,
        types: "address"
      }).asPromise().then((res)=>{
        const predictions = res.json.predictions.map(p => {return {...p, title: p.description, description: "Place ID: "+ p.place_id}})
        this.setState({results: predictions, loading: false})
      }).catch((error) => {
      })
    }else if(value.length === 0) this.setState({results: [], loading: false})
    else this.setState({loading: false})
  }

  handleResultSelect = (e,{result, ordem}) => {
    const splitAdd = result.title.split(' - ')[0]
    const splitted = splitAdd.split(/,\s/)
    const numero = splitted.length === 2 ? splitted[1] : "S/N"
    const newEnd = this.state.enderecos.map(end=> {
      if (end.ordem === ordem) return {
        ...end,
        rua: splitAdd,
        numero: numero,
        place_id:result.place_id,
      }
      else return end
    })
    this.setState({enderecos: newEnd})
  }

  handleChange = (e,{name, value, ordem}) => this.setState({enderecos: this.state.enderecos.map(end=> {
    if(end.ordem === ordem) return {...end, [name]: value}
    else return end
  })})

  getTipoImovelValue = ({predio, escadas}) => {
    if(predio === 1 && escadas === 1) return "sem"
    else if(predio===1 && escadas === 0) return "com"
    else if(predio===0) return "outros"
  }

  getSecImovelOptions = (tipo) => {
    if(tipo === "sem" || tipo === "com") return _.times(36, (i)=> {return {
        key:i, text: (i===0) ? "Térreo" : i + "º andar", value:i
      }
    })
    else return [
      {key:'terreo', text:'Térreo', value: 'terreo'},
      {key:'sobrado', text:'Sobrado', value: 'sobrado'},
      {key:'1lance', text:'1 Lance de escadas', value: '1lance'},
      {key:'2lance', text:'2 Lances de escadas', value: '2lance'},
      {key:'3lance', text:'3 Lances de escadas', value: '3lance'}
    ]
  }

  handleTipoImovelChange = (e, {ordem, value}) => {
    const newEnderecos = this.state.enderecos.map(end=>{
      if(end.ordem === ordem && value === "sem") return {...end, predio: 1, escadas: 1, sobrado: 0}
      else if (end.ordem === ordem && value === "com") return {...end, predio: 1, escadas: 0, sobrado: 0}
      else if (end.ordem === ordem && value === "outros") return {...end, predio: 0, sobrado: 0}
      else return end

    })
    this.setState({enderecos: newEnderecos})
  }

  handleDetalhesChange = (e, {ordem, value}) => {
    const newEnderecos = this.state.enderecos.map(end => {
      const tipo = this.getTipoImovelValue(end)
      if(end.ordem===ordem && value==="terreo") return {...end, sobrado: 0, andares: 0, predio: 0}
      else if (end.ordem===ordem && value==="sobrado") return {...end, sobrado: 1, andares: 1, predio: 0, escadas:1}
      else if (end.ordem===ordem && value==="1lance") return {...end, sobrado: 0, andares: 1, predio: 0}
      else if (end.ordem===ordem && value==="2lance") return {...end, sobrado: 0, andares: 2, predio: 0}
      else if (end.ordem===ordem && value==="3lance") return {...end, sobrado: 0, andares: 3, predio: 0}
      else if (end.ordem===ordem && tipo ==="sem") return {...end, sobrado: 0, andares: value, predio: 1, escadas: 1}
      else if (end.ordem===ordem && tipo ==="com") return {...end, sobrado: 0, andares: value, predio: 1, escadas: 0}
      else return end
    })
    this.setState({enderecos: newEnderecos})
  }

  handleDelete = (index) => {
    let newEnd = [...this.state.enderecos]
    this.setState({enderecos: newEnd.filter((e,i) => i!== index).map((end, i)=> {return {...end, ordem:i+1}})})
  }

  moveEndereco = (oldIndex, positions) => {
    const newIndex = oldIndex + positions
    const toMove = this.state.enderecos[oldIndex]
    let newEnd = [...this.state.enderecos]
    newEnd.splice(oldIndex, 1)
    newEnd.splice(newIndex, 0, toMove)
    this.setState({enderecos: newEnd.map((end, i)=> {return {...end, ordem:i+1}})})
  }

  handleAddAddress = () => {
    const _address = {
      rua:"", numero:"", complemento:"", sobrado:0, predio:0, andares:0, escadas:0, place_id:null
    }
    const newEnd = [...this.state.enderecos].concat(_address)
    this.setState({enderecos: newEnd.map((end, i)=> {return {...end, ordem:i+1}})})
  }

  calculateDistance = () => {
    const {enderecos} = this.state
    let errors = []
    let msgs = []
    if(enderecos.length === 0) return
    enderecos.forEach(e => {
      const end = e.rua.split(', ')
      if(!end[1]) msgs.push("O endereço " + e.rua + "(#" + e.ordem + ") não contém número. O cálculo será impreciso.")
    })
    if(errors.length === 0){
      this.setState({msgs: msgs})
      const places = enderecos.map(e=>"place_id:"+e.place_id)
      const waypoints = places.slice(1,-1)
      client.directions({
        origin: places[0],
        destination: places[places.length-1],
        waypoints: waypoints
      }).asPromise().then(res=>{
        if(res.status === 200){
          const legs = res.json.routes[0].legs
          const total = legs.length === 1 ? legs[0].distance.value
          : _.reduce(legs, (a,acc) => a.distance.value + acc.distance.value)
          this.setState({totalDistance: total/1000})
          this.props.onCalculate(total/1000, this.state.enderecos)
        }
      })
    }
  }

  renderEnderecoList() {
    const {enderecos, loading, results} = this.state
    return enderecos.map((e, i)=> {
      const tipo = this.getTipoImovelValue(e)
      const rua_numero = e.rua
      return (
        <Form.Group key={e.id + "-" + e.ordem} inline unstackable >
          <Label color={e.place_id !== null ? "green" : "red"} content={e.ordem} />
          <Form.Field
            as={Search}
            value={rua_numero}
            width="6"
            size="mini"
            ordem={e.ordem}
            onSearchChange={_.debounce(this.handleSearchEnderecoChange, 500, {
              leading: true,
            })}
            onFocus={_.debounce(this.handleSearchEnderecoChange, 500, {
              leading: true,
            })}
            onResultSelect={this.handleResultSelect}
            selectFirstResult
            results={results}
            loading={loading}
            noResultsMessage="Endereço não encontrado"
            icon={e.place_id !== null ? "check" : "x"}
            minCharacters={4}
          />
          <Form.Field
            control={Form.Input}
            size="mini"
            value={e.complemento}
            name="complemento"
            ordem={e.ordem}
            onChange={this.handleChange}
            placeholder="Complemento"
          />
          <Form.Field width="3">
            <Button size="mini" fluid type="button">
              <Dropdown
                options={tipo_imovel}
                size="tiny"
                width="4"
                value={tipo}
                ordem={e.ordem}
                onChange={this.handleTipoImovelChange}
                item
                fluid
                search
              />
            </Button>
          </Form.Field>
          <Form.Field width="3">
            <Button size="mini" fluid type="button">
              <Dropdown
                options={this.getSecImovelOptions(tipo)}
                value={tipo==="outros" && e.sobrado ? 'sobrado' : tipo==="outros" && !e.sobrado && e.andares===0 ? 'terreo' : tipo==="outros" && e.andares===1 ? '1lance': tipo==="outros" && e.andares===2 ? '2lance' : tipo==="outros" && e.andares===3 ? '3lance' : e.andares}
                ordem={e.ordem}
                onChange={this.handleDetalhesChange}
                fluid
                search
              />
            </Button>
          </Form.Field>
          <Button.Group
            size="mini"
            buttons={[
              {
                key: 'arrow up', icon: 'arrow up', color:"orange", type:"button",
                disabled: i===0,
                onClick: () => this.moveEndereco(i,-1)
              },
              {
                key: 'arrow down', icon: 'arrow down', color:"orange", type:"button",
                disabled: i===enderecos.length-1,
                onClick: () => this.moveEndereco(i,1)
              },
              {
                key: 'delete', icon: 'delete', color:"red", type:"button",
                disabled: enderecos.length<2,
                onClick: () => this.handleDelete(i)
              },
            ]}
          />
        </Form.Group>
      )
    })
  }

  render() {
    const {enderecos, totalDistance, msgs} = this.state
    const valid = enderecos.filter(e=>e.place_id!== null)
    return (
      <>
        {this.renderEnderecoList()}
        <Container textAlign="center">
          {msgs.length > 0 && <Message list={msgs} header="Aviso:" color="orange" />}
          <Button icon='plus' color="blue" content="Adicionar Endereço" labelPosition="left" onClick={this.handleAddAddress} type="button"/>
          <Label
            basic
            color='black'
            icon='road'
            content={totalDistance.toFixed(2) + " Km"}
            size="large"
           />
          <Button
            color="green"
            labelPosition="left"
            icon="calculator"
            type="button"
            content="Calcular nova distância"
            onClick={this.calculateDistance}
            disabled={valid.length !== enderecos.length || enderecos.length === 1}
            negative={valid.length !== enderecos.length || enderecos.length === 1}
          />
      </Container>

      </>
    )
  }
}

export default OrdenadorEnderecos
