import React from 'react'
import {Form, Select, Input, Button} from 'semantic-ui-react'
import * as noliAPI from '../../../utils/noliAPI'
import * as masks from '../../../utils/masks'

const SearchTransporterHelpersFilter = (props) => {

  const [filterType, setFilterType] = React.useState('all')
  const [searchField, setSearchField] = React.useState('')

  React.useEffect(() => {
    if(props.reload){
      setFilterType('all')
      setSearchField('')
    }
  }, [props.reload]);

  const changeFilterType = (e, {value}) => {
    setFilterType(value)
    setSearchField("")
  }

  const handleSubmit = () => {
    props.setLoading(true)
    if(filterType === "all"){
      noliAPI.findTransporterHelpers({withTransporters: true}).then(res => {
        props.setList(res.data)
        props.setLoading(false)
      })
    }else{
      const search = filterType === 'cpf' ? masks.unmaskCpf(searchField) : filterType === 'rg' ? masks.unmaskRG(searchField) : filterType === 'phone' ? masks.unmaskTelefone(searchField) : searchField
      noliAPI.findTransporterHelpers({[filterType]:search,withTransporters: true}).then(res => {
        if(res.status === 200){
          props.setList(res.data)
        }else props.setList([])
        props.setLoading(false)
      })
    }
  }

  const getMaskedValue = () => {
    if(filterType === 'phone') return masks.maskTelefone(searchField)
    else if (filterType === 'cpf') return masks.maskCpf(searchField)
    else return searchField
  }

  return(
    <Form>
      <Form.Group>
        <Form.Field
          control={Select}
          options={options}
          placeholder="Selecione um filtro"
          value={filterType}
          onChange={changeFilterType}
          width="5"
        />
        <Form.Field
          control={Input}
          placeholder="Palavra-chave"
          value={getMaskedValue()}
          onChange={(e,{value}) => setSearchField(value)}
          maxLength={filterType==='phone' ? 15 : 200}
          disabled={filterType === "all"}
          width="8"
        />
        <Form.Field
          control={Button}
          content="BUSCAR"
          fluid
          color="blue"
          width="3"
          icon="search"
          type="submit"
          labelPosition="left"
          loading={props.loading}
          disabled={props.loading || (filterType !== "all" && searchField === "")}
          onClick={handleSubmit}
        />
      </Form.Group>
    </Form>
  )
}

export default SearchTransporterHelpersFilter

const options = [
  {value: "all", text: "Todos os ajudantes"},
  {value: "first_name", text: "por nome"},
  {value: "phone", text: "por telefone"},
  {value: "cpf", text: "por CPF"},
  {value: "rg", text: "por RG"}
]
