import React from 'react'
import { Form, Select, Input, Button, Dropdown } from 'semantic-ui-react'
import * as noliAPI from '../utils/noliAPI'
import * as masks from '../utils/masks'

const SearchPartnersFilter = (props) => {

  const [filterType, setFilterType] = React.useState('todos')
  const [searchField, setSearchField] = React.useState('')

  React.useEffect(() => {
    if (props.reload) {
      setFilterType('todos')
      setSearchField('')
    }
  }, [props.reload]);

  const changeFilterType = (e, { value }) => {
    setFilterType(value)
    setSearchField("")
  }

  const handleSubmit = () => {
    props.setLoading(true)
    if (filterType === "todos") {
      noliAPI.findServicePartner().then(res => {
        if (res.status === 200) {
          props.setList(res.data)
        } else {
          props.setList([])
        }
        props.setLoading(false)
      })
    } else if (filterType === 'category_id') {
      const filterCategory = props.categoryList.filter((item) => item.value === searchField)
      if (filterCategory[0]) {
        const payload = {
          partner_services_category_id: filterCategory[0].id
        }
        noliAPI.findServicePartner(payload).then(res => {
          if (res.status === 200) {
            props.setList(res.data)
          } else {
            props.setList([])
          }
          props.setLoading(false)
        })
      }

    } else {

      noliAPI.findServicePartnerByLike(filterType, searchField).then(res => {
        if (res.status === 200) {
          props.setList(res.data)
        } else {
          props.setList([])
        }
        props.setLoading(false)
      })


    }
  }

  const getMaskedValue = () => {
    if (filterType === 'telefone') return masks.maskTelefone(searchField)
    else if (filterType === 'cpf') return masks.maskCpf(searchField)
    else return searchField
  }

  return (
    <Form>
      <Form.Group>
        <Form.Field
          control={Select}
          options={options}
          placeholder="Selecione um filtro"
          value={filterType}
          onChange={changeFilterType}
          width="5"
        />
        {filterType !== 'category_id' ?
          <Form.Field
            control={Input}
            placeholder="Palavra-chave"
            value={getMaskedValue()}
            onChange={(e, { value }) => setSearchField(value)}
            maxLength={filterType === 'telefone' ? 15 : 200}
            disabled={filterType === "todos"}
            width="8"
          />
          :
          <Form.Field
            control={Select}
            options={props.categoryList}
            placeholder="Selecione um filtro"
            value={searchField}
            onChange={(e, { value }) => setSearchField(value)}
            width="5"
          />
        }
        <Form.Field
          control={Button}
          content="BUSCAR"
          fluid
          color="blue"
          width="3"
          icon="search"
          type="submit"
          labelPosition="left"
          loading={props.loading}
          disabled={props.loading || (filterType !== "todos" && searchField === "")}
          onClick={handleSubmit}
        />
      </Form.Group>
    </Form>
  )
}

export default SearchPartnersFilter

const options = [
  { value: "todos", text: "Todos os parceiros" },
  { value: "name", text: "por nome" },
  { value: "category_id", text: "por categoria" },
]
const optionsCategory = [
  { value: "1", text: "Limpeza e higienização" },
  { value: "2", text: "Storage" },
  { value: "3", text: "Seguro de cargas" },
]
