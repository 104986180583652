import React from 'react'
import { Grid, Segment, Header, Button, Label, Icon, Form, Input, Confirm, Dropdown, Checkbox, Table, Message } from 'semantic-ui-react'
import * as actions from '../../utils/actions'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as noliAPI from '../../utils/noliAPI'
import FuncionariosPermissao from '../../components/FuncionariosPermissao'
import { AxiosResponse } from 'axios'
const _defaultForm = {
  descricao: "",
  relations: []
}

class Permissoes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      details: [],
      selectedType: "",
      selectedName: "",
      selectedId: "",
      mode: "addGroup",
      openConfirm: false,
      form: _defaultForm,
      loading: false,
      funcionariosList: [],
      funcionariosLoad: false
    }
  }

  componentDidMount() {
    if (this.props.pageInfo.title === "Painel Noli") this.props.setPageTitle("Cadastrar e Alterar Permissões de Funcionários")
    if (!this.props.pageInfo.permissoes) this.props.fetchPermissionsData()
  }

  handleChange = (e, { name, value, checked }) => {
    if (name === "relations") this.setState({
      form: {
        ...this.state.form,
        relations: value.reduce((acc, cur) => {
          acc[cur.toString()] = this.state.form.relations[cur.toString()]
            ? this.state.form.relations[cur.toString()]
            : { id: cur, create: false, read: false, update: false, delete: false }
          return acc
        }, {})
      }
    })
    else if (name === "create" || name === "read" || name === "update" || name === "delete")
      this.setState({
        form: {
          ...this.state.form,
          relations: {
            ...this.state.form.relations,
            [value]: { ...this.state.form.relations[value], [name]: checked }
          }
        }
      })
    else
      this.setState({ form: { ...this.state.form, [name]: value } })
  }

  addGroupSubmit = () => {
    this.setState({ loading: true })
    const keys = Object.keys(this.state.form.relations)
    let payload = {}
    payload.descricao = this.state.form.descricao
    if (keys.length > 0)
      payload.permissoes = keys.map(k => {
        const relations = this.state.form.relations[k]
        return {
          ...relations,
          id: parseInt(relations.id)
        }
      })
    noliAPI.cadastrarGrupoPermissao(payload).then((res: AxiosResponse) => {
      if (res.status === 200) {
        if (res.data.status_code === "00") {
          this.props.fetchPermissionsData()
          return this.props.sendMessage([], "Grupo de Permissões cadastrado com sucesso!", { color: 'blue', icon: "check circle" }, false)
          
        }
      }

      this.props.sendMessage(res.data ? res.data.msg : [], "Erro ao cadastrar permissão de acesso!", { size: 'huge', color: 'red' }, true)
      this.setState({ loading: false, openConfirm: false })
    })
  }

  addPermissionSubmit = () => {
    this.setState({ loading: true })
    const keys = Object.keys(this.state.form.relations)
    let payload = {}
    payload.descricao = this.state.form.descricao
    if (keys.length > 0)
      payload.grupos_de_acesso = keys.map(k => {
        const relations = this.state.form.relations[k]
        return {
          ...relations,
          id: parseInt(relations.id)
        }
      })
    noliAPI.cadastrarPermissaoAcesso(payload).then(res => {
      if (res.status === 200) {
        if (res.data.status_code === "00") {
          this.props.fetchPermissionsData()
          this.setState({ loading: false, openConfirm: false })
         return  this.props.sendMessage([], "Permissão de Acesso cadastrada com sucesso!", { color: 'blue', icon: "check circle" }, false)
        }
      }
      this.props.sendMessage(res.data ? res.data.msg : [], "Erro ao cadastrar permissão de acesso!", { size: 'huge', color: "red" }, true)
      this.setState({ loading: false, openConfirm: false })
    })
  }

  seeDetails = (type, id, name) => {
    this.setState({ selectedType: type, loading: true, selectedName: name, selectedId: id })
    const get = type === "grupo" ? noliAPI.detalhesGrupoAcesso(parseInt(id)) : noliAPI.detalhesPermissoesAcesso(parseInt(id))
    get.then(res => {
      if (res.status === 200) {
        this.setState({ details: res.data.data })
      } else if (res.status === 204) {
        this.setState({ details: [] })
      }
      this.setState({ loading: false })
    })
  }

  fetchFuncionariosBySelected = (e, { name, value }) => {
    this.setState({ funcionariosLoad: true })
    const req = name === "grupo" ? noliAPI.funcionariosGrupoPermissao(value) :
      noliAPI.funcionariosPorPermissao(value)
    req.then(res => {
      if (res.status === 200) this.setState({ funcionariosList: res.data.data, funcionariosLoad: false })
      else this.setState({ funcionariosList: [], funcionariosLoad: false })
    })
  }

  openToEdit = (mode, item) => {
    this.setState({ loading: true, mode: mode })
    const get = mode === "editGroup" ? noliAPI.detalhesGrupoAcesso(parseInt(item.id)) : noliAPI.detalhesPermissoesAcesso(parseInt(item.id))
    get.then(res => {
      if (res.status === 200) {
        let relations = res.data.data.reduce((acc, cur) => {
          acc[cur.id.toString()] = { ...cur, id: cur.id.toString() }
          return acc
        }, {})
        this.setState({
          loading: false,
          form: {
            descricao: item.descricao,
            relations: relations,
          },
          selected: { ...item, relations: relations },
          openConfirm: true
        })
      } else if (res.status === 204) {
        this.setState({
          loading: false,
          form: {
            descricao: item.descricao,
            relations: {},
          },
          selected: { ...item, relations: {} },
          openConfirm: true
        })
      }
    })
  }

  commitChanges = () => {
    this.setState({ loading: true })
    const { form, selected, mode } = this.state
    let payload = {}
    const relations = Object.values(form.relations)
      .filter(x => !_.every(_.pick(x, ['create', 'read', 'update', 'delete']), (v) => v === false || v === 0))
      .map(i => {
        const newi = _.pick(i, ['create', 'read', 'update', 'delete'])
        return {
          ...newi,
          id: parseInt(i.id)
        }
      })
    let key = mode === 'editGroup' ? "permissoes" : "grupos_de_acesso"
    if (form.descricao !== selected.descricao) payload.descricao = form.descricao
    if (!_.isEqual(form.relations, selected.relations)) payload[key] = relations
    const req = mode === "editGroup"
      ? noliAPI.alterarGrupoAcesso(payload, selected.id)
      : noliAPI.alterarPermissaoAcesso(payload, selected.id)
    req.then(res => {
      let type = mode === 'editGroup' ? 'grupo' : "permissão"
      if (res.status === 200) {
        this.props.sendMessage([], "Edição de " + type + " realizada com sucesso!", { color: 'blue', icon: "check circle" }, false)
        this.seeDetails(type, selected.id, selected.descricao)
      } else this.props.sendMessage(res.data.msg, "Erro ao tentar editar " + type)
      this.setState({ loading: false, openConfirm: false })
    })

  }

  render() {
    const { selectedType, openConfirm, mode, loading, selectedName, details, selectedId, funcionariosList, funcionariosLoad } = this.state

    return (
      <Grid padded>
        <Grid.Row centered>
          <Grid.Column stretched style={{ maxHeight: '40vh' }} computer="5" mobile="16" tablet="10">
            <Segment>
              <Header as="h5">
                <Button
                  as={Label}
                  floated="right"
                  content="Novo Grupo"
                  color="blue"
                  icon="add"
                  onClick={() => this.setState({
                    openConfirm: true,
                    mode: "addGroup",
                    form: _defaultForm
                  })}
                  disabled={!this.props.user.permissions[this.props.permission].create}
                />
                <Icon name="group" />
                <Header.Content>Grupos de Acesso</Header.Content>
              </Header>
              <div className="scrollable-y" style={{ maxHeight: '30vh' }}>
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Descrição</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">Editar</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.pageInfo.permissoes &&
                      this.props.pageInfo.permissoes.grupos.map((p, i) =>
                        <Table.Row key={p + "-" + i} className="selectable-row">
                          <Table.Cell onClick={() => this.seeDetails("grupo", p.id, p.descricao)}>
                            {p.descricao}</Table.Cell>
                          <Table.Cell textAlign="right">
                            <Button
                              icon="edit"
                              color="green"
                              size="mini"
                              onClick={() => this.openToEdit("editGroup", p)}
                              disabled={!this.props.user.permissions[this.props.permission].update}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                  </Table.Body>
                </Table>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column stretched style={{ maxHeight: '40vh' }} computer="5" mobile="16" tablet="10">
            <Segment loading={loading}>
              <Header as="h5">
                <Icon name="sitemap" rotated="counterclockwise" />
                <Header.Content>
                  Detalhes
                  {selectedType === "grupo" && " do Grupo :"}
                  {selectedType === "permissão" && " da Permissão :"}
                  {selectedName !== "" && <Label content={selectedName} color={selectedType === 'grupo' ? "purple" : "pink"} />}
                </Header.Content>
              </Header>
              {selectedName !== "" ?
                <div className="scrollable-y" style={{ maxHeight: '30vh' }}>
                  <Button
                    fluid
                    content={"Buscar funcionários -> " + selectedName}
                    size="large"
                    labelPosition="left"
                    icon="search"
                    primary
                    name={selectedType}
                    value={selectedId}
                    onClick={this.fetchFuncionariosBySelected}
                  />
                  {details.length > 0 ?
                    <Table
                      compact
                      color={selectedType === 'grupo' ? "purple" : "pink"}
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>
                            <small>{selectedType === 'grupo' ? "Permissões" : "Grupos"}</small>
                          </Table.HeaderCell>
                          <Table.HeaderCell><small>Create</small></Table.HeaderCell>
                          <Table.HeaderCell><small>Read</small></Table.HeaderCell>
                          <Table.HeaderCell><small>Update</small></Table.HeaderCell>
                          <Table.HeaderCell><small>Delete</small></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {details.map((d, i) => {
                          return (<Table.Row key={i}>
                            <Table.Cell><small>{d.descricao}</small></Table.Cell>
                            <Table.Cell>
                              <Icon
                                name={d.create ? 'check circle outline' : 'times circle outline'}
                                color={d.create ? "green" : "red"}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Icon
                                name={d.read ? 'check circle outline' : 'times circle outline'}
                                color={d.read ? "green" : "red"}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Icon
                                name={d.update ? 'check circle outline' : 'times circle outline'}
                                color={d.update ? "green" : "red"}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Icon
                                name={d.delete ? 'check circle outline' : 'times circle outline'}
                                color={d.delete ? "green" : "red"}
                              />
                            </Table.Cell>
                          </Table.Row>)
                        })}
                      </Table.Body>
                    </Table>
                    : <Message color="yellow" content={"Este(a) " + selectedType + " não possui relacionamentos de permissão "} />
                  }
                </div>
                : <Message color="green" content="Selecione um item nas tabelas ao lado" />
              }
            </Segment>
          </Grid.Column>
          <Grid.Column stretched style={{ maxHeight: '40vh' }} computer="5" mobile="16" tablet="10">
            <Segment>
              <Header as="h5">
                <Button
                  as={Label}
                  floated="right"
                  content="Nova Permissão"
                  color="blue"
                  icon="add"
                  onClick={() => this.setState({
                    openConfirm: true,
                    mode: "addPermission",
                    form: _defaultForm
                  })}
                  disabled={!this.props.user.permissions[this.props.permission].create}
                />
                <Icon name="key" />
                <Header.Content>Permissões de Acesso</Header.Content>
              </Header>
              <div className="scrollable-y" style={{ maxHeight: '30vh' }}>
                <Table selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Descrição</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">Editar</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.pageInfo.permissoes &&
                      this.props.pageInfo.permissoes.acessos.map((p, i) =>
                        <Table.Row key={p + "-" + i} className="selectable-row">
                          <Table.Cell onClick={() => this.seeDetails("permissão", p.id, p.descricao)}>
                            {p.descricao}</Table.Cell>
                          <Table.Cell textAlign="right">
                            <Button
                              icon="edit"
                              color="green"
                              size="mini"
                              onClick={() => this.openToEdit("editPermission", p)}
                              disabled={!this.props.user.permissions[this.props.permission].update}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                  </Table.Body>
                </Table>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FuncionariosPermissao
              options={this.props.pageInfo.permissoes ? this.props.pageInfo.permissoes.gruposOpt : []}
              list={funcionariosList}
              loading={funcionariosLoad}
              user={this.props.user}
              permission={this.props.permission}
            />
            <Confirm
              open={openConfirm}
              {...this.confirmProps[mode]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  confirmProps = {
    "addGroup": {
      cancelButton: "Cancelar",
      confirmButton: () => <Button
        disabled={this.state.form.descricao === "" || this.state.loading}
        content="Criar Grupo"
        primary
        onClick={this.addGroupSubmit}
        loading={this.state.loading}
      />,
      header: "Novo Grupo de Acesso",
      onCancel: () => this.setState({ openConfirm: false }),
      size: "small",
      content: () => <AcessoForm
        form={this.state.form}
        onChange={this.handleChange}
        options={this.props.pageInfo.permissoes ? this.props.pageInfo.permissoes.acessosOpt : []}
        mode={this.state.mode}
      />
    },
    "addPermission": {
      cancelButton: "Cancelar",
      confirmButton: () => <Button
        disabled={this.state.form.descricao === "" || this.state.loading}
        content="Criar Permissão"
        primary
        onClick={this.addPermissionSubmit}
        loading={this.state.loading}
      />,
      header: "Nova Permissão de Acesso",
      onCancel: () => this.setState({ openConfirm: false }),
      size: "small",
      content: () => <AcessoForm
        form={this.state.form}
        onChange={this.handleChange}
        options={this.props.pageInfo.permissoes ? this.props.pageInfo.permissoes.gruposOpt : []}
        mode={this.state.mode}
      />
    },
    "editGroup": {
      cancelButton: "Cancelar",
      confirmButton: () => <Button
        disabled={(this.state.form.descricao === this.state.selected.descricao && _.isEqual(this.state.form.relations, this.state.selected.relations)) || this.state.loading || this.state.form.descricao === ""}
        content="Atualizar Grupo"
        primary
        onClick={this.commitChanges}
        loading={this.state.loading}
      />,
      header: "Editar Grupo de Acesso",
      onCancel: () => this.setState({ openConfirm: false }),
      size: "small",
      content: () => <AcessoForm
        form={this.state.form}
        onChange={this.handleChange}
        options={this.props.pageInfo.permissoes ? this.props.pageInfo.permissoes.acessosOpt : []}
        mode={this.state.mode}
        loading={this.state.loading}
      />
    },
    "editPermission": {
      cancelButton: "Cancelar",
      confirmButton: () => <Button
        disabled={(this.state.form.descricao === this.state.selected.descricao && _.isEqual(this.state.form.relations, this.state.selected.relations)) || this.state.loading || this.state.form.descricao === ""}
        content="Atualizar Permissão"
        primary
        onClick={this.commitChanges}
        loading={this.state.loading}
      />,
      header: "Atualizar Permissão de Acesso",
      onCancel: () => this.setState({ openConfirm: false }),
      size: "small",
      content: () => <AcessoForm
        form={this.state.form}
        onChange={this.handleChange}
        options={this.props.pageInfo.permissoes ? this.props.pageInfo.permissoes.gruposOpt : []}
        mode={this.state.mode}
        loading={this.state.loading}
      />
    },
  }

}

const AcessoForm = (props) => {

  const changeForm = (e, { name, value, checked }) => {
    props.onChange(e, { name, value, checked })
  }

  return (
    <Segment basic loading={props.loading}>
      <Form>
        <Form.Field
          label="Descrição"
          control={Input}
          type="text"
          value={props.form.descricao}
          name="descricao"
          onChange={changeForm}
        />
        {props.mode === "addGroup" || props.mode === "editGroup" ? "Permissões" : "Grupos de Acesso"}
        <Dropdown
          multiple
          selection
          fluid
          name="relations"
          style={{ margin: '7px 0' }}
          options={props.options}
          value={Object.keys(props.form.relations)}
          onChange={changeForm}
          upward
        />
        {Object.keys(props.form.relations).map((r, i) => {
          const line = _.find(props.options, { value: props.form.relations[r].id })
          return (
            <Form.Group inline widths='equal' key={r + '-' + i}>
              <Form.Field
                control={Input}
                readOnly
                value={line.text}
              />
              <Form.Field
                control={Checkbox}
                checked={!!props.form.relations[r].create}
                value={props.form.relations[r].id}
                label="Create"
                name="create"
                onChange={changeForm}
              />
              <Form.Field
                control={Checkbox}
                checked={!!props.form.relations[r].read}
                value={props.form.relations[r].id}
                label="Read"
                name="read"
                onChange={changeForm}
              />
              <Form.Field
                control={Checkbox}
                checked={!!props.form.relations[r].update}
                value={props.form.relations[r].id}
                label="Update"
                name="update"
                onChange={changeForm}
              />
              <Form.Field
                control={Checkbox}
                checked={!!props.form.relations[r].delete}
                value={props.form.relations[r].id}
                label="Delete"
                name="delete"
                onChange={changeForm}
              />
            </Form.Group>
          )
        })}
      </Form>
    </Segment>
  )
}

const mapStateToProps = ({ pageInfo, user }) => { return { pageInfo, user } }

export default connect(mapStateToProps, actions)(Permissoes)
