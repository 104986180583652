import React from 'react'
import {Sidebar, Segment, Grid, Breadcrumb, Header, Icon, Table, Input} from 'semantic-ui-react'
import * as masks from '../utils/masks'
import _ from 'lodash'
import formatDate from '../utils/formatDate'


class DashboardOrcamentoList extends React.Component {

  state = {
    selected : null,
    value: "",
    loading: false,
    results: []
  }

  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction= (event) => {
    if(event.keyCode === 27) { //ESC key code
      this.props.hide()
    }
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, value })
    let list = this.props.list
    setTimeout(() => {

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => {
        const test = re.test(result.find)
        return test
      }
      const filter = _.filter(list, isMatch)
      this.setState({
        loading: false,
        results: filter 
      })
    }, 300)
  }

  handleRowSelect = (selected, ordem) => {
    this.setState({selected: {...selected, ordem: ordem}, results: [], value:""})
    window.open('/cliente/'+selected.id+'/orcamento/'+ordem, '_blank');
  }

  renderTable = (results=false) =>{
    const list = (results) ? this.state.results : this.props.list
    const getRowColorClass = (iniciado, atrasado, hoje, amanha) => iniciado === 1 ? "green-row" : atrasado ? "red-row" : hoje ? "blue-row" : amanha ? "orange-row" :'teal-row'

    if(list.length === 0) return(
      <Table.Row negative>
        <Table.Cell colSpan='5' warning>Não encontrado</Table.Cell>
      </Table.Row>
    )
    else return list.map(t => {
      return (
        <Table.Row
          key={t.id}
          className={getRowColorClass(t.transporte_iniciado, t.atrasado, t.hoje, t.amanha)}
          onClick={() => this.handleRowSelect(t.cliente, t.id)} style={{cursor: 'pointer'}}
        >
          <Table.Cell>
            <Header inverted as="h4">
              <Header.Content>
                #{t.ordem_servico}
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell>
            Agendado: {formatDate.getDateAndTimeUnixTimestamp(t.data_transporte)}
            {t.transporte_iniciado===1
              && (<><br />Início:
                {formatDate.getDateAndTimeUnixTimestamp(t.horario_iniciado)}
                </>)
            }
          </Table.Cell>
          <Table.Cell>
            <Header as='h5' inverted>
              <Header.Content>
                {t.cliente.nome}
                <Header.Subheader>{masks.maskTelefone(t.cliente.telefone)}</Header.Subheader>
              </Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell>
            <small>{masks.maskEndereco(t.enderecos[0])} (Início)
            <br />{masks.maskEndereco(t.enderecos[t.enderecos.length-1])} (Último)</small>
          </Table.Cell>
          <Table.Cell>
            {t.transportador ? (
              <Header as='h5' inverted>
                <Header.Content>
                  {t.transportador.nome}
                  <Header.Subheader>{masks.maskTelefone(t.transportador.telefone)}</Header.Subheader>
                </Header.Content>
              </Header>)
            : "Transportador Indefinido"}
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  hide = (e) => {
    if(e!==null && e.target.className === "pusher dimmed")
      this.props.hide()
  }

  render(){
    const { visible, list, type, hide } = this.props
    const { loading, results, value, selected } = this.state
    const tableColor = type==="Em Andamento" ? "green" : type==="Agendados para hoje" ? "blue" : type==="Atrasados" ? "red": type==="Agendados para amanhã" ? "orange" : "teal"

    return(
      
      <Sidebar
        direction='top'
        animation='push'
        visible={visible}
        onHide={this.hide}
        style={{minHeight: "100vh", overflow:'hidden'}}
      >
        <Segment attached='top' secondary>
          <Grid reversed='mobile'>
            <Grid.Row>
            <Grid.Column mobile="16" tablet="10" computer="10">
              <Breadcrumb size="small" style={{margin:'12px'}}>
                <Breadcrumb.Section link onClick={() => hide()}>Dashboard</Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron' />
                <Breadcrumb.Section
                  link={selected === null}
                  active= {selected === null}
                  onClick={list.length > 0 ?
                    () =>this.setState({results: [], selected: null, value: ""})
                    : null
                  }
                >{type + " (" + list.length + ")"}</Breadcrumb.Section>
                {selected && (<>
                  <Breadcrumb.Divider icon='right arrow' />
                  <Breadcrumb.Section active>
                    Última ordem clicada: N{selected.id}-{selected.ordem}
                  </Breadcrumb.Section>
                </>)
                }

              </Breadcrumb>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="6" computer="6">
              <Input
                loading={loading}
                onChange={_.debounce(this.handleSearchChange, 500, {
                  leading: true,
                })}
                results={results}
                value={value}
                disabled={list.length === 0}
                fluid
                icon="search"
                placeholder="Busque por alguma palavra chave" 
              />
            </Grid.Column>
          </Grid.Row>
          </Grid>
        </Segment>
        <Segment attached='bottom' placeholder={list.length === 0} loading={loading}>
          {list.length === 0 ? (
            <Header icon>
              <Icon name='shipping fast' color="red" />
              Não existem transportes {type.toLowerCase()}
            </Header>
          ): (<>
              <Header as='h2' icon='shipping fast' content={'Transportes ' + type.toLowerCase()} />
              <Table selectable celled inverted size="small" color={tableColor}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Ordem</Table.HeaderCell>
                    <Table.HeaderCell>Agendado para <br />/ Iniciado em</Table.HeaderCell>
                    <Table.HeaderCell>Cliente</Table.HeaderCell>
                    <Table.HeaderCell>Endereços</Table.HeaderCell>
                    <Table.HeaderCell>Transportador</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.renderTable(value !== "")}
                </Table.Body>
              </Table>
            </>
            )
          }
        </Segment>
      </Sidebar>
    )
  }
}

export default DashboardOrcamentoList
