import React from 'react'
import {Form, Label, Input, Segment, Grid, Button, Checkbox, Portal, Icon} from 'semantic-ui-react'
import _ from 'lodash'

class SearchTransportadorTags extends React.Component{

  state = {
    tags: [],
    tagInput: "",
    loading: false,
    moreOpen: false,
    moreOptions: [
      {key: 'more1', value: 'infos_perfil=completo', text: "PERFIL COMPLETO"},
      {key: 'more2', value: 'documentação=completo', text: "DOCUMENTAÇÃO COMPLETA"},
      {key: 'more3', value: 'infos_veiculo=completo', text: "VEICULO COMPLETO"},
      {key: 'more4', value: 'dimensões=completo', text: "ALTURA x LARGURA x COMPRIMENTO"},
      {key: 'more5', value: 'endereco=completo', text: "ENDEREÇO COMPLETO"}
    ]
  }

  handleChange = (e,{name, value}) => this.setState({[name]: value})

  handleAddTag = (e,{value, checked}) => {
    const toAdd = checked ? value : this.state.tagInput
    if(toAdd !== ""){
      this.setState({tags: [...this.state.tags, toAdd], tagInput: "", loading: true})
      this.filterData()
    }
  }

  handleRemoveTag = (e,{ value }) => {
    let tags = [...this.state.tags]
    const index = tags.indexOf(value)
    if(index !== -1)
      tags.splice(index, 1)
    this.setState({tags: tags, loading: true})
    this.filterData()
  }

  clearTags = () => {
    this.setState({tags: []})
    this.props.onResult([], true)
  }

  filterData = () => {
    const list = this.props.data
    setTimeout(() => {
      if (this.state.tags.length < 1) {
        this.props.onResult([], true)
        return this.setState({loading: false})
      }

      let finished = list
      for(let i = 0; i < this.state.tags.length; i++){
        const re = new RegExp(_.escapeRegExp(this.state.tags[i]), 'i')
        const isMatch = (result) => re.test(result.find)
        finished = _.filter(finished, isMatch)
      }

      this.setState({
        loading: false
      })
      this.props.onResult(finished)
    }, 300)
  }

  renderTags = () => {
    return this.state.tags.map((t, i) =>
      <Label
        as='a'
        onClick={this.handleRemoveTag}
        value={t}
        key={t + "-" + i}
        content={t}
        color="purple"
        onRemove={this.handleRemoveTag}
        size="small"
      />)
  }

  openConfirm = () => {
    this.setState({moreOpen: true})
  }

  renderMoreCheckboxes = () => {
    const {moreOptions, tags} = this.state
    return moreOptions.map(m =>
      <Form.Field
        control={Checkbox}
        label={m.text} {...m}
        checked={tags.indexOf(m.value) > -1}
        onChange={this.toggleMoreFilters}
      />
    )
  }

  toggleMoreFilters = (e, {value, checked}) => {
    const index = this.state.tags.indexOf(value)
    if(index > -1)
      this.handleRemoveTag(e, {value})
    else{
      this.handleAddTag(e, {value, checked})}
  }

  render(){
    const {tagInput, tags, loading, moreOpen} = this.state
    const optArray = this.props.results.map(t=> {
      return t.find.split(';').map(tag=> tag)
    }).flat()
    const options = [...new Set(optArray)]


    return(
      <Segment tertiary basic>
        <Form size="mini" onSubmit={this.handleAddTag}>
          <Grid>
            <Grid.Column mobile="16" tablet="16" computer="2" textAlign="center">
              <Label color="grey" size="small" icon="truck" content={this.props.results.length + " encontrado(s)" } style={{width: '100%'}} />
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="8">
              {this.renderTags()}
              {tags.length > 0 &&
                <Label
                  as='a'
                  onClick={this.clearTags}
                  content="Limpar Filtros"
                  color="black"
                  onRemove={this.clearTags}
                  size="small"
                />
              }
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="6">
              <Form.Group inline widths="equal">
                <Form.Field>
                  <Input
                    fluid
                    placeholder="Digite os filtros que você procura"
                    icon='filter'
                    iconPosition='left'
                    value={tagInput}
                    name="tagInput"
                    onChange={this.handleChange}
                    size='small'
                    action={
                      <Button
                        value={tagInput}
                        type="button"
                        color='green'
                        size="small"
                        icon
                        onClick={this.handleAddTag} 
                      >
                        <Icon name='plus' />
                      </Button>
                    }
                    labelPosition='right'
                    list="filtros"
                    disabled={loading}
                    loading={loading}
                  />
                </Form.Field>
                <Form.Field>
                  <Button
                    value={tagInput}
                    type="button"
                    content= '+ Filtros'
                    color='grey'
                    size="small"
                    icon='tag'
                    labelPosition="right"
                    onClick={this.openConfirm}
                    fluid
                  />
                  <datalist id='filtros'>
                    {options.map(t=> <option key={t} value={t} />)}
                  </datalist>
                </Form.Field>
              </Form.Group>
              <Portal
                open={moreOpen}
                header="Mais Filtros"

                onClose={() => this.setState({moreOpen: false})}
              >
                <div
                  style={{
                    left: '40%',
                    position: 'fixed',
                    top: '50%',
                    zIndex: 1000,
                  }}
                >
                  <Segment size="huge" raised>
                    <Form.Group inline>
                      {this.renderMoreCheckboxes()} 

                    </Form.Group>
                  </Segment>
                </div>
              </Portal>

            </Grid.Column>
          </Grid>
        </Form>
      </Segment>
    )
  }
}

export default SearchTransportadorTags
