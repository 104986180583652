import React from 'react'
import { Header } from 'semantic-ui-react'
import moment from 'moment'

class DashboardClock extends React.Component {

  state = {time: moment().format('DD/MM/YYYY - HH:mm:ss') }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.displayTime()
    }, 1000)
  }

  displayTime = () => {
    this.setState({time: moment().format('DD/MM/YYYY - HH:mm:ss')})
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {time} = this.state
    return (
      <Header
        color="grey"
        inverted
        content={time}
        size="tiny"
        className={this.props.className}
        style={{margin: 0, padding: 0}}
      />
    )
  }
}

export default DashboardClock
