import React from 'react'
import { Segment, Form, Grid, Header, Icon, Label, Checkbox, Portal, Image } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import { connect } from 'react-redux'
import * as actions from '../../utils/actions'
import * as noliAPI from '../../utils/noliAPI'
import * as masks from '../../utils/masks'
import formatDate from '../../utils/formatDate'
import { withRouter } from 'react-router'

class CadastroTransportador extends React.Component {

  state = {
    loading: true,
    found: false,
    open: false,
    popupImage: "",
    carroceriaOpt: [],
    modeloOpt: [],
    messages: [],

    telefone: "",
    nome: "",
    email: "",
    cpf: "",
    data_nascimento: "",
    work_auth: 0,
    cnh: null,
    cnh_numero: null,
    cep: "",
    rua: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    complemento: "",
    comprovante: "",
    tipo_veiculo_id: "",
    carroceria_veiculo_id: "",
    marca_id: "",
    modelo_id: "",
    altura: "",
    largura: "",
    comprimento: "",
    cor_veiculo_id: null,
    ano: "",
    placa: "",
    tipo_rntrc: "",
    numero_rntrc: "",
    documento_veiculo: "",
  }

  componentDidMount() {
    this.props.setPageTitle('Cadastro de Transportador')
    if (this.props.match.params.id) {
      this.prepareForm()
    } else
      this.setState({ found: false, loading: false })
    if (!("transp_filters" in this.props.pageInfo)) this.props.fetchTransportadoresFilters()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) this.prepareForm()
  }

  prepareForm = () => {
    noliAPI.buscarDetalhesTransportador(this.props.match.params.id).then(res => {
      if (res.status_code === "00")
        this.setFetchedState(res.data[0])
      else
        this.setState({ found: false, loading: false })
    })
  }

  setFetchedState = (data) => this.setState({
    found: true,
    loading: false,
    telefone: masks.maskTelefone(data.telefone),
    nome: data.nome,
    email: data.email ? data.email : "",
    cpf: data.cpf ? masks.maskCpf(data.cpf) : "",
    data_nascimento: data.data_nascimento ? formatDate.dateBR(data.data_nascimento) : "",
    work_auth: data.work_auth,
    cnh: data.cnh,
    cnh_numero: data.cnh_numero,
    cep: data.cep ? masks.maskCep(data.cep) : "",
    rua: data.rua ? data.rua : "",
    numero: data.numero ? data.numero : "",
    bairro: data.bairro ? data.bairro : "",
    cidade: data.cidade ? data.cidade : "",
    estado: data.estado ? data.estado : "",
    complemento: data.complemento ? data.complemento : "",
    comprovante: data.comprovante ? data.comprovante : "",
    tipo_veiculo_id: data.tipo_veiculo_id ? data.tipo_veiculo_id : null,
    carroceria_veiculo_id: data.carroceria_veiculo_id ? data.carroceria_veiculo_id : null,
    marca_id: data.marca_id ? data.marca_id : null,
    modelo_id: data.modelo_id ? data.modelo_id : null,
    altura: data.altura ? data.altura : '',
    largura: data.largura ? data.largura : '',
    comprimento: data.comprimento ? data.comprimento : '',
    placa: data.placa_letras ? masks.maskPlaca(data.placa_letras + data.placa_numeros) : "",
    placa_letras: data.placa_letras,
    placa_numeros: data.placa_numeros,
    cor_veiculo_id: data.cor_veiculo_id ? data.cor_veiculo_id : null,
    ano: data.ano ? data.ano : "",
    tipo_rntrc: data.tipo_rntrc ? data.tipo_rntrc : "",
    numero_rntrc: data.numero_rntrc ? data.numero_rntrc : "",
    documento_veiculo: data.documento_veiculo ? data.documento_veiculo : "",
  }, () => {

    this.state.tipo_veiculo_id !== "" && this.changeTipoVeiculo({}, { value: this.state.tipo_veiculo_id })
    this.state.marca_id !== "" && this.changeMarcaVeiculo({}, { value: this.state.marca_id })
  })

  textChange = (e, { name, value, mask }) => {
    this.setState({ [name]: mask === "telefone" ? masks.maskTelefone(value) : mask === "cpf" ? masks.maskCpf(value) : mask === "cep" ? masks.maskCep(value) : mask === "placa" ? masks.maskPlaca(value) : value })
  }

  checkedChange = (e, { name, checked }) => this.setState({ [name]: checked ? 1 : 0 })

  changeTipoVeiculo = (e, { value }) => {
    if (value !== null) {
      this.setState({ tipo_veiculo_id: value, loading: true })
      noliAPI.buscarTipoCarrocerias(value).then(res => {
        let opt = []

        if (res.data)
          opt = res.data.data.map(c => { return { value: c.id, text: c.descricao } })
        this.setState({ carroceriaOpt: opt, loading: false })
      })
    }
  }

  changeMarcaVeiculo = (e, { value }) => {
    if (value !== null) {
      this.setState({ marca_id: value, loading: true })
      noliAPI.buscarModelosVeiculos(value).then(res => {
        let opt = []
        if (res.data)
          opt = res.data.data.map(c => { return { value: c.id, text: c.descricao } })
        this.setState({ modeloOpt: opt, loading: false })
      })
    }
  }

  selectionChange = (e, { name, value }) => this.setState({ [name]: value })

  validateForm = (send = false) => {
    let msgs = []
    const myPerms = this.props.user.permissions

    if (this.state.found) {
      !myPerms[this.props.permission].update && msgs.push("Sem permissão para alterar dados")
    } else {
      !myPerms[this.props.permission].create && msgs.push("Sem permissão para cadastrar novo")
    }
    this.state.telefone === '' && msgs.push("Telefone não preenchido")
    this.state.nome === '' && msgs.push("Nome não preenchido")
    this.state.marca_id === '' && msgs.push("Informe a marca do veículo")
    this.state.modelo_id === '' && msgs.push("Informe o modelo do veículo")
    this.state.placa === '' && msgs.push("Informe a placa do veículo")
    this.state.telefone.length !== 15 && msgs.push("Formato de telefone incorreto (DDD + número)")
    this.state.placa.replace(/[^\w\s]/gi, '').length !== 7 && msgs.push("Informe a placa do carro corretamente (EXX-0000)")
    send && msgs.length > 0 && this.props.sendMessage(msgs, "Foram encontrados os seguintes erros:")
    if (msgs.length > 0)
      return false
    else
      return true
  }

  submit = () => {
    if (this.validateForm(true)) {
      const toSave = this.createData()
      this.setState({ loading: true })
      noliAPI.cadastrotransportadorveiculo(toSave).then(res => {
        if (res.status !== 200) {
          this.setState({ loading: false })
          return this.props.sendMessage([], "Houve um erro ao cadastrar informações")
        }

        if (res.data.status_code === "00")
          if (this.state.found) {
            this.prepareForm()
            this.props.sendMessage([], "Transportador alterado com sucesso!",
              { icon: "check circle", error: false, positive: true }
            )
          } else {
            this.props.history.push('/cadastro/transportador/' + res.data.data.transportador_id)
            this.props.sendMessage("Redirecionando para página de edição...", "Transportador salvo com sucesso!",
              { icon: "check circle", error: false, positive: true }
            )
          }
        else this.props.sendMessage([], "Houve um erro ao cadastrar informações")
      })
    }
  }

  createData = () => {
    let data
    const { nome, email, telefone, cpf, cnh_numero,data_nascimento, cnh, work_auth } = this.state
    const transportador = {
      ...nome && { nome: nome },
      ...telefone && { telefone: masks.unmaskTelefone(telefone) },
      ...email && { email: email },
      ...cpf && { cpf: masks.unmaskCpf(cpf) },
      ...data_nascimento && { data_nascimento: formatDate.calendarToDateServer(data_nascimento) },
      ...cnh && { cnh: cnh },
      ...cnh_numero && { cnh_numero: cnh_numero },
       work_auth: work_auth
    }
    const { tipo_veiculo_id, carroceria_veiculo_id, marca_id, modelo_id, altura, largura, comprimento, placa, cor_veiculo_id, ano, tipo_rntrc, numero_rntrc, documento_veiculo } = this.state
    const veiculo = {
      ...tipo_veiculo_id && { tipo_veiculo_id: tipo_veiculo_id },
      ...carroceria_veiculo_id && { carroceria_veiculo_id: carroceria_veiculo_id },
      ...marca_id && { marca_id: marca_id },
      ...modelo_id && { modelo_id: modelo_id },
      ...altura && { altura: altura },
      ...largura && { largura: largura },
      ...comprimento && { comprimento: comprimento },
      ...placa && { placa_letras: placa.substring(0, 3) },
      ...placa && { placa_numeros: placa.substring(4, 9) },
      ...cor_veiculo_id && { cor_veiculo_id: cor_veiculo_id },
      ...ano && { ano: ano },
      ...tipo_rntrc && { tipo_rntrc: tipo_rntrc },
      ...numero_rntrc && { numero_rntrc: numero_rntrc },
      ...documento_veiculo && { documento_veiculo: documento_veiculo }
    }
    const { rua, numero, bairro, cidade, estado, complemento, cep, comprovante } = this.state
    const endereco = {
      ...rua && { rua: rua },
      ...numero && { numero: numero },
      ...bairro && { bairro: bairro },
      ...cidade && { cidade: cidade },
      ...estado && { estado: estado },
      ...complemento && { complemento: complemento },
      ...cep && { cep: cep },
      ...comprovante && { comprovante: comprovante },
    }

    if (this.state.found) {
      data = { transportador, veiculo, endereco, updated: true }
    } else {
      data = { transportador, veiculo, endereco, updated: false } 
    }
    return data
  }


  render() {

    const { loading, found, nome, telefone, email, cpf, data_nascimento, work_auth, open, popupImage, cnh, cnh_numero,cep, rua, numero, bairro, cidade, estado, complemento, comprovante, tipo_veiculo_id, carroceriaOpt, carroceria_veiculo_id, marca_id, modeloOpt, modelo_id, altura, largura, comprimento, placa, cor_veiculo_id, ano, tipo_rntrc, numero_rntrc, documento_veiculo } = this.state
    const { pageInfo } = this.props

    return (
      <Grid padded centered >
        <Grid.Row>
          <Grid.Column computer="12" mobile="16" tablet="16">
            <Segment color="orange" loading={loading}>
              <Header as='h2'>
                <Icon name='shipping fast' />
                <Header.Content>
                  {found ? "Editar Transportador" : "Novo Transportador"}
                  <Header.Subheader>
                    <Icon name={found ? "edit" : "add user"} />
                    {found ? "alterar dados" : "cadastrar novo"}
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Form onSubmit={this.submit}>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column width="16">
                      <Segment tertiary>
                        <Label color='black' icon="user" ribbon content="DADOS PESSOAIS" />
                        <Form.Group widths="equal">
                          <Form.Field
                            control={Form.Input}
                            label="Nome"
                            required
                            name="nome"
                            value={nome}
                            onChange={this.textChange}
                            placeholder="Nome do transportador"
                          />
                          <Form.Field
                            control={Form.Input}
                            label="Telefone"
                            required
                            value={telefone}
                            name="telefone"
                            placeholder="Digite o telefone do Transportador"
                            onChange={this.textChange}
                            mask="telefone"
                            maxLength="15"
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field
                            control={Form.Input}
                            label="Email"
                            name="email"
                            value={email}
                            type="email"
                            onChange={this.textChange}
                            placeholder="Email do transportador"
                          />
                          <Form.Field
                            control={Form.Input}
                            label="CPF"
                            name="cpf"
                            value={cpf}
                            onChange={this.textChange}
                            placeholder="CPF do transportador"
                            mask="cpf"
                            maxLength="14"
                          />
                          <Form.Field
                            control={Form.Input}
                            label="Número da CNH"
                            name="cnh_numero"
                            value={cnh_numero}
                            onChange={this.textChange}
                            placeholder="Número da CNH do transportador"
                            //mask=""
                            maxLength="20"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Field
                            control={DateInput}
                            label="Data de Nascimento"
                            name="data_nascimento"
                            value={data_nascimento}
                            onChange={this.textChange}
                            placeholder="Data de Nascimento"
                            dateFormat="DD/MM/YYYY"
                            localization='pt-br'
                            fluid
                            closable
                            clearable
                            width="8"
                          />
                          {cnh !== null &&
                            <Form.Field
                              control={Form.Button}
                              label="CNH Enviada"
                              content="VER DOCUMENTO ENVIADO"
                              fluid
                              type="button"
                              color="pink"
                              width="8"
                              onClick={() => this.setState({ open: true, popupImage: cnh })}
                            />
                          }
                        </Form.Group>
                        <Form.Field
                          control={Checkbox}
                          label="Autorizado"
                          checked={work_auth === 1}
                          name="work_auth"
                          onChange={this.checkedChange}
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="16">
                      <Segment tertiary>
                        <Label color='teal' icon="map marker alternate" ribbon content="ENDEREÇO DO TRANSPORTADOR" />
                        <Form.Group>
                          <Form.Field
                            control={Form.Input}
                            label="CEP"
                            placeholder="Digite o CEP do endereço"
                            mask="cep"
                            name="cep"
                            value={cep}
                            onChange={this.textChange}
                            maxLength="10"
                            width="5"
                          />
                          <Form.Field
                            control={Form.Input}
                            label="Rua"
                            placeholder="Digite a rua do endereço"
                            name="rua"
                            value={rua}
                            onChange={this.textChange}
                            width="8"
                          />
                          <Form.Field
                            control={Form.Input}
                            label="Número"
                            placeholder="No"
                            name="numero"
                            value={numero}
                            onChange={this.textChange}
                            maxLength="5"
                            type="number"
                            width="3"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Field
                            control={Form.Input}
                            label="Bairro"
                            placeholder="Bairro"
                            name="bairro"
                            value={bairro}
                            onChange={this.textChange}
                            width="8"
                          />
                          <Form.Field
                            control={Form.Input}
                            label="Cidade"
                            placeholder="Cidade"
                            name="cidade"
                            value={cidade}
                            onChange={this.textChange}
                            width="5"
                          />
                          <Form.Field
                            control={Form.Input}
                            label="Estado"
                            placeholder="Estado"
                            name="estado"
                            value={estado}
                            onChange={this.textChange}
                            width="3"
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Field
                            control={Form.Input}
                            label="Complemento"
                            placeholder="Complemento"
                            name="complemento"
                            value={complemento}
                            onChange={this.textChange}
                            width={comprovante !== "" ? "8" : "16"}
                          />
                          {comprovante !== "" &&
                            <Form.Field
                              control={Form.Button}
                              label="Comprovante de Residência"
                              content="VER DOCUMENTO ENVIADO"
                              type="button"
                              fluid
                              color="pink"
                              width="8"
                              onClick={() => this.setState({ open: true, popupImage: comprovante })}
                            />
                          }
                        </Form.Group>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="16">
                      <Segment tertiary>
                        <Label color='orange' icon="truck" ribbon content="VEÍCULO DO TRANSPORTADOR" />
                        <Form.Group widths="equal">
                          <Form.Field
                            control={Form.Select}
                            label="Tipo de Veículo"
                            options={pageInfo.transp_filters ? pageInfo.transp_filters.tipos : []}
                            value={tipo_veiculo_id}
                            onChange={this.changeTipoVeiculo}
                          />
                          <Form.Field
                            control={Form.Select}
                            label="Tipo de Carroceria"
                            options={carroceriaOpt}
                            value={carroceria_veiculo_id}
                            disabled={carroceriaOpt.length < 1}
                            required
                            name="carroceria_veiculo_id"
                            onChange={this.selectionChange}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field
                            control={Form.Select}
                            label="Marca"
                            required
                            options={pageInfo.transp_filters ? pageInfo.transp_filters.marcasVeiculos : []}
                            value={marca_id}
                            onChange={this.changeMarcaVeiculo}
                          />
                          <Form.Field
                            control={Form.Select}
                            label="Modelo"
                            required
                            options={modeloOpt}
                            value={modelo_id}
                            disabled={modeloOpt.length < 1}
                            name="modelo_id"
                            onChange={this.selectionChange}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field
                            control={Form.Input}
                            label="Placa"
                            fluid
                            required
                            name="placa"
                            value={placa}
                            mask="placa"
                            onChange={this.textChange}
                            placeholder="Placa do veículo"
                          />
                          <Form.Field
                            control={Form.Select}
                            label="Ano"
                            options={pageInfo.transp_filters ? pageInfo.transp_filters.anos : []}
                            value={ano}
                            name="ano"
                            onChange={this.selectionChange}
                          />
                          <Form.Field
                            control={Form.Select}
                            label="Cor"
                            options={pageInfo.transp_filters ? pageInfo.transp_filters.cores : []}
                            value={cor_veiculo_id}
                            name="cor_veiculo_id"
                            onChange={this.selectionChange}
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field
                            control={Form.Input}
                            label="Altura"
                            type="number"
                            step="0.1"
                            fluid
                            name="altura"
                            value={altura}
                            onChange={this.textChange}
                            placeholder="Decimais com ponto. Ex: 1.2"
                          />
                          <Form.Field
                            control={Form.Input}
                            label="Largura"
                            type="number"
                            step="0.1"
                            fluid
                            name="largura"
                            value={largura}
                            onChange={this.textChange}
                            placeholder="Decimais com ponto. Ex: 1.2"
                          />
                          <Form.Field
                            control={Form.Input}
                            label="Comprimento"
                            type="number"
                            step="0.1"
                            fluid
                            name="comprimento"
                            value={comprimento}
                            onChange={this.textChange}
                            placeholder="Decimais com ponto. Ex: 1.2"
                          />
                        </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field
                            control={Form.Select}
                            label="Tipo de RNTRC"
                            options={pageInfo.transp_filters ? pageInfo.transp_filters.tipo_rntrc : []}
                            value={tipo_rntrc}
                            name="tipo_rntrc"
                            onChange={this.selectionChange}
                            width={documento_veiculo !== "" ? 4 : 8}
                          />
                          <Form.Field
                            control={Form.Input}
                            label="RNTRC Número"
                            type="number"
                            fluid
                            maxLength="8"
                            name="numero_rntrc"
                            value={numero_rntrc}
                            onChange={this.textChange}
                            placeholder="Número do RNTRC"
                            width={documento_veiculo !== "" ? 4 : 8}
                          />
                          {documento_veiculo !== "" &&
                            <Form.Field
                              control={Form.Button}
                              label="Comprovante de Residência"
                              content="VER DOCUMENTO ENVIADO"
                              type="button"
                              fluid
                              color="pink"
                              width="8"
                              onClick={() => this.setState({ open: true, popupImage: documento_veiculo })}
                            />
                          }
                        </Form.Group>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Button
                        type="submit"
                        content={found ? "EDITAR DADOS DO TRANSPORTADOR" : "CADASTRAR NOVO TRANSPORTADOR"}
                        fluid
                        icon={found ? "edit" : "user add"}
                        color="blue"
                        labelPosition="left"
                        disabled={!this.validateForm()}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Segment>
            <Portal onClose={() => this.setState({ open: false })} open={open}>
              <div
                style={{
                  left: 0,
                  position: 'fixed',
                  top: 0,
                  zIndex: 1001,
                  width: "100%"
                }}
              >
                <Grid padded centered columns="1" className="full-height-grid" onClick={() => this.setState({ open: false })}>
                  <Grid.Column
                    mobile="16"
                    computer="6"
                    tablet="8"
                    stretched
                    verticalAlign="middle"
                  >
                    <Image fluid src={`${noliAPI.api_url}` + 'funcionario/getimage/?url=' + popupImage} />
                  </Grid.Column>
                </Grid>
              </div>
            </Portal>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapStateToProps = ({ pageInfo, user }) => {
  return {
    pageInfo, user
  };
};

export default connect(mapStateToProps, actions)(withRouter(CadastroTransportador))
