import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../utils/actions'
import { Grid, Segment, Form, Header, Icon, Radio, Label, Dropdown } from 'semantic-ui-react'
import { Pagination } from '@material-ui/lab';
import * as noliAPI from '../../utils/noliAPI'
import TransportadoresList from '../../components/TransportadoresList'
import { maskCpf, maskTelefone, unmaskCpf, unmaskTelefone } from '../../utils/masks';



class Transportadores extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      firstFilter: "ultimos",
      secondFilter: null,
      thirdFilter: null,
      fourthFilter: 0,
      loading: false,
      results: [],
      optionsCities: [],
      optionsNeighborhoods: [],
      fourthOptions: [],
      textValue: "",
      resultLoading: true,
      noResultMsg: "",
      key: "",
      activePage: 1,
      pageCount: 2,
      finishedPages: false,
      keptState: {}
    }
  }

  async componentDidMount() {
    this.props.fetchTransportersContactCategory()
    this.props.setPageTitle('Transportadores Cadastrados')
    if (!('transp_filters' in this.props.pageInfo)) {
      this.props.fetchTransportadoresFilters()
    }

    const res = await noliAPI.buscarTransportadores()



    this.setState({
      results: res.data.data,
      resultLoading: false,
      activePage: 1,
      pageCount: 2,
      finishedPages: false,
      keptState: {
        firstFilter: this.state.firstFilter,
        secondFilter: this.state.secondFilter,
        thirdFilter: this.state.thirdFilter,
        fourthFilter: this.state.fourthFilter,
        textValue: this.state.textValue
      }
    })
  }

  onFilterChange = (e, { name, value }) => {
    if (name === 'firstFilter') this.setState({
      firstFilter: value,
      fourthFilter: null,
      textValue: "",
      secondFilter: value === 'servicos' ? [] : null
    })
    else {
      const {secondFilter} = this.state
      this.setState({ [name]: secondFilter  === 'cpf' ? maskCpf(value) : secondFilter == 'telefone' ? maskTelefone(value) :  value })
    }
  }

  onSecondFilterAddressChange = async (e, { name, value }) => {
    this.setState({ secondFilter: value, loading: true, fourthOptions: [] })
    await noliAPI.buscarCidadesPorEstados(value).then((resp) => {
      if (resp.status === 200) {
        const cities = resp.data.map(s => { return { text: s.nome, value: s.id } })
        this.setState({ optionsCities: cities })
      }
    })

    this.setState({ loading: false })
  }

  onThirdFilterAddressChange = async (e, { name, value }) => {
    this.setState({ thirdFilter: value, loading: true })
    await noliAPI.buscarBairrosPorCidade(value).then((resp) => {
      if (resp.status === 200) {
        const neighborhoods = resp.data.map(s => { return { text: s.nome, value: s.id } })
        this.setState({ optionsNeighborhoods: neighborhoods })
      }
    })
    this.setState({ loading: false })
  }


  onSecondFilterChange = (e, { name, value }) => {
    this.setState({ secondFilter: value, loading: true, fourthOptions: [] })
    if (value === 0 && this.state.thirdFilter > 0) noliAPI.buscarTipoCarrocerias(this.state.thirdFilter)
      .then(res => this.setFourthOptions(res))
    else if (value === 1 && this.state.thirdFilter > 0) noliAPI.buscarModelosVeiculos(this.state.thirdFilter)
      .then(res => this.setFourthOptions(res))
    else if (value === 2 && this.state.thirdFilter > 0) noliAPI.buscarCarrocerias(this.state.thirdFilter)
      .then(res => this.setFourthOptions(res))
    else this.setState({ loading: false })
  }

  onThirdFilterChange = (e, { value }) => {
    this.setState({ thirdFilter: value, loading: true })
    if (this.state.secondFilter === 0) noliAPI.buscarTipoCarrocerias(value)
      .then(res => this.setFourthOptions(res))
    else if (this.state.secondFilter === 1) noliAPI.buscarModelosVeiculos(value)
      .then(res => this.setFourthOptions(res))
    else if (this.state.secondFilter === 2) noliAPI.buscarTipoPorCarrocerias(value)
      .then(res => this.setFourthOptions(res))
  }

  setFourthOptions = (res) => {
    if (res.data && res.data.data.length > 0)
      this.setState({
        fourthOptions: res.data.data.map(d => {
          return { key: d.id + " " + d.descricao, text: d.descricao, value: d.id }
        }),
        fourthFilter: -1,
        loading: false
      })
    else this.setState({ fourthOptions: [], loading: false })
  }

  renderNoResults = (error = "") => {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name='truck' circular />
          <Header.Content>
            {error !== "" ? error : "Utilize o formulário acima para filtrar os Transportadores"}
          </Header.Content>
        </Header>
      </Segment>
    )
  }

  handleSubmit = () => {
    this.setState({
      resultLoading: true,
      keptState: {
        firstFilter: this.state.firstFilter,
        secondFilter: this.state.secondFilter,
        thirdFilter: this.state.thirdFilter,
        fourthFilter: this.state.fourthFilter,
        textValue: this.state.textValue
      },
      activePage: 1,
      pageCount: 2,
      finishedPages: false
    })
    const st = this.state
    if (st.firstFilter === 'veiculo' && st.thirdFilter > 0) {
      noliAPI.buscarTransportadoresPor(st.secondFilter, st.thirdFilter, st.fourthFilter)
        .then(res => res.status === 200 ? this.setResults(res.data.data, res.data.limit) : this.setState({ resultLoading: false, results: [] }))
    } else if (st.firstFilter === 'transportador' && typeof (st.secondFilter) === 'string' && st.textValue !== '') {
      const value = st.secondFilter === 'cpf' ? unmaskCpf(st.textValue) :  st.secondFilter == 'telefone' ? unmaskTelefone(st.textValue) :  st.textValue 
      noliAPI.buscarTransportadoresCampoValor(st.secondFilter, value, false)
        .then(res => res.status === 200 ? this.setResults(res.data.data, res.data.limit) : this.setState({ resultLoading: false, results: [] }))
    } else if (st.firstFilter === 'ultimos') {
      noliAPI.buscarTransportadores()
        .then(res => res.status === 200 ? this.setResults(res.data.data, res.data.limit) : this.setState({ resultLoading: false, results: [] }))
    } else if (st.firstFilter === 'servicos') {
      noliAPI.buscarTransportadoresPorServicos(st.secondFilter).then(res => res.status === 200 ? this.setResults(res.data.data, res.data.limit) : this.setState({ resultLoading: false, results: [] }))
    } else if (st.firstFilter === 'enderecos') {
      this.callApiByRegion()
    } else if (st.firstFilter === 'etiquetas') {
      const fill2 = this.props.transportersContactCategory.data.filter((element) => element.value === st.secondFilter)
      if (fill2[0]) {
        noliAPI.buscarTransportadoresPorCategoriasdeContato(fill2[0].id).then(res => res.status === 200 ? this.setResults(res.data.data, res.data.limit) : this.setState({ resultLoading: false, results: [] }))
      }
    }
  }

  callApiByRegion = (page = 1) => {
    const st = this.state
    const obj_filter = {}
    if (st.secondFilter) {
      obj_filter.state = st.secondFilter
    }
    if (st.thirdFilter) {
      const fill = this.state.optionsCities.filter((element) => element.value === st.thirdFilter)
      if (fill[0]) {
        obj_filter.city = fill[0].text
      }

    }
    if (st.fourthFilter) {
      const fill2 = this.state.optionsNeighborhoods.filter((element) => element.value === st.fourthFilter)
      if (fill2[0]) {
        obj_filter.neighborhood = fill2[0] ? fill2[0].text : null
      }

    }
    noliAPI.buscarTransportadoresPorRegiao(obj_filter, page).then(res => res.status === 200 ? this.setResults(res.data.data, res.data.limit) : this.setState({ resultLoading: false, results: [] }))
  }

  handlePageClick = (e, page) => {
    this.setState({
      resultLoading: true,
      activePage: page,
    }, () => {
      const st = this.state.keptState
      if (st.firstFilter === 'veiculo' && st.thirdFilter > 0) {
        noliAPI.buscarTransportadoresPor(st.secondFilter, st.thirdFilter, st.fourthFilter, page)
          .then(res => this.setResults(res.data.data, res.data.limit))
      } else if (st.firstFilter === 'transportador' && typeof (st.secondFilter) === 'string' && st.textValue !== '') {
        noliAPI.buscarTransportadoresCampoValor(st.secondFilter, st.textValue, false, page)
          .then(res => res.status === 200 && this.setResults(res.data.data, res.data.limit))
      } else if (st.firstFilter === 'ultimos') {
        noliAPI.buscarTransportadores(page)
          .then(res => res.status === 200 && this.setResults(res.data.data, res.data.limit))
      } else if (st.firstFilter === 'servicos') {
        noliAPI.buscarTransportadoresPorServicos(st.secondFilter).then(res => this.setResults(res.data.data, res.data.limit))
      } else if (st.firstFilter === 'enderecos') {
        this.callApiByRegion(page)
      } else if (st.firstFilter === 'etiquetas') {
        const fill2 = this.props.transportersContactCategory.data.filter((element) => element.value === st.secondFilter)
        if (fill2[0]) {
          noliAPI.buscarTransportadoresPorCategoriasdeContato(fill2[0].id, page).then(res => res.status === 200 ? this.setResults(res.data.data, res.data.limit) : this.setState({ resultLoading: false, results: [] }))
        }
      }
    })
  }


  setResults = (data, limit) => {
    const { pageCount, finishedPages, activePage } = this.state

    if (data && data.length > 0) {
      let toState = {
        results: data,
        resultLoading: false,
        noResultMsg: "",
        key: new Date()
      }
      if (data.length < limit) {
        toState.finishedPages = true
        if (activePage === 1) toState.pageCount = 1
        else this.props.sendMessage([], "Você chegou ao final da lista de transportadores buscados!", { icon: "check circle", error: false, color: 'blue' }, false)

      } else {
        if (activePage === pageCount) toState.pageCount = finishedPages ? pageCount : pageCount + 1
      }
      this.setState(toState)
    } else if (activePage > 1 && !data) this.setState({
      resultLoading: false,
      noResultMsg: "",
      key: new Date(),
      pageCount: !finishedPages ? pageCount - 1 : pageCount,
      activePage: !finishedPages ? activePage - 1 : activePage,
      finishedPages: true
    }, () => this.props.sendMessage([], "Você chegou ao final da lista de transportadores buscados!", { icon: "check circle", error: false, color: 'blue' }, false))
    else if (activePage === 1) this.setState({
      resultLoading: false,
      noResultMsg: "Não foram encontrados transportadores correspondentes aos filtros selecionados",
      results: [],
      key: new Date(),
      keptState: {}
    })
  }

  render() {
    const { transportersContactCategory } = this.props
    const options = this.props.pageInfo.transp_filters
    const { firstFilter, secondFilter, thirdFilter, loading, results, fourthOptions, fourthFilter, textValue, noResultMsg, resultLoading, key, activePage, pageCount, finishedPages, optionsCities, optionsNeighborhoods } = this.state

    return (
      <Segment basic>
        <Grid centered>
          <Grid.Row>
            <Grid.Column computer="16" tablet="16" mobile="16">
              <Segment color="blue" loading={loading}>
                <Form size="mini" onSubmit={this.handleSubmit}>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Radio}
                      label="Últimos cadastrados"
                      name='firstFilter'
                      value="ultimos"
                      checked={firstFilter === "ultimos"}
                      onChange={this.onFilterChange}
                    />
                    <Form.Field
                      control={Radio}
                      label="Buscar por dados do transportador"
                      name='firstFilter'
                      value="transportador"
                      checked={firstFilter === "transportador"}
                      onChange={this.onFilterChange}
                    />
                    <Form.Field
                      control={Radio}
                      label="Buscar por dados do veículo"
                      name='firstFilter'
                      value="veiculo"
                      checked={firstFilter === "veiculo"}
                      onChange={this.onFilterChange}
                    />
                    <Form.Field
                      control={Radio}
                      label="Buscar por serviços"
                      name='firstFilter'
                      value="servicos"
                      checked={firstFilter === "servicos"}
                      onChange={this.onFilterChange}
                    />
                    <Form.Field
                      control={Radio}
                      label="Buscar por região"
                      name='firstFilter'
                      value="enderecos"
                      checked={firstFilter === "enderecos"}
                      onChange={this.onFilterChange}
                    />
                    <Form.Field
                      control={Radio}
                      label="Buscar por etiquetas"
                      name='firstFilter'
                      value="etiquetas"
                      checked={firstFilter === "etiquetas"}
                      onChange={this.onFilterChange}
                    />

                  </Form.Group>
                  {
                    firstFilter === "transportador" &&
                    <Segment tertiary size="mini" basic loading={options ? false : true}>
                      <Form.Group widths="equal" >
                        <Form.Field
                        
                          control={Form.Select}
                          options={options ? options.campos : []}
                          placeholder="Selecione um filtro"
                          label="Pesquisar no Campo"
                          name="secondFilter"
                          onChange={this.onFilterChange}
                          value={secondFilter}
                          required
                        />
                        <Form.Field
                          control={Form.Input}
                          type="text"
                          value={textValue}
                          onChange={this.onFilterChange}
                          name="textValue"
                          label='Palavras chave'
                        />
                      </Form.Group>
                    </Segment>
                  }
                  {firstFilter === "veiculo" &&
                    <Segment tertiary size="mini" basic loading={options ? false : true}>
                      <Form.Group widths="equal">
                        <Form.Field
                          control={Form.Select}
                          options={options ? options.veiculos : []}
                          placeholder="Selecione um filtro"
                          label="Tipo de busca"
                          name="secondFilter"
                          onChange={this.onSecondFilterChange}
                          value={secondFilter}
                          required
                        />
                        <Form.Field
                          control={Form.Select}
                          options={(options) && secondFilter === 0 ? options.tipos : secondFilter === 1 ? options.marcasVeiculos : secondFilter === 2 ? options.carrocerias : []}
                          placeholder="Selecione um filtro"
                          label={secondFilter === 0 ? "Tipo de veículo" : secondFilter === 1 ? "Marca" : secondFilter === 2 ? "Carroceria" : "Selecione um anterior"}
                          name="thirdFilter"
                          onChange={this.onThirdFilterChange}
                          value={thirdFilter}
                          required
                          disabled={typeof (secondFilter) === 'string' || secondFilter === null}
                        />
                        <Form.Field
                          control={Form.Select}
                          options={[{ key: "nda", text: "Nenhum", value: 0 }, ...fourthOptions]}
                          placeholder="Selecione um filtro"
                          label={secondFilter === 0 ? "Tipo de Carroceria" : secondFilter === 1 ? "Modelo" : secondFilter === 2 ? "Tipo de Veículo" : "Selecione um anterior"}
                          name="fourthFilter"
                          onChange={this.onFilterChange}
                          value={fourthFilter}
                          required
                          disabled={fourthOptions.length < 1 || typeof (secondFilter) === 'string'}
                        />
                      </Form.Group>
                    </Segment>
                  }
                  {
                    firstFilter === "servicos" &&
                    <Segment tertiary size="mini" basic loading={options ? false : true}>
                      <Form.Group widths="equal">
                        <Form.Field
                          control={Form.Dropdown}
                          options={options ? options.tipo_servicos : []}
                          placeholder="Selecione um ou mais tipos de serviços"
                          multiple
                          label="Tipos de serviços prestados por transportador"
                          name="secondFilter"
                          onChange={this.onFilterChange}
                          value={secondFilter}
                          required
                        />
                      </Form.Group>
                    </Segment>
                  }
                  {
                    firstFilter === "enderecos" &&
                    <Segment tertiary size="mini" basic loading={options ? false : true}>
                      <Form.Group widths="equal">
                        <Form.Field
                          control={Form.Select}
                          options={options ? options.enderecos ? options.enderecos.estados : [] : []}
                          search
                          placeholder="Selecione um estado"
                          label="Estados"
                          name="secondFilter"
                          onChange={this.onSecondFilterAddressChange}
                          value={secondFilter}
                          required
                        />
                        <Form.Field
                          control={Form.Select}
                          options={optionsCities}
                          search
                          placeholder="Selecione uma cidade"
                          label={'Cidades'}
                          name="thirdFilter"
                          onChange={this.onThirdFilterAddressChange}
                          value={thirdFilter}
                          required
                          disabled={secondFilter === null || optionsCities.length === 0}
                        />
                        <Form.Field
                          control={Form.Select}
                          options={[{ key: "nda", text: "Nenhum", value: 0 }, ...optionsNeighborhoods]}
                          search
                          placeholder="Selecione um bairro"
                          label={"Bairro"}
                          name="fourthFilter"
                          onChange={this.onFilterChange}
                          value={fourthFilter}
                          required
                          disabled={thirdFilter === null || optionsNeighborhoods.length === 0}
                        />
                      </Form.Group>
                    </Segment>
                  }
                  {
                    firstFilter === "etiquetas" &&
                    <Segment tertiary size="mini" basic loading={transportersContactCategory ? false : true}>
                      <Form.Group widths="equal">
                        <Form.Field
                          control={Form.Select}
                          options={transportersContactCategory ? transportersContactCategory.data : []}
                          search
                          placeholder="Selecione um categoria"
                          label="Categorias"
                          name="secondFilter"
                          onChange={this.onFilterChange}
                          value={secondFilter}
                          required
                        />
                      </Form.Group>
                    </Segment>
                  }

                  <Form.Field
                    control={Form.Button}
                    type="submit"
                    label=""
                    fluid
                    primary
                    size="tiny"
                    content="BUSCAR TRANSPORTADORES"
                    disabled={(firstFilter === 'veiculo' && thirdFilter < 1) || (firstFilter === 'transportador' && (textValue === '' || typeof (secondFilter) !== 'string')) || (firstFilter === 'servicos' && (!secondFilter || secondFilter.length < 1)) || resultLoading}
                    loading={resultLoading}
                  />
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer="16" tablet="16" mobile="16">
              <Segment color="blue" loading={resultLoading}>
                {results.length === 0 ? this.renderNoResults(noResultMsg)
                  : <Grid>
                    <Grid.Row>
                      <Grid.Column width="10">
                        <Pagination
                          page={activePage}
                          count={pageCount}
                          onChange={this.handlePageClick}
                          color="secondary"
                          hideNextButton={finishedPages} 
                        />
                      </Grid.Column>
                      <Grid.Column width="6" textAlign="right" verticalAlign="middle">
                        {finishedPages && <Label content="Paginação Conluída" icon="check" color="pink" />}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width="16">
                        <TransportadoresList
                          list={results}
                          key={key}
                          permission={this.props.permission}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width="10">
                        <Pagination
                          page={activePage}
                          count={pageCount}
                          onChange={this.handlePageClick}
                          color="secondary"
                          hideNextButton={finishedPages}
                        />
                      </Grid.Column>
                      <Grid.Column width="6" textAlign="right" verticalAlign="middle">
                        {finishedPages && <Label content="Paginação finalizada" icon="check" color="pink" />}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                }
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }
}

const mapStateToProps = ({ pageInfo, transportersContactCategory }) => {
  return {
    pageInfo,
    transportersContactCategory
  };
};

export default connect(mapStateToProps, actions)(Transportadores)
