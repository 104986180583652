import React from 'react'
import { Segment, Grid, Header, Button, Form, Confirm, Table, Label, Icon, Input, Radio, Checkbox } from 'semantic-ui-react'
import _ from 'lodash'
import * as noliAPI from '../../../utils/noliAPI'
import { connect } from 'react-redux'
import * as actions from '../../../utils/actions'
import formatDate from '../../../utils/formatDate'

const _fv = {
    valor_base: 0,
    titulo: null,
    descricao: null,
    regulamento: null,
    incluso: null,
    qtd_min_disp: 0,
    disponivel: 1
}

const ProtecaoItens = (props) => {
 
    if (props.title === "Painel Noli") props.setPageTitle('Alterar proteção de itens')

    const [loading, setLoading] = React.useState(true)
    const [protecaoItens, setProtecaoItens] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [form, setForm] = React.useState(_fv)
    const [modalProps, setModalProps] = React.useState({})


    React.useEffect(() => {
        if (!props.protecao_itens) props.fetchProtecaoItens()  

        if (props.protecao_itens) {
            setProtecaoItens(props.protecao_itens);
            setLoading(false)
        } 

    }, [props.protecao_itens]);


    const handleDisable = (e, { value }) => {
        props.sendMessage([], 'Função ainda não implementada')
    }

    const openNewProtection = () => {
        setModalProps({
          header: "Cadastrar nova proteção",
          type: "new",
          selected: {}
        })
        setForm(_fv)
        setOpen(true)
      }

    const selectOption = (option) => {
        if(!loading){
          const toPick = ['valor_base', 'titulo', 'descricao', 'regulamento', 'incluso', 'qtd_min_disp','id','disponivel']
          setModalProps({
            header: `Editar ${option.titulo}`,
            type: "edit",
            selected: _.pick(option, toPick)
          })
          setForm(_.pick(option, toPick))
          setOpen(true)
        }
      }
 
      const submitForm = async () => {
        const payload = {
          ...form,
          valor_base:Number(form.valor_base),
          qtd_min_disp:Number(form.qtd_min_disp),
          regulamento: form.regulamento === '' ? null : form.regulamento,
          incluso: form.incluso === '' ? null : form.incluso,
        }
        
        setLoading(true)
        let response;

        if(payload.id) {
           response = await noliAPI.alterarServicosProtecao(payload)
        } else {
           response = await noliAPI.cadastrarServicosProtecao(payload)
        }

        if(response.status === 200) {
            setOpen(false)
            await props.fetchProtecaoItens()
            setLoading(false)
            return props.sendMessage([], payload.id ? 'Dados atualizados com sucesso!' : 'Dados inseridos com sucesso!', {color:'blue', icon: "check circle"}, false)
           
        }
        setLoading(false)
        return props.sendMessage([], payload.id ? 'Erro ao atualizar dados! Tente novamente' : 'Erro ao inserir dados!')
    }

    return (
        <Grid padded>
            <Grid.Row>
                <Grid.Column>
                    <Segment loading={loading}>
                        <Header as="h3">
                            <Button
                                as={Label}
                                floated="right"
                                content="Nova proteção"
                                size="big"
                                color="blue"
                                icon="add"
                                onClick={openNewProtection}
                                disabled={!props.user.permissions[props.permission].create}
                            />
                            <Icon name="dollar" />
                            <Header.Content>Serviço de proteção de itens</Header.Content>
                        </Header>
                        <Form>
                            <Form.Group>
                                <Table selectable color="violet">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell>Criada em</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Valor base</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">titulo</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Descrição</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Regulamento</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Incluso</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Dias para agendamento</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Disponível</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {protecaoItens ? protecaoItens.map((t,i) =>
                                            <Table.Row
                                                key={i + "-" + t.titulo}
                                                className="selectable-row"
                                                positive={t.disponivel === 1}
                                                negative={t.disponivel !== 1}
                                                disabled={!!t.deleted_at || !props.user.permissions[props.permission].update}
                                                onClick={() => selectOption(t)}
                                            >
                                                <Table.Cell>{t.id}</Table.Cell>
                                                <Table.Cell>{formatDate.dateTimeServer(t.created_at)}</Table.Cell>
                                                <Table.Cell textAlign="center">{t.valor_base}</Table.Cell>
                                                <Table.Cell textAlign="center">{t.titulo}</Table.Cell>
                                                <Table.Cell textAlign="center">{t.descricao}</Table.Cell>
                                                <Table.Cell textAlign="center">{t.regulamento ? t.regulamento : 'N/I'}</Table.Cell>
                                                <Table.Cell textAlign="center">{t.incluso ? t.incluso : 'N/I'}</Table.Cell>
                                                <Table.Cell textAlign="center">{t.qtd_min_disp}</Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    {!t.deleted_at ?
                                                        <Form.Field
                                                        control={Checkbox}
                                                        name="disponivel"
                                                        value={t.id}
                                                        checked={t.disponivel === 1}
                                                        //onChange={handleDisable} 
                                                        />
                                                    : "Indisponível"}
                                                </Table.Cell>
                                            </Table.Row>)
                                        : !loading && <Table.Row error>
                                                <Table.Cell colSpan='8'>Proteções não encontradas</Table.Cell>
                                            </Table.Row>
                                        }
                                    </Table.Body>
                                </Table>
                            </Form.Group>
                        </Form>
                        <Confirm
                        open={open}
                        header={modalProps.header}
                        size="large"
                        content={<FormModal
                            loading={loading}
                            form={form}
                            onChange={setForm}
                        />}
                        cancelButton="Cancelar"
                        confirmButton={modalProps.type === "new"
                            ? <Button
                                content="Adicionar"
                                disabled={loading || _.isEqual(form, _fv) || form.titulo === "" || !form.titulo || form.descricao === "" || !form.descricao}
                                onClick={submitForm}
                            />
                            : <Button
                                content="Alterar"
                                disabled={loading || _.isEqual(form, modalProps.selected) || form.titulo === "" || !form.titulo || form.descricao === "" || !form.descricao}
                                onClick={submitForm}
                            />
                        }
                        onCancel={() => setOpen(false)}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

const FormModal = (props) => {
    const { form } = props
  
    const singleChange = (e, {name, value}) => props.onChange({ ...form, [name]: value })
  
    const checkChange = (e, {name, checked}) => props.onChange({...form, [name]: checked ? 1 : 0})
  
    return(
      <Segment basic loading={props.loading}>
        <Form>
            <Form.Group>
                <Form.Field
                label="Titulo"
                control={Form.Input}
                value={form.titulo}
                width="4"
                name="titulo"
                onChange={singleChange}
                />
                <Form.Field
                label="Valor base (múltiplo do volume dos itens)"
                control={Form.Input}
                value={form.valor_base}
                type="number"
                step='10.0'
                onChange={singleChange}
                name="valor_base"
                min="0"
                width="4"
                />
                <Form.Field
                label="Qtd. de dias para liberação do agendamento"
                control={Form.Input}
                value={form.qtd_min_disp}
                type="number"
                step='1'
                onChange={singleChange}
                name="qtd_min_disp"
                width="5"
                min="0"
                />
            </Form.Group>
            <Form.Group>
                <Form.Field
                label="Descrição"
                control={Form.TextArea}
                value={form.descricao}
                width="8"
                name="descricao"
                onChange={singleChange}
                />
                <Form.Field
                label="Regulamento"
                control={Form.TextArea}
                value={form.regulamento}
                onChange={singleChange}
                name="regulamento"
                width="8"
                />
            </Form.Group>
            <Form.Group>
                <Form.Field
                label="Incluso"
                control={Form.TextArea}
                value={form.incluso}
                onChange={singleChange}
                name="incluso"
                width="8"
                />
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Checkbox
                checked={form.disponivel === 1}
                label="Disponível"
                name="disponivel"
                onChange={checkChange}
                />
          </Form.Group>
        </Form>
      </Segment>
    )
  }

const mapStateToProps = ({ pageInfo }) => pageInfo

export default connect(mapStateToProps, actions)(ProtecaoItens)
