import React from 'react'
import { Segment, Grid, Header, Button, Form, Confirm, Table, Label, Icon, Input, Radio, Checkbox } from 'semantic-ui-react'
import _ from 'lodash'
import * as noliAPI from '../../../../../utils/noliAPI'
import { connect } from 'react-redux'
import * as actions from '../../../../../utils/actions'
import formatDate from '../../../../../utils/formatDate'

const _fv = {
    total: 0,
    total_available: 0,
    total_unvailable: 0,
    available: 1,
}

const StockControl = (props) => {

    if (props.title === "Painel Noli") props.setPageTitle('Alterar controle de estoque')

    const [loading, setLoading] = React.useState(true)
    const [stockControl, setStockControl] = React.useState([])
    const [open, openConfirm] = React.useState(false)
    const [modalProps, setModalProps] = React.useState({})
    const [formVal, formChange] = React.useState(_fv)

    if (!props.stock_control) props.fetchRentBoxStockControl()

    React.useEffect(() => {
        setStockControl(props.stock_control);
        setLoading(false)
    }, [props.stock_control]);


    const openNewConfirm = () => {
        setModalProps({
            header: "Cadastrar novo controle",
            type: "new",
            selected: {}
        })
        formChange(_fv)
        openConfirm(true)
    }

    const selectPackage = (item) => {

        if (!loading) {
            const formValues = {
                ...item
            }
            setModalProps({
                header: "Editar controle",
                type: "edit",
                selected: formValues
            })
            formChange(formValues)
            openConfirm(true)
        }
    }

    const submit = (isEdit: boolean = false) => {
        setLoading(true)
        const { total, total_available, total_unvailable, available, id } = formVal

        const toPick = ['total', 'total_available', 'total_unvailable', 'available']
        const payload = _.pick(formVal, toPick)
        if(isEdit) {
            noliAPI.updateRentBoxStockControl(payload, id).then(res => {
                if (res.status === 200) {
                    openConfirm(false)
                    props.sendMessage([], "Controle de estoque atualizado com sucesso!", { color: 'blue', icon: "check circle" }, false)
                    props.fetchRentBoxStockControl()
                } else {
                    setLoading(false)
                    props.sendMessage([], 'Houve um erro ao atualizar controle de estoque. Tente novamente mais tarde')
                }
            }).catch(() => {
                setLoading(false)
                props.sendMessage([], 'Houve um erro ao tentar atualizar o controle de estoque Tente novamente mais tarde')
            })
        } else {
            noliAPI.createRentBoxStockControl(payload).then(res => {
                if (res.status === 200) {
                    openConfirm(false)
                    props.sendMessage([], "Controle de estoque criado com sucesso!", { color: 'blue', icon: "check circle" }, false)
                    props.fetchRentBoxStockControl()
                } else {
                    setLoading(false)
                    props.sendMessage([], 'Houve um erro ao criar controle de estoque. Tente novamente mais tarde')
                }
            }).catch(() => {
                setLoading(false)
                props.sendMessage([], 'Houve um erro ao tentar criar o controle de estoque Tente novamente mais tarde')
            })

        }

    }

    const handleChange = (e, { name, value }) => {
        const { total, total_available, total_unvailable, available } = formVal
        if (name === 'total') {
            const data = {
                ...formVal,
                total: Number(value),
                total_available: Number(total_available) - (Number(total - Number(value)))
            }
            formChange(data)
        }
        if (Number(value) > total) {
            return
        }

        if (name === 'total_available') {
            const data = {
                ...formVal,
                total_available: Number(value),
                total_unvailable:  Number(total) - Number(value)
            }
            formChange(data)
        }


        if (name === 'total_unvailable') {
            const data = {
                ...formVal,
                total_unvailable: Number(value),
                total_available: Number(total_available) + (Number(total_unvailable - Number(value)))
            }
            formChange(data)
        }

        //formChange({ ...formVal, [name]: Number(value) })
    }

    let atual = props.stock_control ? props.stock_control.filter(t => t.available === 1)[0].id : ""

    return (
        <Grid padded>
            <Grid.Row>
                <Grid.Column>
                    <Segment loading={loading}>
                        <Header as="h3">
                            <Button
                                as={Label}
                                floated="right"
                                content="Novo controle"
                                size="big"
                                color="blue"
                                icon="add"
                                onClick={openNewConfirm}
                                disabled={!props.user.permissions[props.permission].create}
                            />
                            <Icon name="cogs" />
                            <Header.Content>Controle de caixas</Header.Content>
                        </Header>
                        <Form>
                            <Form.Group>
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell>Atualizado em</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Total de caixas</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Disponíveis</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Indisponíveis</Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">Atual</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {stockControl && stockControl.map(t =>
                                            <Table.Row
                                                onClick={() => selectPackage(t)}
                                                key={t.id + "-taxa"}
                                                positive={t.available === 1}
                                                error={t.available !== 1}
                                                disabled={!!t.atual || !props.user.permissions[props.permission].update}>
                                                <Table.Cell>{t.id}</Table.Cell>
                                                <Table.Cell>{formatDate.dateTimeServer(t.updated_at)}</Table.Cell>
                                                <Table.Cell textAlign="center">{t.total}</Table.Cell>
                                                <Table.Cell textAlign="center">{t.total_available}</Table.Cell>
                                                <Table.Cell textAlign="center">{t.total_unvailable}</Table.Cell>

                                                <Table.Cell textAlign="center">
                                                    <Checkbox
                                                        checked={atual === t.id}
                                                        onChange={() => {}}
                                                        value={t.id}
                                                        readOnly
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                </Table>
                            </Form.Group>
                        </Form>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Confirm
                open={open}
                header={modalProps.header}
                cancelButton="Cancelar" 
                confirmButton={
                    <Button
                        icon="add"
                        content={modalProps.type === "new" ? "Criar controle" : "Alterar controle"}
                        disabled={_.isEqual(formVal, _fv)}
                        onClick={() => modalProps.type === "new" ? submit() : submit(true)}
                    />
                }
                onCancel={() => openConfirm(false)}
                content={<FormModal
                    loading={loading}
                    form={formVal}
                    handleChange={handleChange}
                    handleAvailable={formChange}
                />}
            />
        </Grid>
    )
}

const FormModal = (props) => {
    const { form, handleChange, loading } = props

    const checkChange = (e, { name, checked }) => props.handleAvailable({ ...form, [name]: checked ? 1 : 0 })


    return (
        <Segment basic loading={loading}>
            <Form>
                <Form.Group widths='2' unstackable>
                    <Form.Field
                        control={Input}
                        label="Total de caixas"
                        name="total"
                        value={form.total}
                        onChange={handleChange}
                        type="number"
                        step="1"
                        min="0"


                    />
                    <Form.Field
                        control={Input}
                        label="Disponíveis"
                        name="total_available"
                        value={form.total_available}
                        onChange={handleChange}
                        type="number"
                        step="1"
                        min="0"
                        max={form.total}
                    />
                    <Form.Field
                        control={Input}
                        label="Indisponíveis"
                        name="total_unvailable"
                        value={form.total_unvailable}
                        onChange={handleChange}
                        type="number"
                        step="1"
                        min="0"
                        max={form.total}
                    />
                    <Form.Checkbox
                        checked={form.available === 1}
                        label="Ativo"
                        name="available"
                        onChange={checkChange}
                    />
                </Form.Group>
            </Form>
        </Segment>
    )
}

const mapStateToProps = ({ pageInfo }) => pageInfo

export default connect(mapStateToProps, actions)(StockControl)
