import React, { useState } from 'react'
import { List, Label, Button, Confirm, Form, Message, Icon } from 'semantic-ui-react'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import { formatStatusCreditCard, paymentFormsById } from '../constants'
import formatDate from '../utils/formatDate'
import { calculate_percent, money, valuesOfPayment } from '../utils/helpers'
import { atualizarBoleto, refreshPix, atualizarPagamento, atualizarPagamentoPix, atualizarOrcamento } from '../utils/noliAPI'
import { AxiosResponse } from 'axios'




const DadosPagamento = (props) => {

  const getFormaIcon = (id: number): string => {

    switch (id) {
      case paymentFormsById.boleto:
        return "barcode"
      case paymentFormsById.credit_card:
        return "credit card"
      case paymentFormsById.pay_pal:
        return "credit card"
      case paymentFormsById.pix:
        return "dollar sign"
      default:
        return "dollar sign"
    }
  }


  const getNoliTaxOfValue = () => {
    const checkDiscount = props.data.filter(e => e.cupom_desconto)

    if (checkDiscount[0]) {
      return checkDiscount[0].cupom_desconto
    }
    return
  }






  const RefreshPix = (props) => {

    const [loading, setLoading] = useState(false)

    const onPressRefreshPix = async (payment_id) => {
      const query = { payment_id: payment_id }
      setLoading(true)
      await refreshPix(query).then((resp: AxiosResponse) => {
        if (resp.status === 200) {
          props.refresher()
        }
        setLoading(false)
      }).catch((error) => {
        setLoading(false)
      })
    }


    return (
      <>
        <Button
          color="black"
          icon="refresh"
          content="Refresh Pix"
          labelPosition='left'
          size="mini"
          loading={loading}
          onClick={() => onPressRefreshPix(props.payment_id)}
        />
      </>
    )
  }

  const RenderPaymentTransport = () => {
    const data = props.dataOfTransporter()
    const { total, value_of_transporter, value_of_noli, transport_fee } = data
    const discountTax = getNoliTaxOfValue()
    return (
      <>
        <List.Header>
          <Label color="red" ribbon content={"Transporte"} />
        </List.Header>
        <List.Content>
          <p><List.Icon name="dollar sign" /><b>Valor:</b> {money(total)}</p>
          <div className='rowC'>
            <List.Icon name="dollar sign" /><b>Transportador recebe:</b> {money(value_of_transporter)}
            <AlterarTaxaTransportador
              transport_id={props.transport_id}
              refresher={props.refresher}
              total_value={total}
              transporter_value={value_of_transporter}
              noli_value={discountTax ? (value_of_noli - discountTax.valor) : value_of_noli}
              transport_fee={transport_fee}
            />
          </div>
          {discountTax ?
            <>
              <p><List.Icon name="money bill alternate" /><b>Cupom:</b> {money(discountTax.valor)}</p>
              <p><List.Icon name="dollar sign" /><b>Noli recebe:</b> {money(value_of_noli - discountTax.valor)}</p>
            </>
            :
            <p><List.Icon name="dollar sign" /><b>Noli recebe:</b> {money(value_of_noli)}</p>
          }
        </List.Content>
      </>
    )
  }

  const RenderPaymentProtectionServvice = () => {
    return (
      <>
        <List.Header>
          <Label color="blue" ribbon content={"Proteção dos itens"} />
        </List.Header>
        <List.Content>
          <p><List.Icon name="dollar sign" /><b>Valor:</b> {money(props.services.protecao_itens.valor_servico)}</p>
          {/*<p><List.Icon name="dollar sign" />Empacotador: R$ 2.000</p>
          <p><List.Icon name="dollar sign" />Noli: R$ 2.000</p>*/}
        </List.Content>
      </>
    )
  }

  const RenderPaymentInsuranceCargo = () => { 
    const total_price_items = props.items.reduce((el, item) => el + item?.suggested_price, 0)
    const total_price_custom_items = props.custom_items ? props.custom_items.reduce((el, item) =>  item?.suggested_price ? (el + item.suggested_price) : 0, 0) : 0
    return (
      <>
        <List.Header>
          <Label color="brown" ribbon content={"Seguro de carga"} />
        </List.Header>
        <List.Content>
          <p><List.Icon name="dollar sign" />Valor total dos itens (P/S): {money(total_price_items + total_price_custom_items)}</p>
        </List.Content>
        <List.Content>
        {props?.services.insurance_cargo?.map((element, index) => {
            return <p><List.Icon name="dollar sign" />{element.description} pag. {index + 1}: {money(element.amount)}</p>
          })}
        </List.Content>
        <List.Content>
          <p><List.Icon name="dollar sign" /><b>Valor total (Incluso no valor do transporte):</b> {money(props?.services.insurance_cargo?.reduce((el, item) => el + item.amount, 0))}</p>
        </List.Content>
      </>
    )
  }

  const RenderPaymentAssemblyDisassembly = () => {
    return (
      <>
        <List.Header>
          <Label color="purple" ribbon content={"Montagem / Demontagem"} />
        </List.Header>
        <List.Content>
          {props.services.montagem_desmontagem.map((element) => {
            return <p><List.Icon name="dollar sign" /><b>{element.titulo}:</b> {money(element.valor_servico)}</p>
          })}

          {/*<p><List.Icon name="dollar sign" />Empacotador: R$ 2.000</p>
          <p><List.Icon name="dollar sign" />Noli: R$ 2.000</p>*/}
        </List.Content>
      </>
    )
  }


  return (
    <List relaxed>
      <List.Item icon="credit card" header="RECIBO" />
      <List.Item>
        <List relaxed>
          {props.canChange && <RenderPaymentTransport />}
          {props.services && props.services.insurance_cargo && props.services.insurance_cargo.length > 0 && <RenderPaymentInsuranceCargo />}
          {props.services && props.services.protecao_itens && <RenderPaymentProtectionServvice />}
          {props.services && props.services.montagem_desmontagem && props.services.montagem_desmontagem.length > 0 && <RenderPaymentAssemblyDisassembly />}
        
        </List>
      </List.Item>

      <List.Item icon="credit card" header="PAGAMENTOS" />
      <List.Item>
        <List relaxed>
          {props.data.map((p, i) => {
            return (
              <List.Item key={i}>
                <List.Header>
                  <Label color="teal" ribbon content={"Pagamento nº " + (i + 1)} />
                </List.Header>
                <List.Content>
                  <p><List.Icon name="dollar sign" /><b>{money(p.valor)}</b></p>
                  <p><List.Icon name={getFormaIcon(p.forma_pagamento_id)} /><b>{p.forma_pagamento_descricao}</b></p>
                  <p><List.Icon name="calendar alternate" /><b>Criado em:</b> {formatDate.getDateUnixTimestamp(p.data_solicitacao)}</p>
                  {p.cupom_desconto && (<>
                    <p><List.Icon name="ticket" /><b>Cupom:</b> {p.cupom_desconto.codigo}</p>
                    <p><List.Icon name="money bill alternate" /><b>Desconto de:</b> {money(p.cupom_desconto.valor)}</p></>)
                  }

                  {(p.forma_pagamento_id === paymentFormsById.credit_card) && p.card_data && p.card_data.status && (<>
                    <p><List.Icon name="calendar alternate" /><b>Detalhes:</b> {String(p.card_data.brand).toUpperCase()} final  *{p.card_data.last_digit}</p>
                    <p><List.Icon name="ticket" /><b>Status (provedor):</b> {formatStatusCreditCard(p.card_data.status)}</p>
                    <p><List.Icon name="money bill alternate" /><b>{p.card_data.installment === 1 ? 'Pagamento à vista' : 'Parcelado em ' + p.card_data.installment + 'x'}</b></p>
                    {/* criar estorno */}
                  </>)
                  }

                  {(p.forma_pagamento_id === paymentFormsById.pix) && (p.pix_data) && (p.pix_data.status) && (<>
                    <p><List.Icon name="calendar alternate" /><b>Expira em:</b> {formatDate.expirationDatePix(p.pix_data.expiration_date, p.pix_data.review_date ? p.pix_data.review_date : p.pix_data.creation_date)}</p>
                    <p><List.Icon name="ticket" /><b>Status (provedor):</b> {p.pix_data.status}</p>
                    <p><List.Icon name="money bill alternate" /><b>Chave:</b> {p.pix_data.qr_code_image_text}</p>
                    {props.canChange &&
                      <>
                        <p><RefreshPix payment_id={p.id} refresher={props.refresher} /></p>
                        <p> <List.Item className="button-list">
                          <AlterarStatusPix refresher={props.refresher} pagData={p} />
                        </List.Item></p>
                      </>
                    }
                  </>)
                  }
                  {props.canChange && <p><List.Item >
                    <AlterarStatusPagamento refresher={props.refresher} pagData={p} />
                  </List.Item> </p>
                  }
                  <p><Label size="tiny" color={p.status !== null ? p.status === 0 ? "red" : "green" : "brown"}>
                    <List.Icon name={p.status !== null ? p.status === 0 ? "cancel" : "check" : "cancel"} />
                    {p.status !== null ? p.status === 0 ? "Não pago" : "Pago" : "N/I"}
                  </Label></p>

                  {p.dados_boleto &&
                    <List.List>
                      {p.dados_boleto.data_emissao && <List.Item icon="calendar plus" content={"Emissão: " + formatDate.dateBR(p.dados_boleto.data_emissao)} />}
                      {p.dados_boleto.data_vencimento && <List.Item icon="delete calendar" content={"Vencimento: " + formatDate.dateBR(p.dados_boleto.data_vencimento)} />}
                      {p.dados_boleto.status_boleto_id && <List.Item icon="file alternate outline" content={`Status: ` + p.dados_boleto.status_boleto_descricao} />}
                      {p.dados_boleto.linha_digitavel && <List.Item icon="edit outline" content={"Linha digitável: " + p.dados_boleto.linha_digitavel} />}

                      {<List.Item icon="user outline" content={"Nome de cobrança: " + (p.dados_boleto.nome ? p.dados_boleto.nome : p.billing_data ? p.billing_data.first_name + ' ' + p.billing_data.last_name : 'N/I')} />}
                      {<List.Item icon="user outline" content={"CPF de cobrança: " + (p.dados_boleto.cpf ? p.dados_boleto.cpf : p.billing_data ? p.billing_data.cpf : 'N/I')} />}
                      {<List.Item icon="mail outline" content={"E-mail de cobrança: " + (p.dados_boleto.email ? p.dados_boleto.email : p.billing_data ? p.billing_data.email : 'N/I')} />}
                      {<List.Item icon="location arrow" content={"Endereço de cobrança: " + (
                        p.dados_boleto.rua ? p.dados_boleto.rua + ", " + p.dados_boleto.numero + " - " + p.dados_boleto.bairro + " - " + p.dados_boleto.cidade + "/" + p.dados_boleto.estado + " - " + p.dados_boleto.cep + "\n Complemento - " + (p.dados_boleto.complemento ? p.dados_boleto.complemento : 'N/I')
                          : p.billing_data ? p.billing_data.street + ", " + p.billing_data.street_number + " - " + p.billing_data.neighborhood + " - " + p.billing_data.city + "/" + p.billing_data.state + " - " + p.billing_data.zip_code + "\n Complemento - " + (p.billing_data.complement ? p.billing_data.complement : 'não informado') : 'N/I')} />}
                      {props.canChange && <List.Item>
                        <AlterarBoleto refresher={props.refresher} pagData={p} clienteInfo={props.clienteInfo} />
                      </List.Item>}
                    </List.List>
                  }
                </List.Content>
              </List.Item>
            )
          })}
        </List>
      </List.Item>
      {props.complementar &&
        <List.Item>
          <Message error icon compact size="mini" style={{ margin: 0 }}>
            <Icon name="info circle" />
            <Message.Content >
              <Message.Header content="Observações" />
              {props.complementar}
            </Message.Content>
          </Message>
        </List.Item>
      }

    </List>
  )
}

export default DadosPagamento

interface IDataTransportPayment {
  total_value: string,
  transporter_value: string,
  noli_value: string,
  transport_fee: number,
  transport_id: number
}

const AlterarTaxaTransportador: React.FC<IDataTransportPayment> = (props) => {

  const [open, toggleConfirm] = useState(false)
  let form = React.createRef();

  return (
    <>
      <Button style={{ marginLeft: 10 }} icon onClick={() => toggleConfirm(true)} >
        <Icon name='pencil' />
      </Button>
      <Confirm
        open={open}
        onCancel={() => toggleConfirm(false)}
        onConfirm={() => form.current.onSubmit()}
        cancelButton="Cancelar"
        confirmButton="Realizar Alterações"
        size="tiny"
        header="Alterar valor de recebimento do transportador"
        content={<AlterarFormTaxaTransportador
          onSubmit={() => toggleConfirm(false)}
          refresher={props.refresher}
          data={props}
          ref={form}
        />}
      />
    </>
  )
}

const AlterarBoleto = (props) => {
  const [open, toggleConfirm] = useState(false)
  let form = React.createRef();

  return (
    <>
      <Button
        secondary
        icon="barcode"
        content="Status do Boleto"
        labelPosition='left'
        size="mini"
        onClick={() => toggleConfirm(true)}
      />
      <Confirm
        open={open}
        onCancel={() => toggleConfirm(false)}
        onConfirm={() => form.current.onSubmit()}
        cancelButton="Cancelar"
        confirmButton="Realizar Alterações"
        size="tiny"
        header="Alterar Informações de Boleto"
        content={<AlterarForm
          onSubmit={() => toggleConfirm(false)}
          refresher={props.refresher}
          ref={form}
          pagData={props.pagData}
          clienteInfo={props.clienteInfo}
        />}
      />
    </>
  )
}

const AlterarStatusPagamento = (props) => {
  const [open, toggleConfirm] = useState(false)
  let form = React.createRef();

  return (
    <>
      <Button
        color="blue"
        icon="dollar sign"
        content="Status do pagamento"
        labelPosition='left'
        size="mini"
        onClick={() => toggleConfirm(true)}
      />
      <Confirm
        open={open}
        onCancel={() => toggleConfirm(false)}
        onConfirm={() => form.current.onSubmit()}
        cancelButton="Cancelar"
        confirmButton="Realizar Alterações"
        size="tiny"
        header="Alterar status do pagamento"
        content={<AlterarStatusPagamentoForm
          onSubmit={() => toggleConfirm(false)}
          refresher={props.refresher}
          ref={form}
          pagData={props.pagData}
        />}
      />
    </>
  )
}

const AlterarStatusPix = (props) => {
  const [open, toggleConfirm] = useState(false)
  let form = React.createRef();

  return (
    <>
      <Button
        color="purple"
        icon="dollar sign"
        content="Alterar pix"
        labelPosition='left'
        size="mini"
        onClick={() => toggleConfirm(true)}
      />
      <Confirm
        open={open}
        onCancel={() => toggleConfirm(false)}
        onConfirm={() => form.current.onSubmit()}
        cancelButton="Cancelar"
        confirmButton="Realizar Alterações"
        size="tiny"
        header="Alterar informações do pix"
        content={<AlterarPixForm
          onSubmit={() => toggleConfirm(false)}
          refresher={props.refresher}
          ref={form}
          pagData={props.pagData}
        />}
      />
    </>
  )
}



class AlterarPixForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      expiration_date: "",
      //initial_date: formatDate.expirationDatePix(this.props.pagData.pix_data.expiration_date, this.props.pagData.pix_data.creation_date, false),
      initial_date: new Date(),
      loading: false
    }
  }

  onSelectionChange = (e, data) => {
    this.setState({ expiration_date: data.value })
  }

  onSubmit = async () => {
    const { expiration_date } = this.state
    if (expiration_date) {
      this.setState({ loading: true })
      const payload = {
        calendar: {
          expiration_date: formatDate.getDifferenceInSeconds(new Date(), this.state.expiration_date),
        }
      }

      await atualizarPagamentoPix(this.props.pagData.id, payload).then((resp: AxiosResponse) => {
        if (resp.status === 200) {
          this.props.refresher()
          this.props.onSubmit()
        }
        this.setState({ loading: false })
      }).catch((error) => {
        this.setState({ loading: false })
      })
    }
  }

  render() {
    const { expiration_date, initial_date } = this.state
    return (
      <div style={{ margin: 0, padding: "10px 15px" }}>
        <Form onSubmit={this.onSubmit} loading={this.state.loading}>
          <DateTimeInput
            onChange={this.onSelectionChange}
            fluid
            value={expiration_date ? expiration_date : initial_date}
            minDate={initial_date}
            dateTimeFormat="YYYY-MM-DDTHH:mm:ss"
            iconPosition="left"
            localization='pt-br'
            popupPosition="top center"
            closable
            clearable
            placeholder="Selecione uma nova data para o transporte"
            label="Nova data"
          />
        </Form>
      </div>
    )
  }
}


class AlterarFormTaxaTransportador extends React.Component {

  constructor(props: { data: IDataTransportPayment }) {
    super(props)
    this.state = {
      selected: 0,
      transporter_value: Number(props.data.transporter_value).toFixed(2),
      total_value: props.data.total_value,
      total_noli: props.data.noli_value,
      fee_noli: props.data.transport_fee,
      fee_transporter: Number(100 - props.data.transport_fee),
      loading: false
    }
  }

  handleChange = (e) => {
    const { fee_noli, loading, selected, total_noli, total_value, transporter_value, fee_transporter } = this.state
    const feeT = Number((Number(e.target.value) * 100) / total_value)
    const feeN = Number(100 - feeT)
    const noli_value = Number(total_value - Number(e.target.value))
    this.setState({
      fee_transporter: feeT,
      fee_noli: feeN,
      transporter_value: e.target.value,
      total_noli: noli_value
    });
  }

  onSubmit = () => {
    const { fee_noli, loading, selected, total_noli, total_value, transporter_value, fee_transporter } = this.state
    if (fee_noli) {
      this.setState({ loading: true })
      atualizarOrcamento({
        company_fee: fee_noli.toFixed(3)
      }, this.props.data.transport_id).then((response: AxiosResponse) => {
        if (response.status === 200) {
          this.props.refresher()
          this.props.onSubmit()
        }
        this.setState({ loading: false })
      })
    }
  }



  render() {
    const { fee_noli, loading, selected, total_noli, total_value, transporter_value, fee_transporter } = this.state
    return (
      <div style={{ margin: 0, padding: "10px 15px" }}>

        <Form onSubmit={this.onSubmit} loading={loading}>
          <List.Content>
            <p><List.Icon name="dollar sign" /><b>Valor total do transporte:</b> {money(total_value)}</p>
            <p><List.Icon name="dollar sign" /><b>Percentual do transportador:</b> {Number(fee_transporter).toFixed(2)}%</p>
            <p><List.Icon name="dollar sign" /><b>Percentual da Noli:</b> {Number(fee_noli).toFixed(2)}%</p>
            <p><List.Icon name="dollar sign" /><b>Noli recebe:</b> {money(total_noli)}</p>
            <Form.Field
              control={Form.Input}
              type="number"
              placeholder="Valor"
              label="Transportador recebe"
              width="5"
              // step="0.1"
              required
              name="transporter_value"
              value={transporter_value}
              onChange={this.handleChange}
            />

          </List.Content>
        </Form>
      </div>
    )
  }
}
class AlterarForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selected: 0,
      digitavel: "",
      loading: false
    }
  }

  options = [
    { text: "Gerando boleto", value: 1, key: 1 },
    { text: "Boleto Gerado", value: 2, key: 2 },
    { text: "Pago", value: 3, key: 3 },
    { text: "Não Pago", value: 4, key: 4 },
    { text: "Cancelado", value: 5, key: 5 },
  ]

  onSelectionChange = (e, data) => this.setState({ selected: data.value })

  handleChange = (e) => this.setState({ digitavel: e.target.value })
  onSubmit = () => {
    this.setState({ loading: true })
    this.saveToAPI().then(() => {
      this.setState({ loading: false })
      this.props.refresher()
      this.props.onSubmit()
    })
  }

  saveToAPI = () => {
    let payload;
    if (this.state.digitavel && this.state.digitavel !== "") {
      payload = {
        dados_boleto: {
          status_boleto_id: this.state.selected,
          linha_digitavel: this.state.digitavel
        },
        data_vencimento: this.props.pagData.dados_boleto.data_vencimento,
        cliente_id: this.props.clienteInfo.cliente_id,
        ordem_servico: this.props.clienteInfo.ordem_servico
      }
    } else {
      payload = {
        dados_boleto: {
          status_boleto_id: this.state.selected
        },
        cliente_id: this.props.clienteInfo.cliente_id,
        ordem_servico: this.props.clienteInfo.ordem_servico
      }
    }
    return atualizarBoleto(this.props.pagData.id, payload)
  }

  render() {
    return (
      <div style={{ margin: 0, padding: "10px 15px" }}>
        <Form onSubmit={this.onSubmit} loading={this.state.loading}>
          <Form.Field
            label="Novo status do boleto"
            required
            control={Form.Select}
            options={this.options}
            onChange={this.onSelectionChange}
            placeholder="Selecione uma opção"
          />
          <Form.Field
            label="Linha digitável"
            control={Form.Input}
            type="text"
            placeholder="Linha digitável do boleto"
            defaultValue={this.props.pagData.dados_boleto.linha_digitavel}
            onChange={this.handleChange}
          />
        </Form>
      </div>
    )
  }
}
class AlterarStatusPagamentoForm extends React.Component {

  constructor(props) {

    super(props)
    this.state = {
      status: this.props.pagData.status,
      details: this.props.pagData.observacao,
      loading: false
    }
  }

  options = [
    { text: "Não Pago", value: 0, key: 1 },
    { text: "Pago", value: 1, key: 2 },
  ]


  onSelectionChange = (e, data) => {
    this.setState({ status: data.value })
  }

  handleChange = (e) => this.setState({ details: e.target.value })

  onSubmit = async () => {

    let payload;
    if (!this.state.loading) {
      payload = {
        ...this.state.status !== null && { status: Boolean(this.state.status) },
        ...this.state.details && { observacao: this.state.details },
      }
    }

    if (Object.keys(payload).length === 0) return

    this.setState({ loading: true })

    await atualizarPagamento(this.props.pagData.id, payload).then((resp: AxiosResponse) => {
      if (resp.status === 200) {
        this.props.refresher()
        this.props.onSubmit()
      }
      this.setState({ loading: false })
    }).catch((error) => {
      this.setState({ loading: false })
    })


    //return atualizarBoleto(this.props.pagData.id, payload)
  }

  render() {
    return (
      <div style={{ margin: 0, padding: "10px 15px" }}>
        <Form onSubmit={this.onSubmit} loading={this.state.loading}>
          <Form.Select
            label="Novo status do pagamento"
            required
            control={Form.Select}
            //selection
            options={this.options}
            onChange={this.onSelectionChange}
            placeholder="Selecione uma opção"
            value={this.state.status}
          />
          <Form.Field
            label="Observação"
            control={Form.Input}
            type="text"
            placeholder="Digite alguma observação sobre o pagamento"
            defaultValue={this.state.details}
            onChange={this.handleChange}
          />
        </Form>
      </div>
    )
  }
}
