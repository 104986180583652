import React from 'react'
import { Form, Search, Message, Button } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../utils/actions'
import * as masks from '../utils/masks'
import { escolherTransportadorOrcamento, transportUpdate } from '../utils/noliAPI'


class SearchTransportadorFilter extends React.Component {



  constructor(props) {
    super(props)
    this.state = {
      filter: "all",
      secondFilter: "nome",
      keyword: "",
      results: [],
      loading: false,
      value: "",
      selected: this.props.data?.transportador ? this.props.data?.transportador : {},
      error: "",
      open: false,
    }
  }

  componentDidMount() {
    this.props.clearSearchedTransportadores()
  }

  submit = () => {
    if (this.props.return_data) {
      this.props.return_data(this.state.selected)
      return true
    }
    this.setState({ loading: true })
    const { id, ordem_servico, data_transporte } = this.props.data
    const { selected } = this.state

    if ('nome' in selected) {
      return escolherTransportadorOrcamento(selected.id, id, data_transporte, ordem_servico).then(res => {
        return true
      }).catch(() => false)
    } else {
      this.setState({ loading: false, error: "Selecione um transportador" })
      return false
    }
  }

  changeFilter = (e, { value }) => {
    this.setState({ filter: value, selected: {}, open: false, value: "", loading: true })
    if (value === "agreed" && !(this.props.data.id in this.props.transportadores)) {
      this.props.fetchTransportadores({ agree: this.props.data.id }).then(() => this.setState({ loading: false }))
    } else this.setState({ loading: false })
  }

  changeSecondFilter = (e, { value }) => this.setState({ secondFilter: value })

  handleKeywordChange = (e, { value }) => this.setState({ keyword: value })

  getTransportadorFromAPI = (type, keyword) => {
    this.setState({ loading: true })
    this.props.fetchTransportadores({ type: type, value: keyword }).then(x => this.setState({ loading: false }))
  }

  handleKeyPress = (e) => {
    if (e.charCode === 13)
      this.getTransportadorFromAPI(this.state.secondFilter, this.state.keyword)
  }

  handleResultSelect = (e, { result }) => {
    this.setState({ selected: result, value: result.nome, open: false })
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, value })
    let list = this.state.filter === "all" ? this.props.transportadores.searched : this.props.transportadores[this.props.data.id]

    list = list.map(l => {
      return {
        ...l,
        find: "nome " + l.nome + " telefone " + l.telefone + " email " + l.email + " nota " + l.nota_inicial
      }
    })

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState({ loading: false, results: [], value: '' })

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result.find)

      this.setState({
        loading: false,
        results: _.filter(list, isMatch),
        open: true
      })
    }, 300)
  }

  onRemoveItem = (item) => {
    if (item?.id) {
      this.setState({ loading: true })
      const { id} = this.props.data
       transportUpdate({ transporter_id: null }, id).then(res => {
        this.setState({ loading: false })
        if (res.status === 200) { 
          this.setState({ selected: {} })
          this.props.refresher()
        } 
      }).catch(() => {
        this.setState({ loading: false })
      })
    }
  }

  renderLabel = () => {
    if (this.state.filter === "") return "Buscar Transportador"
    else if (this.state.filter === "all" && ("searched" in this.props.transportadores)) return "Buscar Transportador (" + this.props.transportadores.searched.length + " encontrados)"
    else if (this.state.filter === "agreed" && (this.props.data.id in this.props.transportadores)) return "Buscar Transportador (" + this.props.transportadores[this.props.data.id].length + " concordaram)"
    else return "Buscar Transportador"
  }

  openDropdown = () => {
    this.state.value === "" ? this.setState({ open: true })
      : this.setState({ open: false })
  }


  render() {
    const { loading, results, value, filter, secondFilter, selected, open, keyword } = this.state
    return (
      <div style={{ margin: 0, padding: "10px 15px" }} className="confirm-form-container">
        <Form onSubmit={this.submit} loading={loading} >
          <Form.Field
            control={Form.Select}
            options={[
              { text: "Todos", key: 0, value: 'all' },
              { text: "Concordaram", key: 1, value: 'agreed' },
            ]}
            label="Transportadores"
            placeholder="Filtros"
            onChange={this.changeFilter}
            value={filter}
          />
          {filter === "all" &&
            <>
              <Form.Field
                control={Form.Select}
                options={[
                  { text: "Telefone", key: 0, value: 'telefone' },
                  { text: "Nome", key: 1, value: 'nome' },
                  { text: "CPF", key: 2, value: 'cpf' },
                  { text: "Email", key: 3, value: 'email' }
                ]}
                label="Buscar transportador por"
                placeholder="Filtros"
                onChange={this.changeSecondFilter}
                value={secondFilter}
              />
              <Form.Field
                control={Form.Input}
                label="Palavra-chave"
                placeholder={"Digite aqui o " + secondFilter + " do transportador que deseja buscar"}
                onChange={this.handleKeywordChange}
                value={keyword}
                action={{
                  color: 'teal', icon: 'search', content: 'Buscar', type: 'button', onClick: () => this.getTransportadorFromAPI(secondFilter, keyword)
                }}
                onKeyPress={this.handleKeyPress}
                required
              />
            </>
          }
          <Form.Field
            control={Search}
            label={this.renderLabel()}
            placeholder="Digite nome, telefone ou outras Informações"
            loading={loading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            results={(value === "" && filter === "all") ? this.props.transportadores.searched : results}
            value={value}
            disabled={filter.length < 1}
            noResultsMessage="NENHUM TRANSPORTADOR ENCONTRADO"
            noResultsDescription="É possível que nenhum transportador tenha concordado com o serviço até o momento."
            open={open}
            onFocus={() => this.openDropdown()}
            onBlur={() => this.setState({ open: false })}
          />
          <Message
            icon='truck'
            header={'nome' in selected ? "Transportador Selecionado" : "Selecione um transportador no campo acima"}
            content={'nome' in selected && (
              <>
                Nome: {selected.nome} <br />
                Telefone: {masks.maskTelefone(selected.telefone)}<br />
                <Link to={"/cadastro/transportador/" + selected.id} target="_blank">
                  Ver dados completos
                </Link>
                <Button
                  floated='right'
                  size="mini"
                  icon="delete"
                  color='red'
                  onClick={() => { this.onRemoveItem(selected) }}
                />
              </>
            )}
            size="mini"
            negative={!('nome' in selected)}
            positive={'nome' in selected}
          />
        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({ transportadores }) => {
  return {
    transportadores
  };
};

export default connect(mapStateToProps, actions, null, { forwardRef: true })(SearchTransportadorFilter) 
