import React from 'react'
import {Form, Button, Label, Container } from 'semantic-ui-react'

class OrcamentoItensCustomizados extends React.Component {

  state={
    descricao: "",
    peso: "",
    altura: "",
    largura: "",
    comprimento: "",
    quantidade: 1,
    aereo: false,
    //id : 1 ??
  }

  handleChange = (e, {name, value}) => this.setState({[name]: value})

  changeItemProps = (e, {value, index, name}) => {
    let newList = [...this.props.ItemList]
    const newItem = {...newList[index], [name]: value}
    if (name === "quantidade" && value < 1) newList.splice(index, 1)
    else newList.splice(index, 1, newItem)
    this.props.onChange(newList)
  }

  handleAddNewItem = () => {
    this.setState({descricao:"", peso:"", altura:"", largura:"", comprimento:""})
    let newList = [...this.props.ItemList]
    const newItem = {...this.state}
    newList.push(newItem)
    this.props.onChange(newList)
  }

  render() {
    const {descricao, peso, altura, largura, comprimento } = this.state
    return(
      <Container>
        <Form.Group
          unstackable
          style={{ display:"flex", flexDirection:"row", alignItems:"flex-end"  }}
        >
          <Form.Field
            control={Form.Input}
            type="text"
            placeholder="Digite uma descrição de identificação do item"
            label="Descrição"
            width="6"
            required
            name="descricao"
            value={descricao}
            onChange={this.handleChange}
          />
          <Form.Field
            control={Form.Input}
            type="number"
            label="Peso (Kg)"
            width="2"
            name="peso"
            value={peso}
            onChange={this.handleChange}
          />
          <Form.Field
            control={Form.Input}
            type="number"
            placeholder="Número"
            label="Altura"
            width="2"
            step="0.1"
            required
            name="altura"
            value={altura}
            onChange={this.handleChange}
          />
          <Form.Field
            control={Form.Input}
            type="number"
            placeholder="Número"
            label="Largura"
            width="2"
            step="0.1"
            required
            name="largura"
            value={largura}
            onChange={this.handleChange}
          />
          <Form.Field
            control={Form.Input}
            type="number"
            placeholder="Número"
            label="Profundidade"
            width="2"
            step="0.1"
            required
            name="comprimento"
            value={comprimento}
            onChange={this.handleChange}
          />
          <Form.Field width="2">
            <Button
              color="blue"
              content="Adicionar"
              style={{width: 82, paddingLeft:12 }}
              type="button"
              onClick={this.handleAddNewItem}
              disabled={descricao==="" || altura<0.1 || largura<0.1 || comprimento<0.1}
            />
          </Form.Field>
        </Form.Group>
        {this.renderItems(this.props.ItemList)}
      </Container>
    )
  }

  renderItems = (itens) => {
    if(itens.length> 0){
      return itens.map((i,index) => {
        return (
          <Form.Group
            unstackable
            key={index+"-"+i.descricao}
            style={{ display:"flex", flexDirection:"row", alignItems:"center"  }}
          >
            <Form.Field
              control={Label}
              content={index+1}
              color="green"
              size="big"
              width="1"
            />
            <Form.Field
              control={Label}
              content={i.descricao}
              width="5"
              size="big"
              color="teal"
              style={{width: '100%'}}
            />
            <Form.Field
              control={Form.Input}
              type="number"
              width="2"
              value={i.peso}
              index={index}
              name="peso"
              onChange={this.changeItemProps}
            />
            <Form.Field
              control={Form.Input}
              type="number"
              placeholder="Número"
              width="2"
              step="0.1"
              value={i.altura}
              index={index}
              name="altura"
              onChange={this.changeItemProps}
            />
            <Form.Field
              control={Form.Input}
              type="number"
              placeholder="Número"
              width="2"
              step="0.1"
              value={i.largura}
              index={index}
              name="largura"
              onChange={this.changeItemProps}
            />
            <Form.Field
              control={Form.Input}
              type="number"
              placeholder="Número"
              width="2"
              step="0.1"
              value={i.comprimento}
              index={index}
              name="comprimento"
              onChange={this.changeItemProps}
            />
            <Form.Field
              control={Button.Group}
              width="2"
            >
              <Button icon="minus" color="red" type="button"
                onClick={this.changeItemProps}
                index={index}
                value={i.quantidade -1}
                name="quantidade"
              />
              <Button.Or text={i.quantidade}  />
              <Button icon="plus" color="green" type="button"
                onClick={this.changeItemProps}
                index={index}
                value={i.quantidade +1}
                name="quantidade"
              />
            </Form.Field>
          </Form.Group>
        )
      })
    }else
      return "Não tem customizados"
  }
}

export default OrcamentoItensCustomizados
