import React from 'react'
import { Form, Search, Message, Button } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../../utils/actions'
import * as masks from '../../../utils/masks'
import { createTransporterHelpersToTransport, findTransporterHelpers, findTransporterHelpersByTransporter } from '../../../utils/noliAPI'


class SearchModalTransporterFilter extends React.Component {

  prepareData = (data) => data.map((element, i) => {
    return {
      ...element,
      title: "" + element.first_name + ' ' + element.last_name,
      price: masks.maskTelefone(element.phone),
      key: i,
      description: element.email,
    }
  })

  state = {
    filter: "helper",
    secondFilter: "name",
    keyword: "",
    results: [],
    loading: false,
    value: "",
    selected: [],
    error: "",
    open: false,
  }

  componentDidMount() {
    const { ajudantes } = this.props.data
    if (ajudantes.length > 0) {
      this.setState({ selected: this.prepareData(ajudantes) }) 
    }
  }

  submit = () => {
    const { selected } = this.state
    const { id, numero_ajudante } = this.props.data
    if (selected.length <= numero_ajudante) {
      this.setState({ loading: true })
      const payload = {
        transport_id: id,
        transporter_helpers: selected.length > 0 ? selected.map((item) => { return { id: item.id } }) : []
      }
      return createTransporterHelpersToTransport(payload).then(res => {
        if (res.status === 200) {
          return true
        } else {
          return false
        }
      }).catch(() => false)
    } else if ((selected.length === 0) && numero_ajudante > 0) {
      this.setState({ loading: false, error: "Selecione um ajudante" })
      return false
    } else {
      return false
    }
  }

  callAPI(payload) {
    const request = payload.by === 'transporter' ? findTransporterHelpersByTransporter(payload.params) : findTransporterHelpers(payload.params)
    return request.then(res => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          const map = this.prepareData(res.data)
          this.setState({ results: map })
        } else {
          this.setState({ results: [] })
        }
      } else {
        this.setState({ results: [] })
      }
    }).catch((error) => {
      this.setState({ results: [] })
    })
  }



  changeFilter = (e, { value }) => {
    this.setState({ filter: value, open: false, value: "" })
  }

  changeSecondFilter = (e, { value }) => this.setState({ secondFilter: value })

  handleKeywordChange = (e, { value }) => this.setState({ keyword: value })

  getTransportadorFromAPI = (type, keyword) => {
    this.setState({ loading: true })
    this.callAPI({ by: this.state.filter, params: { [type]: keyword , byLike: true }}).then(x => this.setState({ loading: false }))
  }

  handleKeyPress = (e) => {
    if (e.charCode === 13)
      this.callAPI({ by: this.state.filter, params: { [this.state.secondFilter]: this.state.keyword, byLike: true } }).then(x => this.setState({ loading: false }))
  }

  handleResultSelect = (e, { result }) => {
    const { selected } = this.state
    let exists: boolean = false
    if (selected.length > 0) {
      exists = selected.some((item) => item.id === result.id)
    }

    this.setState({ selected: !exists ? [...selected, result] : selected, value: result.name, open: false })
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ loading: true, value })
    let list = this.state.results
    list = list.map(l => {
      return {
        ...l,
        find: "name " + l.first_name + " phone " + l.phone + " email " + l.email
      }
    })

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState({ loading: false, results: [], value: '' })

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result.find)

      this.setState({
        loading: false,
        results: _.filter(list, isMatch),
        open: true
      })
    }, 300)
  }

  renderLabel = () => {
    const { filter, selected, results } = this.state
    const { numero_ajudante } = this.props.data
    if (filter === "") return "Resultado da busca"
    if (selected.length >= numero_ajudante) return `Limite de ajudantes atingido! (máx ${numero_ajudante} ${numero_ajudante > 1 ? 'ajudantes' : 'ajudante'})`
    else if (results.length > 0) return "Resultado: (" + results.length + " ajudantes encontrados)"
    else return "Resultado da busca"
  }

  openDropdown = () => {
    this.state.value === "" ? this.setState({ open: true })
      : this.setState({ open: false })
  }

  onRemoveItem = (item) => {
    const rest = this.state.selected.filter((element) => element.id !== item.id)
    this.setState({ selected: rest })
  }


  render() {
    const { loading, results, value, filter, secondFilter, selected, open, keyword } = this.state
    const active = filter.length < 1 || selected.length >= this.props.data?.numero_ajudante
    return (
      <div style={{ margin: 0, padding: "10px 15px" }} className="confirm-form-container">
        <Form onSubmit={this.submit} loading={loading}>
          {<Form.Field
            control={Form.Select}
            options={[
              { text: "Dados do ajudante", key: 0, value: 'helper' },
              { text: "Transportador", key: 1, value: 'transporter' },
            ]}
            label="Filtrar por"
            placeholder="Filtros"
            onChange={this.changeFilter}
            value={filter}
          />}
          <>
            <Form.Field
              control={Form.Select}
              options={[
                { text: "Telefone", key: 0, value: 'phone' },
                { text: "Nome", key: 1, value: 'name' },
                { text: "CPF", key: 2, value: 'cpf' },
                { text: "Email", key: 3, value: 'email' }
              ]}
              label={`Buscar ajudantes por ${filter === "helper" ? '' : 'dados do transportador'}`}
              placeholder="Filtros"
              onChange={this.changeSecondFilter}
              value={secondFilter}
            />
            <Form.Field
              control={Form.Input}
              label="Palavra-chave"
              placeholder={"Digite aqui o " + secondFilter + ` do ${filter === "helper" ? 'ajudante' : 'transportador'} que deseja buscar`}
              onChange={this.handleKeywordChange}
              value={keyword}
              action={{
                color: 'teal', icon: 'search', content: 'Buscar', type: 'button', onClick: () => this.getTransportadorFromAPI(secondFilter, keyword)
              }}
              onKeyPress={this.handleKeyPress}
              required
            />
          </>

          <Form.Field
            control={Search}
            label={this.renderLabel()}
            placeholder="Digite o nome, telefone ou outras Informações do ajudante"
            loading={loading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            results={results}
            value={value}
            disabled={active}
            noResultsMessage="NENHUM AJUDANTE ENCONTRADO"
            noResultsDescription="É possível que nenhum ajudante tenha se cadastrado até o momento."
            open={open}
            onFocus={() => this.openDropdown()}
            onBlur={() => this.setState({ open: false })}
          />
          {
            selected.length > 0 ? selected.map((item) => {
              return (
                <Message
                  icon='truck'
                  header={"Ajudante Selecionado"}
                  content={'first_name' in item && (
                    <>
                      Nome: {item.first_name + ' ' + item.last_name} <br /> 
                      Telefone: {masks.maskTelefone(item.phone)}<br />
                      <Button
                        floated='right'
                        size="mini"
                        icon="delete"
                        color='red'
                        onClick={() => { this.onRemoveItem(item) }}
                      />
                    </>
                  )}
                  size="mini"
                  negative={!('first_name' in item)}
                  positive={'first_name' in item}
                />
              )
            })
              : <Message
                icon='truck'
                header={"Selecione um ajudante no campo acima"}
                size="mini"
                negative={true}
                positive={false}
              />

          }
        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({ transportadores }) => {
  return {
    transportadores
  };
};

export default connect(mapStateToProps, actions, null, { forwardRef: true })(SearchModalTransporterFilter) 
