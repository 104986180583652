import React, {useState} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'
import {Grid, Segment, Header, Table, Button, Confirm, Form} from 'semantic-ui-react'
import _ from 'lodash'
import Ordenador from '../../components/Ordenador'
import {alterarCategoria, adicionarCategoria, alterarOrdemCategoria} from '../../utils/noliAPI'

const _categoriaDefault = {
  descricao: "",
  preco_inicial: 0,
  custo_km: 0,
  altura: 0,
  largura: 0,
  comprimento: 0
}

const CategoriaNoli = (props) => {
  if(props.title === "Painel Noli" || props.title === "Veículos Noli") props.setPageTitle('Alterar Dados de Categoria Noli')
  if(!props.transp_filters) props.fetchTransportadoresFilters()

  const [action, setAction] = useState('new')
  const [loading, setLoading] = useState(false)
  const [edited, setEdited] = useState(_categoriaDefault)
  const [selected, setSelected] = useState({})
  const [open, openConfirm] = useState(false)

  const actionOpen = (e, {name, value}) => {
    setAction(name)
    setSelected(value)
    setEdited(value)
    openConfirm(true)
  }

  const changeEdited = (e, {name, value}) => {
    setEdited({...edited, [name]: value})
  }

  const renderCategoriaTable = () => {
    return props.dados_veiculos.categoriaNoli.map(c => {
      return(
        <Table.Row key={c.id + " " + c.ordem}>
          <Table.Cell textAlign="center">{c.id}</Table.Cell>
          <Table.Cell>{c.descricao}</Table.Cell>
          <Table.Cell textAlign="center">{c.altura}</Table.Cell>
          <Table.Cell textAlign="center">{c.largura}</Table.Cell>
          <Table.Cell textAlign="center">{c.comprimento}</Table.Cell>
          <Table.Cell textAlign="center">{c.preco_inicial}</Table.Cell>
          <Table.Cell textAlign="center">{c.custo_km}</Table.Cell>
          <Table.Cell textAlign="center">
            <Button
              icon="edit"
              onClick={actionOpen}
              color="green"
              value={c}
              name="edit"
              disabled={!props.user.permissions[props.permission].update}
            />
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  const renderActionConfirm = () => {
    return(
      <Confirm
        onCancel={() => openConfirm(false)}
        confirmButton={renderConfirmButton()}
        header={renderConfirmHeader()}
        content={
          <Segment basic>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Form.Input}
                  label="Descrição"
                  name='descricao'
                  placeholder="Descrição do Item"
                  value={edited.descricao}
                  onChange={changeEdited}
                  fluid
                  width="10"
                />
                <Form.Field
                  control={Form.Input}
                  label="Preço Inicial"
                  name='preco_inicial'
                  value={edited.preco_inicial}
                  onChange={changeEdited}
                  fluid
                  width="3"
                  type="number"
                />
                <Form.Field
                  control={Form.Input}
                  label="Custo KM"
                  name='custo_km'
                  value={edited.custo_km}
                  onChange={changeEdited}
                  fluid
                  width="3"
                  type="number"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Form.Input}
                  label="Altura"
                  name='altura'
                  value={edited.altura}
                  onChange={changeEdited}
                  fluid
                  type="number"
                />
                <Form.Field
                  control={Form.Input}
                  label="Largura"
                  name='largura'
                  value={edited.largura}
                  onChange={changeEdited}
                  fluid
                  type="number"
                />
                <Form.Field
                  control={Form.Input}
                  label="Comprimento"
                  name='comprimento'
                  value={edited.comprimento}
                  onChange={changeEdited}
                  fluid
                  type="number"
                />
              </Form.Group>
            </Form>
          </Segment>
        }
        size="small"
        open={open}
      />
    )
  }

  const renderConfirmButton = () => {
    const ed = edited
    if(action === 'new')
      return <Button
        disabled={ed.descricao==="" || ed.preco_inicial===0 || ed.custo_km===0 || ed.altura===0 || ed.largura===0 || ed.comprimento===0 || loading}
        content="Criar Categoria"
        onClick={submit}
      />
    else
      return <Button
        disabled={_.isEqual(edited,selected) || loading}
        content="Alterar Categoria"
        onClick={submit}
      />
  }

  const renderConfirmHeader = () => {
    if(action === 'new') return "Criar nova Categoria de Veículos Noli"
    else return "Alterar Categoria (#"+ selected.id + ") " + selected.descricao
  }

  const submit = () => {
    setLoading(true)
    if(action === "new")
      adicionarCategoria(edited).then(res => {
        setLoading(false)
        openConfirm(false)
        setSelected({})
        setEdited({})
        props.fetchTransportadoresFilters()
      })
    else{
      let payload = {}
      if(edited.descricao !== selected.descricao) payload.descricao = edited.descricao
      if(edited.altura !== selected.altura) payload.altura = edited.altura
      if(edited.largura !== selected.largura) payload.largura = edited.largura
      if(edited.comprimento !== selected.comprimento) payload.comprimento = edited.comprimento
      if(edited.preco_inicial !== selected.preco_inicial) payload.preco_inicial = edited.preco_inicial
      if(edited.custo_km !== selected.custo_km) payload.custo_km = edited.custo_km
      alterarCategoria(payload, selected.id).then(res => {
        setLoading(false)
        openConfirm(false)
        setSelected({})
        setEdited({})
        props.fetchTransportadoresFilters()
      })
    }
  }

  const trocarOrdem = (payload) => {
    alterarOrdemCategoria(payload).then(res => {
      props.fetchTransportadoresFilters()
    })
  }

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width="12">
          <Segment>
            <Header
              as='h3'
              icon='sitemap'
              content="Veículos Noli"
              className="float-left"
            />
            <Button
              className="float-right"
              content="Nova Categoria"
              primary
              icon="add"
              labelPosition="left"
              onClick={actionOpen}
              value={_categoriaDefault}
              name="new"
              disabled={!props.user.permissions[props.permission].create}
            />
          <div style={{marginTop:47}}>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      ID
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Descrição
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Altura
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Largura
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Comprimento
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Preço Inicial
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Custo KM
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Editar
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {props.dados_veiculos
                    ? renderCategoriaTable()
                    : <Table.Row><Table.Cell colSpan="8" negative> Não encontrado</Table.Cell></Table.Row>
                  }
                </Table.Body>
              </Table>
            </div>
          </Segment>
        </Grid.Column>
        <Grid.Column width="4">
          <Segment>
            <Header
              as='h4'
              icon='ordered list'
              content="Ordenar Categorias"
            />
          <small>Arraste os itens para cima e para baixo para ordená-los</small>
            <Ordenador
              items={props.dados_veiculos ? props.dados_veiculos.categoriaNoli : []}
              onSubmit={trocarOrdem}
              disabled={!props.user.permissions[props.permission].update}
            />
          </Segment>
        </Grid.Column>
        {renderActionConfirm()}
      </Grid.Row>
    </Grid>
  )
}

const mapStateToProps = ({pageInfo}) => pageInfo

export default connect(mapStateToProps, actions)(CategoriaNoli)
