import React from 'react'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import MenuIcon from '@material-ui/icons/Menu';
import {connect} from 'react-redux'
import moment from 'moment'
import {Button, Icon} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import DashboardClock from './DashboardClock'

class TopBar extends React.Component {

    render() {
        const { classes, openMenu, pageInfo, check } = this.props;
        return (
            <AppBar position="sticky" className={classNames(classes.appBar)}>
                <Toolbar className={classes.toolbar} variant="dense">
                    <IconButton className={classes.button} onClick={openMenu} color="inherit" >
                      <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                      >{pageInfo.title}</Typography>
                      {pageInfo.title === "Painel Noli" && <DashboardClock className={classes.clock} />}
                      <AuthTimer expiration={pageInfo.auth_expiration} check={check} className={classes.timer} />
                </Toolbar>
            </AppBar>
        );
    }
}

TopBar.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    toolbar: {
        paddingRight: 24,
        display: "flex",
justifyContent: "flex-end",
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    menuButtonHidden: {
        display: "none"
    },
    title: {
        flexGrow: 1
    },
    clock: {
      flex: '0 1 auto',
      flexGrow: 1,
      textAlign: "center"
    },
    timer: {
      flex: '0 1 auto',
      flexGrow: 1,
      textAlign: "right"
    }
});

const mapStateToProps = ({ pageInfo }) => {
	return {
		pageInfo
	};
};

export default connect(mapStateToProps)(withStyles(styles)(TopBar));

class AuthTimer extends React.Component {

  state = {
    finished: false,
    exp: this.props.expiration,
  }

  interval = setInterval(() => {
    this.setState({
      exp: this.state.exp - 1
    }, ()=> {
      if(this.state.exp < 1){
        this.setState({finished: true})
      }
    })
  }, 1000);

  componentWillUnmount(){
    clearInterval(this.interval)
  }

  reAuth = () => {
    this.setState({exp: this.props.expiration, finished: false})
    this.props.check()
  }

  render() {
    const {exp, finished} = this.state

    if(exp > 0)
      return (
        <div className={this.props.className}>
          <Button
            compact
            icon
            labelPosition="right"
            secondary
            onClick={this.reAuth}
            disabled={exp > 1800} // 30 minutos
            size="mini"

          >
            <Icon name="refresh" />
            {!finished
              ? "Expirar autenticação em " + moment.utc(moment.duration(exp, 's').asMilliseconds()).format('mm:ss')
              : "Re-Autenticar"
            }
          </Button>
        </div>
      )
    else return <Redirect to="/logout" />
  }
}
