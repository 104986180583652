import React, {useState} from 'react'
import {Sidebar} from 'semantic-ui-react'
import Dashboard from './Dashboard'
import DashboardOrcamentoList from '../../components/DashboardOrcamentoList'

const DashboardStructure = (props) => {
  const [dimmed, toggleDimmed] = useState(false)
  const [list, setList] = useState([])
  const [type, setType] = useState("")

  return (
    <Sidebar.Pushable style={{overflow: 'scroll'}}> 
      <DashboardOrcamentoList
        list={list}
        visible={dimmed}
        hide={toggleDimmed}
        type={type}
        
      />
      <Sidebar.Pusher dimmed={dimmed}>
        <Dashboard
        {...props}
          openList={() => toggleDimmed(!dimmed)} 
          setList={setList}
          setType={setType} /> 
      </Sidebar.Pusher>

    </Sidebar.Pushable>
  )

}

export default DashboardStructure
