import React from 'react'
import { Accordion, Segment, Header, Icon, Label, Grid, List, Statistic, Container } from 'semantic-ui-react'
import formatDate from '../../utils/formatDate'
import RentBoxListProductItems from './RentBoxListProductItems'
import DadosPagamento from '../DadosPagamento'
import OrcamentoActions from './RentBoxActions'
import * as masks from '../../utils/masks'
import { connect } from 'react-redux'
import DirectionsMap from '../DirectionsMap'
import { DistanceMatrixService, LoadScript } from '@react-google-maps/api'
import { calculate_percent, money, valuesOfPayment } from '../../utils/helpers'

class RentBoxList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activeIndex: props.active ? parseInt(props.active) : "",
            scrolled: false,
            distance: '0 km'
        }
        this.goto = React.createRef()

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.goto.current && (prevState.activeIndex !== this.state.activeIndex || !this.state.scrolled)) {
            if (!this.state.scrolled) this.setState({ scrolled: true })
            this.goto.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            document.title = this.goto.current.id;
        }
    }

    getFilterType = () => {
        switch (this.props.filterType) {
            case 'all': return ""
            case 'open': return "abertos"
            case 'close': return "fechados"
            case 'canceled': return "cancelados"
            default: return ""
        }
    }

    renderNoResults = () => {
        return (
            <Header icon>
                <Icon name='file alternate outline' />
                Não existem orçamentos {this.getFilterType()} para este cliente
            </Header>
        )
    }

    getValuesOfOnlyTransporter = (orcamento) => {
        const { valor, payments, taxa_noli } = orcamento
        if (valor) {
            return calculate_percent(Number(valor), Number(taxa_noli))
        }
        return valuesOfPayment(payments, null, taxa_noli).total_only_transport
    }

    getTotal = (orcamento) => {
        const { payments, taxa_noli } = orcamento
        const result = valuesOfPayment(payments, null, taxa_noli)
        return money(result.total_value)
    }

    getDistance = (distance) => distance ? Number(distance).toFixed(2) : distance

    getDateAndTimeTransport = (date, timeStart, timeEnd) => {
        const transportDate = formatDate.dateBR(date, true)
        const transportTimeStart = formatDate.getTime(timeStart, true)
        const transportDateEnd = formatDate.getTime(timeEnd, true)
        return transportDate + ' ' + transportTimeStart + ' - ' + transportDateEnd
    }

    handleAccordionClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({ activeIndex: newIndex })
    }

    RenderContainerTotalValue = ({ orcamento }) => (
        <Container textAlign="center">
            <Statistic size='small' >
                <Statistic.Value>{this.getTotal(orcamento)}</Statistic.Value>
            </Statistic>
        </Container>
    )

    renderRentBoxPackage = (data) => {
        return data.map((element) => (
            <List.Item key={element.id.toString()}>
                <List relaxed>
                    <List.Header>
                        <Label color="blue" ribbon content={"Pacote escolhido"} />
                    </List.Header>
                    <List.Item >
                        <List.List>
                            {element.title && <p><List.Icon name="boxes" />Pacote: {element.title}</p>}
                            {element.sales_price && <p><List.Icon name="boxes" />Valor: {element.sales_price}</p>}
                            {element.box_qtd && <p><List.Icon name="boxes" />Qtd caixas: {element.box_qtd}</p>}
                            {element.rent_days && <p><List.Icon name="boxes" />Qtd. dias: {element.rent_days}</p>}
                            {element.includes && element.includes.data  && element.includes.data.length > 0 && element.includes.data.map((item, index) => {
                                return (
                                    <p><List.Icon key={index} name="boxes" />Incluso: {item.description}</p>
                                )
                            })} 

                        </List.List> 
                    </List.Item>
                </List>
            </List.Item>

        ))
    }


    /*RenderContainerDistance = ({ origin, destination }) => {
        return (
            <Container textAlign="center" >
                <Statistic size='small'>
                    <Statistic.Value>{this.state.distance}</Statistic.Value>
                    <DistanceMatrixService
                        options={{
                            origins: [{ lng: -23.5265504, lat: -46.5102185 }],
                            destinations: [{ lat: -23.5781037, lng: -46.6404042 }],
                            travelMode: "DRIVING",

                        }}
                        callback={(response) => {
                            if (response) {
                                const { distance } = response.rows[0].elements[0]
                                this.setState({ distance: distance ? distance.text : '0 km' })
                            }
                        }}
                    />
                </Statistic>
            </Container> 
        )
    }*/

        RenderContainerDistance = ({ origin, destination }) => {
        return (
            <Container textAlign="center" >
                <Statistic size='mini'>
                    <Statistic.Value>Entrega: {money(Number(origin.value))} - Retirada: {money(Number(destination.value))}</Statistic.Value>
                    <Statistic.Value >Total: {money(Number(origin.value + destination.value))}</Statistic.Value>
                </Statistic>
            </Container> 
        )
    }

    RenderAddresses = (e) => (
        <List.Item key={e.id}>
            <List.Icon name="map marker alternate" />
            <List.Content>
                <List.Header>
                    {e.rua}, {e.numero} - {e.bairro} - CEP {e.cep} - {e.cidade} / {e.estado}
                </List.Header>
                <List.Description>
                    {e.complemento !== "" && "Complemento " + e.complemento + " - "}
                </List.Description>
            </List.Content>
        </List.Item>

    )

    renderResults = () => {

        return (
            <LoadScript
                id={"script-loader-directions"}
                googleMapsApiKey="AIzaSyA1Ro7FjdmhtqE9KG65JeJQSPIsBlQnHrw"
            >
                <Accordion fluid styled>
                    {this.props.orcamentos.map(orcamento => {
                        const { activeIndex } = this.state

                        return (
                            <div key={orcamento.id} id={orcamento.ordem_servico} ref={orcamento.id === activeIndex && this.goto}>
                                <Accordion.Title
                                    active={activeIndex === orcamento.id}
                                    index={orcamento.id}
                                    onClick={this.handleAccordionClick}
                                >
                                    <Icon name='dropdown' />
                                    {this.renderStatusLabel(orcamento)}
                                    {/*this.renderVisualizadoIcon(orcamento.painel_visualizado)*/}
                                    <Label color="black" size='large' horizontal>
                                        Ordem
                                        <Label.Detail>{orcamento.ordem_servico}</Label.Detail>
                                    </Label>
                                    <Label color="green" size='large' horizontal>
                                        <Icon name="clock outline" />
                                        ENTREGA - {this.getDateAndTimeTransport(orcamento.details.schedule.delivery.start_date, orcamento.details.schedule.delivery.start_time, orcamento.details.schedule.delivery.end_time)}
                                    </Label>
                                    {this.renderTransportadorLabel(orcamento.details.transport.delivery.transporter, true)}
                                    <Label color="red" size='large' horizontal>
                                        <Icon name="clock outline" />
                                        RETIRADA - {this.getDateAndTimeTransport(orcamento.details.schedule.fallback.end_date, orcamento.details.schedule.fallback.start_time, orcamento.details.schedule.fallback.end_time)}
                                    </Label>
                                    {this.renderTransportadorLabel(orcamento.details.transport.fallback.transporter, false)}
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === orcamento.id}>
                                    <Grid>
                                         <Grid.Row>
                                            <Grid.Column mobile="16" tablet="8" computer="8">
                                                <Segment raised color="blue" >
                                                    <Label ribbon color="blue">Frete total</Label>
                                                    <Container textAlign="center">
                                                        <this.RenderContainerDistance origin={orcamento.details.transport.delivery.freight} destination={orcamento.details.transport.fallback.freight} /> 
                                                    </Container>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column mobile="16" tablet="8" computer="8">
                                                <Segment raised color="orange">
                                                    <Label ribbon color="orange">Valor total</Label>
                                                    <this.RenderContainerTotalValue orcamento={orcamento} />
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row> 
                                        <Grid.Row color="violet">
                                            <Grid.Column color="violet" computer={orcamento.details.transport.details ? 10 : 16} mobile="16">
                                                <List inverted relaxed>
                                                    <List.Item>
                                                        <List.Icon name="map outline" />
                                                        <List.Content>
                                                            <List.Header>ENDEREÇOS DO TRANSPORTE DE CAIXAS</List.Header>
                                                            <List.List>
                                                                <br />
                                                                <List.Header>ENTREGA</List.Header>
                                                                {this.RenderAddresses(orcamento.details.transport.delivery.address)}
                                                                <br />
                                                                <List.Header>RETIRADA</List.Header>
                                                                {this.RenderAddresses(orcamento.details.transport.fallback.address)}
                                                            </List.List>
                                                        </List.Content>
                                                    </List.Item>
                                                    <List.Item>
                                                        {/* <DirectionsMap adresses={[orcamento.details.transport.delivery.address, orcamento.details.transport.fallback.address]} orcamento={orcamento} /> */}
                                                    </List.Item>
                                                </List>
                                            </Grid.Column>
                                            {orcamento.transportador &&
                                                <Grid.Column computer="6" mobile="16">
                                                    <Segment color="purple" inverted basic>
                                                        <List inverted relaxed divided>
                                                            <List.Item
                                                                icon="truck"
                                                                header="TRANSPORTADOR SELECIONADO"
                                                            />
                                                            <List.Item disabled>
                                                                <List.List>
                                                                    <List.Item icon="user" header={orcamento.transportador.nome} />
                                                                    <List.Item icon="phone square" header={"TEL: " + masks.maskTelefone(orcamento.transportador.telefone)} />
                                                                    <List.Item icon="drivers license" header={"CPF: " + masks.maskCpf(orcamento.transportador.cpf)} />
                                                                </List.List>
                                                            </List.Item>
                                                        </List>
                                                    </Segment>
                                                </Grid.Column>
                                            }
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column mobile="16" tablet="8" computer="10">
                                                {this.renderRentBoxPackage(orcamento.packages)}
                                                <RentBoxListProductItems products={orcamento.products} />
                                            </Grid.Column>
                                            <Grid.Column mobile="16" tablet="8" computer="6">
                                                <DadosPagamento
                                                    services={null}
                                                    dataOfTransporter={() => this.getValuesOfOnlyTransporter(orcamento)}
                                                    data={orcamento.payments}
                                                    refresher={this.props.refresher}
                                                    clienteInfo={{ 
                                                        cliente_id: orcamento.cliente_id,
                                                        ordem_servico: orcamento.ordem_servico
                                                    }}
                                                    transport_id={orcamento.id}
                                                    complementar={orcamento.info_complementar}
                                                    canChange={!!this.props.user.permissions[this.props.permission].update}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        {!!this.props.user.permissions[this.props.permission].update &&
                                            <>
                                                <Grid.Row>
                                                    <Grid.Column color="violet" mobile="16" tablet="16" computer="16">
                                                        <Icon name="cogs" /> GERENCIAR ENTREGA DAS CAIXAS
                                                        <Segment basic>
                                                            <OrcamentoActions
                                                                is_delivery={true}
                                                                data={orcamento}
                                                                refresher={this.props.refresher}  
                                                                sendMessage={this.props.sendMessage}
                                                            />
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column color="black" mobile="16" tablet="16" computer="16">
                                                        <Icon name="cogs" /> GERENCIAR RETIRADA DAS CAIXAS
                                                        <Segment basic>
                                                            <OrcamentoActions
                                                                is_delivery={false}
                                                                data={orcamento}
                                                                refresher={this.props.refresher}
                                                                sendMessage={this.props.sendMessage}
                                                            />
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </>
                                        }
                                    </Grid>
                                </Accordion.Content>
                            </div>)
                    })
                    }
                </Accordion>
            </LoadScript>
        )
    }



    renderStatusLabel = (data) => {
        if (data.transporte_cancelado === 1)
            return <Label color="red" icon="cancel" content="Cancelado" size='tiny' basic className="float-right orcamentos-status-label" />
        if (data.transporte_iniciado === 0)
            return <Label color="teal" icon="play circle" content="Para iniciar" size='tiny' basic className="float-right orcamentos-status-label" />
        if (data.transporte_iniciado === 1 && data.transporte_finalizado === 0)
            return <Label color="brown" icon="truck" content="Iniciado" size='tiny' basic className="float-right orcamentos-status-label" />
        if (data.transporte_iniciado === 1 && data.transporte_finalizado === 1)
            return <Label color="green" icon="flag checkered" content="Finalizado" size='tiny' className="float-right orcamentos-status-label" />
    }

    renderTransportadorLabel = (transportador, isDelivery: boolean) => {
        if (transportador) {
            return (
                <Label color={isDelivery ?  'green' : 'red'} size='large' className="transportador-label" horizontal>
                    <Icon name='truck' />
                    {transportador.nome} -  {isDelivery ?  `(ENTREGA)` : `(RETIRADA)`}
                </Label>
            )
        } else {
            return (
                <Label color={isDelivery ?  'green' : 'red'} basic size='medium' horizontal>
                    <Icon name='truck' />
                    Não selecionado
                </Label>
            )
        }
    }


    render() {
        const { loading, orcamentos } = this.props
        return (
            <Segment basic loading={loading} placeholder={!!!orcamentos}>
                {!!!orcamentos ? this.renderNoResults() : this.renderResults()}
            </Segment>
        )
    }
}

const mapStateToProps = ({pageInfo,  user }) => { return {pageInfo,  user } }

export default connect(mapStateToProps)(RentBoxList)
