import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyA3WHBDXekMvze0z2Fi2GlGddnFJrws4RQ");

export const fromLatLng = (lat,lng) => {
  return Geocode.fromLatLng(lat, lng).then(
    response => {
      const address = response.results[0].formatted_address;
      return address
    },
    error => {

      return error
    }
  )
}
