import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'

import DashboardStructure from './dashboard/DashboardStructure'
import DashboardBoxStructure from './dashboard/box/DashboardBoxStructure'
import Clientes from './clientes/Clientes'
import Orcamentos from './orcamentos/Orcamentos'
import OrcamentosCliente from './orcamentos/OrcamentosCliente'
import Box from './box/Box'
import BoxUsers from './box/BoxUsers'
import Transportadores from './transportadores/Transportadores'
import TransporterHelpers from './transportadores/helpers'
import CadastroTransportador from './cadastro/CadastroTransportador'
import AlterarOrcamento from './cadastro/AlterarOrcamento'
import Logout from './logout/Logout'
import Itens from './cadastro/Itens'
import ItensForSale from './cadastro/forSale'
import Taxas from './cadastro/Taxas'
import BoxStockControl from './cadastro/servicos/box/stock/control'
import ProtecaoItens from './cadastro/servicos/ProtecaoItens'
import MontagemDesmontagem from './cadastro/servicos/MontagemDesmontagem'
import PartnerServices from './cadastro/servicos/partners'
import BoxPackages from './cadastro/servicos/box/Packages'
import BoxPeriodsPackages from './cadastro/servicos/box/Periods'
import TransportBlockScheduleDates from './cadastro/servicos/mooving/BlockScheduleDates'
import Funcionarios from './cadastro/Funcionarios'
import TiposVeiculos from './dados-veiculos/Tipos'
import MarcasModelos from './dados-veiculos/MarcasModelos'
import CategoriaNoli from './dados-veiculos/CategoriaNoli'
import AlterarSenha from './configuracoes/AlterarSenha'
import DadosPessoais from './configuracoes/DadosPessoais'
import Permissoes from './cadastro/Permissoes'
import Cupons from './cadastro/Cupons'
import Page401 from './error/401'

const Routes = (props) => {

  if(Object.keys(props.user.permissions).length === 0) return (<></>)
  return (
    <Switch>
      <Route 
      user={props.user} 
      path={"/"}
      exact={true} 
      //render={ () => (!props.user?.permissions?.modulo_orcamentos?.read  && props.user?.permissions?.modulo_caixas?.read ) ? <DashboardBoxStructure/> :  <DashboardStructure {...props}/> } />
      render={ () => props.user?.permissions?.modulo_orcamentos?.read ?
        <DashboardStructure {...props}/> :
           props.user?.permissions?.modulo_caixas?.read ?
           <DashboardBoxStructure/>
             : props.user?.permissions?.modulo_transportadores?.read ?
               <Transportadores {...props}/> :
               <DashboardStructure {...props}/>
      }/>
      <PermissionProtectedRoute
        permission="modulo_clientes"
        user={props.user}
        path="/clientes"
        component={Clientes}
      />
      <PermissionProtectedRoute
        permission="modulo_orcamentos"
        user={props.user}
        path="/orcamentos"
        component={Orcamentos}
      />
      <PermissionProtectedRoute
        permission="modulo_orcamentos"
        user={props.user}
        path="/cliente/:cid/orcamento/:ordem"
        component={OrcamentosCliente}
      />
      <PermissionProtectedRoute
        permission="modulo_caixas"
        exact={true}
        user={props.user}
       // path={(!props.user?.permissions?.modulo_orcamentos?.id  && props.user?.permissions?.modulo_caixas?.id ) ? "/" : "/dashboard"}
        path="/box/dashboard"
        component={DashboardBoxStructure}
      />
      <PermissionProtectedRoute
        permission="modulo_caixas"
        user={props.user}
        path="/box"
        component={Box}
      />
  
      <PermissionProtectedRoute
        permission="modulo_caixas"
        user={props.user}
        path="/user/:cid/box/:ordem"
        component={BoxUsers}
      />
  
      <PermissionProtectedRoute
        permission="modulo_transportadores"
        user={props.user}
        path="/transportadores"
        component={Transportadores}
      />
      <PermissionProtectedRoute
        permission="modulo_transportadores"
        user={props.user}
        path="/cadastro/transportador/:id"
        component={CadastroTransportador}
      />
      <PermissionProtectedRoute
        permission="modulo_transportadores"
        user={props.user}
        path="/cadastro/transportador/"
        component={CadastroTransportador} />
            <PermissionProtectedRoute
      permission="modulo_transportadores"
      user={props.user}
      path="/helpers"
      component={TransporterHelpers}
    />
      <PermissionProtectedRoute
        permission="modulo_orcamentos"
        user={props.user}
        path="/cadastro/orcamento/:id?"
        component={AlterarOrcamento}
      />
  
  
      <PermissionProtectedRoute
        permission="modulo_taxas"
        user={props.user}
        path="/cadastro/taxa"
        component={Taxas}
      />
      <PermissionProtectedRoute
        permission="modulo_servicos"
        user={props.user}
        path="/cadastro/servicos/mudancas/datas/bloqueadas"
        component={TransportBlockScheduleDates}
      />
      <PermissionProtectedRoute
        permission="modulo_servicos"
        user={props.user}
        path="/cadastro/servicos/box/stock/control"
        component={BoxStockControl}
      />
      <PermissionProtectedRoute
        permission="modulo_servicos"
        user={props.user}
        path="/cadastro/servicos/protecaoItens"
        component={ProtecaoItens}
      />
      <PermissionProtectedRoute
        permission="modulo_servicos"
        user={props.user}
        path="/cadastro/servicos/montagemDesmontagem"
        component={MontagemDesmontagem}
      />
      <PermissionProtectedRoute
        permission="modulo_servicos"
        user={props.user}
        path="/cadastro/servicos/parceiros"
        component={PartnerServices}
      />
      <PermissionProtectedRoute
        permission="modulo_servicos"
        user={props.user}
        path="/cadastro/servicos/box/packages"
        component={BoxPackages}
      />
      <PermissionProtectedRoute
        permission="modulo_servicos"
        user={props.user}
        path="/cadastro/servicos/box/periods"
        component={BoxPeriodsPackages}
      />
      <PermissionProtectedRoute
        permission="modulo_itens"
        user={props.user}
        path="/cadastro/itens"
        component={Itens}
      />
      <PermissionProtectedRoute
        permission="modulo_itens"
        user={props.user}
        path="/cadastro/productsforsale"
        component={ItensForSale}
      />
      <PermissionProtectedRoute
        permission="modulo_funcionarios"
        user={props.user}
        path="/cadastro/funcionario"
        component={Funcionarios}
      />
      <PermissionProtectedRoute
        permission="modulo_permissoes"
        user={props.user}
        path="/cadastro/permissoes"
        component={Permissoes} />
      <PermissionProtectedRoute
        permission="modulo_cupons"
        user={props.user}
        path="/cadastro/cupons"
        component={Cupons}
      />
      <PermissionProtectedRoute
        permission="modulo_veiculos"
        user={props.user}
        path="/dados_veiculos/tipos"
        component={TiposVeiculos} />
      <PermissionProtectedRoute
        permission="modulo_veiculos"
        user={props.user}
        path="/dados_veiculos/marcas"
        component={MarcasModelos}
      />
      <PermissionProtectedRoute
        permission="modulo_veiculos"
        user={props.user}
        path="/dados_veiculos/noli"
        component={CategoriaNoli}
      />
      <Route path="/configuracoes/novasenha" render={() => <AlterarSenha check={props.check} />} />
      <Route path="/configuracoes/dadospessoais" component={DadosPessoais} />
      <Route path="/logout" render={() => <Logout logout={props.logout} />} />
      <Route path="/401" component={Page401} />
    </Switch>
  )
}

export default Routes

const PermissionProtectedRoute = ({ component: C, ...rest }) => {

  const permissionsLoaded = rest.user.permissions.permissionsLoaded
  const [loading, setLoading] = React.useState(!permissionsLoaded)

  React.useEffect(() => {
    if (rest.user.permissionsLoaded) setLoading(false)
  }, [rest.user.permissionsLoaded])

  if (loading) return <Route {...rest} render={() => <Dimmer active={loading}>
    <Loader active={loading} size="massive" content="Verificando Permissão..." />
  </Dimmer>} />
  else if (rest.user.permissions[rest.permission] && rest.user.permissions[rest.permission].read)
    return <Route {...rest} render={() => <C {...rest} />} />
  else return <Route {...rest} render={() => <Redirect to="/401" />} />
}
