import React from 'react'
import { Grid, Segment, Button, Statistic, Icon, Header, List, Label } from 'semantic-ui-react'
import DashboardMap from '../../components/DashboardMap'
import * as noliAPI from '../../utils/noliAPI'
import formatDate from '../../utils/formatDate'
import moment from 'moment'
import RefresherButton from '../../components/RefresherButton'
import DashboardSearch from '../../components/DashboardSearch'
import * as masks from '../../utils/masks'
import * as geocode from '../../utils/geocodeInfo'


class Dashboard extends React.Component {

  state = {
    loading: false,
    data: [],
    markers: [],
    now: formatDate.getTimestamp(new Date()),
    selectedMarker: null,
    triggerOverlay: {}
  }

  today = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').unix()

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    if (this.props.user?.permissions?.modulo_orcamentos?.read) {
      this.setState({ loading: true })
      noliAPI.transportesAbertos().then(res => {
        if (res.status === 200) {
          this.prepareData(res.data.data)
        } else {
          this.setState({ loading: false })
        }
      })
    }
  }

  prepareData = (data) => {
    const { selectedMarker } = this.state
    const prepared = data.map(m => {
      const ultimo = m.enderecos[m.enderecos.length - 1]
      const ultimo_end = ultimo.rua + ", " + ultimo.numero + " - " + ultimo.bairro + " - " + ultimo.cidade + "/" + ultimo.estado
      let find = m.ordem_servico + " " + m.cliente.nome + " " + ultimo_end + " " + m.cliente.telefone
      find += (m.transportador) && m.transportador.nome + " " + m.transportador.telefone
      find += " Último endereço " + ultimo_end
      return {
        ...m,
        atrasado: m.data_transporte < this.state.now && m.transporte_iniciado === 0,
        hoje: m.data_transporte > this.today && m.data_transporte < this.today + 86400,
        amanha: m.data_transporte > this.today + 86400 && m.data_transporte < this.today + 86400 * 2,
        title: m.ordem_servico + " " + m.cliente.nome,
        price: masks.maskTelefone(m.cliente.telefone),
        description: ultimo_end,
        find: find
      }
    })

    let triggerOverlay = {}
    let markers = data.map((m) => {
      if (m.ordem_servico === this.state.triggerOverlay.ordem) {
        triggerOverlay = {
          ordem: m.ordem_servico,
          lat: (m.rota_transportador[0]) ? m.rota_transportador[0].latitude : 0,
          lng: (m.rota_transportador[0]) ? m.rota_transportador[0].longitude : 0
        }
      }
      return (m.rota_transportador[0] !== undefined && m.transporte_iniciado === 1) && {
        rota: m.rota_transportador[0],
        lat: m.rota_transportador[0].latitude,
        lng: m.rota_transportador[0].longitude,
        ordem: m.ordem_servico,
        transportador: m.transportador ? m.transportador.nome : "Erro",
        key: m.rota_transportador[0].id,
        agendado: formatDate.getDateAndTimeUnixTimestamp(m.data_transporte)
      }
    })

    this.setState({
      data: prepared,
      markers: markers,
      loading: false,
      triggerOverlay: triggerOverlay,
    })

    const selected = selectedMarker !== null && markers.filter(m => m).filter(m => m.ordem === selectedMarker.ordem_servico).length === 1 ? markers.filter(m => m).filter(m => m.ordem === selectedMarker.ordem_servico) : null
    if (selected !== null) {
      geocode.fromLatLng(selected[0].lat, selected[0].lng).then(local => {
        this.handleOrcamentoSelect(selected[0], local)
      })
    }

  }

  handleOrcamentoSelect = (marker, local) => {
    let triggerOverlay = {}

    const details = this.state.data.filter(d => {
      return d.ordem_servico === marker.ordem
    }).map(o => {
      const status_enderecos = o.status.map(s => s.endereco_id)
      triggerOverlay = {
        ordem: o.ordem_servico,
        lat: (o.rota_transportador[0]) ? o.rota_transportador[0].latitude : 0,
        lng: (o.rota_transportador[0]) ? o.rota_transportador[0].longitude : 0
      }
      return {
        ...o,
        local: local,
        end_status: o.enderecos.map(e => {
          const index = status_enderecos.indexOf(e.id)
          return {
            text: e.rua + ", " + e.numero + " - " + e.bairro + " Compl. " + e.complemento + " - " + e.cidade + "/" + e.estado,
            status: (index === -1) ? "futuro" : (o.status[index].chegou === 1) ? "finalizado" : "futuro",
            key: e.id
          }
        })
      }
    })
    if (details.length === 1) this.setState({ selectedMarker: details[0], triggerOverlay: triggerOverlay })

  }

  handleTriggerOverlay = (result) => {
    this.setState({ triggerOverlay: result })
  }

  openList = (list, type) => {
    this.props.openList()
    this.props.setList(list)
    this.props.setType(type)
  }

  showActiveDetails = (selected, andamento) => {
    if (selected === null) return false
    else return andamento.filter(a => a.ordem_servico === selected.ordem_servico).length === 1
  }

  render() {
    const { loading, data, markers, selectedMarker, triggerOverlay } = this.state
    const andamento = data.filter(d => d.transporte_iniciado === 1)
    const atrasados = data.filter(d => d.atrasado)
    const hoje = data.filter(d => d.hoje)
    const amanha = data.filter(d => d.amanha)

    return (<>

      <Segment basic>
        <Grid className="dashboard-container">
          <Grid.Row centered>
            <Grid.Column stretched computer="3" mobile="16" tablet="8">
              <Segment color="green" as={Button} onClick={() => this.openList(andamento, "Em Andamento")} textAlign="center">
                <Statistic
                  label="em andamento"
                  color="grey"
                  inverted
                  value={andamento.length}
                  size="small" />
              </Segment>
            </Grid.Column>
            <Grid.Column stretched computer="3" mobile="16" tablet="8">
              <Segment color="blue" as={Button} onClick={() => this.openList(hoje, "Agendados para hoje")} textAlign="center">
                <Statistic
                  label="agendados para hoje"
                  color="grey"
                  inverted
                  value={hoje.length}
                  size="small" />
              </Segment>
            </Grid.Column>

            <Grid.Column stretched computer="3" mobile="16" tablet="8">
              <Segment color="orange" as={Button} onClick={() => this.openList(amanha, "Agendados para amanhã")} textAlign="center">
                <Statistic
                  label="agendados para amanhã"
                  color="grey"
                  inverted
                  value={amanha.length}
                  size="small" />
              </Segment>
            </Grid.Column>

            <Grid.Column stretched computer="3" mobile="16" tablet="8">
              <Segment color="teal" as={Button} onClick={() => this.openList(data, "Agendados")} textAlign="center">
                <Statistic
                  label="agendados total"
                  color="grey"
                  inverted
                  value={data.length}
                  size="small" />
              </Segment>
            </Grid.Column>
            <Grid.Column stretched computer="3" mobile="16" tablet="8">
              <Segment color="red" as={Button} onClick={() => this.openList(atrasados, "Atrasados")} textAlign="center">
                <Statistic
                  label="atrasados"
                  color="grey"
                  inverted
                  value={atrasados.length}
                  size="small" />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ padding: '0' }}>
            <Grid.Column computer="12" tablet="12" mobile="16" className="dashboard-map">
              <DashboardMap markers={markers}
                onMarkerClick={this.handleOrcamentoSelect}
                triggerOverlay={triggerOverlay}
              />
            </Grid.Column>
            <Grid.Column computer="4" tablet="4" mobile="16" stretched >
              <DashboardSearch
                transportes={andamento}
                onSelect={this.handleTriggerOverlay}
              />
              <Segment style={{ minHeight: "100%" }} placeholder={!this.showActiveDetails(selectedMarker, andamento)} loading={loading}>
                {!this.showActiveDetails(selectedMarker, andamento) ? (<>
                  <Header icon>
                    <Icon name='map marker alternate' color="red" />
                    Clique em um Marcador no mapa para visualizar os detalhes do transporte
                  </Header>
                  {this.props.user?.permissions?.modulo_orcamentos?.read &&
                    <RefresherButton onFinish={this.fetchData} interval="180" />
                  }
                </>) : <OrcamentoDetails {...selectedMarker} onFinish={this.fetchData} />}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>)
  }
}

export default Dashboard

const OrcamentoDetails = (props) => {

  const { data_transporte, horario_iniciado, ordem_servico, cliente, transportador, local, end_status, status_endereco_orcamento, rota_transportador } = props
  return (<>
    <List relaxed>
      <List.Item disabled icon="info circle" header={"#" + ordem_servico} />
      <List.Item>
        <List size="tiny" relaxed>
          <List.Item icon="calendar alternate outline" header="Data agendada" content={formatDate.getDateAndTimeUnixTimestamp(data_transporte)} />
          <List.Item icon="calendar check outline" header="Transporte iniciado" content={formatDate.getDateAndTimeUnixTimestamp(horario_iniciado)} />
          <List.Item icon="user circle" header={"Cliente: " + cliente.nome} content={"Tel: " + masks.maskTelefone(cliente.telefone)} />
          <List.Item icon="drivers license" header={"Transportador: " + transportador.nome} content={"Tel: " + masks.maskTelefone(transportador.telefone)} />
          <List.Item icon="shipping fast" active header="Posição atual" content={local} description={"[" + formatDate.dateTimeServer(rota_transportador[0].created_at) + "]"} />
          {status_endereco_orcamento && <List.Item icon="chat" header="STATUS ATUAL" content={status_endereco_orcamento && status_endereco_orcamento.mensagem_cliente} description={"[" + formatDate.dateTimeServer(status_endereco_orcamento.created_at) + "]"} />}
        </List>
      </List.Item>
      <List.Item icon="map" header="ENDEREÇOS" disabled />
      <List.Item content={<EnderecosList enderecos={end_status} />} />
    </List>
    <div align="center"><RefresherButton onFinish={props.onFinish} interval="180" /></div>
  </>)
}

const EnderecosList = (props) => {
  const colors = { finalizado: "green", futuro: "grey" }
  return (
    <List.List>
      {props.enderecos.map(e => {
        return (
          <List.Item icon="map marker alternate" key={e.key}
            content={
              <Label color={colors[e.status]} content={e.text} size="mini" />
            }
          />)
      })
      }
    </List.List>
  )
}
