import React from 'react'
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {Item, Button} from 'semantic-ui-react'
import _ from 'lodash'

const SortableItem = SortableElement((props) =>{
  return(<Item className="draggable-item">
    <Item.Content>
      <Item.Description>{props.value.ordem} - { props.value.titulo ? props.value.titulo : props.value.descricao}</Item.Description>
    </Item.Content>
  </Item>)
}
);

const SortableList = SortableContainer(({items}) => {
  return (
    <Item.Group divided unstackable>
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} style={{zIndex:10000}} index={index} value={value} />
      ))}
    </Item.Group>
  )
})

const Ordenador = (props) => {
  const [items, setItems] = React.useState([{descricao: "Carregando...", id:0}]);

  React.useEffect(() => {
    setItems(props.items);
  }, [props]);

  const onSortEnd = ({oldIndex, newIndex}) => {
    setItems(array_move(items, oldIndex, newIndex));
  };

  const array_move = (array, old_index, new_index) => {
    let arr = [...array]
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const submit = () => {
    let payload = {
      ordem: items.map((i,index) => {
        return {
          id: i.id,
          ordem: index+1
        }
      })
    }
    props.onSubmit(payload)
  }

  if(items.length > 0)
    return (
      <>
        <SortableList
          items={items}
          onSortEnd={onSortEnd}
          helperClass="draggable-helper"
        />
        {!_.isEqual(items, props.items) &&
          <Button
            content="Salvar nova ordem"
            fluid
            color="orange"
            onClick={submit}
            disabled={props.disabled}
          />
        }
      </>
    )
  else return " Carregando..."
}

export default Ordenador
