import axios, { AxiosResponse } from 'axios';
const api = require('../constants')
export const api_url = api.url.prod_v2
//const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJmdW5jaW9uYXJpb0Bub2xpZnJldGVzLmNvbS5iciIsImlhdCI6MTU2NjQwOTc4N30.MUs_9LCkOkE3jx_2j-RJHKXKCoz-Eopatreu96mNDvg"
//const token_teste = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwiZW1haWwiOiJkZXZub2xpQG5vbGlmcmV0ZXMuY29tLmJyIiwic2VuaGEiOiIkMmIkMTAkL1BhMTBiRno4QjJQWVlGOVpqQlNLZTVzak9nd252QzYxd1k4VngzQi51cW0ucnVhQ29jdnUiLCJpYXQiOjE1NzI3MTc1MjJ9.I_2-WW6Ye__GvitwBf7Jm7LfsEXiqODCis87B84YqRo"

let headers = {
  'accept': 'application/json',
  'content-type': 'application/json',
  //'noli-auth-token': localStorage.getItem('@painel-noli/token'), 
  //'noli-auth-token': token_teste, 
}

export const realizarLogin = (email, senha) =>
  axios.post(api_url + "funcionario/autenticar", { email: email, senha: senha }, { headers: headers })
    .then(res => res)
    .catch(error => error)

export const validarLogin = (token) => {
  return axios.get(api_url + "funcionario/validarlogin/", { headers: headers })
    .then(res => res)
    .catch(error => error)
}

export const setDefaultToken = (token) => {
  axios.defaults.headers['noli-auth-token'] = token
  localStorage.setItem('@painel-noli/token', token)
}

export const buscarClientesOrcamento = (all = true, open = true) => {
  if (all) {
    return axios.get(api_url + "funcionario/clientesorcamentos", { headers: headers })
      .then(response => response)
      .catch(error => error)
  } else {
    return axios.get(api_url + "funcionario/clientesorcamentos/open/" + open.toString(), { headers: headers })
      .then(response => response)
      .catch(error => error)
  }
}

export const buscarClientesOrcamentosCancelados = () => {
  return axios.get(api_url + "funcionario/clientesorcamentos/canceled", { headers: headers })
    .then(response => response)
    .catch(error => error)
}

export const buscarCliente = (type, value) =>
  axios.get(api_url + "funcionario/cadastrousuario/" + type + "/" + value, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarOrcamentosDoCliente = (idCliente) =>
  axios.get(api_url + "funcionario/transportescliente/" + idCliente, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const buscarOrcamentosAbertosDoCliente = (idCliente, open = true) =>
  axios.get(api_url + "funcionario/transportescliente/open/" + open + "/" + idCliente, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const buscarOrcamentosCanceladosDoCliente = (idCliente) =>
  axios.get(api_url + "funcionario/transportescliente/canceled/" + idCliente, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const orcamentoVisualizado = (payload) =>
  axios.post(api_url + "funcionario/orcamentovisualizado", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const atualizarBoleto = (id_pagamento, payload) =>
  axios.put(api_url + "funcionario/statusboleto/" + id_pagamento, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const refreshPix = (params) =>
  axios.get(api_url + "payments/pix/refresh", { headers: headers, params: params })
    .then(response => response)
    .catch(error => error)

export const atualizarStatusOrcamento = (payload, idOrcamento) =>
  axios.put(api_url + "funcionario/statusorcamento/" + idOrcamento, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const atualizarDataTransporte = (orcamentoId, payload) =>
  axios.put(api_url + "funcionario/atualizardatatransporte/" + orcamentoId, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarTransportadores = (page = 1) =>
  axios.get(api_url + "funcionario/transportadores/cadastro/" + page, { headers: headers })
    .then(response => response)
    .catch(error => error)

/* se todos = true, buscar todos os transportadores com CAMPO parecido */
export const buscarTransportadoresCampoValor = (tipo, valor, todos = true, page = 1) =>
  axios.get(api_url + "funcionario/transportadores/cadastro/" + page + "/" + todos + "/" + tipo + "/" + valor, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarTransportadoresConcordam = id =>
  axios.get(api_url + "funcionario/aceitetransportador/" + id, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const escolherTransportadorOrcamento = (transportadorId, orcamentoId, dataTransporte, ordemServico) =>
  axios.post(api_url + "funcionario/transportadororcamento",
    {
      transportador_id: transportadorId,
      orcamento_id: orcamentoId,
      data_transporte: dataTransporte,
      ordem_servico: ordemServico
    }, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarMarcasVeiculos = () => // Apenas marcas com veículos cadastrados
  axios.get(api_url + "funcionario/marcaveiculo", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarMarcas = () => // Todas as marcas cadastradas
  axios.get(api_url + "funcionario/marcaveiculo/true", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarTiposVeiculos = () =>
  axios.get(api_url + "funcionario/tipoveiculo", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarTipoCarrocerias = (idTipo) =>
  axios.get(api_url + "funcionario/tipocarroceriaveiculo/true/" + idTipo, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarTipoPorCarrocerias = (idCarroceria) =>
  axios.get(api_url + "funcionario/tipocarroceriaveiculo/false/" + idCarroceria, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarCarrocerias = () =>
  axios.get(api_url + "funcionario/carroceriaveiculo/", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarModelosVeiculos = (idMarca) =>
  axios.get(api_url + "funcionario/modeloveiculo/" + idMarca, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarTodosModelos = () =>
  axios.get(api_url + "funcionario/modeloveiculo/", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarCategoriasNoli = () =>
  axios.get(api_url + "funcionario/categoriaveiculonoli/", { headers: headers })
    .then(response => response)
    .catch(error => error)

/*
 * tipoBusca: 0 tipo de veículo / 1 marca
 * segundo(parametro): se tipoBusca = 0: tipo de veiculo / se = 1: marca e modelo / se = 2: carroceria
 * valor: valor a ser buscado
 */
export const buscarTransportadoresPor = (tipoBusca, segundo, valor, page = 1) =>
  axios.get(api_url + "funcionario/transportadores/veiculo/" + page + "/" + tipoBusca + "/" + segundo + "/" + valor, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarTransportadoresPorServicos = (tipos, page = 1) =>
  axios.get(api_url + "funcionario/transportadores/tiposervicos/" + page, {
    params: {
      tipo_servicos: tipos.map(t => { return { id: t } })
    },
    headers: headers
  })
    .then(response => response)
    .catch(error => error)

export const buscarTransportadoresPorRegiao = (queryParams, page = 1) =>
  axios.get(api_url + "funcionario/transportadores/region/" + page, {
    params: queryParams,
    headers: headers
  })
    .then(response => response)
    .catch(error => error)

export const buscarTransportadoresPorCategoriasdeContato = (category_id: number, page = 1) =>
  axios.get(api_url + "funcionario/transportadores/contact/categories/" + page, {
    params: { id: category_id },
    headers: headers
  })
    .then(response => response)
    .catch(error => error)

export const buscarDetalhesTransportador = (idTransportador) =>
  axios.get(api_url + "funcionario/cadastrocompletotransportador/" + idTransportador, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const buscarCoresVeiculos = () =>
  axios.get(api_url + "funcionario/corveiculo/", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarServicosTransportador = () =>
  axios.get(api_url + "funcionario/tiposervicos/", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const cadastrotransportadorveiculo = (payload) =>
  axios.post(api_url + "funcionario/cadastrotransportadorveiculo", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const transportesAbertos = (payload) =>
  axios.get(api_url + "funcionario/transportesabertos", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarDetalhesCliente = (idCliente) =>
  axios.get(api_url + "funcionario/cadastrousuario/" + idCliente, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const buscarOrcamento = (idOrcamento) =>
  axios.get(api_url + "funcionario/orcamento/" + idOrcamento, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const buscarItens = () =>
  axios.get(api_url + "item/admin", { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const calcularOrcamento = (payload) =>
  axios.post(api_url + "admin/transports/estimate", payload, { headers: headers })
    .then((response: AxiosResponse) => response)
    .catch(error => error)

    export const transportUpdate = (payload:{},id: mumber) =>
    axios.put(api_url + "admin/transport/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const adicionarItem = (payload) =>
  axios.post(api_url + "item", payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarItem = (id, payload) =>
  axios.put(api_url + "item/" + id, payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const apagarItem = (id) =>
  axios.delete(api_url + "item/" + id, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const adicionarCarroceria = (payload) =>
  axios.post(api_url + "funcionario/carroceriaveiculo", payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarCarroceria = (payload, id) =>
  axios.put(api_url + "funcionario/carroceriaveiculo/" + id, payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const adicionarTipoVeiculo = (payload) =>
  axios.post(api_url + "funcionario/tipoveiculo", payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarTipoVeiculo = (payload, id) =>
  axios.put(api_url + "funcionario/tipoveiculo/" + id, payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const adicionarMarca = (payload) =>
  axios.post(api_url + "funcionario/marcaveiculo", payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarMarca = (payload, id) =>
  axios.put(api_url + "funcionario/marcaveiculo/" + id, payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const adicionarModelo = (payload) =>
  axios.post(api_url + "funcionario/modeloveiculo", payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarModelo = (payload, id) =>
  axios.put(api_url + "funcionario/modeloveiculo/" + id, payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const adicionarCategoria = (payload) =>
  axios.post(api_url + "funcionario/categoriaveiculonoli", payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarCategoria = (payload, id) =>
  axios.put(api_url + "funcionario/categoriaveiculonoli/" + id, payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarOrdemCategoria = (payload) =>
  axios.put(api_url + "funcionario/categoriaveiculonoli/ordem", payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarOrdemMarca = (payload) =>
  axios.put(api_url + "funcionario/marcaveiculo/ordem", payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarOrdemTipo = (payload) =>
  axios.put(api_url + "funcionario/tipoveiculo/ordem", payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const buscarTaxasOrcamento = () =>
  axios.get(api_url + "orcamento/taxaorcamento", { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const adicionarTaxaOrcamento = (payload) =>
  axios.post(api_url + "orcamento/taxaorcamento", payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarTaxaAtual = (payload) =>
  axios.put(api_url + "orcamento/taxaorcamento/" + payload.id, payload, { headers: headers })
    .then(response => response.data)
    .catch(error => error)



export const cadastrarFuncionario = (payload) =>
  axios.post(api_url + "funcionario/cadastrofuncionario", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarFuncionariosPor = (campo = null, valor = null) => {
  let url = api_url + "funcionario/cadastrofuncionario"
  if (campo !== null && valor !== null) url += '/' + campo + '/' + valor
  return axios.get(url, { headers: headers })
    .then(response => response)
    .catch(error => error)
}

export const alterarFuncionario = (id, payload) =>
  axios.put(api_url + "funcionario/cadastrofuncionario/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const alterarSenha = (payload) =>
  axios.put(api_url + "funcionario/alterarsenha", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarPerfil = () =>
  axios.get(api_url + "funcionario/perfil", { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const alterarPerfil = (payload) =>
  axios.put(api_url + "funcionario/perfil", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarGruposPermissao = () =>
  axios.get(api_url + "funcionario/gruposdeacesso", { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const buscarPermissoesAcesso = () =>
  axios.get(api_url + "funcionario/permissoesdeacesso", { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const cadastrarGrupoPermissao = (payload) =>
  axios.post(api_url + "funcionario/grupodeacesso", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const cadastrarPermissaoAcesso = (payload) =>
  axios.post(api_url + "funcionario/permissoesdeacesso", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const detalhesGrupoAcesso = (id) =>
  axios.get(api_url + "funcionario/grupopermissoes/true/" + id, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const detalhesPermissoesAcesso = (id) =>
  axios.get(api_url + "funcionario/grupopermissoes/false/" + id, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const grupoPermissaoFuncionario = (id) =>
  axios.get(api_url + "funcionario/grupofuncionario/false/" + id, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const funcionariosGrupoPermissao = (id) =>
  axios.get(api_url + "funcionario/grupofuncionario/true/" + id, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const funcionariosPorPermissao = (id) =>
  axios.get(api_url + "funcionario/funcionariospermissao/" + id, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const alterarPermissaoAcesso = (payload, id) =>
  axios.put(api_url + "funcionario/permissoesdeacesso/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const alterarGrupoAcesso = (payload, id) =>
  axios.put(api_url + "funcionario/grupodeacesso/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const alterarGrupoAcessoFuncionario = (payload, id) =>
  axios.put(api_url + "funcionario/grupofuncionario/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarTodosCupons = () =>
  axios.get(api_url + "funcionario/cupom", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarCuponsPorCampo = (campo, valor) =>
  axios.get(api_url + "funcionario/cupom/" + campo + "/" + valor, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const alterarCupom = (id, payload) =>
  axios.put(api_url + "funcionario/cupom/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarTiposCupom = () =>
  axios.get(api_url + "funcionario/cupom/tipodesconto", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const cadastrarCupom = (payload) =>
  axios.post(api_url + "funcionario/cupom", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarServicosProtecao = () =>
  axios.get(api_url + "servico/admin/protecao/item", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const alterarServicosProtecao = (payload) =>
  axios.put(api_url + "servico/admin/protecao/item", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const cadastrarServicosProtecao = (payload) =>
  axios.post(api_url + "servico/admin/protecao/item", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)


export const buscarServicosMontagemDesmontagem = () =>
  axios.get(api_url + "services/assembly-disassembly/admin", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const alterarServicosMontagemDesmontagem = (payload, id) =>
  axios.put(api_url + "services/assembly-disassembly/admin/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const alterarOrdemServicosMontagemDesmontagem = (payload) =>
  axios.post(api_url + "services/assembly-disassembly/admin/ordem", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const cadastrarServicosMontagemDesmontagem = (payload) =>
  axios.post(api_url + "services/assembly-disassembly/admin", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)


export const atualizarPagamento = (pagamento_id, payload) =>
  axios.put(api_url + "payments/" + pagamento_id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const atualizarPagamentoPix = (payment_id, payload) =>
  axios.put(api_url + "payments/pix/" + payment_id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarEstados = () =>
  axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados", { headers: headers, params: { orderBy: 'nome' } })
    .then(response => response)
    .catch(error => error)

export const buscarCidadesPorEstados = (estado_uf: string) =>
  axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado_uf}/municipios`)
    .then(response => response)
    .catch(error => error)

export const buscarBairrosPorCidade = (cidade_id: id) =>
  axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${cidade_id}/distritos`)
    .then(response => response)
    .catch(error => error)


export const buscarCategoriasDeContatoDeTransportadores = () =>
  axios.get(api_url + "funcionario/contact/categories/transporters", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const criarCategoriasDeContatoDeTransportadores = (payload: ICreateTransporterContactCategoryDTO) =>
  axios.post(api_url + "funcionario/contact/categories/transporters", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const atualizarCategoriasDeContatoDeTransportadores = (payload: ICreateTransporterContactCategoryDTO, id: number) =>
  axios.put(api_url + "funcionario/contact/categories/transporters" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const associarCategoriasDeContatoDeTransportadores = (payload: ICreateTransporterContactCategoryToTransporterDTO) =>
  axios.post(api_url + "funcionario/contact/categories/transporters/associate", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const atualizarOrcamento = (payload: ITransportUpdateByAdminDTO, id: number) =>
  axios.put(api_url + "funcionario/transport/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const buscarImagem = (payload) => window.open(api_url + "funcionario/getimage/?url=" + payload, '_blank');


//Caixas
export const createRentBoxPackages = (payload: {}) =>
  axios.post(api_url + "admin/services/rent-box/packages", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const updateRentBoxPackages = (payload: {}, package_id: number) =>
  axios.put(api_url + "admin/services/rent-box/package/" + package_id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const associateScheduleDatesToPackages = (periods: {}, package_id: number) =>
  axios.put(api_url + "admin/services/rent-box/associate/schedule/dates/package/" + package_id, periods, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const rentBoxPackages = (query?: IFindRentBoxPackage) =>
  axios.get(api_url + "admin/services/rent-box/packages", { params: query, headers: headers })
    .then(response => response)
    .catch(error => error)


export const rentBoxPackagesDates = (package_id: number) =>
  axios.get(api_url + "admin/services/rent-box/schedule/dates/by-package/" + package_id, { headers: headers })
    .then(response => response)
    .catch(error => error)



export const rentBoxOrdersById = (id: number) =>
  axios.get(api_url + "admin/services/rent-box/orders/" + id, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const rentBoxOrdersOpen = () =>
  axios.get(api_url + "admin/services/rent-box/orders/open", { headers: headers })
    .then(response => response)
    .catch(error => error)


export const rentBoxOrdersClosed = () =>
  axios.get(api_url + "admin/services/rent-box/orders/closed", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const rentBoxOrdersByUserId = (idCliente) =>
  axios.get(api_url + "admin/services/rent-box/orders/user/" + idCliente, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const rentBoxOrdersOpenOrClosedByUserId = (idCliente, open = true) =>
  axios.get(api_url + "admin/services/rent-box/orders/open/" + open + "/user/" + idCliente, { headers: headers })
    .then(response => response.data)
    .catch(error => error)

export const rentBoxOrdersCanceledByUserId = (idCliente) =>
  axios.get(api_url + "admin/services/rent-box/orders/canceled/user/" + idCliente, { headers: headers })
    .then(response => response.data)
    .catch(error => error)


export const rentBoxOrdersOfUsers = () =>
  axios.get(api_url + "admin/services/rent-box/orders/users/teste", { headers: headers })
    .then(response => response)
    .catch(error => error)

export const rentBoxOrdersOpenOfUsers = (open: boolean) =>
  axios.get(api_url + "admin/services/rent-box/orders/open/users/" + open, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const rentBoxOrdersCanceledOfUsers = () => {
  return axios.get(api_url + "admin/services/rent-box/orders/canceled/users", { headers: headers })
    .then(response => response)
    .catch(error => error)
}

export const rentBoxScheduleDates = (query?: {}) => {
  return axios.get(api_url + "admin/services/rent-box/schedule/dates", { params: query, headers: headers })
    .then(response => response)
    .catch(error => error)
}
export const createRentBoxScheduleDates = (payload: {}) => {
  return axios.post(api_url + "admin/services/rent-box/schedule/dates", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)
}
export const updateRentBoxScheduleDates = (payload: {}, id: number) => {
  return axios.put(api_url + "admin/services/rent-box/schedule/dates/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)
}

export const rentBoxScheduleTimes = () => {
  return axios.get(api_url + "admin/services/rent-box/schedule/times", { headers: headers })
    .then(response => response)
    .catch(error => error)
}
export const createRentBoxScheduleTimes = (payload: {}) => {
  return axios.post(api_url + "admin/services/rent-box/schedule/times", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)
}
export const updateRentBoxScheduleTimes = (payload: {}, id: number) => {
  return axios.put(api_url + "admin/services/rent-box/schedule/times/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)
}

export const rentBoxScheduleTimesByDateId = (date_id: number) => {
  return axios.get(api_url + "admin/services/rent-box/schedule/times/date/" + date_id, { headers: headers })
    .then(response => response)
    .catch(error => error)
}

//rent box control
export const findRentBoxStockControl = (payload: {}) => {
  return axios.get(api_url + "admin/services/rent-box/stock/control", { params: payload, headers: headers })
    .then(response => response)
    .catch(error => error)
}
export const createRentBoxStockControl = (payload: {}) => {
  return axios.post(api_url + "admin/services/rent-box/stock/control", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)
}
export const updateRentBoxStockControl = (payload: {}, id: number) => {
  return axios.put(api_url + "admin/services/rent-box/stock/control/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)
}


export const updateRentBoxScheduleToOrder = (payload: {}, id: number) => {
  return axios.put(api_url + "admin/services/rent-box/orders/schedule/" + id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)
}


//Products for sale
export const createProductsForSale = (payload: {}) => {
  return axios.post(api_url + "admin/services/products/sale", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)
}
export const updateProductsForSale = (product_id: number, payload: {}) => {
  return axios.put(api_url + "admin/services/products/sale/" + product_id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)
}
export const findProductsForSale = (query: {}) => {
  return axios.get(api_url + "admin/services/products/sale", { params: query, headers: headers })
    .then(response => response)
    .catch(error => error)
}
export const findAllProductsForSale = () => {
  return axios.get(api_url + "admin/services/products/sale/all", { headers: headers })
    .then(response => response)
    .catch(error => error)
}

export const selectTransporterToTransportRentBox = (payload: {}) =>
  axios.post(api_url + "admin/services/rent-box/orders/transport/transporter",
    payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const updateRentBoxStatus = (payload: {}, transport_id: number) =>
  axios.put(api_url + "admin/services/rent-box/orders/transport/status/" + transport_id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const updateRentBoxTransportOrder = (payload: {}, transport_id: number) =>
  axios.put(api_url + "admin/services/rent-box/orders/transport/" + transport_id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)


export const createServicePartner = (payload: {}) =>
  axios.post(api_url + "admin/services/partners", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const findServicePartner = (query: {}) =>
  axios.get(api_url + "admin/services/partners", { params: query, headers: headers })
    .then(response => response)
    .catch(error => error)

export const findServicePartnerByLike = (key: string, value: any) =>
  axios.get(api_url + "admin/services/partners/" + key + '/' + value, { headers: headers })
    .then(response => response)
    .catch(error => error)


export const updateServicePartner = (payload: {}, partner_id: number) =>
  axios.put(api_url + "admin/services/partners/" + partner_id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const createServicePartnerCategory = (payload: {}) =>
  axios.post(api_url + "admin/services/partners/category", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const findServicePartnerCategory = (query: {}) =>
  axios.get(api_url + "admin/services/partners/categories", { params: query, headers: headers })
    .then(response => response)
    .catch(error => error)

export const updateServicePartnerCategory = (payload: {}, partner_id: number) =>
  axios.put(api_url + "admin/services/partners/category/" + partner_id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)




export const createTransportsBlockScheduleDates = (payload: {}) =>
  axios.post(api_url + "admin/transports/schedule/dates", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const findTransportsBlockScheduleDates = (query: {}) =>
  axios.get(api_url + "admin/transports/schedule/dates", { params: query, headers: headers })
    .then(response => response)
    .catch(error => error)

export const updateTransportsBlockScheduleDates = (payload: {}, date_id: number) =>
  axios.put(api_url + "admin/transports/schedule/dates/" + date_id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)


//HELPERS
export const createTransporterHelpers = (payload: {}) =>
  axios.post(api_url + "admin/transporter/helpers", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)

export const findTransporterHelpers = (query: {}) =>
  axios.get(api_url + "admin/transporter/helpers", { params: query, headers: headers })
    .then(response => response)
    .catch(error => error)
export const findTransporterHelpersByTransporter = (query: {}) =>
  axios.get(api_url + "admin/transporter/helpers/transporters", { params: query, headers: headers })
    .then(response => response)
    .catch(error => error)

export const updateTransporterHelpers = (payload: {}, transporter_helper_id: number) =>
  axios.put(api_url + "admin/transporter/helpers/" + transporter_helper_id, payload, { headers: headers })
    .then(response => response)
    .catch(error => error)


export const createTransporterHelpersToTransport = (payload: {}) =>
  axios.post(api_url + "admin/transporter/helpers/transport", payload, { headers: headers })
    .then(response => response)
    .catch(error => error)



//Transporters operation identifier
  export const createTransportersOperationIdentifier = (payload: {}) : AxiosResponse =>
axios.post(api_url + "admin/transporters/operations/identifier", payload, { headers: headers })
  .then(response => response)
  .catch(error => error)

export const findTransportersOperationIdentifier = (query: {}) : AxiosResponse=>
axios.get(api_url + "admin/transporters/operations/identifier", { params: query, headers: headers })
  .then(response => response)
  .catch(error => error)

export const updateTransportersOperationIdentifier = (payload: {}, operation_id: number) : AxiosResponse =>
axios.put(api_url + "admin/transporters/operations/identifier/" + operation_id, payload, { headers: headers })
  .then(response => response)
  .catch(error => error)


export interface IFindRentBoxPackage {
  with_period_dates?: boolean,
  with_period_times?: boolean,

}

export interface ITransportUpdateByAdminDTO {
  date?: number,
  date_change?: number,
  value?: number,
  company_fee?: number,
  number_helpers?: number,
  transport_start?: boolean,
  time_finalized?: number,
  transport_cancelled?: boolean,
  info_details?: string,
  time_started?: number,
  transport_fee_id?: number
}
export interface ICreateTransporterContactCategoryToTransporterDTO {
  category_id: number;
  transporter_id: number;
  available?: boolean;
  description?: string;
}

export interface ICreateTransporterContactCategoryDTO {
  description?: string;
  type?: string;
  color_flag?: number;
  order?: number;
}
