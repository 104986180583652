import { combineReducers } from "redux"


const SET_PAGE_TITLE = "SET_PAGE_TITLE"
const SET_EXPIRATION = "SET_EXPIRATION"
const SET_TRANSPORTADORES_FILTERS = "SET_TRANSPORTADORES_FILTERS"
const SEND_MESSAGE = "SEND_MESSAGE"
const RESET_MESSAGE = "RESET_MESSAGE"
const ENABLE_DIMMER = "ENABLE_DIMMER"
const DISABLE_DIMMER = "DISABLE_DIMMER"
const SET_DADOS_VEICULOS = "SET_DADOS_VEICULOS"
const FETCH_TAXAS = "FETCH_TAXAS"
const FETCH_RENT_BOX_STOCK_CONTROL = "FETCH_RENT_BOX_STOCK_CONTROL"
const FETCH_PROTECAO_ITENS = "FETCH_PROTECAO_ITENS"
const FETCH_MONTAGEM_DESMONTAGEM = "FETCH_MONTAGEM_DESMONTAGEM"
const SET_PERMISSIONS_DATA = "SET_PERMISSIONS_DATA"
const FETCH_TIPOS_CUPOM = "FETCH_TIPOS_CUPOM"
export const FETCH_TRANSPORTERS_CONTACT_CATEGORY = "FETCH_TRANSPORTERS_CONTACT_CATEGORY"

const _pageInfo = {
  title: "Painel Noli",
  message: { props: { color: "blue" } },
  dimmed: false
}

function pageInfo(state = _pageInfo, action) {
  switch (action.type) {
    case SET_PAGE_TITLE:
      document.title = action.payload
      return {
        ...state,
        title: action.payload
      }
    case SET_EXPIRATION:
      return {
        ...state,
        auth_expiration: action.payload
      }
    case SET_TRANSPORTADORES_FILTERS:
      return {
        ...state,
        transp_filters: action.payload
      }
    case SET_DADOS_VEICULOS:
      return {
        ...state,
        dados_veiculos: action.payload
      }
    case FETCH_TIPOS_CUPOM:
      return {
        ...state,
        tipos_cupom: action.payload
      }
    case FETCH_TAXAS:
      return {
        ...state,
        taxas: action.payload
      }
    case FETCH_RENT_BOX_STOCK_CONTROL:
      return {
        ...state,
        stock_control: action.payload
      }
    case FETCH_PROTECAO_ITENS:
      return {
        ...state,
        protecao_itens: action.payload
      }
    case FETCH_MONTAGEM_DESMONTAGEM:
      return {
        ...state,
        montagem_desmontagem: action.payload
      }
    case SEND_MESSAGE:
      return {
        ...state,
        message: action.payload
      }
    case RESET_MESSAGE:
      return {
        ...state,
        message: { props: { color: "blue", header: "Bom trabalho.." } },
        dimmed: false
      }
    case ENABLE_DIMMER:
      return {
        ...state,
        dimmed: true,
        visibleDim: action.payload
      }
    case DISABLE_DIMMER:
      return {
        ...state,
        dimmed: false,
        visibleDim: action.payload 
      }
    case SET_PERMISSIONS_DATA:
      return {
        ...state,
        permissoes: action.payload
      }
    default:
      document.title = state.title
      return state
  }
}

const FETCH_CLIENTES_ORCAMENTOS = "FETCH_CLIENTES_ORCAMENTOS"

function clientes(state = [], action) {
  switch (action.type) {
    case FETCH_CLIENTES_ORCAMENTOS:
      return action.payload
    default:
      return state
  }
}

const FETCH_CLIENTES_RENT_BOX = "FETCH_CLIENTES_RENT_BOX"

function usersToRentbox(state = [], action) {
  switch (action.type) {
    case FETCH_CLIENTES_RENT_BOX:
      return action.payload
    default:
      return state
  }
}

const FETCH_SEARCHED_TRANSPORTADORES = "FETCH_SEARCHED_TRANSPORTADORES"
const FETCH_AGREE_TRANSPORTADORES = "FETCH_AGREE_TRANSPORTADORES"

function transportadores(state = {}, action) {
  switch (action.type) {
    case FETCH_AGREE_TRANSPORTADORES:
      return {
        ...state,
        [action.agree]: action.payload
      }
    case FETCH_SEARCHED_TRANSPORTADORES:
      return {
        ...state,
        searched: action.payload
      }
    default:
      return state;
  }
}

const FETCH_ITENS = "FETCH_ITENS"

function itens(state = [], action) {
  switch (action.type) {
    case FETCH_ITENS:
      return action.payload
    default:
      return state;
  }
}

const FETCH_PRODUCTS_FOR_SALE = "FETCH_PRODUCTS_FOR_SALE"

function productsForSale(state = [], action) {
  switch (action.type) {
    case FETCH_PRODUCTS_FOR_SALE:
      return action.payload
    default:
      return state;
  }
}

const FETCH_USER = "FETCH_USER"
const SET_PERMISSIONS = "SET_PERMISSIONS"
const LOGOUT_USER = "LOGOUT_USER"

const _user = {
  nome: "",
  data_nascimento: '',
  permissions: {}
}

function user(state = _user, action) {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        ...action.payload
      }
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
        permissionsLoaded: true
      }
    case LOGOUT_USER:
      return _user
    default:
      return state;
  }
}

const contactCategory = {
  data: []
}

function transportersContactCategory (state = contactCategory, action) {
  switch (action.type) {
    case FETCH_TRANSPORTERS_CONTACT_CATEGORY:
      return {
        ...state,
        data: action.payload
      }
    default:
      return state;
  }
}

export default combineReducers({
  itens, pageInfo, clientes, transportadores, user, transportersContactCategory,usersToRentbox,productsForSale
}
)
