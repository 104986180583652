import React, { useState } from 'react'
import { Table, Segment, Header, Button, Confirm, Form, Dropdown, Label, Grid } from 'semantic-ui-react'
import SearchFuncionariosFilter from '../SearchFuncionariosFilter'
import * as masks from '../../utils/masks'
import * as noliAPI from '../../utils/noliAPI'
import _ from 'lodash'
import formatDate from '../../utils/formatDate'
import { AxiosResponse } from 'axios'

const RentBoxPackagesSchedule = (props) => {

    const [loading, setLoading] = React.useState(false)
    const [open, openConfirm] = React.useState(false)
    const [list, setList] = React.useState(props.list)
    const [selected, setSelected] = React.useState({})
    const [prevGroup, setPrevGroup] = React.useState("")
    const [dates, setDates] = useState([])
    const [dateDeliveryInput, setDateDeliveryInput] = useState("")

    React.useEffect(() => {

        setLoading(props.loading)
        setList(props.list)
    }, [props.list, props.loading]);


    const openModal = (selected) => {

        setDates(selected.period_dates)
        setSelected({
            ...selected
        })
        openConfirm(true)
    }

    const changeGroup = (e, { value }) => setSelected({ ...selected, group: value })


    const handleAddDates = (e, { value }) => {
        const exists = dates.find((item) => item.id === value.id)
        if (!exists) {
            setDates((prevState) => [...prevState, value])
        }
    }


    const handleConfirm = () => {
        setLoading(true)
        noliAPI.associateScheduleDatesToPackages({ periods: dates }, selected.id).then((resp: AxiosResponse) => {
            setLoading(false)
            setDates([])
            setSelected({})
            openConfirm(false)
            if (resp.status === 200) {
                props.handleCallback(resp.status)
            }
        })
        //atualizar datas do pacote
    }

    const handleCancel = () => {
        if (!loading) {
            setDates([])
            setSelected({})
            openConfirm(false)
        }

    }

    const clearDates = () => setDates([])

    const handleRemoveDate = (e, { value }) => {
        let tagsPrev = [...dates]
        const index = dates.indexOf(value)
        if (index !== -1)
            tagsPrev.splice(index, 1)
        setDates(tagsPrev)
    }

    const RenderDatesTags = () => {
        return dates.map((t, i) =>
            <Label
                as='a'
                onClick={handleRemoveDate}
                value={t}
                key={i}
                content={formatDate.periodsRentBox(t.start_date, t.end_date)}
                color="purple"
                onRemove={handleRemoveDate}
                size="small"
            />)
    }


    return (
        <Segment loading={loading}>
            <Header as="h4" content="Associar pacotes com períodos" icon="boxes" />
            <Table selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="center">ID</Table.HeaderCell>
                        <Table.HeaderCell>Titulo</Table.HeaderCell>
                        <Table.HeaderCell>Descrição</Table.HeaderCell>
                        <Table.HeaderCell>Períodos</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {list.length === 0 ? <Table.Row><Table.Cell colSpan="5">Nenhum pacote retornado da busca</Table.Cell></Table.Row>
                        : list.map((f, i) =>
                            <Table.Row key={f.id + "-" + i} positive>
                                <Table.Cell>{f.id}</Table.Cell>
                                <Table.Cell>{f.title}</Table.Cell>
                                <Table.Cell>{f.description}</Table.Cell>
                                {f.period_dates && f.period_dates.length > 0 &&
                                    <Table.Cell textAlign="left">
                                        {
                                            f.period_dates.map((item, index) => {
                                                return (

                                                    <Label
                                                        as='a'
                                                        value={item}
                                                        key={item.id + "-" + index}
                                                        content={formatDate.periodsRentBox(item.start_date, item.end_date)}
                                                        color="purple"
                                                        size="small"
                                                    />

                                                )
                                            })
                                        }
                                    </Table.Cell>
                                }
                                <Table.Cell textAlign="center">
                                    <Button
                                        size="mini"
                                        icon="edit"
                                        primary
                                        onClick={() => openModal(f)}
                                        disabled={!props.user.permissions[props.permission].update}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )
                    }
                </Table.Body>
            </Table>
            <Confirm
                cancelButton="Cancelar"
                confirmButton={<Button
                    //disabled={_.isEqual(prevGroup, selected.group)  !selected.group||}
                    disabled={false}
                    primary
                    content={"Atualizar pacote"}
                />}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                open={open}
                size="mini"
                header="Associar datas aos pacotes"
                content={
                    <Segment basic loading={loading}>
                        <Header as="h2" textAlign="center" content={" (#" + selected.id + ") " + selected.title} />
                        <Form>
                            <Form.Field
                                label={"Datas"}
                                control={Dropdown}
                                selection
                                fluid
                                style={{ margin: '7px 0' }}
                                options={props.dates.map((item, index) => {
                                    return { key: index + '-' + index, text: formatDate.periodsRentBox(item.start_date, item.end_date), value: item }
                                })}

                                value={selected}
                                onChange={handleAddDates}
                                upward
                            />
                            <Grid.Column mobile="16" tablet="16" computer="8">
                                <RenderDatesTags />
                                {dates.length > 0 &&
                                    <Label
                                        as='a'
                                        onClick={clearDates}
                                        content="Limpar lista"
                                        color="red"
                                        onRemove={clearDates}
                                        size="small"
                                    />
                                }
                            </Grid.Column>
                        </Form>
                    </Segment>
                }
            />
        </Segment>
    )
}

export default RentBoxPackagesSchedule
