import React from 'react'
import {connect} from 'react-redux'
import * as actions from '../../utils/actions'
import {Grid, Segment, Header, Form, Button, Label, Icon, Table, Checkbox, Confirm} from 'semantic-ui-react'
import {DateInput} from 'semantic-ui-calendar-react'
import * as noliAPI from '../../utils/noliAPI'
import formatDate from '../../utils/formatDate'
import moment from 'moment'
import _ from 'lodash'
import { money } from '../../utils/helpers'

const _form = {
  codigo: "",
  descricao: "",
  valor: 0,
  quantidade_total: 1,
  quantidade_cliente: 1,
  ativo: 1,
  data_inicio: moment().format('DD/MM/YYYY'),
  data_fim: moment().format('DD/MM/YYYY'),
  tipo_id: 1,
  id: 0
}

const Cupons = (props) => {

  const [loading, setLoading] = React.useState(false)
  const [filter, setFilter] = React.useState({campo: 0, valor: ""})
  const [list, setList] = React.useState([])
  const [open, toggleConfirm] = React.useState(false)
  const [modalProps, setModalProps] = React.useState({})
  const [form, setForm] = React.useState(_form)

  React.useEffect(() => {
    setLoading(true)
    noliAPI.buscarTodosCupons().then(res => {
      setList(res.data.data)
      setLoading(false)
    })
  }, []);

  if(props.title === "Painel Noli") props.setPageTitle('Cadastrar e Alterar Cupons de Desconto')

  if(!props.tipos_cupom) props.fetchTiposCupom()

  const changeFilter = (e,{name, value}) => setFilter({...filter, [name]: value})

  const toggleActive = (e, {value, checked}) => {
    e.stopPropagation()
    setLoading(true)
    noliAPI.alterarCupom(value, {ativo: checked}).then(() =>
      noliAPI.buscarTodosCupons().then(res => {
        setList(res.data.data)
        setLoading(false)
      })
    )
  }

  const submitFilter = () => {
    setLoading(true)
    if(filter.campo === 0){
      noliAPI.buscarTodosCupons().then(res => {
        setList(res.data.data)
        setLoading(false)
      })
    }else{
      noliAPI.buscarCuponsPorCampo(filter.campo, filter.valor).then(res => {
        if(res.status === 200){
          setList(res.data.data)
        }else{
          props.sendMessage([], 'Não foram encontrados cupons correspondentes à sua busca')
        }
        setLoading(false)
      })
    }
  }

  const openNewConfirm = () => {
    setModalProps({
      header: "Cadastrar novo Cupom",
      type: "new",
      selected: {}
    })
    setForm(_form)
    toggleConfirm(true)
  }

  const selectCupom = (cupom) => {
    if(!loading){
      const toPick = ['codigo', 'ativo', 'data_fim', 'data_inicio', 'descricao', 'quantidade_cliente', 'quantidade_total', 'tipo_id', 'valor', 'id']
      let formValues = {
        ...cupom,
        data_inicio: moment.unix(cupom.data_inicio).format('DD/MM/YYYY'),
        data_fim: moment.unix(cupom.data_fim).format('DD/MM/YYYY'),
      }
      setModalProps({
        header: "Editar Cupom",
        type: "edit",
        selected: _.pick(formValues, toPick)
      })
      setForm(_.pick(formValues, toPick))
      toggleConfirm(true)
    }
  }

  const submitNew = () => {
    const payload = {
      ...form,
      data_fim: moment(form.data_fim, 'DD/MM/YYYY').unix(),
      data_inicio: moment(form.data_inicio, 'DD/MM/YYYY').unix(),
      ativo: form.ativo === 1
    }
    setLoading(true)
    noliAPI.cadastrarCupom(payload).then(r => { //TODO RESPONSE status_code
      noliAPI.buscarTodosCupons().then(res => {
        setList(res.data.data)
        toggleConfirm(false)
        setLoading(false)
        props.sendMessage([], "Cupom criado com sucesso!", {color:'blue', icon: "check circle"}, false)
      })
    })
  }

  const submitEdit = () => {
    const payload = {
      ...form,
      data_fim: moment(form.data_fim, 'DD/MM/YYYY').unix(),
      data_inicio: moment(form.data_inicio, 'DD/MM/YYYY').unix(),
      ativo: form.ativo === 1
    }
    setLoading(true)
    noliAPI.alterarCupom(form.id,payload).then(r => { //TODO RESPONSE status_code
      noliAPI.buscarTodosCupons().then(res => {
        setList(res.data.data)
        toggleConfirm(false)
        setLoading(false)
        props.sendMessage([], "Cupom alterado com sucesso!", {color:'blue', icon: "check circle"}, false)
      })
    })
  }

  return(
    <Grid padded>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Segment>
            <Header as="h4" icon="search" content="Buscar Cupons" />
            <Form onSubmit={submitFilter}>
              <Form.Group>
                <Form.Field
                  label="Campo"
                  control={Form.Select}
                  options={fieldOpts}
                  fluid
                  width="5"
                  placeholder="Selecione o campo"
                  name="campo"
                  value={filter.campo}
                  onChange={changeFilter}
                />
                <Form.Field
                  label="Palavra-chave"
                  control={Form.Input}
                  placeholder="Digite a palavra chave"
                  width="7"
                  fluid
                  name="valor"
                  value={filter.valor}
                  disabled={filter.campo === 0}
                  onChange={changeFilter}
                />
                <Form.Field
                  control={Form.Button}
                  type="submit"
                  label="Buscar"
                  content="Buscar Cupom"
                  width="4"
                  fluid
                  primary
                  icon="search"
                  labelPosition="left"
                  disabled={(filter.campo !== 0 && filter.valor === "") || loading }
                />
              </Form.Group>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Segment loading={loading}>
            <Header as="h3">
              <Button
                as={Label}
                floated="right"
                content="Novo Cupom"
                size="big"
                color="blue"
                icon="add"
                onClick={openNewConfirm}
              />
            <Icon name="ticket" />
              <Header.Content>Cupons Noli</Header.Content>
            </Header>
            <Table selectable color="violet">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center">ID</Table.HeaderCell>
                  <Table.HeaderCell>Código</Table.HeaderCell>
                  <Table.HeaderCell>Período</Table.HeaderCell>
                  <Table.HeaderCell>Descrição</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Valor</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Qtd. Total</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Qtd. Cliente</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Ativo</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {list.length > 0
                  ? list.map((c, i) =>
                    <Table.Row
                      key={i + "-" + c.codigo}
                      className="selectable-row"
                      positive={c.ativo === 1}
                      negative={c.data_fim && c.data_fim < Math.round((new Date()).getTime() / 1000)}
                      onClick={() => selectCupom(c)}
                    >
                      <Table.Cell textAlign="center">{c.id}</Table.Cell>
                      <Table.Cell>
                        <b>{c.codigo}</b><br /><small>Criado em: {formatDate.convertDateTime(c.created_at)}</small>
                      </Table.Cell>
                      <Table.Cell>
                        <small>Início: {formatDate.getDateUnixTimestamp(c.data_inicio)}<br />
                        Fim: { c.data_fim ? formatDate.getDateUnixTimestamp(c.data_fim) : "Indetermidado"}
                        </small>
                      </Table.Cell>
                      <Table.Cell>{c.tipo_descricao}<br /><small>{c.descricao}</small></Table.Cell>
                      <Table.Cell textAlign="center"><b>{money(c.valor)}</b></Table.Cell>
                      <Table.Cell textAlign="center">{c.quantidade_total ? c.quantidade_total : "ilimitado"}</Table.Cell>
                      <Table.Cell textAlign="center">{c.quantidade_cliente}</Table.Cell>
                      <Table.Cell textAlign="center">
                        <Checkbox
                          checked={c.ativo === 1}
                          onChange={toggleActive}
                          value={c.id}
                          disabled={c.data_fim < Math.round((new Date()).getTime() / 1000)}
                        />
                      </Table.Cell>
                    </Table.Row>)
                  : <Table.Row error>
                      <Table.Cell colSpan='8'>Cupons não encontrados</Table.Cell>
                    </Table.Row>
                }
              </Table.Body>
            </Table>
            <Confirm
              open={open}
              header={modalProps.header}
              size="large"
              content={<FormModal
                loading={loading}
                form={form}
                tiposCupom = {props.tipos_cupom}
                onChange={setForm}
              />}
              cancelButton="Cancelar"
              confirmButton={modalProps.type === "new"
                ? <Button
                    content="Novo Cupom"
                    disabled={loading || _.isEqual(form, _form) || form.valor <= 0 || form.codigo === "" || form.descricao === ""}
                    onClick={submitNew}
                  />
                : <Button
                    content="Alterar Cupom"
                    disabled={loading || _.isEqual(form, modalProps.selected) || form.valor <= 0 || form.codigo === "" || form.descricao === ""}
                    onClick={submitEdit}
                  />
              }
              onCancel={() => toggleConfirm(false)}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const mapStateToProps = ({pageInfo}) => pageInfo

export default connect(mapStateToProps, actions)(Cupons)

const fieldOpts = [
  {value: 0, text: "Todos os Cupons"},
  {value: "codigo", text: "Código"},
  {value: "descricao", text: "Descrição"},
  {value: "valor", text: "Valor"},
  {value: "quantidade_total", text: "Quantidade Total"},
  {value: "quantidade_cliente", text: "Quantidade Cliente"}
]

const FormModal = (props) => {
  const { form } = props

  const singleChange = (e, {name, value}) => props.onChange({ ...form, [name]: value })

  const upperCaseChange = (e, {name, value}) => props.onChange({...form, [name]:value.toUpperCase()})

  const checkChange = (e, {name, checked}) => props.onChange({...form, [name]: checked ? 1 : 0})

  return(
    <Segment basic loading={props.loading}>
      <Form>
        <Form.Group>
          <Form.Field
            label="Código"
            control={Form.Input}
            value={form.codigo}
            width="4"
            name="codigo"
            onChange={upperCaseChange}
          />
          <Form.Field
            label="Descrição"
            control={Form.Input}
            value={form.descricao}
            width="8"
            name="descricao"
            onChange={singleChange}
          />
          <Form.Field
            label="Tipo de Cupom"
            control={Form.Select}
            options={props.tiposCupom}
            value={form.tipo_id}
            onChange={singleChange}
            name="tipo_id"
            width="4"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            label="Valor"
            control={Form.Input}
            value={form.valor}
            type="number"
            step='10.0'
            onChange={singleChange}
            name="valor"
            min="0"
          />
          <Form.Field
            label="Início"
            control={DateInput}
            value={form.data_inicio}
            dateFormat={'DD/MM/YYYY'}
            onChange={singleChange}
            name="data_inicio"
            localization="pt-br"
            closable
          />
          <Form.Field
            label="Fim"
            control={DateInput}
            value={form.data_fim}
            dateFormat={'DD/MM/YYYY'}
            onChange={singleChange}
            name="data_fim"
            localization="pt-br"
            closable
          />
        </Form.Group>
        <Form.Group widths='equal' inline>
          <Form.Field
            label="Qtd. Total"
            control={Form.Input}
            value={form.quantidade_total}
            type="number"
            step='10'
            onChange={singleChange}
            name="quantidade_total"
            min="1"
          />
          <Form.Field
            label="Qtd. p/ Cliente"
            control={Form.Input}
            value={form.quantidade_cliente}
            type="number"
            step='1'
            onChange={singleChange}
            name="quantidade_cliente"
            min="1"
          />
          <Form.Checkbox
            checked={form.ativo === 1}
            label="Ativo"
            name="ativo"
            onChange={checkChange}
          />
        </Form.Group>
      </Form>
    </Segment>
  )
}
